import { Table, TextBody, TextHeading } from '@isdd/idsk-ui-kit/index'
import { ReportExport, useList } from '@isdd/metais-common/api/generated/opendata-swagger'
import { formatBytes } from '@isdd/metais-common/components/file-import/fileImportUtils'
import { QueryFeedback } from '@isdd/metais-common/index'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const OpenDataView: React.FC = () => {
    const { data, isLoading, isError } = useList({ reportExportFileSize: 1000 })
    const DOWNLOAD_FILE_LINK = `${import.meta.env.VITE_REST_CLIENT_OPENDATA_TARGET_URL}/files/`

    const { t } = useTranslation()

    const columns: ColumnDef<ReportExport>[] = [
        {
            id: 'name',
            header: t('opendata.tableHeaders.name'),
            accessorFn: (row) => row?.name,
            meta: { getCellContext: (ctx: CellContext<ReportExport, unknown>) => ctx?.row.original.name },
            size: 250,
        },
        {
            id: 'format',
            header: t('opendata.tableHeaders.format'),
            accessorFn: (row) => row?.reportExportFiles?.[0]?.type,
            size: 100,
        },
        {
            id: 'size',
            header: t('opendata.tableHeaders.size'),
            accessorFn: (row) => formatBytes(row?.reportExportFiles?.[0]?.length ?? 0),
            size: 100,
        },
        {
            id: 'file',
            header: t('opendata.tableHeaders.file'),
            cell: (row) => {
                return (
                    <a href={`${DOWNLOAD_FILE_LINK}${row.row.original.reportExportFiles?.[0]?.path}`}>
                        {row.row.original.reportExportFiles?.[0]?.path}
                    </a>
                )
            },
            size: 700,
        },
    ]

    return (
        <QueryFeedback loading={isLoading} error={isError} indicatorProps={{ layer: 'parent' }} withChildren>
            <TextHeading size="XL">{t('opendata.title')}</TextHeading>
            <TextBody>{t('opendata.description')}</TextBody>
            <Table columns={columns} data={data} isLoading={isLoading} />
        </QueryFeedback>
    )
}
