//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * MetaIS Notification Engine
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { useNotificationsSwaggerClient } from '../hooks/useNotificationsSwaggerClient'
export type GetNotificationListElasticParams = {
    eventType?: string
    pageNumber: number
    perPage: number
    onlyUnread?: boolean
    sortBy?: string
    ascending?: boolean
    fulltextSearch?: string
}

export type RemoveNotificationList200 = { [key: string]: unknown }

export type RemoveNotificationListParams = {
    idList?: number[]
}

export type GetNotificationListParams = {
    eventType?: string
    pageNumber: number
    perPage: number
    onlyUnread?: boolean
    sortBy?: string
    ascending?: boolean
}

export type Reindex200 = { [key: string]: unknown }

export type ReindexParams = {
    offset?: number
    limit?: number
}

export type AddNotification200 = { [key: string]: unknown }

export type RemoveNotification200 = { [key: string]: unknown }

export type SetNotificationAsRead200 = { [key: string]: unknown }

export type RemoveNotifications200 = { [key: string]: unknown }

export type RemoveNotificationsParams = {
    onlyUnread?: boolean
}

export type SetAllNotificationsAsRead200 = { [key: string]: unknown }

export interface ApiNewsPreview {
    createdAt?: string
    createdBy?: string
    id?: number
    shortDescription?: string
    title?: string
}

export interface ApiNewsPreviewList {
    news?: ApiNewsPreview[]
    newsCount?: number
}

export interface PaginationData {
    page?: number
    perPage?: number
    totalItems?: number
    totalPages?: number
    totalUnreadedItems?: number
}

export interface Notification {
    createdAt?: string
    deleted?: boolean
    eventType?: string
    id?: number
    message?: string
    messagePerex?: string
    notifType?: string
    readedAt?: string
    sendToUserIdSet?: string[]
    targetUri?: string
    userId?: string
}

export interface NotificationsList {
    notifications?: Notification[]
    pagination?: PaginationData
}

export interface ApiNews {
    createdAt?: string
    createdBy?: string
    id?: number
    longDescription?: string
    shortDescription?: string
    title?: string
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useSetAllNotificationsAsReadHook = () => {
    const setAllNotificationsAsRead = useNotificationsSwaggerClient<SetAllNotificationsAsRead200>()

    return useCallback(() => {
        return setAllNotificationsAsRead({ url: `/notifications`, method: 'PUT' })
    }, [setAllNotificationsAsRead])
}

export const useSetAllNotificationsAsReadMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetAllNotificationsAsReadHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetAllNotificationsAsReadHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const setAllNotificationsAsRead = useSetAllNotificationsAsReadHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSetAllNotificationsAsReadHook>>>, void> = () => {
        return setAllNotificationsAsRead()
    }

    return { mutationFn, ...mutationOptions }
}

export type SetAllNotificationsAsReadMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetAllNotificationsAsReadHook>>>>

export type SetAllNotificationsAsReadMutationError = ApiError

export const useSetAllNotificationsAsRead = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetAllNotificationsAsReadHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useSetAllNotificationsAsReadHook>>>, TError, void, TContext> => {
    const mutationOptions = useSetAllNotificationsAsReadMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRemoveNotificationsHook = () => {
    const removeNotifications = useNotificationsSwaggerClient<RemoveNotifications200>()

    return useCallback(
        (params?: RemoveNotificationsParams) => {
            return removeNotifications({ url: `/notifications`, method: 'DELETE', params })
        },
        [removeNotifications],
    )
}

export const useRemoveNotificationsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveNotificationsHook>>>,
        TError,
        { params?: RemoveNotificationsParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveNotificationsHook>>>,
    TError,
    { params?: RemoveNotificationsParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const removeNotifications = useRemoveNotificationsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationsHook>>>, { params?: RemoveNotificationsParams }> = (
        props,
    ) => {
        const { params } = props ?? {}

        return removeNotifications(params)
    }

    return { mutationFn, ...mutationOptions }
}

export type RemoveNotificationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationsHook>>>>

export type RemoveNotificationsMutationError = ApiError

export const useRemoveNotifications = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveNotificationsHook>>>,
        TError,
        { params?: RemoveNotificationsParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useRemoveNotificationsHook>>>,
    TError,
    { params?: RemoveNotificationsParams },
    TContext
> => {
    const mutationOptions = useRemoveNotificationsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetNotificationDetailHook = () => {
    const getNotificationDetail = useNotificationsSwaggerClient<Notification>()

    return useCallback(
        (id: number, signal?: AbortSignal) => {
            return getNotificationDetail({ url: `/notifications/${id}`, method: 'GET', signal })
        },
        [getNotificationDetail],
    )
}

export const getGetNotificationDetailQueryKey = (id: number) => {
    return [`/notifications/${id}`] as const
}

export const useGetNotificationDetailQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetNotificationDetailQueryKey(id)

    const getNotificationDetail = useGetNotificationDetailHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>> = ({ signal }) =>
        getNotificationDetail(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetNotificationDetailQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>>
export type GetNotificationDetailQueryError = ApiError

export function useGetNotificationDetail<TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetNotificationDetailQueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useSetNotificationAsReadHook = () => {
    const setNotificationAsRead = useNotificationsSwaggerClient<SetNotificationAsRead200>()

    return useCallback(
        (id: number) => {
            return setNotificationAsRead({ url: `/notifications/${id}`, method: 'PUT' })
        },
        [setNotificationAsRead],
    )
}

export const useSetNotificationAsReadMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetNotificationAsReadHook>>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetNotificationAsReadHook>>>, TError, { id: number }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const setNotificationAsRead = useSetNotificationAsReadHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSetNotificationAsReadHook>>>, { id: number }> = (props) => {
        const { id } = props ?? {}

        return setNotificationAsRead(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type SetNotificationAsReadMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetNotificationAsReadHook>>>>

export type SetNotificationAsReadMutationError = ApiError

export const useSetNotificationAsRead = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetNotificationAsReadHook>>>, TError, { id: number }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useSetNotificationAsReadHook>>>, TError, { id: number }, TContext> => {
    const mutationOptions = useSetNotificationAsReadMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRemoveNotificationHook = () => {
    const removeNotification = useNotificationsSwaggerClient<RemoveNotification200>()

    return useCallback(
        (id: number) => {
            return removeNotification({ url: `/notifications/${id}`, method: 'DELETE' })
        },
        [removeNotification],
    )
}

export const useRemoveNotificationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationHook>>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationHook>>>, TError, { id: number }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const removeNotification = useRemoveNotificationHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationHook>>>, { id: number }> = (props) => {
        const { id } = props ?? {}

        return removeNotification(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type RemoveNotificationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationHook>>>>

export type RemoveNotificationMutationError = ApiError

export const useRemoveNotification = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationHook>>>, TError, { id: number }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationHook>>>, TError, { id: number }, TContext> => {
    const mutationOptions = useRemoveNotificationMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddNotificationHook = () => {
    const addNotification = useNotificationsSwaggerClient<AddNotification200>()

    return useCallback(
        (notification: Notification) => {
            return addNotification({
                url: `/notifications/new`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: notification,
            })
        },
        [addNotification],
    )
}

export const useAddNotificationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddNotificationHook>>>, TError, { data: Notification }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddNotificationHook>>>, TError, { data: Notification }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const addNotification = useAddNotificationHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddNotificationHook>>>, { data: Notification }> = (props) => {
        const { data } = props ?? {}

        return addNotification(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddNotificationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddNotificationHook>>>>
export type AddNotificationMutationBody = Notification
export type AddNotificationMutationError = ApiError

export const useAddNotification = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddNotificationHook>>>, TError, { data: Notification }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useAddNotificationHook>>>, TError, { data: Notification }, TContext> => {
    const mutationOptions = useAddNotificationMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReindexHook = () => {
    const reindex = useNotificationsSwaggerClient<Reindex200>()

    return useCallback(
        (params?: ReindexParams, signal?: AbortSignal) => {
            return reindex({ url: `/notifications/reindex`, method: 'GET', params, signal })
        },
        [reindex],
    )
}

export const getReindexQueryKey = (params?: ReindexParams) => {
    return [`/notifications/reindex`, ...(params ? [params] : [])] as const
}

export const useReindexQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError = ApiError>(
    params?: ReindexParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReindexQueryKey(params)

    const reindex = useReindexHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>> = ({ signal }) => reindex(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type ReindexQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>>
export type ReindexQueryError = ApiError

export function useReindex<TData = Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError = ApiError>(
    params?: ReindexParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useReindexQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetNotificationListHook = () => {
    const getNotificationList = useNotificationsSwaggerClient<NotificationsList>()

    return useCallback(
        (params: GetNotificationListParams, signal?: AbortSignal) => {
            return getNotificationList({ url: `/notifications/list`, method: 'GET', params, signal })
        },
        [getNotificationList],
    )
}

export const getGetNotificationListQueryKey = (params: GetNotificationListParams) => {
    return [`/notifications/list`, ...(params ? [params] : [])] as const
}

export const useGetNotificationListQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>, TError = ApiError>(
    params: GetNotificationListParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetNotificationListQueryKey(params)

    const getNotificationList = useGetNotificationListHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>> = ({ signal }) =>
        getNotificationList(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetNotificationListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>>
export type GetNotificationListQueryError = ApiError

export function useGetNotificationList<TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>, TError = ApiError>(
    params: GetNotificationListParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetNotificationListQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useRemoveNotificationListHook = () => {
    const removeNotificationList = useNotificationsSwaggerClient<RemoveNotificationList200>()

    return useCallback(
        (params?: RemoveNotificationListParams) => {
            return removeNotificationList({ url: `/notifications/list`, method: 'DELETE', params })
        },
        [removeNotificationList],
    )
}

export const useRemoveNotificationListMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveNotificationListHook>>>,
        TError,
        { params?: RemoveNotificationListParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveNotificationListHook>>>,
    TError,
    { params?: RemoveNotificationListParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const removeNotificationList = useRemoveNotificationListHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useRemoveNotificationListHook>>>,
        { params?: RemoveNotificationListParams }
    > = (props) => {
        const { params } = props ?? {}

        return removeNotificationList(params)
    }

    return { mutationFn, ...mutationOptions }
}

export type RemoveNotificationListMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationListHook>>>>

export type RemoveNotificationListMutationError = ApiError

export const useRemoveNotificationList = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveNotificationListHook>>>,
        TError,
        { params?: RemoveNotificationListParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useRemoveNotificationListHook>>>,
    TError,
    { params?: RemoveNotificationListParams },
    TContext
> => {
    const mutationOptions = useRemoveNotificationListMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetNotificationListElasticHook = () => {
    const getNotificationListElastic = useNotificationsSwaggerClient<NotificationsList>()

    return useCallback(
        (params: GetNotificationListElasticParams, signal?: AbortSignal) => {
            return getNotificationListElastic({ url: `/notifications/listElastic`, method: 'GET', params, signal })
        },
        [getNotificationListElastic],
    )
}

export const getGetNotificationListElasticQueryKey = (params: GetNotificationListElasticParams) => {
    return [`/notifications/listElastic`, ...(params ? [params] : [])] as const
}

export const useGetNotificationListElasticQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>,
    TError = ApiError,
>(
    params: GetNotificationListElasticParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetNotificationListElasticQueryKey(params)

    const getNotificationListElastic = useGetNotificationListElasticHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>> = ({ signal }) =>
        getNotificationListElastic(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetNotificationListElasticQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>>
export type GetNotificationListElasticQueryError = ApiError

export function useGetNotificationListElastic<TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>, TError = ApiError>(
    params: GetNotificationListElasticParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetNotificationListElasticQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
