import { BreadCrumbs, Filter, HomeIcon, Input, MultiSelect, PaginatorWrapper, SimpleSelect, TextHeading } from '@isdd/idsk-ui-kit/index'
import { Table } from '@isdd/idsk-ui-kit/table/Table'
import { TextLink } from '@isdd/idsk-ui-kit/typography/TextLink'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE, DEFAULT_PAGESIZE_OPTIONS, ENTITY_REQUEST_LIST, RequestListState } from '@isdd/metais-common/constants'
import { ActionsOverTable, CreateEntityButton, MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { NavigationSubRoutes, RouteNames } from '@isdd/metais-common/navigation/routeNames'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Actions, Subjects } from '@isdd/metais-common/hooks/permissions/useRequestPermissions'
import { useAbilityContextWithFeedback } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'

import { CodeListFilterOnlyBase, CodelistTableData } from '@/components/containers/CodeListListContainer'
import { MainContentWrapper } from '@/components/MainContentWrapper'
import { RequestListFilterData, RequestListViewProps, defaultFilterValues } from '@/components/containers/RequestListContainer'

export const RequestListsView: React.FC<RequestListViewProps> = ({ data, filter, handleFilterChange, isLoading, isError }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { ability, isLoading: isAbilityLoading, isError: isAbilityError } = useAbilityContextWithFeedback()

    const {
        isActionSuccess: { value: isSuccess, additionalInfo },
    } = useActionSuccess()

    const columns: Array<ColumnDef<CodelistTableData>> = [
        {
            id: 'codelistName',
            header: t('codeListList.table.name'),
            size: 150,
            accessorFn: (row) => row.codelistName,
            enableSorting: true,
            cell: (row) => {
                return <TextLink to={`${NavigationSubRoutes.REQUESTLIST}/${row.row.original.id}`}>{row.getValue() as string}</TextLink>
            },
        },
        {
            id: 'id',
            header: t('codeListList.table.code'),
            accessorFn: (row) => row.code,
            meta: {
                getCellContext: (ctx: CellContext<CodelistTableData, unknown>) => ctx?.getValue?.(),
            },
            enableSorting: true,
        },
        {
            id: 'base',
            header: t('codeListList.table.isBase'),
            accessorFn: (row) => row.base,
            enableSorting: true,
            cell: (row) => {
                return row.getValue() ? t('radioButton.yes') : t('radioButton.no')
            },
        },
        {
            id: 'codelistState',
            header: t('codeListList.table.state'),
            size: 150,
            accessorFn: (row) => row.codelistState,
            enableSorting: true,
            cell: (row) => {
                return t(`codeListList.state.${row.getValue()}`)
            },
        },
    ]

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('codeList.breadcrumbs.home'), href: RouteNames.HOME, icon: HomeIcon },
                    { label: t('codeList.breadcrumbs.dataObjects'), href: RouteNames.HOW_TO_DATA_OBJECTS_MAIN },
                    { label: t('codeList.breadcrumbs.codeLists'), href: RouteNames.HOW_TO_CODELIST },
                    { label: t('codeList.breadcrumbs.requestList'), href: NavigationSubRoutes.REQUESTLIST },
                ]}
            />

            <MainContentWrapper>
                <MutationFeedback success={isSuccess} successMessage={t([additionalInfo?.messageKey ?? '', 'mutationFeedback.successfulUpdated'])} />
                <QueryFeedback loading={isLoading || !!isAbilityLoading} error={isError || isAbilityError} withChildren>
                    {ability.can(Actions.SHOW, Subjects.LIST) && (
                        <>
                            <TextHeading size="XL">{t('codeListList.requestTitle')}</TextHeading>

                            <Filter<RequestListFilterData>
                                heading={t('codeListList.filter.title')}
                                defaultFilterValues={defaultFilterValues}
                                form={({ filter: formFilter, register, setValue, isOpen }) => (
                                    <div>
                                        <MultiSelect
                                            id="wfState"
                                            name="wfState"
                                            label={t('codeListList.filter.state')}
                                            options={Object.values(RequestListState).map((state) => ({
                                                value: state,
                                                label: t(`codeListList.state.${state}`),
                                            }))}
                                            setValue={setValue}
                                            defaultValue={formFilter.wfState || defaultFilterValues.wfState}
                                            tabIndex={isOpen ? undefined : -1}
                                        />
                                        <Input {...register('code')} type="text" label={t('codeListList.filter.code')} />
                                        <Input {...register('name')} type="text" label={t('codeListList.filter.name')} />
                                        <SimpleSelect
                                            id="onlyBase"
                                            name="onlyBase"
                                            label={t('codeListList.filter.onlyBase.label')}
                                            options={[
                                                { value: CodeListFilterOnlyBase.TRUE, label: t('codeListList.filter.onlyBase.true') },
                                                { value: CodeListFilterOnlyBase.FALSE, label: t('codeListList.filter.onlyBase.false') },
                                            ]}
                                            setValue={setValue}
                                            defaultValue={formFilter.onlyBase || defaultFilterValues.onlyBase}
                                            tabIndex={isOpen ? undefined : -1}
                                        />
                                    </div>
                                )}
                            />
                            <ActionsOverTable
                                pagination={{
                                    pageNumber: filter.pageNumber ?? BASE_PAGE_NUMBER,
                                    pageSize: filter.pageSize ?? BASE_PAGE_SIZE,
                                    dataLength: data?.dataLength || 0,
                                }}
                                entityName={ENTITY_REQUEST_LIST}
                                pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                                createButton={
                                    ability.can(Actions.CREATE, Subjects.DETAIL) && (
                                        <CreateEntityButton
                                            onClick={() => navigate(`${NavigationSubRoutes.REQUESTLIST}/create`, { state: { from: location } })}
                                            label={t('codeListList.requestCreate.addBtn')}
                                        />
                                    )
                                }
                                handleFilterChange={handleFilterChange}
                                hiddenButtons={{ SELECT_COLUMNS: true }}
                            />
                            <Table<CodelistTableData>
                                data={data?.list}
                                columns={columns}
                                sort={filter.sort ?? []}
                                onSortingChange={(columnSort) => {
                                    handleFilterChange({ sort: columnSort })
                                }}
                            />
                            <PaginatorWrapper
                                pageNumber={filter.pageNumber || BASE_PAGE_NUMBER}
                                pageSize={filter.pageSize || BASE_PAGE_SIZE}
                                dataLength={data?.dataLength || 0}
                                handlePageChange={handleFilterChange}
                            />
                        </>
                    )}
                </QueryFeedback>
            </MainContentWrapper>
        </>
    )
}

export default RequestListsView
