import { DateInput } from '@isdd/idsk-ui-kit/date-input/DateInput'
import { ButtonLink, Filter, MultiSelect, PaginatorWrapper, SimpleSelect, TextHeading } from '@isdd/idsk-ui-kit/index'
import { Table } from '@isdd/idsk-ui-kit/table/Table'
import { FollowedItemItemType } from '@isdd/metais-common/api/generated/user-config-swagger'
import { NotificationBlackIcon } from '@isdd/metais-common/assets/images'
import actionsOverTableStyles from '@isdd/metais-common/components/actions-over-table/actionsOverTable.module.scss'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE, DEFAULT_PAGESIZE_OPTIONS } from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useAddFavorite } from '@isdd/metais-common/hooks/useAddFavorite'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import {
    ActionsOverTable,
    BulkPopup,
    CreateEntityButton,
    MutationFeedback,
    QueryFeedback,
    Reference_Identifiers,
    RefIdentifierTypeEnum,
} from '@isdd/metais-common/index'
import { RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'

import { refIdentifierColumns, refIdentifierStateOptions, refIdentifierTypeOptions, refIdentifierViewOptions } from './refIdentifierListProps'

import { ColumnsOutputDefinition } from '@/componentHelpers/ci/ciTableHelpers'
import { RefIdentifierListFilterData, RefIdentifiersContainerViewProps } from '@/components/containers/ref-identifiers/RefIdentifiersContainer'

export const RefIdentifierListView: React.FC<RefIdentifiersContainerViewProps> = ({
    data,
    registrationState,
    defaultFilter,
    pagination,
    filter,
    isLoggedIn,
    isError,
    isLoading,
    handleFilterChange,
    rowSelection,
    pageType,
}) => {
    const { t, i18n } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const {
        state: { user },
    } = useAuth()
    const tableRef = useRef<HTMLTableElement>(null)
    const {
        isActionSuccess: { value: isExternalSuccess },
    } = useActionSuccess()
    const { wrapperRef, scrollToMutationFeedback } = useScroll()
    const {
        addFavorite,
        error: errorAddToFavorite,
        isLoading: isLoadingAddToFavorite,
        isSuccess: isSuccessAddToFavorite,
        resetState,
        successMessage,
    } = useAddFavorite()
    const focusTriggerRef = useRef<() => void>()
    const handleAddToFavorite = async () => {
        await addFavorite(rowSelection.ids, FollowedItemItemType.REFID)
        focusTriggerRef.current?.()
    }

    useEffect(() => {
        scrollToMutationFeedback()
    }, [isError, isExternalSuccess, isSuccessAddToFavorite, errorAddToFavorite, scrollToMutationFeedback])

    return (
        <QueryFeedback loading={isLoading || isLoadingAddToFavorite} error={false} withChildren>
            <FlexColumnReverseWrapper>
                <TextHeading size="XL">{t('refIdentifiers.title')}</TextHeading>
                <div ref={wrapperRef}>
                    <MutationFeedback
                        success={isSuccessAddToFavorite}
                        successMessage={successMessage}
                        error={!!errorAddToFavorite}
                        errorMessage={t('userProfile.notifications.feedback.error')}
                        onMessageClose={() => resetState()}
                    />
                    <QueryFeedback error={isError} loading={false} />
                    <MutationFeedback success={isExternalSuccess} />
                </div>
            </FlexColumnReverseWrapper>

            <Filter<RefIdentifierListFilterData, object>
                heading={t('codeList.filter.title')}
                defaultFilterValues={defaultFilter}
                rowSelection={rowSelection}
                form={({ filter: formFilter, register, setValue, control, isOpen }) => {
                    const stateOptions = refIdentifierStateOptions(registrationState, i18n.language)
                    return (
                        <div>
                            <MultiSelect
                                name="type"
                                label={t('refIdentifiers.filter.type')}
                                options={refIdentifierTypeOptions(t)}
                                onChange={(values) => setValue('type', values as RefIdentifierTypeEnum[])}
                                defaultValue={formFilter.type || defaultFilter.type}
                                tabIndex={isOpen ? undefined : -1}
                            />
                            {pageType === 'requests' && (
                                <SimpleSelect
                                    key={stateOptions.length}
                                    label={t('refIdentifiers.filter.state')}
                                    options={stateOptions}
                                    setValue={setValue}
                                    defaultValue={formFilter?.state || defaultFilter.state}
                                    name="state"
                                    tabIndex={isOpen ? undefined : -1}
                                />
                            )}

                            <DateInput
                                label={t('refIdentifiers.filter.createdAtFrom')}
                                id={'createdAtFrom'}
                                {...register('createdAtFrom')}
                                control={control}
                                setValue={setValue}
                            />
                            <DateInput
                                label={t('refIdentifiers.filter.createdAtTo')}
                                id={'createdAtTo'}
                                {...register('createdAtTo')}
                                control={control}
                                setValue={setValue}
                            />
                            {isLoggedIn && (
                                <SimpleSelect
                                    label={t('refIdentifiers.filter.view')}
                                    options={refIdentifierViewOptions(t)}
                                    setValue={setValue}
                                    isClearable={false}
                                    defaultValue={formFilter?.view}
                                    name="view"
                                    tabIndex={isOpen ? undefined : -1}
                                />
                            )}
                        </div>
                    )
                }}
            />
            <ActionsOverTable
                pagination={{
                    pageNumber: filter.pageNumber ?? BASE_PAGE_NUMBER,
                    pageSize: filter.pageSize ?? BASE_PAGE_SIZE,
                    dataLength: data?.configurationItemSet?.length ?? 0,
                }}
                selectedRowsCount={rowSelection.count}
                bulkPopup={({ selectedRowsCount }) => (
                    <BulkPopup
                        checkedRowItems={selectedRowsCount}
                        manuallyHandledFocusAfterClose
                        onOpen={(focusTriggerEl) => {
                            focusTriggerRef.current = focusTriggerEl
                        }}
                        onClose={() => {
                            focusTriggerRef.current?.()
                        }}
                        items={() => [
                            <ButtonLink
                                inPopup
                                key={'favorite'}
                                label={t('codeListList.buttons.addToFavorites')}
                                className={actionsOverTableStyles.buttonLinkWithIcon}
                                icon={NotificationBlackIcon}
                                onClick={() => {
                                    handleAddToFavorite()
                                    rowSelection.clearSelectedRows()
                                }}
                            />,
                        ]}
                    />
                )}
                entityName={Reference_Identifiers}
                createButton={
                    pageType === 'requests' && (
                        <CreateEntityButton
                            onClick={() => navigate(RouterRoutes.DATA_OBJECT_REF_IDENTIFIERS_CREATE, { state: { from: location } })}
                        />
                    )
                }
                handleFilterChange={handleFilterChange}
                pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                hiddenButtons={{ SELECT_COLUMNS: true }}
            />
            <Table
                tableRef={tableRef}
                data={data?.configurationItemSet as ColumnsOutputDefinition[]}
                columns={refIdentifierColumns(t, i18n.language, registrationState, !!user, pageType)}
                sort={filter.sort ?? []}
                onSortingChange={(columnSort) => {
                    handleFilterChange({ sort: columnSort })
                    rowSelection.clearSelectedRows()
                }}
                getRowId={(originalRow) => `${originalRow.uuid}`}
                rowSelection={rowSelection.rowSelection}
                onRowSelectionChange={rowSelection.getOnRowSelectionChange()}
            />
            <PaginatorWrapper
                {...pagination}
                handlePageChange={(filterValues) => {
                    handleFilterChange(filterValues)
                    tableRef.current?.scrollIntoView({ behavior: 'smooth' })
                }}
            />
        </QueryFeedback>
    )
}

export default RefIdentifierListView
