//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * MetaIS Claim Manager
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { useClaimManagerSwaggerClient } from '../hooks/useClaimManagerSwaggerClient'
export type ReadList1Params = {
    createdBy: string
    name: string
    state: string
}

export type _DeleteParams = {
    createdBy: string
}

export interface Claim {
    assignedTo?: string
    createdAt?: string
    createdBy?: string
    decisionReason?: string
    description?: string
    email?: string
    identityCompleteName?: string
    identityFirstName?: string
    identityLastName?: string
    identityLogin?: string
    name?: string
    po?: string
    poName?: string
    position?: string
    processedAt?: string
    searchFilter?: string
    state?: string
    telephone?: string
    uuid?: string
}

export interface PaginationUi {
    totalItems?: number
}

export interface ClaimSetUi {
    claimSet?: ClaimUi[]
    pagination?: PaginationUi
}

export interface ClaimListFilter {
    anonymous?: boolean
    completeName?: string
    email?: string
    login?: string
    name?: string
    phone?: string
    poName?: string
    searchFilter?: string
    status?: string
}

export interface ClaimListFilterContainerUi {
    ascending?: boolean
    filter?: ClaimListFilter
    hkoUuid?: string
    page?: number
    perpage?: number
    sortAttribute?: string
}

export type ClaimEventType = (typeof ClaimEventType)[keyof typeof ClaimEventType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClaimEventType = {
    DECISION_EVENT: 'DECISION_EVENT',
    CREATE_EVENT: 'CREATE_EVENT',
    REGISTER_USER_EVENT: 'REGISTER_USER_EVENT',
} as const

export type ClaimDecisionDataAction = (typeof ClaimDecisionDataAction)[keyof typeof ClaimDecisionDataAction]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClaimDecisionDataAction = {
    ACCEPT: 'ACCEPT',
    REFUSE: 'REFUSE',
} as const

export interface ClaimDecisionData {
    action?: ClaimDecisionDataAction
    decisionReason?: string
    uuid?: string
}

export interface ClaimEvent {
    claimDecisionData?: ClaimDecisionData
    claimUi?: ClaimUi
    type?: ClaimEventType
}

export type IdentityWithoutAuthResourceType = (typeof IdentityWithoutAuthResourceType)[keyof typeof IdentityWithoutAuthResourceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityWithoutAuthResourceType = {
    PHYSICAL: 'PHYSICAL',
    TECH: 'TECH',
} as const

export type IdentityWithoutAuthResourceState = (typeof IdentityWithoutAuthResourceState)[keyof typeof IdentityWithoutAuthResourceState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityWithoutAuthResourceState = {
    REGISTERED: 'REGISTERED',
    ACTIVATED: 'ACTIVATED',
    VERIFIED: 'VERIFIED',
    BLOCKED: 'BLOCKED',
    DEACTIVATED: 'DEACTIVATED',
    ANONYMIZED: 'ANONYMIZED',
} as const

export interface IdentityWithoutAuthResource {
    email?: string
    firstName?: string
    lastName?: string
    login?: string
    phone?: string
    position?: string
    state?: IdentityWithoutAuthResourceState
    type?: IdentityWithoutAuthResourceType
    uuid?: string
}

export interface ClaimActionResult {
    message?: string
    resultCode?: number
}

export type ClaimUiClaimState = (typeof ClaimUiClaimState)[keyof typeof ClaimUiClaimState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClaimUiClaimState = {
    WAITING: 'WAITING',
    ACCEPTED: 'ACCEPTED',
    REFUSED: 'REFUSED',
} as const

export interface ClaimUi {
    assignedTo?: string
    claimState?: ClaimUiClaimState
    createdAt?: string
    createdBy?: string
    decisionReason?: string
    description?: string
    email?: string
    identityFirstName?: string
    identityLastName?: string
    identityLogin?: string
    name?: string
    password?: string
    po?: string
    poName?: string
    position?: string
    processedAt?: string
    telephone?: string
    uuid?: string
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useStoreHook = () => {
    const store = useClaimManagerSwaggerClient<ClaimActionResult>()

    return useCallback(
        (claimUi: ClaimUi) => {
            return store({ url: `/claims`, method: 'POST', headers: { 'Content-Type': 'application/json;charset=UTF-8' }, data: claimUi })
        },
        [store],
    )
}

export const useStoreMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreHook>>>, TError, { data: ClaimUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreHook>>>, TError, { data: ClaimUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const store = useStoreHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreHook>>>, { data: ClaimUi }> = (props) => {
        const { data } = props ?? {}

        return store(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreHook>>>>
export type StoreMutationBody = ClaimUi
export type StoreMutationError = ApiError

export const useStore = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreHook>>>, TError, { data: ClaimUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreHook>>>, TError, { data: ClaimUi }, TContext> => {
    const mutationOptions = useStoreMutationOptions(options)

    return useMutation(mutationOptions)
}

export const use_DeleteHook = () => {
    const _delete = useClaimManagerSwaggerClient<void>()

    return useCallback(
        (params: _DeleteParams) => {
            return _delete({ url: `/claims`, method: 'DELETE', params })
        },
        [_delete],
    )
}

export const useDeleteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>, TError, { params: _DeleteParams }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>, TError, { params: _DeleteParams }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const _delete = use_DeleteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>, { params: _DeleteParams }> = (props) => {
        const { params } = props ?? {}

        return _delete(params)
    }

    return { mutationFn, ...mutationOptions }
}

export type _DeleteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>>

export type _DeleteMutationError = ApiError

export const useDelete = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>, TError, { params: _DeleteParams }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>, TError, { params: _DeleteParams }, TContext> => {
    const mutationOptions = useDeleteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateIdentityHook = () => {
    const updateIdentity = useClaimManagerSwaggerClient<void>()

    return useCallback(
        (identityWithoutAuthResource: IdentityWithoutAuthResource) => {
            return updateIdentity({
                url: `/claims/updateIdentity`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: identityWithoutAuthResource,
            })
        },
        [updateIdentity],
    )
}

export const useUpdateIdentityMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateIdentityHook>>>,
        TError,
        { data: IdentityWithoutAuthResource },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateIdentityHook>>>, TError, { data: IdentityWithoutAuthResource }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateIdentity = useUpdateIdentityHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateIdentityHook>>>, { data: IdentityWithoutAuthResource }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return updateIdentity(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateIdentityMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateIdentityHook>>>>
export type UpdateIdentityMutationBody = IdentityWithoutAuthResource
export type UpdateIdentityMutationError = ApiError

export const useUpdateIdentity = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateIdentityHook>>>,
        TError,
        { data: IdentityWithoutAuthResource },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useUpdateIdentityHook>>>, TError, { data: IdentityWithoutAuthResource }, TContext> => {
    const mutationOptions = useUpdateIdentityMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRegisterUserHook = () => {
    const registerUser = useClaimManagerSwaggerClient<ClaimActionResult>()

    return useCallback(
        (claimEvent: ClaimEvent) => {
            return registerUser({
                url: `/claims/registerUser`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: claimEvent,
            })
        },
        [registerUser],
    )
}

export const useRegisterUserMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRegisterUserHook>>>, TError, { data: ClaimEvent }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRegisterUserHook>>>, TError, { data: ClaimEvent }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const registerUser = useRegisterUserHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRegisterUserHook>>>, { data: ClaimEvent }> = (props) => {
        const { data } = props ?? {}

        return registerUser(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type RegisterUserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRegisterUserHook>>>>
export type RegisterUserMutationBody = ClaimEvent
export type RegisterUserMutationError = ApiError

export const useRegisterUser = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRegisterUserHook>>>, TError, { data: ClaimEvent }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useRegisterUserHook>>>, TError, { data: ClaimEvent }, TContext> => {
    const mutationOptions = useRegisterUserMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useProcessEventHook = () => {
    const processEvent = useClaimManagerSwaggerClient<ClaimActionResult>()

    return useCallback(
        (claimEvent: ClaimEvent) => {
            return processEvent({
                url: `/claims/processEvent`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: claimEvent,
            })
        },
        [processEvent],
    )
}

export const useProcessEventMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useProcessEventHook>>>, TError, { data: ClaimEvent }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useProcessEventHook>>>, TError, { data: ClaimEvent }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const processEvent = useProcessEventHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useProcessEventHook>>>, { data: ClaimEvent }> = (props) => {
        const { data } = props ?? {}

        return processEvent(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ProcessEventMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProcessEventHook>>>>
export type ProcessEventMutationBody = ClaimEvent
export type ProcessEventMutationError = ApiError

export const useProcessEvent = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useProcessEventHook>>>, TError, { data: ClaimEvent }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useProcessEventHook>>>, TError, { data: ClaimEvent }, TContext> => {
    const mutationOptions = useProcessEventMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary readList
 */
export const useReadListHook = () => {
    const readList = useClaimManagerSwaggerClient<ClaimSetUi>()

    return useCallback(
        (claimListFilterContainerUi: ClaimListFilterContainerUi) => {
            return readList({
                url: `/claims/list`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: claimListFilterContainerUi,
            })
        },
        [readList],
    )
}

export const useReadListMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadListHook>>>, TError, { data: ClaimListFilterContainerUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadListHook>>>, TError, { data: ClaimListFilterContainerUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const readList = useReadListHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReadListHook>>>, { data: ClaimListFilterContainerUi }> = (props) => {
        const { data } = props ?? {}

        return readList(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReadListMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadListHook>>>>
export type ReadListMutationBody = ClaimListFilterContainerUi
export type ReadListMutationError = ApiError

/**
 * @summary readList
 */
export const useReadList = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReadListHook>>>, TError, { data: ClaimListFilterContainerUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReadListHook>>>, TError, { data: ClaimListFilterContainerUi }, TContext> => {
    const mutationOptions = useReadListMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReadHook = () => {
    const read = useClaimManagerSwaggerClient<ClaimUi>()

    return useCallback(
        (uuid: string, signal?: AbortSignal) => {
            return read({ url: `/claims/${uuid}`, method: 'GET', signal })
        },
        [read],
    )
}

export const getReadQueryKey = (uuid: string) => {
    return [`/claims/${uuid}`] as const
}

export const useReadQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadQueryKey(uuid)

    const read = useReadHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadHook>>>> = ({ signal }) => read(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useReadHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ReadQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadHook>>>>
export type ReadQueryError = ApiError

export function useRead<TData = Awaited<ReturnType<ReturnType<typeof useReadHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useReadQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useReadList1Hook = () => {
    const readList1 = useClaimManagerSwaggerClient<Claim[]>()

    return useCallback(
        (params: ReadList1Params, signal?: AbortSignal) => {
            return readList1({ url: `/claims/list/`, method: 'GET', params, signal })
        },
        [readList1],
    )
}

export const getReadList1QueryKey = (params: ReadList1Params) => {
    return [`/claims/list/`, ...(params ? [params] : [])] as const
}

export const useReadList1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadList1Hook>>>, TError = ApiError>(
    params: ReadList1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadList1Hook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadList1QueryKey(params)

    const readList1 = useReadList1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadList1Hook>>>> = ({ signal }) => readList1(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadList1Hook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type ReadList1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadList1Hook>>>>
export type ReadList1QueryError = ApiError

export function useReadList1<TData = Awaited<ReturnType<ReturnType<typeof useReadList1Hook>>>, TError = ApiError>(
    params: ReadList1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadList1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useReadList1QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
