import { TFunction } from 'i18next'

import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { MetainformationColumns } from '@isdd/metais-common/componentHelpers/ci/getCiDefaultMetaAttributes'

export const BASE_PAGE_NUMBER = 1
export const BASE_PAGE_SIZE = 10

export const REGEX_EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$|^$/
export const REGEX_TEL = /^[+]?[(]?[0-9]{1,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3}[-\s.]?[0-9]{3}$|^$/
export const REGEX_URL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$|^$/

export const filterKeysToSkip = new Set(['fullTextSearch', 'attributeFilters', 'sort', 'pageSize', 'pageNumber'])

export type OPERATOR_SEPARATOR_TYPE = '--'
export const OPERATOR_SEPARATOR = '--'
export const JOIN_OPERATOR = '+'
export const RELATION_FILTER_PREFIX = 'Relation'
export const CHECKBOX_FILTER_SUFFIX = 'Checkbox'
export const MODAL_TOOLTIP_ZINDEX = 10001

export const MAX_DYNAMIC_ATTRIBUTES_LENGHT = 10
export const DEFAULT_PAGESIZE_OPTIONS = [
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
]
export const ALL_EVENT_TYPES = 'All'
export const NOTIFICATION_TITLE = 'messagePerex'

export const CHANGE_OWNER_CHANGE_REASON = ['architectureChange', 'personalChange', 'othersChange']

export const CHANGE_OWNER_CHANGE_TYPE = ['changeCmdbItemAndRelatedCmdbItemsWithSameOwner', 'changeCmdbItemAndRelatedCmdbItems', 'changeCmdbItem']

export const notificationDefaultSelectedColumns = [
    { technicalName: 'deleted', name: 'Deleted', selected: false },
    { technicalName: 'eventType', name: 'EventType', selected: false },
    { technicalName: 'id', name: 'Id', selected: false },
    { technicalName: 'message', name: 'Message', selected: false },
    { technicalName: 'messagePerex', name: 'MessagePerex', selected: true },
    { technicalName: 'notifType', name: 'NotifType', selected: false },
    { technicalName: 'readedAt', name: 'ReadedAt', selected: false },
    { technicalName: 'sendToUserIdSet', name: 'SendToUserIdSet', selected: false },
    { technicalName: 'targetUri', name: 'TargetUri', selected: false },
    { technicalName: 'userId', name: 'UserId', selected: false },
    { technicalName: 'createdAt', name: 'CreatedAt', selected: true },
]

export const documentsManagementDefaultSelectedColumns = (t: TFunction<'translation', undefined, 'translation'>) => [
    { technicalName: 'id', name: 'Id', selected: false },
    { technicalName: 'state', name: t('documentsManagement.status'), selected: true },
    { technicalName: 'name', name: t('documentsManagement.name'), selected: true },
    { technicalName: 'nameEng', name: t('egov.engName'), selected: true },
    { technicalName: 'description', name: t('documentsManagement.description'), selected: false },
    { technicalName: 'descriptionEng', name: t('egov.engDescription'), selected: false },
]

export const documentsManagementGroupDocumentsDefaultSelectedColumns = (t: TFunction<'translation', undefined, 'translation'>) => [
    { technicalName: 'id', name: 'Id', selected: false },
    { technicalName: 'name', name: t('documentsManagement.name'), selected: true },
    { technicalName: 'nameEng', name: t('documentsManagement.nameEng'), selected: false },
    { technicalName: 'description', name: t('documentsManagement.description'), selected: true },
    { technicalName: 'descriptionEng', name: t('documentsManagement.descriptionEng'), selected: false },
    { technicalName: 'confluence', name: t('documentsManagement.xWiki'), selected: true },
    { technicalName: 'required', name: t('documentsManagement.required'), selected: true },
    { technicalName: 'documentGroup', name: t('documentsManagement.documentGroup'), selected: false },
    { technicalName: 'type', name: t('documentsManagement.type'), selected: false },
    { technicalName: 'template', name: t('documentsManagement.template'), selected: true },
]

export const getProjectsFinanceManagementSelectedColumns = (t: TFunction<'translation', undefined, 'translation'>) => [
    { technicalName: 'name', name: t('projects.financeManagement.approvalProcessName'), selected: false },
    { technicalName: 'approvalProcess', name: t('projects.financeManagement.approvalProcess'), selected: true },
    { technicalName: 'min', name: t('projects.financeManagement.tableTitleMin'), selected: true },
    { technicalName: 'max', name: t('projects.financeManagement.tableTitleMax'), selected: true },
]

export const getRolesListSelectedColumns = (t: TFunction<'translation', undefined, 'translation'>) => [
    { technicalName: 'name', name: t('adminRolesPage.name'), selected: true },
    { technicalName: 'description', name: t('adminRolesPage.description'), selected: true },
    { technicalName: 'assignedGroup', name: t('adminRolesPage.group'), selected: true },
    { technicalName: 'type', name: t('adminRolesPage.systemRole'), selected: true },
]

export const projectsFinanceManagementInvestmentType = [
    { label: 'Project', value: 'c_projekt' },
    { label: 'Zmenová požiadavka', value: 'c_zmenova_poziadavka' },
]

export const ROLES_GROUP = 'SKUPINA_ROL'
export const metaisEmail = 'metais@mirri.gov.sk'

export const CAN_CREATE_GRAPH_QUERY_KEY = 'canCreateGraph'
export enum EClaimState {
    WAITING = 'WAITING',
    ACCEPTED = 'ACCEPTED',
    REFUSED = 'REFUSED',
    ALL = '',
}
export enum ReponseErrorCodeEnum {
    DEFAULT = 'default',
    GNR403 = 'gnr403',
    GNR404 = 'gnr404',
    GNR412 = 'gnr412',
    NTM01 = 'ntm01',
    GNR500 = 'gnr500',
    OPERATION_NOT_ALLOWED = 'OperationNotAllowed',
    WRONG_OLD_PASSWORD = 'WRONG_OLD_PASSWORD',
    EKO_CODE_USED = 'EkoCodeUsed',
}
export const REPORTS = 'reports'
export const FIRST_PAGE_NUMBER = 1

export enum GROUP_ROLES {
    STD_PSPRE = 'STD_PSPRE',
    STD_PSPODP = 'STD_PSPODP',
    STD_PSCLEN = 'STD_PSCLEN',
    STD_PSPO = 'STD_PSPO',
    STD_PSZAST = 'STD_PSZAST',
}

export enum KSISVS_ROLES {
    STD_KSPRE = 'STD_KSPRE',
    STD_KSPODP = 'STD_KSPODP',
    STD_KSTAJ = 'STD_KSTAJ',
    STD_KSCLEN = 'STD_KSCLEN',
    STD_KOORDINATOR_AGENDY = 'STD_KOORDINATOR_AGENDY',
}
export enum RoleEnum {
    PROJEKT_SCHVALOVATEL = 'PROJEKT_SCHVALOVATEL',
}
export const KSIVS_SHORT_NAME = 'KSISVS'
export const REFID_URI_SZ = 'REFID_URI_SZ'
export const REFID_URI_DEF = 'REFID_URI_DEF'
export const META_PREFERENCES_KEY = 'META_'
export const FILTER_LOCAL_STORAGE_KEY = 'filter_'

export const PUBLIC_ORG_CMDB_CODE = '00003010'
export const NULL = 'null'

export const USER_INFO_QUERY_KEY = 'userInfo'
export const CONFLUENCE = 'CONFLUENCE'
export const IS_KOORDINATOR = 'isKoordinator'

export enum RequestListState {
    DRAFT = 'DRAFT',
    ACCEPTED = 'ACCEPTED',
    NEW_REQUEST = 'NEW_REQUEST',
    REJECTED = 'REJECTED',
    PUBLISHED = 'PUBLISHED',
    UPDATING = 'UPDATING',
    READY_TO_PUBLISH = 'READY_TO_PUBLISH',
    ISVS_PROCESSING = 'ISVS_PROCESSING',
    ISVS_REJECTED = 'ISVS_REJECTED',
    ISVS_ACCEPTED = 'ISVS_ACCEPTED',
    KS_ISVS_REJECTED = 'KS_ISVS_REJECTED',
    KS_ISVS_ACCEPTED = 'KS_ISVS_ACCEPTED',
    ACCEPTED_SZZC = 'ACCEPTED_SZZC',
}

export enum CodeListFilterOnlyBase {
    TRUE = 'TRUE',
    FALSE = 'FALSE',
}
export const PORTAL_URL = import.meta.env.VITE_PORTAL_URL
export const DEFAULT_ERROR = 'Error'
export const ROLES_CAN_CHANGE_STD_DRAFTS = [KSISVS_ROLES.STD_KSPODP, KSISVS_ROLES.STD_KSPRE, KSISVS_ROLES.STD_KSTAJ]

export const STAV_REGISTRACIE = 'STAV_REGISTRACIE'
export const TYP_DATOVEHO_PRVKU = 'TYP_DATOVEHO_PRVKU'
export const STAV_PROJEKTU = 'STAV_PROJEKTU'
export const STAV_PROJEKTU_AGILE = 'STAV_PROJEKTU_AGILE'
export const FAZA_PROJEKTU = 'FAZA_PROJEKTU'
export const FAZA_PROJEKTU_AGILE = 'FAZA_PROJEKTU_AGILE'
export const CI_ITEM_QUERY_KEY = 'ciItemData'
export const RELATIONSHIP_TYPES_QUERY_KEY = 'useListRelationshipTypes'
export const CI_TYPES_QUERY_KEY = 'useListCiTypes'
export const REPORTS_LIST_QUERY_KEY = '/reports/list'
export const ADMIN_EGOV_ENTITY_LIST_QKEY = `/citypes/list`
export const ADMIN_EGOV_RELATION_LIST_QKEY = `/relationshiptypes/list`
export const ADMIN_EKO_LIST_QKEY = `/ekocodes`
export const HTML_TYPE = 'HTML'
export const STRING_TYPE = 'STRING'
export const DATETIME_TYPE = 'DATETIME'
export const MUK = 'muk'

export const P_REALIZUJE_AKT = 'Projekt_realizuje_aktivitu'
export const ENTITY_ACTIVITY = 'Aktivita'

export const DESCRIPTION = 'Popis'

export const PROJECT_DOCUMENTS_SECTIONS_EXPANDABLE: { [key: string]: string } = {
    'Projektový iniciálny dokument (PID)': 'projektovy_inicialny_dokument_',
    'Manažérske správy, reporty, zoznamy a požiadavky': 'manazerske_spravy_reporty_',
    'Dokumenty architektúry': 'dokumenty_architektury_',
}
export const FINISHED_STATE = 'c_stav_projektu_9'
export const RATED_STATE = 'c_stav_projektu_4'
export const NOT_APPROVED_STATE = 'c_stav_projektu_12'
export const RE_RATED_STATE = 'c_stav_projektu_11'
export const RETURNED_STATE = 'c_stav_projektu_5'
export const CANCELED_STATE = 'c_stav_projektu_7'

export const FINISHED_STATE_AGILE = 'c_stav_projektu_agile.9'
export const RATED_STATE_AGILE = 'c_stav_projektu_agile.4'
export const NOT_APPROVED_STATE_AGILE = 'c_stav_projektu_agile.12'
export const RE_RATED_STATE_AGILE = 'c_stav_projektu_agile.11'
export const RETURNED_STATE_AGILE = 'c_stav_projektu_agile.5'
export const CANCELED_STATE_AGILE = 'c_stav_projektu_agile.7'
export const INIT_STATE_AGILE = 'c_stav_projektu_agile.1'

export const PROJECT_STATUS = 'EA_Profil_Projekt_status'

export const ACTION_CREATE = 'CREATE'
export const ACTION_UPDATE = 'UPDATE'
export const ENTITY_PROJECT = 'Projekt'

export const API_CALL_RETRY_COUNT = 50
export const PO_IS = 'PO_IS'
export const PO_PO = 'PO_PO'
export const PO_IS_PO = 'PO_IS_PO'
export const PO = 'PO'
export const PROJECT = 'Projekt'
export const PROGRAM = 'Program'
export const WEBOVE_SIDLO = 'WeboveSidlo'
export const ENTITY_KS = 'KS'
export const ENTITY_AS = 'AS'
export const ENTITY_ISVS = 'ISVS'
export const ENTITY_KONTRAKT = 'Kontrakt'
export const ENTITY_MIGRATION = 'Migracia'
export const ENTITY_INFRA_SLUZBA = 'InfraSluzba'
export const ENTITY_TRAINING = 'Training'
export const ENTITY_ZC = 'ZC'
export const ENTITY_OSOBITNY_POSTUP = 'OsobitnyPostup'
export const STANDARDIZATION_DRAFTS_LIST = 'draftsList'
export const REFERENCE_REGISTER = 'ReferenceRegister'

export const MAX_TITLE_LENGTH = 2000
export const INVALIDATED = 'INVALIDATED'
export const DRAFT = 'DRAFT'

export const ENTITY_CIEL = 'Ciel'
export const ENTITY_KRIS = 'KRIS'
export const ENTITY_KRIT = 'KRIT'
export const KRIS_stanovuje_Ciel = 'KRIS_stanovuje_Ciel'
export const KRIS_Profil_nazov = 'Koncepcia rozvoja IT'
export const ENTITY_PRINCIP = 'Princip'
export const PO_predklada_KRIS = 'PO_predklada_KRIS'
export const ENTITY_ZS = 'ZS'
export const ENTITY_AGENDA = 'Agenda'
export const OE_patri_Agenda = 'OE_patri_Agenda'
export const ENTITY_INTEGRATION = 'Integracia'
export const ENTITY_MIESTO_PREVADZKY = 'MiestoPrevadzky'
export const ENTITY_REQUEST_LIST = 'requestList'
export const CODE_LISTS = 'codelists'

export const Referencny_register_ma_objekt_evidencie = 'Referencny_register_ma_objekt_evidencie'
export const Referencny_register_ma_referencny_udaj = 'Referencny_register_ma_referencny_udaj'
export const ENTITY_DATAVY_PRVOK = 'DatovyPrvok'
export const ENTITY_OBJEKT_EVIDENCIE = 'ObjektEvidencie'
export const ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE = 'AtributObjektuEvidencie'

export const INACTIVE_LOGOUT_TIME = import.meta.env.VITE_INACTIVE_LOGOUT_TIME
export const INACTIVE_WARNING_TIME = import.meta.env.VITE_INACTIVE_WARNING_TIME
export const IAM_OIDC_BASE_URL = import.meta.env.VITE_REST_CLIENT_IAM_OIDC_BASE_URL
export const LOCAL_STORAGE_ACTIVITY_TRIGGER = 'activityTrigger'
export const LOCAL_STORAGE_LOGOUT_POPUP_OPENED = 'logoutPopup'
export const ACTIVITY_DEBOUNCE = 200

export const ciInformationTab = 'information'
export const integrationHarmonogramTab = 'harmonogram'
export const LIFE_CYCLE_PHASE = 'FazaZivotnehoCyklu'

export const PROFILE_REF_LIFE_CYCLE_START = 'Profil_Rel_FazaZivotnehoCyklu_datum_zacatia'
export const PROFILE_REF_LIFE_CYCLE_END = 'Profil_Rel_FazaZivotnehoCyklu_datum_ukoncenia'
export const KRIScolumnsTechNames = [
    ATTRIBUTE_NAME.Gen_Profil_nazov,
    ATTRIBUTE_NAME.Gen_Profil_kod_metais,
    ATTRIBUTE_NAME.Profil_KRIS_stav_kris,
    MetainformationColumns.OWNER,
    MetainformationColumns.LAST_MODIFIED_AT,
]
export const krisRelatedCiTabsNames = [ENTITY_KS, ENTITY_ISVS, ENTITY_PROJECT, ENTITY_ZS, ENTITY_AGENDA]
export enum ROLES {
    EA_GARPO = 'EA_GARPO',
    R_EGOV = 'R_EGOV',
    R_ADMIN = 'R_ADMIN',
    SKOLITEL = 'SKOLITEL',
    STD_KOORDINATOR_AGENDY = 'STD_KOORDINATOR_AGENDY',
}
export const NO_USER_COLUMNS_LS_KEY = 'METAIS_columns_noUser/'

export const CAN_CREATE_AND_EDIT_VOTES_USER_ROLES = ['STD_KSPODP', 'STD_KSPRE', 'STD_KSTAJ', 'STD_PSPRE', 'STD_PSPODP', 'STD_KOORDINATOR_AGENDY']

export const CAN_NOT_MANAGE_CI = [
    'Agenda',
    'DOCUMENT',
    'DOKUMENT',
    'DatovyPrvok',
    'DistribuciaDatasetu',
    'DokumentRefID',
    'Individuum',
    'KRIS_Protokol',
    'License',
    'OkruhZS',
    'Ontologia',
    'OvladanaPO',
    'PO',
    'ParameterKontraktu',
    'ParameterSluzby',
    'PlanKontroly',
    'ReferenceRegister',
    'ReferenceRegisterHistory',
    'ReferenceRegisterItem',
    'ReferenceRegisterItemGroup',
    'ReferenceRegisterItemSubGroup',
    'SCCMLicenses',
    'SCCMLicensesItem',
    'SccmLicense',
    'SccmLicenseItem',
    'URICiselnik',
    'URIDataset',
    'URIKatalog',
    'UVS',
    'VerziaOntologie',
    'ZC',
    'ZS',
    'FazaZivotnehoCyklu',
    'ElektronickaSchranka',
    'Endpoint',
    'OperaciaAS',
    'Ciel',
]

export const API_MODULES_DEV: { [key: string]: string }[] = [
    { module: 'userconfig', url: `${import.meta.env.VITE_API_BASE_URL}/userconfig/endpoints/info` },
    { module: 'dms', url: `${import.meta.env.VITE_API_BASE_URL}/dms/endpoints/info` },
    { module: 'typesrepository', url: `${import.meta.env.VITE_API_BASE_URL}/typesrepository/endpoints/info` },
    { module: 'bpm-engine', url: `${import.meta.env.VITE_API_BASE_URL}/bpm-engine/endpoints/info` },
    { module: 'validation', url: `${import.meta.env.VITE_API_BASE_URL}/validation/endpoints/info` },
    { module: 'notificationengine', url: `${import.meta.env.VITE_API_BASE_URL}/notificationengine/endpoints/info` },
    { module: 'report', url: `${import.meta.env.VITE_API_BASE_URL}/report/endpoints/info` },
    { module: 'claimmanager', url: `${import.meta.env.VITE_API_BASE_URL}/claimmanager/endpoints/info` },
    { module: 'tco', url: `${import.meta.env.VITE_API_BASE_URL}/tco/endpoints/info` },
    { module: 'enumsrepository', url: `${import.meta.env.VITE_API_BASE_URL}/enumsrepository/endpoints/info` },
    { module: 'impexp', url: `${import.meta.env.VITE_API_BASE_URL}/impexp/endpoints/info` },
    { module: 'standards', url: `${import.meta.env.VITE_API_BASE_URL}/standards/endpoints/info` },
    { module: 'codelistrepository', url: `${import.meta.env.VITE_API_BASE_URL}/codelistrepository/endpoints/info` },
    { module: 'kris', url: `${import.meta.env.VITE_API_BASE_URL}/kris/endpoints/info` },
    { module: 'pdf-creator', url: `${import.meta.env.VITE_API_BASE_URL}/pdf-creator/endpoints/info` },
    { module: 'notification-manager', url: `${import.meta.env.VITE_API_BASE_URL}/notification-manager/endpoints/info` },
    { module: 'monitoring', url: `${import.meta.env.VITE_API_BASE_URL}/monitoring/endpoints/info` },
    { module: 'iam-oidc', url: `https://iam-oidc-metais3.apps.dev.isdd.sk/endpoints/info` },
    { module: 'iam', url: `${import.meta.env.VITE_API_BASE_URL}/metaisiam-rest/endpoints/info` },
    { module: 'trainings', url: `${import.meta.env.VITE_API_BASE_URL}/trainings/endpoints/info` },
    { module: 'wiki', url: `${import.meta.env.VITE_API_BASE_URL}/wiki/endpoints/info` },
    { module: 'global-config', url: 'http://global-config-metais3.apps.dev.isdd.sk/endpoints/info' },
    { module: 'opendata', url: 'http://opendata-metais3.apps.dev.isdd.sk/endpoints/info' },
    { module: 'cmdb', url: 'http://cmdb-metais3.apps.dev.isdd.sk/endpoints/info' },
    { module: 'integration', url: 'http://integration-metais3.apps.dev.isdd.sk/endpoints/info' },
    { module: 'elastic', url: 'http://elastic-metais3.apps.dev.isdd.sk/endpoints/info' },
    { module: 'reference-registers', url: 'http://reference-registers-metais3.apps.dev.isdd.sk/endpoints/info' },
    { module: 'cmdb-audit', url: 'http://cmdb-audit-metais3.apps.dev.isdd.sk/endpoints/info' },
    { module: 'dcom-integration', url: 'http://dcom-integration-metais3.apps.dev.isdd.sk/endpoints/info' },
]

export const API_MODULES: { module: string; url: string }[] = [
    { module: 'userconfig', url: `${import.meta.env.VITE_API_BASE_URL}/user-config/endpoints/info` },
    { module: 'dms', url: `${import.meta.env.VITE_API_BASE_URL}/dms/endpoints/info` },
    { module: 'typesrepository', url: `${import.meta.env.VITE_API_BASE_URL}/types-repo/endpoints/info` },
    { module: 'bpm-engine', url: `${import.meta.env.VITE_API_BASE_URL}/bpm-engine/endpoints/info` },
    { module: 'validation', url: `${import.meta.env.VITE_API_BASE_URL}/validation/endpoints/info` },
    { module: 'notificationengine', url: `${import.meta.env.VITE_API_BASE_URL}/notification-engine/endpoints/info` },
    { module: 'report', url: `${import.meta.env.VITE_API_BASE_URL}/report/endpoints/info` },
    { module: 'claimmanager', url: `${import.meta.env.VITE_API_BASE_URL}/claim-manager/endpoints/info` },
    { module: 'tco', url: `${import.meta.env.VITE_API_BASE_URL}/tco/endpoints/info` },
    { module: 'enumsrepository', url: `${import.meta.env.VITE_API_BASE_URL}/enums-repo/endpoints/info` },
    { module: 'impexp', url: `${import.meta.env.VITE_API_BASE_URL}/impexp-cmdb/endpoints/info` },
    { module: 'standards', url: `${import.meta.env.VITE_API_BASE_URL}/standards/endpoints/info` },
    { module: 'codelistrepository', url: `${import.meta.env.VITE_API_BASE_URL}/codelist-repo/endpoints/info` },
    { module: 'kris', url: `${import.meta.env.VITE_API_BASE_URL}/kris/endpoints/info` },
    { module: 'pdf-creator', url: `${import.meta.env.VITE_API_BASE_URL}/pdf-creator/endpoints/info` },
    { module: 'notification-manager', url: `${import.meta.env.VITE_API_BASE_URL}/notification-manager/endpoints/info` },
    { module: 'monitoring', url: `${import.meta.env.VITE_API_BASE_URL}/monitoring/endpoints/info` },
    { module: 'iam-oidc', url: `${import.meta.env.VITE_BASE_URL}/iam/endpoints/info` },
    { module: 'iam', url: `${import.meta.env.VITE_BASE_URL}/api/iam/endpoints/info` },
    { module: 'trainings', url: `${import.meta.env.VITE_API_BASE_URL}/trainings/endpoints/info` },
    { module: 'wiki', url: `${import.meta.env.VITE_API_BASE_URL}/wiki/endpoints/info` },
    { module: 'global-config', url: `${import.meta.env.VITE_API_BASE_URL}/global-config/endpoints/info` },
    { module: 'opendata', url: `${import.meta.env.VITE_API_BASE_URL}/opendata/endpoints/info` },
    { module: 'cmdb', url: `${import.meta.env.VITE_API_BASE_URL}/cmdb/endpoints/info` },
    { module: 'integration', url: `${import.meta.env.VITE_API_BASE_URL}/integration/endpoints/info` },
    { module: 'elastic', url: `${import.meta.env.VITE_API_BASE_URL}/elastic/endpoints/info` },
    { module: 'reference-registers', url: `${import.meta.env.VITE_API_BASE_URL}/reference-registers/endpoints/info` },
    { module: 'cmdb-audit', url: `${import.meta.env.VITE_API_BASE_URL}/cmdb-audit/endpoints/info` },
    { module: 'dcom-integration', url: `${import.meta.env.VITE_API_BASE_URL}/dcom-integration/endpoints/info` },
]

export const CAN_CREATE_MEETING_USER_ROLES = ['STD_KSPODP', 'STD_KSPRE', 'STD_KSTAJ', 'STD_PSPRE', 'STD_PSPODP', 'STD_KOORDINATOR_AGENDY']
export const CAN_EDIT_MEETING_USER_ROLES = ['STD_KOORDINATOR_AGENDY']
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const SPACES_REGEX = /\s+/g
export const baseWikiUrl = import.meta.env.VITE_REST_CLIENT_WIKI_BASE_URL
export const STAV_DIZ_ENUM_CODE = 'STAV_DIZ'
export const DECLARATION_ID = 'isvs_63'

export const categoryParameterMap = new Map<string, string>([
    ['AS', 'c_typ_parametra_kategoria.3'],
    ['KS', 'c_typ_parametra_kategoria.4'],
])
export const INTEGRACIA_KONZUMUJE_PROJEKT = 'Integracia_konzumuje_Projekt'
export const INTEGRACIA_VYSTAVUJE_PROJEKT = 'Integracia_vystavuje_Projekt'
export const KS_MA_FAZU_ZIVOTNEHO_CYKLU = 'KS_ma_FazaZivotnehoCyklu'
export const AS_MA_FAZU_ZIVOTNEHO_CYKLU = 'AS_ma_FazaZivotnehoCyklu'
export const INTEGRATION_HARMONOGRAM_EDIT_SEARCH_PARAM = 'edit'
export const FAZA_INTEGRACNEHO_MILNIKA = 'FAZA_INTEGRACNEHO_MILNIKA'
export const GUI_PROFILE_DIZ = 'Gui_Profil_DIZ'
export const PROVIDING_ISVS_NAME = 'providingIsvsName'
export const INTEGRACIA_Profil_Integracia_stav_diz = 'Integracia_Profil_Integracia_stav_diz'
export const Gui_Profil_DIZ_konzumujuci_projekt = 'Gui_Profil_DIZ_konzumujuci_projekt'
export const Gui_Profil_DIZ_poskytujuci_projekt = 'Gui_Profil_DIZ_poskytujuci_projekt'
export const PO_E_GOVERNMENT_UUID = '67d48d04-321e-426e-a321-e0bc6fa40437'
export const INT_SPRAVA = 'INT_SPRAVA'
export const INT_PODPIS = 'INT_PODPIS'
export const INT_MANAZ = 'INT_MANAZ'
export const PO_asociuje_Projekt = 'PO_asociuje_Projekt'
export const FAZA_KONTRAKTU = 'FAZA_KONTRAKTU'
export const GET_DOCUMENT_GROUPS_QUERY_KEY = 'projectDocumentManagementGroups'
export const Profil_Kontrakt = 'Profil_Kontrakt'
export const Program_financuje_Projekt = 'Program_financuje_Projekt'
export const GET_MEETING_REQUEST_DETAIL = 'getMeetingRequestDetail'
export const SLA_SPRAVA = 'SLA_SPRAVA'
export const OLA_Kontrakt = 'OLA_Kontrakt'
export const Profil_OLA_Kontrakt = 'Profil_OLA_Kontrakt'
export const STAV_OLA_KONTRAKT = 'STAV_OLA_KONTRAKT'
export const STAV_OLA_KONTRAKT_INITIAL = 'c_stav_ola_kontrakt.1'
export const OLA_Kontrakt_dodavatela_ISVS = 'OLA_Kontrakt_dodavatela_ISVS'
export const META_IS_TITLE = '| MetaIS'
export const META_IS_ADMIN_TITLE = '| MetaIS Admin'
export const UNAUTHORIZED = 'unauthorized'

export const OLA_CONTRACT_STATES = {
    PLANNED: 'c_stav_ola_kontrakt.1',
    APPROVED: 'c_stav_ola_kontrakt.2',
    RETURNED: 'c_stav_ola_kontrakt.3',
    CONTRACTED: 'c_stav_ola_kontrakt.4',
}

export const OLA_CONTRACT_STATE_ACTIONS = {
    APPROVE: 'APPROVE',
    RETURN: 'RETURN',
    PLAN: 'PLAN',
    CONTRACT: 'CONTRACT',
}

export enum C_STAV_REGISTRACIE {
    c_stav_registracie_2 = 'c_stav_registracie.2', //accepted registration
}
export enum HowTo {
    EGOV_HOWTO = 'EGOV_HOWTO',
    SPK_HOWTO = 'SPK_HOWTO',
    CODELISTS_HOWTO = 'CODELISTS_HOWTO',
    URI_HOWTO = 'URI_HOWTO',
    REF_REG_HOWTO = 'REF_REG_HOWTO',
    MONITORING_HOWTO = 'MONITORING_HOWTO',
    DATA_OBJECTS = 'DATA_OBJECTS',
}

export const BULK_ACTION_ITEM_SEPARATOR = ','
export const BULK_ACTION_ITEM_SEARCH_KEY = 'list'
export const ELASTIC_MAX_RECORDS = 10000
export const ELASTIC_MAX_PAGES = 1000

export const TASKS_QUERY_KEY = 'tasksQueryKey'

export enum ConstraintTypes {
    REGEX = 'regex',
    ENUM = 'enum',
    INTERVAL = 'interval',
    CI_TYPE = 'ciType',
}
export const DOT_SIGN = '.'

export const USERS_EXPORT_FILE_NAME = 'Zoznam_pouzivatelov_MetaIS'

export const STATUTORY_PROFILE = 'Profil_PO_statutar'
export const NARROW_MODAL_WIDTH_IN_PX = 640

export const datePlaceholder = 'dd.mm.yyyy'
export const dateTimePlaceholder = 'dd.mm.yyyy hh:mm'

export const DATE_FORMAT = {
    dayMonthYear: 'dd.MM.yyyy',
    dateWithHoursMinutes: 'dd.MM.yyyy HH:mm',
} as const

export const STICKY_HEADER_Z_INDEX = 9999

export const Aktivita_naplna_ciel = 'Aktivita_naplna_ciel'

export const NOTIFICATION_QUERY_KEY = 'NotificationsQueryKey'
export const ZDROJ_PRAVNEHO_ZAKLADU = 'ZDROJ_PRAVNEHO_ZAKLADU'
export const OTVORENOST_UDAJOV = 'OTVORENOST_UDAJOV'
export const TYP_OPRAVNENIA = 'TYP_OPRAVNENIA'

export const EURO = 'c_merna_jednotka.11'
export const EUR = 'c_merna_jednotka.13'
export const MAX_NUMBER_INPUT = 999_999_999_999_999
export const MAX_NUMBER_INPUT_SPLIT = MAX_NUMBER_INPUT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
export const GRAPH_SVG_ID = 'graphSvg'
export const ACESS_TOKEN = 'ROCP_token'

export const PAYMENT_TYPE_EKOLKY_1 = 'c_lv_payment_type.1'
export const PAYMENT_TYPE_EKOLKY_6 = 'c_lv_payment_type.6'

export const DOCUMENTS = 'documents'
export const ADD_REQUEST_ITEM = 'ADD_REQUEST_ITEM'

export const Profil_Webove_Sidlo_typ_ISVS_APP_WEB = ['c_typ_isvs.2', 'c_typ_isvs.8'] // c_typ_isvs.2=Website, c_typ_isvs.8=Mobile Application
export const Profil_Webove_Sidlo_typ_rozhrania_list = ['c_typ_rozhrania.5', 'c_typ_rozhrania.6', 'c_typ_rozhrania.2', 'c_typ_rozhrania.4'] // 5=mobile, 6=WEB, 2=extranet, 4=intranet
export const ISVS_Attribute_Profiles_for_web = ['Profil_WeboveSidlo', 'Profil_Vyhlasenie_Pristupnost']
export const EA_Profil_ISVS_typ_isvs = 'EA_Profil_ISVS_typ_isvs'
export const METODA_RIADENIA_PROJEKTU = {
    waterfall: 'c_metoda_riadenia_projektu.1',
    agile: 'c_metoda_riadenia_projektu.2',
} as const
export const EA_Profil_Formular = 'EA_Profil_Formular'

export const LOCAL_STORAGE_PUBLIC_AUTHORITY_CREATE = 'public-authority-create'

export const ZC_CONNECTED_TO_ENUM = 'je_prepojena_Ciselnik'
