//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * MetaIS Standards
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { useStandardsSwaggerClient } from '../hooks/useStandardsSwaggerClient'
export type AcceptMeetingRequestParams = {
    token: string
}

export type RejectMeetingRequestParams = {
    token: string
}

export type CancelMeetingRequest200 = { [key: string]: unknown }

export type ParticipateMeetingRequest200 = { [key: string]: unknown }

export type ParticipateMeetingRequestParams = {
    participation: string
}

export type GetMeetingRequestsParams = {
    pageNumber: number
    perPage: number
    sortBy?: string
    ascending?: boolean
    state?: string
    createdBy?: string
    onlyMy?: boolean
    fromDate?: string
    toDate?: string
    workGroupId?: string
}

export type Reindex2200 = { [key: string]: unknown }

export type Reindex1200 = { [key: string]: unknown }

export type Reindex200 = { [key: string]: unknown }

export type CreateStandardRequestUpload200 = { [key: string]: unknown }

export type CreateStandardRequestUploadBody = {
    file?: Blob
}

export type CreateStandardRequestUploadParams = {
    request: string
}

export type GetFOPStandardRequestsRequestChannel = (typeof GetFOPStandardRequestsRequestChannel)[keyof typeof GetFOPStandardRequestsRequestChannel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetFOPStandardRequestsRequestChannel = {
    WEB: 'WEB',
    RI: 'RI',
    RR: 'RR',
    ZC_Request: 'ZC_Request',
    ZC_Header: 'ZC_Header',
} as const

export type GetFOPStandardRequestsParams = {
    pageNumber: number
    perPage: number
    sortBy?: string
    ascending?: boolean
    state?: string
    createdBy?: string
    name?: string
    workGroupId?: string
    requestChannel?: GetFOPStandardRequestsRequestChannel
    fromDate?: string
    toDate?: string
    draftName?: string
}

export type UpdateVoteRole1200 = { [key: string]: unknown }

export type CancelVote200 = { [key: string]: unknown }

export type SummarizeVote200 = { [key: string]: unknown }

export type CastVote1200 = { [key: string]: unknown }

export type VetoVote1200 = { [key: string]: unknown }

export type VoteNote200 = { [key: string]: unknown }

export type GetVotesParams = {
    pageNumber: number
    perPage: number
    sortBy?: string
    ascending?: boolean
    isSecret?: boolean
    state?: string
    createdBy?: string
    fromDate?: string
    toDate?: string
    onlyMy?: boolean
}

export type SummarizeMeetingRequest200 = { [key: string]: unknown }

export type UpdateSummarizeData200 = { [key: string]: unknown }

export type AddUserToActiveVotes200 = { [key: string]: unknown }

export type AddUserToActiveVotesBody = { [key: string]: string }

export type ActionStandardRequest200 = { [key: string]: unknown }

export type ActionStandardRequestParams = {
    action: string
}

export type AssignStandardRequest200 = { [key: string]: unknown }

export type UpdateActorsVote200 = { [key: string]: unknown }

export interface ApiMeetingRequestPreview {
    actionDesription?: string
    beginDate?: string
    createdAt?: string
    createdBy?: string
    description?: string
    endDate?: string
    groups?: string[]
    id?: number
    lastModifiedAt?: string
    name?: string
    place?: string
    state?: string
    summarizedAt?: string
    summarizedBy?: string
    summarizedLink?: string
}

export interface ApiMeetingRequestPreviewList {
    meetingRequests?: ApiMeetingRequestPreview[]
    meetingRequestsCount?: number
}

export type ApiStandardRequestPreviewRequestChannel =
    (typeof ApiStandardRequestPreviewRequestChannel)[keyof typeof ApiStandardRequestPreviewRequestChannel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiStandardRequestPreviewRequestChannel = {
    WEB: 'WEB',
    RI: 'RI',
    RR: 'RR',
    ZC_Request: 'ZC_Request',
    ZC_Header: 'ZC_Header',
} as const

export interface ApiStandardRequestPreview {
    actionDescription?: string
    createdAt?: string
    createdBy?: string
    email?: string
    fullName?: string
    id?: number
    name?: string
    requestChannel?: ApiStandardRequestPreviewRequestChannel
    standardRequestState?: string
    workGroupId?: string
}

export interface ApiStandardRequestPreviewList {
    standardRequests?: ApiStandardRequestPreview[]
    standardRequestsCount?: number
}

export interface ApiVoteChoiceResult {
    description?: string
    id?: number
    value?: string
    votedActorsCount?: number
}

export interface ApiVoteActorResult {
    groupId?: string
    groupName?: string
    groupShortName?: string
    sendNotification?: boolean
    substitutorIds?: string[]
    token?: string
    userId?: string
    userName?: string
    userOrgId?: string
    userOrgName?: string
    userRoleDesc?: string
    userRoleId?: string
    userRoleName?: string
    veto?: boolean
    votedAt?: string
    votedBy?: string
    votedChoiceId?: number
    votedChoiceValue?: string
    voteDescription?: string
}

export interface ApiVoteUserActorResults {
    loggedUserActorResult?: ApiVoteActorResult
    substitutedByLoggedUserActorResults?: ApiVoteActorResult[]
}

export interface ApiVoteResult {
    actorResults?: ApiVoteActorResult[]
    choiceResults?: ApiVoteChoiceResult[]
    invitedActorsCount?: number
    vetoedBy?: string
    vetoedDescription?: string
    voteActorPendingChanges?: ApiVoteActorPendingChange[]
    votedActorsCount?: number
}

export interface ApiVotePreview {
    actionDesription?: string
    canCast?: boolean
    createdAt?: string
    createdBy?: string
    description?: string
    effectiveFrom?: string
    effectiveTo?: string
    groups?: ApiGroup[]
    hasCast?: string
    id?: number
    name?: string
    secret?: boolean
    standardRequestId?: number
    veto?: boolean
    voteState?: string
}

export interface ApiVotePreviewList {
    votes?: ApiVotePreview[]
    votesCount?: number
}

export interface Group {
    description?: string
    name?: string
    shortName?: string
    uuid?: string
}

export interface GroupMeetings {
    group?: Group
    lastMeetingDate?: string
    nextMeetingDate?: string
}

export type FillRefAttributesTaskStatus = (typeof FillRefAttributesTaskStatus)[keyof typeof FillRefAttributesTaskStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FillRefAttributesTaskStatus = {
    CREATED: 'CREATED',
    IN_PROGRESS_PREPARE_DATA: 'IN_PROGRESS_PREPARE_DATA',
    IN_PROGRESS_UPDATE_METADATA_IN_DMS: 'IN_PROGRESS_UPDATE_METADATA_IN_DMS',
    FINISHED: 'FINISHED',
    CANCELLED: 'CANCELLED',
} as const

export type DocumentInfoState = (typeof DocumentInfoState)[keyof typeof DocumentInfoState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentInfoState = {
    ERROR: 'ERROR',
    INCONSISTENT: 'INCONSISTENT',
    NOT_FOUND: 'NOT_FOUND',
    SOFT_DELETED: 'SOFT_DELETED',
    STATE_MISSING: 'STATE_MISSING',
    UPDATED: 'UPDATED',
} as const

export interface DocumentInfo {
    errorMessage?: string
    id?: string
    state?: DocumentInfoState
}

export interface FillRefAttributesTask {
    endedAt?: string
    failedCount?: number
    failedCountError?: number
    failedCountInconsistent?: number
    failedCountNotFound?: number
    failedCountSoftDeleted?: number
    failedCountStateMissing?: number
    failedUuids?: DocumentInfo[]
    id?: string
    startedAt?: string
    status?: FillRefAttributesTaskStatus
    successCount?: number
    successUuids?: string[]
    taskBriefInfo?: string
    totalCount?: number
}

export interface UpdateVoteRequest {
    newRoleDesc?: string
    newRoleName?: string
    newRoleUuid?: string
    userIdLogin?: string
}

export interface ApiVoteNote {
    note?: string
}

export interface ApiMeetingResult {
    linkUrl?: string
}

export interface ApiMeetingExternalActor {
    description?: string
    email?: string
    id?: number
    name?: string
    participation?: string
}

export interface ApiMeetingActor {
    groupId?: string
    groupName?: string
    groupShortName?: string
    participation?: string
    userId?: string
    userName?: string
    userOrgId?: string
    userOrgName?: string
    userRoleDesc?: string
    userRoleId?: string
    userRoleName?: string
}

export interface ApiMeetingRequest {
    actionDesription?: string
    addedActors?: ApiMeetingActor[]
    addedExternalActors?: ApiMeetingExternalActor[]
    beginDate?: string
    createdAt?: string
    createdBy?: string
    description?: string
    descriptionOfChange?: string
    endDate?: string
    groupNames?: string[]
    groups?: string[]
    id?: number
    ignorePersonalSettings?: boolean
    lastModifiedAt?: string
    meetingActors?: ApiMeetingActor[]
    meetingAttachments?: ApiAttachment[]
    meetingExternalActors?: ApiMeetingExternalActor[]
    meetingLinks?: ApiLink[]
    name?: string
    notifNewUsers?: boolean
    place?: string
    removedActors?: ApiMeetingActor[]
    removedExternalActors?: ApiMeetingExternalActor[]
    standardRequestIds?: number[]
    standardRequestsNames?: string[]
    state?: string
    summarizedAt?: string
    summarizedBy?: string
    summarizedLink?: string
}

export interface ApiDescription {
    description?: string
}

export type ApiStandardRequestRequestChannel = (typeof ApiStandardRequestRequestChannel)[keyof typeof ApiStandardRequestRequestChannel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiStandardRequestRequestChannel = {
    WEB: 'WEB',
    RI: 'RI',
    RR: 'RR',
    ZC_Request: 'ZC_Request',
    ZC_Header: 'ZC_Header',
} as const

export interface ApiAttribute {
    attributeName?: string
    attributeValue?: string
}

export interface ApiVoteChoice {
    description?: string
    id?: number
    value?: string
}

export interface ApiVoteActorPendingChange {
    changeAction?: string
    changeActionDescription?: string
    changeDate?: string
    groupId?: string
    groupName?: string
    groupShortName?: string
    userId?: string
    userName?: string
    userOrgId?: string
    userOrgName?: string
    userRoleDesc?: string
    userRoleId?: string
    userRoleName?: string
}

export interface ApiVoteActor {
    groupId?: string
    groupName?: string
    groupShortName?: string
    sendNotification?: boolean
    substitutorIds?: string[]
    token?: string
    userId?: string
    userName?: string
    userOrgId?: string
    userOrgName?: string
    userRoleDesc?: string
    userRoleId?: string
    userRoleName?: string
}

export interface ApiLink {
    id?: number
    linkDescription?: string
    linkSize?: string
    linkType?: string
    name?: string
    type?: string
    url?: string
}

export interface ApiGroup {
    id?: string
    name?: string
    shortName?: string
}

export interface ApiAttachment {
    attachmentDescription?: string
    attachmentId?: string
    attachmentName?: string
    id?: number
}

export interface ApiStandardRequest {
    actionDescription?: string
    attachments?: ApiAttachment[]
    createdAt?: string
    createdBy?: string
    description?: string
    email?: string
    financialImpact?: string
    fullName?: string
    id?: number
    legislativeTextProposal?: string
    links?: ApiLink[]
    name?: string
    placementProposal?: string
    privacyImpact?: string
    requestChannel?: ApiStandardRequestRequestChannel
    requestChannelAttributes?: ApiAttribute[]
    securityImpact?: string
    standardRequestState?: string
    workGroupId?: string
}

export interface ApiVote {
    actionDesription?: string
    attachments?: ApiAttachment[]
    canCast?: boolean
    createdAt?: string
    createdBy?: string
    description?: string
    descriptionOfChange?: string
    effectiveFrom?: string
    effectiveTo?: string
    groups?: ApiGroup[]
    hasCast?: string
    id?: number
    links?: ApiLink[]
    name?: string
    secret?: boolean
    standardRequestId?: number
    veto?: boolean
    voteActorPendingChanges?: ApiVoteActorPendingChange[]
    voteActors?: ApiVoteActor[]
    voteChoices?: ApiVoteChoice[]
    voteState?: string
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useGetVoteDetailHook = () => {
    const getVoteDetail = useStandardsSwaggerClient<ApiVote>()

    return useCallback(
        (voteId: number, signal?: AbortSignal) => {
            return getVoteDetail({ url: `/standards/votes/${voteId}`, method: 'GET', signal })
        },
        [getVoteDetail],
    )
}

export const getGetVoteDetailQueryKey = (voteId: number) => {
    return [`/standards/votes/${voteId}`] as const
}

export const useGetVoteDetailQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>, TError = ApiError>(
    voteId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetVoteDetailQueryKey(voteId)

    const getVoteDetail = useGetVoteDetailHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>> = ({ signal }) => getVoteDetail(voteId, signal)

    return { queryKey, queryFn, enabled: !!voteId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetVoteDetailQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>>
export type GetVoteDetailQueryError = ApiError

export function useGetVoteDetail<TData = Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>, TError = ApiError>(
    voteId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetVoteDetailQueryOptions(voteId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateVoteHook = () => {
    const updateVote = useStandardsSwaggerClient<ApiVote>()

    return useCallback(
        (voteId: number, apiVote: ApiVote) => {
            return updateVote({ url: `/standards/votes/${voteId}`, method: 'PUT', headers: { 'Content-Type': 'application/json' }, data: apiVote })
        },
        [updateVote],
    )
}

export const useUpdateVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateVoteHook>>>, TError, { voteId: number; data: ApiVote }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateVoteHook>>>, TError, { voteId: number; data: ApiVote }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateVote = useUpdateVoteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateVoteHook>>>, { voteId: number; data: ApiVote }> = (props) => {
        const { voteId, data } = props ?? {}

        return updateVote(voteId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateVoteHook>>>>
export type UpdateVoteMutationBody = ApiVote
export type UpdateVoteMutationError = ApiError

export const useUpdateVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateVoteHook>>>, TError, { voteId: number; data: ApiVote }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useUpdateVoteHook>>>, TError, { voteId: number; data: ApiVote }, TContext> => {
    const mutationOptions = useUpdateVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateActorsVoteHook = () => {
    const updateActorsVote = useStandardsSwaggerClient<UpdateActorsVote200>()

    return useCallback(
        (voteId: number, apiVote: ApiVote) => {
            return updateActorsVote({
                url: `/standards/votes/${voteId}/actors`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiVote,
            })
        },
        [updateActorsVote],
    )
}

export const useUpdateActorsVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateActorsVoteHook>>>,
        TError,
        { voteId: number; data: ApiVote },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateActorsVoteHook>>>, TError, { voteId: number; data: ApiVote }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateActorsVote = useUpdateActorsVoteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateActorsVoteHook>>>, { voteId: number; data: ApiVote }> = (
        props,
    ) => {
        const { voteId, data } = props ?? {}

        return updateActorsVote(voteId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateActorsVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateActorsVoteHook>>>>
export type UpdateActorsVoteMutationBody = ApiVote
export type UpdateActorsVoteMutationError = ApiError

export const useUpdateActorsVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateActorsVoteHook>>>,
        TError,
        { voteId: number; data: ApiVote },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useUpdateActorsVoteHook>>>, TError, { voteId: number; data: ApiVote }, TContext> => {
    const mutationOptions = useUpdateActorsVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetStandardRequestDetailHook = () => {
    const getStandardRequestDetail = useStandardsSwaggerClient<ApiStandardRequest>()

    return useCallback(
        (standardRequestId: number, signal?: AbortSignal) => {
            return getStandardRequestDetail({ url: `/standards/requests/${standardRequestId}`, method: 'GET', signal })
        },
        [getStandardRequestDetail],
    )
}

export const getGetStandardRequestDetailQueryKey = (standardRequestId: number) => {
    return [`/standards/requests/${standardRequestId}`] as const
}

export const useGetStandardRequestDetailQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>,
    TError = ApiError,
>(
    standardRequestId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetStandardRequestDetailQueryKey(standardRequestId)

    const getStandardRequestDetail = useGetStandardRequestDetailHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>> = ({ signal }) =>
        getStandardRequestDetail(standardRequestId, signal)

    return { queryKey, queryFn, enabled: !!standardRequestId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetStandardRequestDetailQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>>
export type GetStandardRequestDetailQueryError = ApiError

export function useGetStandardRequestDetail<TData = Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>, TError = ApiError>(
    standardRequestId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetStandardRequestDetailQueryOptions(standardRequestId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateStandardRequestHook = () => {
    const updateStandardRequest = useStandardsSwaggerClient<ApiStandardRequest>()

    return useCallback(
        (standardRequestId: number, apiStandardRequest: ApiStandardRequest) => {
            return updateStandardRequest({
                url: `/standards/requests/${standardRequestId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiStandardRequest,
            })
        },
        [updateStandardRequest],
    )
}

export const useUpdateStandardRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateStandardRequestHook>>>,
        TError,
        { standardRequestId: number; data: ApiStandardRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateStandardRequestHook>>>,
    TError,
    { standardRequestId: number; data: ApiStandardRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateStandardRequest = useUpdateStandardRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateStandardRequestHook>>>,
        { standardRequestId: number; data: ApiStandardRequest }
    > = (props) => {
        const { standardRequestId, data } = props ?? {}

        return updateStandardRequest(standardRequestId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateStandardRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateStandardRequestHook>>>>
export type UpdateStandardRequestMutationBody = ApiStandardRequest
export type UpdateStandardRequestMutationError = ApiError

export const useUpdateStandardRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateStandardRequestHook>>>,
        TError,
        { standardRequestId: number; data: ApiStandardRequest },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateStandardRequestHook>>>,
    TError,
    { standardRequestId: number; data: ApiStandardRequest },
    TContext
> => {
    const mutationOptions = useUpdateStandardRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAssignStandardRequestHook = () => {
    const assignStandardRequest = useStandardsSwaggerClient<AssignStandardRequest200>()

    return useCallback(
        (standardRequestId: number, groupId: string) => {
            return assignStandardRequest({ url: `/standards/requests/${standardRequestId}/assign/${groupId}`, method: 'PUT' })
        },
        [assignStandardRequest],
    )
}

export const useAssignStandardRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAssignStandardRequestHook>>>,
        TError,
        { standardRequestId: number; groupId: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignStandardRequestHook>>>,
    TError,
    { standardRequestId: number; groupId: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const assignStandardRequest = useAssignStandardRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAssignStandardRequestHook>>>,
        { standardRequestId: number; groupId: string }
    > = (props) => {
        const { standardRequestId, groupId } = props ?? {}

        return assignStandardRequest(standardRequestId, groupId)
    }

    return { mutationFn, ...mutationOptions }
}

export type AssignStandardRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAssignStandardRequestHook>>>>

export type AssignStandardRequestMutationError = ApiError

export const useAssignStandardRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAssignStandardRequestHook>>>,
        TError,
        { standardRequestId: number; groupId: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useAssignStandardRequestHook>>>,
    TError,
    { standardRequestId: number; groupId: string },
    TContext
> => {
    const mutationOptions = useAssignStandardRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useActionStandardRequestHook = () => {
    const actionStandardRequest = useStandardsSwaggerClient<ActionStandardRequest200>()

    return useCallback(
        (standardRequestId: number, apiDescription: ApiDescription, params: ActionStandardRequestParams) => {
            return actionStandardRequest({
                url: `/standards/requests/${standardRequestId}/action`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiDescription,
                params,
            })
        },
        [actionStandardRequest],
    )
}

export const useActionStandardRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useActionStandardRequestHook>>>,
        TError,
        { standardRequestId: number; data: ApiDescription; params: ActionStandardRequestParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useActionStandardRequestHook>>>,
    TError,
    { standardRequestId: number; data: ApiDescription; params: ActionStandardRequestParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const actionStandardRequest = useActionStandardRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useActionStandardRequestHook>>>,
        { standardRequestId: number; data: ApiDescription; params: ActionStandardRequestParams }
    > = (props) => {
        const { standardRequestId, data, params } = props ?? {}

        return actionStandardRequest(standardRequestId, data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ActionStandardRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useActionStandardRequestHook>>>>
export type ActionStandardRequestMutationBody = ApiDescription
export type ActionStandardRequestMutationError = ApiError

export const useActionStandardRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useActionStandardRequestHook>>>,
        TError,
        { standardRequestId: number; data: ApiDescription; params: ActionStandardRequestParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useActionStandardRequestHook>>>,
    TError,
    { standardRequestId: number; data: ApiDescription; params: ActionStandardRequestParams },
    TContext
> => {
    const mutationOptions = useActionStandardRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddUserToActiveVotesHook = () => {
    const addUserToActiveVotes = useStandardsSwaggerClient<AddUserToActiveVotes200>()

    return useCallback(
        (groupShortName: string, userId: string, addUserToActiveVotesBody: AddUserToActiveVotesBody) => {
            return addUserToActiveVotes({
                url: `/standards/addToActiveVotes/${groupShortName}/${userId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: addUserToActiveVotesBody,
            })
        },
        [addUserToActiveVotes],
    )
}

export const useAddUserToActiveVotesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddUserToActiveVotesHook>>>,
        TError,
        { groupShortName: string; userId: string; data: AddUserToActiveVotesBody },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddUserToActiveVotesHook>>>,
    TError,
    { groupShortName: string; userId: string; data: AddUserToActiveVotesBody },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const addUserToActiveVotes = useAddUserToActiveVotesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddUserToActiveVotesHook>>>,
        { groupShortName: string; userId: string; data: AddUserToActiveVotesBody }
    > = (props) => {
        const { groupShortName, userId, data } = props ?? {}

        return addUserToActiveVotes(groupShortName, userId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddUserToActiveVotesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddUserToActiveVotesHook>>>>
export type AddUserToActiveVotesMutationBody = AddUserToActiveVotesBody
export type AddUserToActiveVotesMutationError = ApiError

export const useAddUserToActiveVotes = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddUserToActiveVotesHook>>>,
        TError,
        { groupShortName: string; userId: string; data: AddUserToActiveVotesBody },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useAddUserToActiveVotesHook>>>,
    TError,
    { groupShortName: string; userId: string; data: AddUserToActiveVotesBody },
    TContext
> => {
    const mutationOptions = useAddUserToActiveVotesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetMeetingRequestDetailHook = () => {
    const getMeetingRequestDetail = useStandardsSwaggerClient<ApiMeetingRequest>()

    return useCallback(
        (meetingRequestId: number, signal?: AbortSignal) => {
            return getMeetingRequestDetail({ url: `/meetings/${meetingRequestId}`, method: 'GET', signal })
        },
        [getMeetingRequestDetail],
    )
}

export const getGetMeetingRequestDetailQueryKey = (meetingRequestId: number) => {
    return [`/meetings/${meetingRequestId}`] as const
}

export const useGetMeetingRequestDetailQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>,
    TError = ApiError,
>(
    meetingRequestId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetMeetingRequestDetailQueryKey(meetingRequestId)

    const getMeetingRequestDetail = useGetMeetingRequestDetailHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>> = ({ signal }) =>
        getMeetingRequestDetail(meetingRequestId, signal)

    return { queryKey, queryFn, enabled: !!meetingRequestId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetMeetingRequestDetailQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>>
export type GetMeetingRequestDetailQueryError = ApiError

export function useGetMeetingRequestDetail<TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>, TError = ApiError>(
    meetingRequestId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetMeetingRequestDetailQueryOptions(meetingRequestId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateMeetingRequestHook = () => {
    const updateMeetingRequest = useStandardsSwaggerClient<ApiMeetingRequest>()

    return useCallback(
        (meetingRequestId: number, apiMeetingRequest: ApiMeetingRequest) => {
            return updateMeetingRequest({
                url: `/meetings/${meetingRequestId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiMeetingRequest,
            })
        },
        [updateMeetingRequest],
    )
}

export const useUpdateMeetingRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; data: ApiMeetingRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingRequestHook>>>,
    TError,
    { meetingRequestId: number; data: ApiMeetingRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateMeetingRequest = useUpdateMeetingRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateMeetingRequestHook>>>,
        { meetingRequestId: number; data: ApiMeetingRequest }
    > = (props) => {
        const { meetingRequestId, data } = props ?? {}

        return updateMeetingRequest(meetingRequestId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateMeetingRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateMeetingRequestHook>>>>
export type UpdateMeetingRequestMutationBody = ApiMeetingRequest
export type UpdateMeetingRequestMutationError = ApiError

export const useUpdateMeetingRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; data: ApiMeetingRequest },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingRequestHook>>>,
    TError,
    { meetingRequestId: number; data: ApiMeetingRequest },
    TContext
> => {
    const mutationOptions = useUpdateMeetingRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateSummarizeDataHook = () => {
    const updateSummarizeData = useStandardsSwaggerClient<UpdateSummarizeData200>()

    return useCallback(
        (meetingRequestId: number, apiMeetingResult: ApiMeetingResult) => {
            return updateSummarizeData({
                url: `/meetings/${meetingRequestId}/summarize`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiMeetingResult,
            })
        },
        [updateSummarizeData],
    )
}

export const useUpdateSummarizeDataMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateSummarizeDataHook>>>,
        TError,
        { meetingRequestId: number; data: ApiMeetingResult },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateSummarizeDataHook>>>,
    TError,
    { meetingRequestId: number; data: ApiMeetingResult },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateSummarizeData = useUpdateSummarizeDataHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateSummarizeDataHook>>>,
        { meetingRequestId: number; data: ApiMeetingResult }
    > = (props) => {
        const { meetingRequestId, data } = props ?? {}

        return updateSummarizeData(meetingRequestId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateSummarizeDataMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateSummarizeDataHook>>>>
export type UpdateSummarizeDataMutationBody = ApiMeetingResult
export type UpdateSummarizeDataMutationError = ApiError

export const useUpdateSummarizeData = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateSummarizeDataHook>>>,
        TError,
        { meetingRequestId: number; data: ApiMeetingResult },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateSummarizeDataHook>>>,
    TError,
    { meetingRequestId: number; data: ApiMeetingResult },
    TContext
> => {
    const mutationOptions = useUpdateSummarizeDataMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useSummarizeMeetingRequestHook = () => {
    const summarizeMeetingRequest = useStandardsSwaggerClient<SummarizeMeetingRequest200>()

    return useCallback(
        (meetingRequestId: number, apiMeetingResult: ApiMeetingResult) => {
            return summarizeMeetingRequest({
                url: `/meetings/${meetingRequestId}/summarize`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiMeetingResult,
            })
        },
        [summarizeMeetingRequest],
    )
}

export const useSummarizeMeetingRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSummarizeMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; data: ApiMeetingResult },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSummarizeMeetingRequestHook>>>,
    TError,
    { meetingRequestId: number; data: ApiMeetingResult },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const summarizeMeetingRequest = useSummarizeMeetingRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useSummarizeMeetingRequestHook>>>,
        { meetingRequestId: number; data: ApiMeetingResult }
    > = (props) => {
        const { meetingRequestId, data } = props ?? {}

        return summarizeMeetingRequest(meetingRequestId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SummarizeMeetingRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSummarizeMeetingRequestHook>>>>
export type SummarizeMeetingRequestMutationBody = ApiMeetingResult
export type SummarizeMeetingRequestMutationError = ApiError

export const useSummarizeMeetingRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSummarizeMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; data: ApiMeetingResult },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useSummarizeMeetingRequestHook>>>,
    TError,
    { meetingRequestId: number; data: ApiMeetingResult },
    TContext
> => {
    const mutationOptions = useSummarizeMeetingRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetVotesHook = () => {
    const getVotes = useStandardsSwaggerClient<ApiVotePreviewList>()

    return useCallback(
        (params: GetVotesParams, signal?: AbortSignal) => {
            return getVotes({ url: `/standards/votes`, method: 'GET', params, signal })
        },
        [getVotes],
    )
}

export const getGetVotesQueryKey = (params: GetVotesParams) => {
    return [`/standards/votes`, ...(params ? [params] : [])] as const
}

export const useGetVotesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>, TError = ApiError>(
    params: GetVotesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetVotesQueryKey(params)

    const getVotes = useGetVotesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>> = ({ signal }) => getVotes(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type GetVotesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>>
export type GetVotesQueryError = ApiError

export function useGetVotes<TData = Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>, TError = ApiError>(
    params: GetVotesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetVotesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCreateVoteHook = () => {
    const createVote = useStandardsSwaggerClient<ApiVote>()

    return useCallback(
        (apiVote: ApiVote) => {
            return createVote({ url: `/standards/votes`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: apiVote })
        },
        [createVote],
    )
}

export const useCreateVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateVoteHook>>>, TError, { data: ApiVote }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateVoteHook>>>, TError, { data: ApiVote }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const createVote = useCreateVoteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateVoteHook>>>, { data: ApiVote }> = (props) => {
        const { data } = props ?? {}

        return createVote(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateVoteHook>>>>
export type CreateVoteMutationBody = ApiVote
export type CreateVoteMutationError = ApiError

export const useCreateVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateVoteHook>>>, TError, { data: ApiVote }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCreateVoteHook>>>, TError, { data: ApiVote }, TContext> => {
    const mutationOptions = useCreateVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useVetoVoteHook = () => {
    const vetoVote = useStandardsSwaggerClient<void>()

    return useCallback(
        (voteId: number, token: string) => {
            return vetoVote({ url: `/standards/votes/${voteId}/userToken/${token}/veto`, method: 'POST' })
        },
        [vetoVote],
    )
}

export const useVetoVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useVetoVoteHook>>>, TError, { voteId: number; token: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useVetoVoteHook>>>, TError, { voteId: number; token: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const vetoVote = useVetoVoteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useVetoVoteHook>>>, { voteId: number; token: string }> = (props) => {
        const { voteId, token } = props ?? {}

        return vetoVote(voteId, token)
    }

    return { mutationFn, ...mutationOptions }
}

export type VetoVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useVetoVoteHook>>>>

export type VetoVoteMutationError = ApiError

export const useVetoVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useVetoVoteHook>>>, TError, { voteId: number; token: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useVetoVoteHook>>>, TError, { voteId: number; token: string }, TContext> => {
    const mutationOptions = useVetoVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useVoteNoteHook = () => {
    const voteNote = useStandardsSwaggerClient<VoteNote200>()

    return useCallback(
        (voteId: number, token: string, apiVoteNote: ApiVoteNote) => {
            return voteNote({
                url: `/standards/votes/${voteId}/userToken/${token}/note`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiVoteNote,
            })
        },
        [voteNote],
    )
}

export const useVoteNoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useVoteNoteHook>>>,
        TError,
        { voteId: number; token: string; data: ApiVoteNote },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useVoteNoteHook>>>,
    TError,
    { voteId: number; token: string; data: ApiVoteNote },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const voteNote = useVoteNoteHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useVoteNoteHook>>>,
        { voteId: number; token: string; data: ApiVoteNote }
    > = (props) => {
        const { voteId, token, data } = props ?? {}

        return voteNote(voteId, token, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type VoteNoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useVoteNoteHook>>>>
export type VoteNoteMutationBody = ApiVoteNote
export type VoteNoteMutationError = ApiError

export const useVoteNote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useVoteNoteHook>>>,
        TError,
        { voteId: number; token: string; data: ApiVoteNote },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useVoteNoteHook>>>,
    TError,
    { voteId: number; token: string; data: ApiVoteNote },
    TContext
> => {
    const mutationOptions = useVoteNoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCastVoteHook = () => {
    const castVote = useStandardsSwaggerClient<void>()

    return useCallback(
        (voteId: number, token: string, choiceId: number) => {
            return castVote({ url: `/standards/votes/${voteId}/userToken/${token}/cast/${choiceId}`, method: 'POST' })
        },
        [castVote],
    )
}

export const useCastVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCastVoteHook>>>,
        TError,
        { voteId: number; token: string; choiceId: number },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCastVoteHook>>>,
    TError,
    { voteId: number; token: string; choiceId: number },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const castVote = useCastVoteHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCastVoteHook>>>,
        { voteId: number; token: string; choiceId: number }
    > = (props) => {
        const { voteId, token, choiceId } = props ?? {}

        return castVote(voteId, token, choiceId)
    }

    return { mutationFn, ...mutationOptions }
}

export type CastVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCastVoteHook>>>>

export type CastVoteMutationError = ApiError

export const useCastVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCastVoteHook>>>,
        TError,
        { voteId: number; token: string; choiceId: number },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useCastVoteHook>>>,
    TError,
    { voteId: number; token: string; choiceId: number },
    TContext
> => {
    const mutationOptions = useCastVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useVetoVote1Hook = () => {
    const vetoVote1 = useStandardsSwaggerClient<VetoVote1200>()

    return useCallback(
        (voteId: number, castedUserId: string, apiDescription: ApiDescription) => {
            return vetoVote1({
                url: `/standards/votes/${voteId}/user/${castedUserId}/veto`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiDescription,
            })
        },
        [vetoVote1],
    )
}

export const useVetoVote1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useVetoVote1Hook>>>,
        TError,
        { voteId: number; castedUserId: string; data: ApiDescription },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useVetoVote1Hook>>>,
    TError,
    { voteId: number; castedUserId: string; data: ApiDescription },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const vetoVote1 = useVetoVote1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useVetoVote1Hook>>>,
        { voteId: number; castedUserId: string; data: ApiDescription }
    > = (props) => {
        const { voteId, castedUserId, data } = props ?? {}

        return vetoVote1(voteId, castedUserId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type VetoVote1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useVetoVote1Hook>>>>
export type VetoVote1MutationBody = ApiDescription
export type VetoVote1MutationError = ApiError

export const useVetoVote1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useVetoVote1Hook>>>,
        TError,
        { voteId: number; castedUserId: string; data: ApiDescription },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useVetoVote1Hook>>>,
    TError,
    { voteId: number; castedUserId: string; data: ApiDescription },
    TContext
> => {
    const mutationOptions = useVetoVote1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCastVote1Hook = () => {
    const castVote1 = useStandardsSwaggerClient<CastVote1200>()

    return useCallback(
        (voteId: number, castedUserId: string, choiceId: number, apiDescription: ApiDescription) => {
            return castVote1({
                url: `/standards/votes/${voteId}/user/${castedUserId}/cast/${choiceId}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiDescription,
            })
        },
        [castVote1],
    )
}

export const useCastVote1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCastVote1Hook>>>,
        TError,
        { voteId: number; castedUserId: string; choiceId: number; data: ApiDescription },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCastVote1Hook>>>,
    TError,
    { voteId: number; castedUserId: string; choiceId: number; data: ApiDescription },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const castVote1 = useCastVote1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCastVote1Hook>>>,
        { voteId: number; castedUserId: string; choiceId: number; data: ApiDescription }
    > = (props) => {
        const { voteId, castedUserId, choiceId, data } = props ?? {}

        return castVote1(voteId, castedUserId, choiceId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CastVote1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCastVote1Hook>>>>
export type CastVote1MutationBody = ApiDescription
export type CastVote1MutationError = ApiError

export const useCastVote1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCastVote1Hook>>>,
        TError,
        { voteId: number; castedUserId: string; choiceId: number; data: ApiDescription },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useCastVote1Hook>>>,
    TError,
    { voteId: number; castedUserId: string; choiceId: number; data: ApiDescription },
    TContext
> => {
    const mutationOptions = useCastVote1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useSummarizeVoteHook = () => {
    const summarizeVote = useStandardsSwaggerClient<SummarizeVote200>()

    return useCallback(
        (voteId: number, apiDescription: ApiDescription) => {
            return summarizeVote({
                url: `/standards/votes/${voteId}/summarize`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiDescription,
            })
        },
        [summarizeVote],
    )
}

export const useSummarizeVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSummarizeVoteHook>>>,
        TError,
        { voteId: number; data: ApiDescription },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSummarizeVoteHook>>>, TError, { voteId: number; data: ApiDescription }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const summarizeVote = useSummarizeVoteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSummarizeVoteHook>>>, { voteId: number; data: ApiDescription }> = (
        props,
    ) => {
        const { voteId, data } = props ?? {}

        return summarizeVote(voteId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SummarizeVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSummarizeVoteHook>>>>
export type SummarizeVoteMutationBody = ApiDescription
export type SummarizeVoteMutationError = ApiError

export const useSummarizeVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSummarizeVoteHook>>>,
        TError,
        { voteId: number; data: ApiDescription },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useSummarizeVoteHook>>>, TError, { voteId: number; data: ApiDescription }, TContext> => {
    const mutationOptions = useSummarizeVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCancelVoteHook = () => {
    const cancelVote = useStandardsSwaggerClient<CancelVote200>()

    return useCallback(
        (voteId: number, apiDescription: ApiDescription) => {
            return cancelVote({
                url: `/standards/votes/${voteId}/cancel`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiDescription,
            })
        },
        [cancelVote],
    )
}

export const useCancelVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCancelVoteHook>>>,
        TError,
        { voteId: number; data: ApiDescription },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelVoteHook>>>, TError, { voteId: number; data: ApiDescription }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const cancelVote = useCancelVoteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCancelVoteHook>>>, { voteId: number; data: ApiDescription }> = (
        props,
    ) => {
        const { voteId, data } = props ?? {}

        return cancelVote(voteId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CancelVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelVoteHook>>>>
export type CancelVoteMutationBody = ApiDescription
export type CancelVoteMutationError = ApiError

export const useCancelVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCancelVoteHook>>>,
        TError,
        { voteId: number; data: ApiDescription },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCancelVoteHook>>>, TError, { voteId: number; data: ApiDescription }, TContext> => {
    const mutationOptions = useCancelVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateVoteRoleHook = () => {
    const updateVoteRole = useStandardsSwaggerClient<void>()

    return useCallback(
        (updateVoteRequest: UpdateVoteRequest) => {
            return updateVoteRole({
                url: `/standards/votes/updateVoteRole`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: updateVoteRequest,
            })
        },
        [updateVoteRole],
    )
}

export const useUpdateVoteRoleMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateVoteRoleHook>>>, TError, { data: UpdateVoteRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateVoteRoleHook>>>, TError, { data: UpdateVoteRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateVoteRole = useUpdateVoteRoleHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateVoteRoleHook>>>, { data: UpdateVoteRequest }> = (props) => {
        const { data } = props ?? {}

        return updateVoteRole(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateVoteRoleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateVoteRoleHook>>>>
export type UpdateVoteRoleMutationBody = UpdateVoteRequest
export type UpdateVoteRoleMutationError = ApiError

export const useUpdateVoteRole = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateVoteRoleHook>>>, TError, { data: UpdateVoteRequest }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useUpdateVoteRoleHook>>>, TError, { data: UpdateVoteRequest }, TContext> => {
    const mutationOptions = useUpdateVoteRoleMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @deprecated
 */
export const useUpdateVoteRole1Hook = () => {
    const updateVoteRole1 = useStandardsSwaggerClient<UpdateVoteRole1200>()

    return useCallback(
        (userIdLogin: string, newRoleUuid: string, newRoleName: string, newRoleDesc: string) => {
            return updateVoteRole1({
                url: `/standards/votes/updateVoteRole/userIdLogin/${userIdLogin}/newRoleUuid/${newRoleUuid}/newRoleName/${newRoleName}/newRoleDesc/${newRoleDesc}`,
                method: 'POST',
            })
        },
        [updateVoteRole1],
    )
}

export const useUpdateVoteRole1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateVoteRole1Hook>>>,
        TError,
        { userIdLogin: string; newRoleUuid: string; newRoleName: string; newRoleDesc: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateVoteRole1Hook>>>,
    TError,
    { userIdLogin: string; newRoleUuid: string; newRoleName: string; newRoleDesc: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateVoteRole1 = useUpdateVoteRole1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateVoteRole1Hook>>>,
        { userIdLogin: string; newRoleUuid: string; newRoleName: string; newRoleDesc: string }
    > = (props) => {
        const { userIdLogin, newRoleUuid, newRoleName, newRoleDesc } = props ?? {}

        return updateVoteRole1(userIdLogin, newRoleUuid, newRoleName, newRoleDesc)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateVoteRole1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateVoteRole1Hook>>>>

export type UpdateVoteRole1MutationError = ApiError

/**
 * @deprecated
 */
export const useUpdateVoteRole1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateVoteRole1Hook>>>,
        TError,
        { userIdLogin: string; newRoleUuid: string; newRoleName: string; newRoleDesc: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateVoteRole1Hook>>>,
    TError,
    { userIdLogin: string; newRoleUuid: string; newRoleName: string; newRoleDesc: string },
    TContext
> => {
    const mutationOptions = useUpdateVoteRole1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetFOPStandardRequestsHook = () => {
    const getFOPStandardRequests = useStandardsSwaggerClient<ApiStandardRequestPreviewList>()

    return useCallback(
        (params: GetFOPStandardRequestsParams, signal?: AbortSignal) => {
            return getFOPStandardRequests({ url: `/standards/requests`, method: 'GET', params, signal })
        },
        [getFOPStandardRequests],
    )
}

export const getGetFOPStandardRequestsQueryKey = (params: GetFOPStandardRequestsParams) => {
    return [`/standards/requests`, ...(params ? [params] : [])] as const
}

export const useGetFOPStandardRequestsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>,
    TError = ApiError,
>(
    params: GetFOPStandardRequestsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetFOPStandardRequestsQueryKey(params)

    const getFOPStandardRequests = useGetFOPStandardRequestsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>> = ({ signal }) =>
        getFOPStandardRequests(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetFOPStandardRequestsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>>
export type GetFOPStandardRequestsQueryError = ApiError

export function useGetFOPStandardRequests<TData = Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>, TError = ApiError>(
    params: GetFOPStandardRequestsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetFOPStandardRequestsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCreateStandardRequestHook = () => {
    const createStandardRequest = useStandardsSwaggerClient<ApiStandardRequest>()

    return useCallback(
        (apiStandardRequest: ApiStandardRequest) => {
            return createStandardRequest({
                url: `/standards/requests`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiStandardRequest,
            })
        },
        [createStandardRequest],
    )
}

export const useCreateStandardRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestHook>>>,
        TError,
        { data: ApiStandardRequest },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestHook>>>, TError, { data: ApiStandardRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const createStandardRequest = useCreateStandardRequestHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestHook>>>, { data: ApiStandardRequest }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return createStandardRequest(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateStandardRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestHook>>>>
export type CreateStandardRequestMutationBody = ApiStandardRequest
export type CreateStandardRequestMutationError = ApiError

export const useCreateStandardRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestHook>>>,
        TError,
        { data: ApiStandardRequest },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestHook>>>, TError, { data: ApiStandardRequest }, TContext> => {
    const mutationOptions = useCreateStandardRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCreateStandardRequestUploadHook = () => {
    const createStandardRequestUpload = useStandardsSwaggerClient<CreateStandardRequestUpload200>()

    return useCallback(
        (createStandardRequestUploadBody: CreateStandardRequestUploadBody, params: CreateStandardRequestUploadParams) => {
            const formData = new FormData()
            if (createStandardRequestUploadBody.file !== undefined) {
                formData.append('file', createStandardRequestUploadBody.file)
            }

            return createStandardRequestUpload({
                url: `/standards/requests/upload`,
                method: 'POST',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData,
                params,
            })
        },
        [createStandardRequestUpload],
    )
}

export const useCreateStandardRequestUploadMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestUploadHook>>>,
        TError,
        { data: CreateStandardRequestUploadBody; params: CreateStandardRequestUploadParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestUploadHook>>>,
    TError,
    { data: CreateStandardRequestUploadBody; params: CreateStandardRequestUploadParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const createStandardRequestUpload = useCreateStandardRequestUploadHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestUploadHook>>>,
        { data: CreateStandardRequestUploadBody; params: CreateStandardRequestUploadParams }
    > = (props) => {
        const { data, params } = props ?? {}

        return createStandardRequestUpload(data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateStandardRequestUploadMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestUploadHook>>>>
export type CreateStandardRequestUploadMutationBody = CreateStandardRequestUploadBody
export type CreateStandardRequestUploadMutationError = ApiError

export const useCreateStandardRequestUpload = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestUploadHook>>>,
        TError,
        { data: CreateStandardRequestUploadBody; params: CreateStandardRequestUploadParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestUploadHook>>>,
    TError,
    { data: CreateStandardRequestUploadBody; params: CreateStandardRequestUploadParams },
    TContext
> => {
    const mutationOptions = useCreateStandardRequestUploadMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReindexHook = () => {
    const reindex = useStandardsSwaggerClient<Reindex200>()

    return useCallback(() => {
        return reindex({ url: `/standards/reindex/votes`, method: 'POST' })
    }, [reindex])
}

export const useReindexMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const reindex = useReindexHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, void> = () => {
        return reindex()
    }

    return { mutationFn, ...mutationOptions }
}

export type ReindexMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>>

export type ReindexMutationError = ApiError

export const useReindex = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError, void, TContext> => {
    const mutationOptions = useReindexMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReindex1Hook = () => {
    const reindex1 = useStandardsSwaggerClient<Reindex1200>()

    return useCallback(() => {
        return reindex1({ url: `/standards/reindex/requests`, method: 'POST' })
    }, [reindex1])
}

export const useReindex1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindex1Hook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindex1Hook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const reindex1 = useReindex1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReindex1Hook>>>, void> = () => {
        return reindex1()
    }

    return { mutationFn, ...mutationOptions }
}

export type Reindex1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReindex1Hook>>>>

export type Reindex1MutationError = ApiError

export const useReindex1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindex1Hook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReindex1Hook>>>, TError, void, TContext> => {
    const mutationOptions = useReindex1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReindex2Hook = () => {
    const reindex2 = useStandardsSwaggerClient<Reindex2200>()

    return useCallback(() => {
        return reindex2({ url: `/reindex/meetings`, method: 'POST' })
    }, [reindex2])
}

export const useReindex2MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindex2Hook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindex2Hook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const reindex2 = useReindex2Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReindex2Hook>>>, void> = () => {
        return reindex2()
    }

    return { mutationFn, ...mutationOptions }
}

export type Reindex2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReindex2Hook>>>>

export type Reindex2MutationError = ApiError

export const useReindex2 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindex2Hook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReindex2Hook>>>, TError, void, TContext> => {
    const mutationOptions = useReindex2MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetMeetingRequestsHook = () => {
    const getMeetingRequests = useStandardsSwaggerClient<ApiMeetingRequestPreviewList>()

    return useCallback(
        (params: GetMeetingRequestsParams, signal?: AbortSignal) => {
            return getMeetingRequests({ url: `/meetings`, method: 'GET', params, signal })
        },
        [getMeetingRequests],
    )
}

export const getGetMeetingRequestsQueryKey = (params: GetMeetingRequestsParams) => {
    return [`/meetings`, ...(params ? [params] : [])] as const
}

export const useGetMeetingRequestsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>, TError = ApiError>(
    params: GetMeetingRequestsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetMeetingRequestsQueryKey(params)

    const getMeetingRequests = useGetMeetingRequestsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>> = ({ signal }) =>
        getMeetingRequests(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetMeetingRequestsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>>
export type GetMeetingRequestsQueryError = ApiError

export function useGetMeetingRequests<TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>, TError = ApiError>(
    params: GetMeetingRequestsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetMeetingRequestsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCreateMeetingHook = () => {
    const createMeeting = useStandardsSwaggerClient<ApiMeetingRequest>()

    return useCallback(
        (apiMeetingRequest: ApiMeetingRequest) => {
            return createMeeting({ url: `/meetings`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: apiMeetingRequest })
        },
        [createMeeting],
    )
}

export const useCreateMeetingMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateMeetingHook>>>, TError, { data: ApiMeetingRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateMeetingHook>>>, TError, { data: ApiMeetingRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const createMeeting = useCreateMeetingHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateMeetingHook>>>, { data: ApiMeetingRequest }> = (props) => {
        const { data } = props ?? {}

        return createMeeting(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateMeetingMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateMeetingHook>>>>
export type CreateMeetingMutationBody = ApiMeetingRequest
export type CreateMeetingMutationError = ApiError

export const useCreateMeeting = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateMeetingHook>>>, TError, { data: ApiMeetingRequest }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCreateMeetingHook>>>, TError, { data: ApiMeetingRequest }, TContext> => {
    const mutationOptions = useCreateMeetingMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useParticipateMeetingRequestHook = () => {
    const participateMeetingRequest = useStandardsSwaggerClient<ParticipateMeetingRequest200>()

    return useCallback(
        (meetingRequestId: number, params: ParticipateMeetingRequestParams) => {
            return participateMeetingRequest({ url: `/meetings/${meetingRequestId}/participate`, method: 'POST', params })
        },
        [participateMeetingRequest],
    )
}

export const useParticipateMeetingRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useParticipateMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; params: ParticipateMeetingRequestParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useParticipateMeetingRequestHook>>>,
    TError,
    { meetingRequestId: number; params: ParticipateMeetingRequestParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const participateMeetingRequest = useParticipateMeetingRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useParticipateMeetingRequestHook>>>,
        { meetingRequestId: number; params: ParticipateMeetingRequestParams }
    > = (props) => {
        const { meetingRequestId, params } = props ?? {}

        return participateMeetingRequest(meetingRequestId, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ParticipateMeetingRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useParticipateMeetingRequestHook>>>>

export type ParticipateMeetingRequestMutationError = ApiError

export const useParticipateMeetingRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useParticipateMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; params: ParticipateMeetingRequestParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useParticipateMeetingRequestHook>>>,
    TError,
    { meetingRequestId: number; params: ParticipateMeetingRequestParams },
    TContext
> => {
    const mutationOptions = useParticipateMeetingRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCancelMeetingRequestHook = () => {
    const cancelMeetingRequest = useStandardsSwaggerClient<CancelMeetingRequest200>()

    return useCallback(
        (meetingRequestId: number, apiDescription: ApiDescription) => {
            return cancelMeetingRequest({
                url: `/meetings/${meetingRequestId}/cancel`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiDescription,
            })
        },
        [cancelMeetingRequest],
    )
}

export const useCancelMeetingRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCancelMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; data: ApiDescription },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelMeetingRequestHook>>>,
    TError,
    { meetingRequestId: number; data: ApiDescription },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const cancelMeetingRequest = useCancelMeetingRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCancelMeetingRequestHook>>>,
        { meetingRequestId: number; data: ApiDescription }
    > = (props) => {
        const { meetingRequestId, data } = props ?? {}

        return cancelMeetingRequest(meetingRequestId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CancelMeetingRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelMeetingRequestHook>>>>
export type CancelMeetingRequestMutationBody = ApiDescription
export type CancelMeetingRequestMutationError = ApiError

export const useCancelMeetingRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCancelMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; data: ApiDescription },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useCancelMeetingRequestHook>>>,
    TError,
    { meetingRequestId: number; data: ApiDescription },
    TContext
> => {
    const mutationOptions = useCancelMeetingRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStartTaskHook = () => {
    const startTask = useStandardsSwaggerClient<FillRefAttributesTask>()

    return useCallback(
        (id: string) => {
            return startTask({ url: `/fillRefAttributes/startTask/${id}`, method: 'POST' })
        },
        [startTask],
    )
}

export const useStartTaskMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>, TError, { id: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>, TError, { id: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const startTask = useStartTaskHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>, { id: string }> = (props) => {
        const { id } = props ?? {}

        return startTask(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type StartTaskMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>>

export type StartTaskMutationError = ApiError

export const useStartTask = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>, TError, { id: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>, TError, { id: string }, TContext> => {
    const mutationOptions = useStartTaskMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGroupsWithMeetingsHook = () => {
    const groupsWithMeetings = useStandardsSwaggerClient<GroupMeetings[]>()

    return useCallback(
        (signal?: AbortSignal) => {
            return groupsWithMeetings({ url: `/user-groups/meetings`, method: 'GET', signal })
        },
        [groupsWithMeetings],
    )
}

export const getGroupsWithMeetingsQueryKey = () => {
    return [`/user-groups/meetings`] as const
}

export const useGroupsWithMeetingsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGroupsWithMeetingsQueryKey()

    const groupsWithMeetings = useGroupsWithMeetingsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>> = ({ signal }) => groupsWithMeetings(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GroupsWithMeetingsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>>
export type GroupsWithMeetingsQueryError = ApiError

export function useGroupsWithMeetings<TData = Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGroupsWithMeetingsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetVoteResultHook = () => {
    const getVoteResult = useStandardsSwaggerClient<ApiVoteResult>()

    return useCallback(
        (voteId: number, signal?: AbortSignal) => {
            return getVoteResult({ url: `/standards/votes/${voteId}/result`, method: 'GET', signal })
        },
        [getVoteResult],
    )
}

export const getGetVoteResultQueryKey = (voteId: number) => {
    return [`/standards/votes/${voteId}/result`] as const
}

export const useGetVoteResultQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>, TError = ApiError>(
    voteId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetVoteResultQueryKey(voteId)

    const getVoteResult = useGetVoteResultHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>> = ({ signal }) => getVoteResult(voteId, signal)

    return { queryKey, queryFn, enabled: !!voteId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetVoteResultQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>>
export type GetVoteResultQueryError = ApiError

export function useGetVoteResult<TData = Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>, TError = ApiError>(
    voteId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetVoteResultQueryOptions(voteId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetUserVoteActorResultsHook = () => {
    const getUserVoteActorResults = useStandardsSwaggerClient<ApiVoteUserActorResults>()

    return useCallback(
        (voteId: number, signal?: AbortSignal) => {
            return getUserVoteActorResults({ url: `/standards/votes/${voteId}/actorresults`, method: 'GET', signal })
        },
        [getUserVoteActorResults],
    )
}

export const getGetUserVoteActorResultsQueryKey = (voteId: number) => {
    return [`/standards/votes/${voteId}/actorresults`] as const
}

export const useGetUserVoteActorResultsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>,
    TError = ApiError,
>(
    voteId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetUserVoteActorResultsQueryKey(voteId)

    const getUserVoteActorResults = useGetUserVoteActorResultsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>> = ({ signal }) =>
        getUserVoteActorResults(voteId, signal)

    return { queryKey, queryFn, enabled: !!voteId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetUserVoteActorResultsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>>
export type GetUserVoteActorResultsQueryError = ApiError

export function useGetUserVoteActorResults<TData = Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>, TError = ApiError>(
    voteId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetUserVoteActorResultsQueryOptions(voteId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetVoteActorResultHook = () => {
    const getVoteActorResult = useStandardsSwaggerClient<ApiVoteActorResult>()

    return useCallback(
        (voteId: number, castedUserId: string, signal?: AbortSignal) => {
            return getVoteActorResult({ url: `/standards/votes/${voteId}/actorresult/${castedUserId}`, method: 'GET', signal })
        },
        [getVoteActorResult],
    )
}

export const getGetVoteActorResultQueryKey = (voteId: number, castedUserId: string) => {
    return [`/standards/votes/${voteId}/actorresult/${castedUserId}`] as const
}

export const useGetVoteActorResultQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>, TError = ApiError>(
    voteId: number,
    castedUserId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetVoteActorResultQueryKey(voteId, castedUserId)

    const getVoteActorResult = useGetVoteActorResultHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>> = ({ signal }) =>
        getVoteActorResult(voteId, castedUserId, signal)

    return { queryKey, queryFn, enabled: !!(voteId && castedUserId), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetVoteActorResultQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>>
export type GetVoteActorResultQueryError = ApiError

export function useGetVoteActorResult<TData = Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>, TError = ApiError>(
    voteId: number,
    castedUserId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetVoteActorResultQueryOptions(voteId, castedUserId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAllStandardRequestsHook = () => {
    const getAllStandardRequests = useStandardsSwaggerClient<ApiStandardRequestPreviewList>()

    return useCallback(
        (signal?: AbortSignal) => {
            return getAllStandardRequests({ url: `/standards/requests/all`, method: 'GET', signal })
        },
        [getAllStandardRequests],
    )
}

export const getGetAllStandardRequestsQueryKey = () => {
    return [`/standards/requests/all`] as const
}

export const useGetAllStandardRequestsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAllStandardRequestsQueryKey()

    const getAllStandardRequests = useGetAllStandardRequestsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>> = ({ signal }) =>
        getAllStandardRequests(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetAllStandardRequestsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>>
export type GetAllStandardRequestsQueryError = ApiError

export function useGetAllStandardRequests<
    TData = Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetAllStandardRequestsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useRejectMeetingRequestHook = () => {
    const rejectMeetingRequest = useStandardsSwaggerClient<void>()

    return useCallback(
        (id: number, params: RejectMeetingRequestParams, signal?: AbortSignal) => {
            return rejectMeetingRequest({ url: `/meetings/participate/${id}/reject`, method: 'GET', params, signal })
        },
        [rejectMeetingRequest],
    )
}

export const useRejectMeetingRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRejectMeetingRequestHook>>>,
        TError,
        { id: number; params: RejectMeetingRequestParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRejectMeetingRequestHook>>>,
    TError,
    { id: number; params: RejectMeetingRequestParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const rejectMeetingRequest = useRejectMeetingRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useRejectMeetingRequestHook>>>,
        { id: number; params: RejectMeetingRequestParams }
    > = (props) => {
        const { id, params } = props ?? {}

        return rejectMeetingRequest(id, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type RejectMeetingRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRejectMeetingRequestHook>>>>

export type RejectMeetingRequestMutationError = ApiError

export const useRejectMeetingRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRejectMeetingRequestHook>>>,
        TError,
        { id: number; params: RejectMeetingRequestParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useRejectMeetingRequestHook>>>,
    TError,
    { id: number; params: RejectMeetingRequestParams },
    TContext
> => {
    const mutationOptions = useRejectMeetingRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAcceptMeetingRequestHook = () => {
    const acceptMeetingRequest = useStandardsSwaggerClient<void>()

    return useCallback(
        (id: number, params: AcceptMeetingRequestParams, signal?: AbortSignal) => {
            return acceptMeetingRequest({ url: `/meetings/participate/${id}/accept`, method: 'GET', params, signal })
        },
        [acceptMeetingRequest],
    )
}

export const useAcceptMeetingRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAcceptMeetingRequestHook>>>,
        TError,
        { id: number; params: AcceptMeetingRequestParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAcceptMeetingRequestHook>>>,
    TError,
    { id: number; params: AcceptMeetingRequestParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const acceptMeetingRequest = useAcceptMeetingRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAcceptMeetingRequestHook>>>,
        { id: number; params: AcceptMeetingRequestParams }
    > = (props) => {
        const { id, params } = props ?? {}

        return acceptMeetingRequest(id, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type AcceptMeetingRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAcceptMeetingRequestHook>>>>

export type AcceptMeetingRequestMutationError = ApiError

export const useAcceptMeetingRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAcceptMeetingRequestHook>>>,
        TError,
        { id: number; params: AcceptMeetingRequestParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useAcceptMeetingRequestHook>>>,
    TError,
    { id: number; params: AcceptMeetingRequestParams },
    TContext
> => {
    const mutationOptions = useAcceptMeetingRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetTasksHook = () => {
    const getTasks = useStandardsSwaggerClient<FillRefAttributesTask[]>()

    return useCallback(
        (signal?: AbortSignal) => {
            return getTasks({ url: `/fillRefAttributes/tasks`, method: 'GET', signal })
        },
        [getTasks],
    )
}

export const getGetTasksQueryKey = () => {
    return [`/fillRefAttributes/tasks`] as const
}

export const useGetTasksQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTasksQueryKey()

    const getTasks = useGetTasksHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>> = ({ signal }) => getTasks(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type GetTasksQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>>
export type GetTasksQueryError = ApiError

export function useGetTasks<TData = Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTasksQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTaskHook = () => {
    const getTask = useStandardsSwaggerClient<FillRefAttributesTask>()

    return useCallback(
        (id: string, signal?: AbortSignal) => {
            return getTask({ url: `/fillRefAttributes/tasks/${id}`, method: 'GET', signal })
        },
        [getTask],
    )
}

export const getGetTaskQueryKey = (id: string) => {
    return [`/fillRefAttributes/tasks/${id}`] as const
}

export const useGetTaskQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError = ApiError>(
    id: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTaskQueryKey(id)

    const getTask = useGetTaskHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>> = ({ signal }) => getTask(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTaskQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>>
export type GetTaskQueryError = ApiError

export function useGetTask<TData = Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError = ApiError>(
    id: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTaskQueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTaskBriefInfosHook = () => {
    const getTaskBriefInfos = useStandardsSwaggerClient<string[]>()

    return useCallback(
        (signal?: AbortSignal) => {
            return getTaskBriefInfos({ url: `/fillRefAttributes/tasks/briefInfos`, method: 'GET', signal })
        },
        [getTaskBriefInfos],
    )
}

export const getGetTaskBriefInfosQueryKey = () => {
    return [`/fillRefAttributes/tasks/briefInfos`] as const
}

export const useGetTaskBriefInfosQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTaskBriefInfosQueryKey()

    const getTaskBriefInfos = useGetTaskBriefInfosHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>> = ({ signal }) => getTaskBriefInfos(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTaskBriefInfosQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>>
export type GetTaskBriefInfosQueryError = ApiError

export function useGetTaskBriefInfos<TData = Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTaskBriefInfosQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTaskBriefInfoHook = () => {
    const getTaskBriefInfo = useStandardsSwaggerClient<string>()

    return useCallback(
        (id: string, signal?: AbortSignal) => {
            return getTaskBriefInfo({ url: `/fillRefAttributes/tasks/briefInfos/${id}`, method: 'GET', signal })
        },
        [getTaskBriefInfo],
    )
}

export const getGetTaskBriefInfoQueryKey = (id: string) => {
    return [`/fillRefAttributes/tasks/briefInfos/${id}`] as const
}

export const useGetTaskBriefInfoQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError = ApiError>(
    id: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTaskBriefInfoQueryKey(id)

    const getTaskBriefInfo = useGetTaskBriefInfoHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>> = ({ signal }) => getTaskBriefInfo(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTaskBriefInfoQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>>
export type GetTaskBriefInfoQueryError = ApiError

export function useGetTaskBriefInfo<TData = Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError = ApiError>(
    id: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTaskBriefInfoQueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCancelTaskHook = () => {
    const cancelTask = useStandardsSwaggerClient<FillRefAttributesTask>()

    return useCallback(
        (id: string) => {
            return cancelTask({ url: `/fillRefAttributes/cancelTask/${id}`, method: 'DELETE' })
        },
        [cancelTask],
    )
}

export const useCancelTaskMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>, TError, { id: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>, TError, { id: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const cancelTask = useCancelTaskHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>, { id: string }> = (props) => {
        const { id } = props ?? {}

        return cancelTask(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type CancelTaskMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>>

export type CancelTaskMutationError = ApiError

export const useCancelTask = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>, TError, { id: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>, TError, { id: string }, TContext> => {
    const mutationOptions = useCancelTaskMutationOptions(options)

    return useMutation(mutationOptions)
}
