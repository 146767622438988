//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * MetaIS Types Repo
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { useTypesRepoSwaggerClient } from '../hooks/useTypesRepoSwaggerClient'
export type ListGenericAttrProfileParams = {
    filter: AttributeProfileFilter
}

export type GenerateCodeAndURL1Params = {
    count: number
}

export type ListRelationshipTypes2Params = {
    roles: string[]
}

export type StoreAdminEntity1Params = {
    isFromZC?: boolean
}

export type ListAttrProfileParams = {
    filter: AttributeProfileFilter
}

export type ListCiTypesParams = {
    filter: CiTypeFilter
}

export type ListRelationshipTypesParams = {
    filter: RelationshipTypeFilter
}

export interface AttributeTranslateMap {
    sourceAttributeTN?: string
    targetAttributeTN?: string
    targetLocale?: string
}

export interface AttributeTranslateMapList {
    mapList?: AttributeTranslateMap[]
}

export type RelatedCiTypePreviewRelationshipTypeUsageType =
    (typeof RelatedCiTypePreviewRelationshipTypeUsageType)[keyof typeof RelatedCiTypePreviewRelationshipTypeUsageType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelatedCiTypePreviewRelationshipTypeUsageType = {
    system: 'system',
    application: 'application',
} as const

export type RelatedCiTypePreviewCiTypeUsageType = (typeof RelatedCiTypePreviewCiTypeUsageType)[keyof typeof RelatedCiTypePreviewCiTypeUsageType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelatedCiTypePreviewCiTypeUsageType = {
    system: 'system',
    application: 'application',
} as const

export interface RelatedCiTypePreview {
    ciCategory?: string
    ciColor?: string
    ciLabels?: string[]
    ciRoleList?: string[]
    ciTypeName?: string
    ciTypeTechnicalName?: string
    ciTypeUsageType?: RelatedCiTypePreviewCiTypeUsageType
    ciTypeValid?: boolean
    engCiTypeName?: string
    engRelationshipTypeName?: string
    isOwnerRelationship?: boolean
    ownerRelationship?: boolean
    relationshipCategory?: string
    relationshipRoleList?: string[]
    relationshipTypeName?: string
    relationshipTypeTechnicalName?: string
    relationshipTypeUsageType?: RelatedCiTypePreviewRelationshipTypeUsageType
    relationshipTypeValid?: boolean
}

export interface RelatedCiTypePreviewList {
    cisAsSources?: RelatedCiTypePreview[]
    cisAsTargets?: RelatedCiTypePreview[]
    derivedCisAsSources?: DerivedCiTypePreview[]
    derivedCisAsTargets?: DerivedCiTypePreview[]
}

export interface CiCode {
    cicode?: string
    ciurl?: string
}

export interface RelationshipCode {
    code?: string
    refId?: string
}

export interface DerivedRelationshipType {
    description?: string
    descriptionEn?: string
    name?: string
    nameEn?: string
    relationshipTns?: string[]
    sourceCiTypeTn?: string
    targetCiTypeTn?: string
    technicalName?: string
    valid?: boolean
}

export interface RightsTypeHolder {
    attributeProfile?: AttributeProfilePreview
    ciType?: CiTypePreviewList
    relationshipsType?: RelationshipTypePreviewList
    role?: string
}

export interface CiTypesRelationshipTypesList {
    ciTypes?: CiTypePreview[]
    relationshipTypes?: RelationshipTypePreview[]
}

export interface TypesFilter {
    ciTypes?: string[]
    relTypes?: string[]
}

export type CiTypeRelationshipTypeMapCiTypeRoleEnum =
    (typeof CiTypeRelationshipTypeMapCiTypeRoleEnum)[keyof typeof CiTypeRelationshipTypeMapCiTypeRoleEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CiTypeRelationshipTypeMapCiTypeRoleEnum = {
    SOURCE: 'SOURCE',
    TARGET: 'TARGET',
} as const

export interface CiTypeRelationshipTypeMap {
    ciTypeId?: number
    ciTypeRoleEnum?: CiTypeRelationshipTypeMapCiTypeRoleEnum
    id?: number
    relationshipTypeid?: number
}

export interface CiTypeRelationshipTypesList {
    asSource?: RelationshipTypePreview[]
    asTarget?: RelationshipTypePreview[]
}

export type CiTypeType = (typeof CiTypeType)[keyof typeof CiTypeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CiTypeType = {
    system: 'system',
    application: 'application',
} as const

export interface CiType {
    attributeProfiles?: AttributeProfile[]
    attributes?: Attribute[]
    category?: string
    codePrefix?: string
    color?: string
    description?: string
    engName?: string
    id?: number
    labels?: string[]
    name?: string
    ownerRelation?: string
    relationshipTypes?: CiTypeRelationshipTypesList
    roleList?: string[]
    technicalName?: string
    type?: CiTypeType
    uriPrefix?: string
    valid?: boolean
}

export interface SummarizingCardItemUi {
    attribute?: Attribute
    attrOrder?: number
    description?: string
}

export interface SummarizingCardUi {
    ciType?: string
    description?: string
    showOwner?: boolean
    summarizingCardItems?: SummarizingCardItemUi[]
}

export type RelationshipTypeType = (typeof RelationshipTypeType)[keyof typeof RelationshipTypeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelationshipTypeType = {
    system: 'system',
    application: 'application',
} as const

export interface AttributeProfilePreview {
    attributeProfileList?: AttributeProfile[]
}

export interface AttributeProfileFilter {
    role?: string
}

export interface Mandatory {
    processId?: string[]
    processIds?: string[]
    type?: string
}

export type CiTypePreviewType = (typeof CiTypePreviewType)[keyof typeof CiTypePreviewType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CiTypePreviewType = {
    system: 'system',
    application: 'application',
} as const

export type AttributeProfileType = (typeof AttributeProfileType)[keyof typeof AttributeProfileType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttributeProfileType = {
    system: 'system',
    application: 'application',
} as const

export interface AttributeProfile {
    attributes?: Attribute[]
    description?: string
    engDescription?: string
    id?: number
    isGeneric?: boolean
    name?: string
    roleList?: string[]
    technicalName?: string
    type?: AttributeProfileType
    valid?: boolean
}

export interface CiTypePreview {
    attributeProfiles?: AttributeProfile[]
    attributes?: Attribute[]
    category?: string
    codePrefix?: string
    color?: string
    description?: string
    engName?: string
    id?: number
    labels?: string[]
    name?: string
    ownerRelation?: string
    roleList?: string[]
    roles?: string
    technicalName?: string
    type?: CiTypePreviewType
    uriPrefix?: string
    valid?: boolean
}

export interface RelationshipType {
    attributeProfiles?: AttributeProfile[]
    attributes?: Attribute[]
    category?: string
    codePrefix?: string
    color?: string
    description?: string
    engDescription?: string
    engName?: string
    id?: number
    name?: string
    roleList?: string[]
    sourceCardinality?: Cardinality
    sources?: CiTypePreview[]
    targetCardinality?: Cardinality
    targets?: CiTypePreview[]
    technicalName?: string
    type?: RelationshipTypeType
    uriPrefix?: string
    valid?: boolean
}

export interface DerivedCiTypePreview {
    ciType?: CiTypePreview
    description?: string
    descriptionEn?: string
    engName?: string
    name?: string
    relations?: RelationshipType[]
    technicalName?: string
}

export interface CiTypePreviewList {
    results?: CiTypePreview[]
}

export type AttributeConstraintRegexAllOf = {
    regex?: string
}

export type AttributeConstraintRegex = AttributeConstraint & AttributeConstraintRegexAllOf

export type AttributeConstraintIntervalAllOf = {
    maxValue?: number
    minValue?: number
}

export type AttributeConstraintEnumAllOf = {
    enumCode?: string
}

export type AttributeConstraintEnum = AttributeConstraint & AttributeConstraintEnumAllOf

export type AttributeConstraintCiTypeAllOf = {
    ciType?: string
}

export interface AttributeConstraint {
    id?: number
    type?: string
}

export type AttributeConstraintInterval = AttributeConstraint & AttributeConstraintIntervalAllOf

export type AttributeConstraintCiType = AttributeConstraint & AttributeConstraintCiTypeAllOf

export type AttributeConstraintsItem = AttributeConstraintCiType | AttributeConstraintEnum | AttributeConstraintInterval | AttributeConstraintRegex

export type AttributeAttributeTypeEnum = (typeof AttributeAttributeTypeEnum)[keyof typeof AttributeAttributeTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttributeAttributeTypeEnum = {
    BOOLEAN: 'BOOLEAN',
    BYTE: 'BYTE',
    SHORT: 'SHORT',
    INTEGER: 'INTEGER',
    LONG: 'LONG',
    FLOAT: 'FLOAT',
    DOUBLE: 'DOUBLE',
    CHARACTER: 'CHARACTER',
    STRING: 'STRING',
    DATE: 'DATE',
    STRING_PAIR: 'STRING_PAIR',
    IMAGE: 'IMAGE',
    HTML: 'HTML',
    DATETIME: 'DATETIME',
    URL: 'URL',
    EMAIL: 'EMAIL',
    PHONE: 'PHONE',
} as const

export interface Attribute {
    array?: boolean
    attributeTypeEnum?: AttributeAttributeTypeEnum
    constraints?: AttributeConstraintsItem[]
    defaultValue?: string
    description?: string
    displayAs?: string
    engDescription?: string
    engName?: string
    id?: number
    invisible?: boolean
    isArray?: boolean
    mandatory?: Mandatory
    name?: string
    opendata?: boolean
    order?: number
    readOnly?: boolean
    technicalName?: string
    type?: string
    units?: string
    valid?: boolean
}

export interface CiTypeFilter {
    labels?: string[]
    role?: string
    roles?: string[]
}

export interface RelationshipTypePreviewList {
    results?: RelationshipTypePreview[]
}

export type RelationshipTypePreviewType = (typeof RelationshipTypePreviewType)[keyof typeof RelationshipTypePreviewType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelationshipTypePreviewType = {
    system: 'system',
    application: 'application',
} as const

export interface Cardinality {
    max?: number
    min?: number
}

export interface RelationshipTypePreview {
    cardinalitySource?: Cardinality
    cardinalityTarget?: Cardinality
    category?: string
    codePrefix?: string
    description?: string
    engDescription?: string
    engName?: string
    id?: number
    name?: string
    roleList?: string[]
    technicalName?: string
    type?: RelationshipTypePreviewType
    uriPrefix?: string
    valid?: boolean
}

export interface RelationshipTypeFilter {
    role?: string
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

/**
 * @summary listRelationshipTypes
 */
export const useListRelationshipTypesHook = () => {
    const listRelationshipTypes = useTypesRepoSwaggerClient<RelationshipTypePreviewList>()

    return useCallback(
        (params: ListRelationshipTypesParams, signal?: AbortSignal) => {
            return listRelationshipTypes({ url: `/relationshiptypes/list`, method: 'GET', params, signal })
        },
        [listRelationshipTypes],
    )
}

export const getListRelationshipTypesQueryKey = (params: ListRelationshipTypesParams) => {
    return [`/relationshiptypes/list`, ...(params ? [params] : [])] as const
}

export const useListRelationshipTypesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>, TError = ApiError>(
    params: ListRelationshipTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListRelationshipTypesQueryKey(params)

    const listRelationshipTypes = useListRelationshipTypesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>> = ({ signal }) =>
        listRelationshipTypes(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListRelationshipTypesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>>
export type ListRelationshipTypesQueryError = ApiError

/**
 * @summary listRelationshipTypes
 */

export function useListRelationshipTypes<TData = Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>, TError = ApiError>(
    params: ListRelationshipTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListRelationshipTypesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary listRelationshipTypes
 */
export const useListRelationshipTypes1Hook = () => {
    const listRelationshipTypes1 = useTypesRepoSwaggerClient<RelationshipTypePreviewList>()

    return useCallback(
        (relationshipTypeFilter: RelationshipTypeFilter) => {
            return listRelationshipTypes1({
                url: `/relationshiptypes/list`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: relationshipTypeFilter,
            })
        },
        [listRelationshipTypes1],
    )
}

export const useListRelationshipTypes1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes1Hook>>>,
        TError,
        { data: RelationshipTypeFilter },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes1Hook>>>, TError, { data: RelationshipTypeFilter }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const listRelationshipTypes1 = useListRelationshipTypes1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes1Hook>>>, { data: RelationshipTypeFilter }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return listRelationshipTypes1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ListRelationshipTypes1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes1Hook>>>>
export type ListRelationshipTypes1MutationBody = RelationshipTypeFilter
export type ListRelationshipTypes1MutationError = ApiError

/**
 * @summary listRelationshipTypes
 */
export const useListRelationshipTypes1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes1Hook>>>,
        TError,
        { data: RelationshipTypeFilter },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes1Hook>>>, TError, { data: RelationshipTypeFilter }, TContext> => {
    const mutationOptions = useListRelationshipTypes1MutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary listCiTypes
 */
export const useListCiTypesHook = () => {
    const listCiTypes = useTypesRepoSwaggerClient<CiTypePreviewList>()

    return useCallback(
        (params: ListCiTypesParams, signal?: AbortSignal) => {
            return listCiTypes({ url: `/citypes/list`, method: 'GET', params, signal })
        },
        [listCiTypes],
    )
}

export const getListCiTypesQueryKey = (params: ListCiTypesParams) => {
    return [`/citypes/list`, ...(params ? [params] : [])] as const
}

export const useListCiTypesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>, TError = ApiError>(
    params: ListCiTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListCiTypesQueryKey(params)

    const listCiTypes = useListCiTypesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>> = ({ signal }) => listCiTypes(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type ListCiTypesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>>
export type ListCiTypesQueryError = ApiError

/**
 * @summary listCiTypes
 */

export function useListCiTypes<TData = Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>, TError = ApiError>(
    params: ListCiTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListCiTypesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary listCiTypes
 */
export const useListCiTypes1Hook = () => {
    const listCiTypes1 = useTypesRepoSwaggerClient<CiTypePreviewList>()

    return useCallback(
        (ciTypeFilter: CiTypeFilter) => {
            return listCiTypes1({ url: `/citypes/list`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: ciTypeFilter })
        },
        [listCiTypes1],
    )
}

export const useListCiTypes1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListCiTypes1Hook>>>, TError, { data: CiTypeFilter }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListCiTypes1Hook>>>, TError, { data: CiTypeFilter }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const listCiTypes1 = useListCiTypes1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useListCiTypes1Hook>>>, { data: CiTypeFilter }> = (props) => {
        const { data } = props ?? {}

        return listCiTypes1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ListCiTypes1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListCiTypes1Hook>>>>
export type ListCiTypes1MutationBody = CiTypeFilter
export type ListCiTypes1MutationError = ApiError

/**
 * @summary listCiTypes
 */
export const useListCiTypes1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListCiTypes1Hook>>>, TError, { data: CiTypeFilter }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useListCiTypes1Hook>>>, TError, { data: CiTypeFilter }, TContext> => {
    const mutationOptions = useListCiTypes1MutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary listAttrProfile
 */
export const useListAttrProfileHook = () => {
    const listAttrProfile = useTypesRepoSwaggerClient<AttributeProfilePreview>()

    return useCallback(
        (params: ListAttrProfileParams, signal?: AbortSignal) => {
            return listAttrProfile({ url: `/attrprofiles/list`, method: 'GET', params, signal })
        },
        [listAttrProfile],
    )
}

export const getListAttrProfileQueryKey = (params: ListAttrProfileParams) => {
    return [`/attrprofiles/list`, ...(params ? [params] : [])] as const
}

export const useListAttrProfileQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>, TError = ApiError>(
    params: ListAttrProfileParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListAttrProfileQueryKey(params)

    const listAttrProfile = useListAttrProfileHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>> = ({ signal }) => listAttrProfile(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListAttrProfileQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>>
export type ListAttrProfileQueryError = ApiError

/**
 * @summary listAttrProfile
 */

export function useListAttrProfile<TData = Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>, TError = ApiError>(
    params: ListAttrProfileParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListAttrProfileQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary listAttrProfile
 */
export const useListAttrProfile1Hook = () => {
    const listAttrProfile1 = useTypesRepoSwaggerClient<AttributeProfilePreview>()

    return useCallback(
        (attributeProfileFilter: AttributeProfileFilter) => {
            return listAttrProfile1({
                url: `/attrprofiles/list`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: attributeProfileFilter,
            })
        },
        [listAttrProfile1],
    )
}

export const getListAttrProfile1QueryKey = (attributeProfileFilter: AttributeProfileFilter) => {
    return [`/attrprofiles/list`, attributeProfileFilter] as const
}

export const useListAttrProfile1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>, TError = ApiError>(
    attributeProfileFilter: AttributeProfileFilter,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListAttrProfile1QueryKey(attributeProfileFilter)

    const listAttrProfile1 = useListAttrProfile1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>> = ({ signal }) =>
        listAttrProfile1(attributeProfileFilter, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListAttrProfile1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>>
export type ListAttrProfile1QueryError = ApiError

/**
 * @summary listAttrProfile
 */

export function useListAttrProfile1<TData = Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>, TError = ApiError>(
    attributeProfileFilter: AttributeProfileFilter,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListAttrProfile1QueryOptions(attributeProfileFilter, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useStoreAttributeTextationHook = () => {
    const storeAttributeTextation = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (technicalName: string, attTecName: string, attribute: Attribute) => {
            return storeAttributeTextation({
                url: `/relationshiptypes/relationshiptype/${technicalName}/attribute/${attTecName}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: attribute,
            })
        },
        [storeAttributeTextation],
    )
}

export const useStoreAttributeTextationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextationHook>>>,
        TError,
        { technicalName: string; attTecName: string; data: Attribute },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextationHook>>>,
    TError,
    { technicalName: string; attTecName: string; data: Attribute },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeAttributeTextation = useStoreAttributeTextationHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextationHook>>>,
        { technicalName: string; attTecName: string; data: Attribute }
    > = (props) => {
        const { technicalName, attTecName, data } = props ?? {}

        return storeAttributeTextation(technicalName, attTecName, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreAttributeTextationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextationHook>>>>
export type StoreAttributeTextationMutationBody = Attribute
export type StoreAttributeTextationMutationError = ApiError

export const useStoreAttributeTextation = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextationHook>>>,
        TError,
        { technicalName: string; attTecName: string; data: Attribute },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextationHook>>>,
    TError,
    { technicalName: string; attTecName: string; data: Attribute },
    TContext
> => {
    const mutationOptions = useStoreAttributeTextationMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteAttributeTextationHook = () => {
    const deleteAttributeTextation = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (technicalName: string, attTecName: string) => {
            return deleteAttributeTextation({ url: `/relationshiptypes/relationshiptype/${technicalName}/attribute/${attTecName}`, method: 'DELETE' })
        },
        [deleteAttributeTextation],
    )
}

export const useDeleteAttributeTextationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextationHook>>>,
        TError,
        { technicalName: string; attTecName: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextationHook>>>,
    TError,
    { technicalName: string; attTecName: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteAttributeTextation = useDeleteAttributeTextationHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextationHook>>>,
        { technicalName: string; attTecName: string }
    > = (props) => {
        const { technicalName, attTecName } = props ?? {}

        return deleteAttributeTextation(technicalName, attTecName)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteAttributeTextationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextationHook>>>>

export type DeleteAttributeTextationMutationError = ApiError

export const useDeleteAttributeTextation = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextationHook>>>,
        TError,
        { technicalName: string; attTecName: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextationHook>>>,
    TError,
    { technicalName: string; attTecName: string },
    TContext
> => {
    const mutationOptions = useDeleteAttributeTextationMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useValidRelationshipTypeHook = () => {
    const validRelationshipType = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (technicalName: string) => {
            return validRelationshipType({ url: `/relationshiptypes/relationshiptype/valid/${technicalName}`, method: 'PUT' })
        },
        [validRelationshipType],
    )
}

export const useValidRelationshipTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const validRelationshipType = useValidRelationshipTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useValidRelationshipTypeHook>>>, { technicalName: string }> = (props) => {
        const { technicalName } = props ?? {}

        return validRelationshipType(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type ValidRelationshipTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useValidRelationshipTypeHook>>>>

export type ValidRelationshipTypeMutationError = ApiError

export const useValidRelationshipType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useValidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext> => {
    const mutationOptions = useValidRelationshipTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUnvalidRelationshipTypeHook = () => {
    const unvalidRelationshipType = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (technicalName: string) => {
            return unvalidRelationshipType({ url: `/relationshiptypes/relationshiptype/valid/${technicalName}`, method: 'DELETE' })
        },
        [unvalidRelationshipType],
    )
}

export const useUnvalidRelationshipTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUnvalidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUnvalidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const unvalidRelationshipType = useUnvalidRelationshipTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUnvalidRelationshipTypeHook>>>, { technicalName: string }> = (
        props,
    ) => {
        const { technicalName } = props ?? {}

        return unvalidRelationshipType(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type UnvalidRelationshipTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUnvalidRelationshipTypeHook>>>>

export type UnvalidRelationshipTypeMutationError = ApiError

export const useUnvalidRelationshipType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUnvalidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useUnvalidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext> => {
    const mutationOptions = useUnvalidRelationshipTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreExistRelationshipTypeHook = () => {
    const storeExistRelationshipType = useTypesRepoSwaggerClient<RelationshipType>()

    return useCallback(
        (relationshipType: RelationshipType) => {
            return storeExistRelationshipType({
                url: `/relationshiptypes/relationshiptype/store`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: relationshipType,
            })
        },
        [storeExistRelationshipType],
    )
}

export const useStoreExistRelationshipTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreExistRelationshipTypeHook>>>,
        TError,
        { data: RelationshipType },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistRelationshipTypeHook>>>, TError, { data: RelationshipType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeExistRelationshipType = useStoreExistRelationshipTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreExistRelationshipTypeHook>>>, { data: RelationshipType }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return storeExistRelationshipType(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreExistRelationshipTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreExistRelationshipTypeHook>>>>
export type StoreExistRelationshipTypeMutationBody = RelationshipType
export type StoreExistRelationshipTypeMutationError = ApiError

export const useStoreExistRelationshipType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreExistRelationshipTypeHook>>>,
        TError,
        { data: RelationshipType },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreExistRelationshipTypeHook>>>, TError, { data: RelationshipType }, TContext> => {
    const mutationOptions = useStoreExistRelationshipTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreNewRelationshipTypeHook = () => {
    const storeNewRelationshipType = useTypesRepoSwaggerClient<RelationshipType>()

    return useCallback(
        (relationshipType: RelationshipType) => {
            return storeNewRelationshipType({
                url: `/relationshiptypes/relationshiptype/store`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: relationshipType,
            })
        },
        [storeNewRelationshipType],
    )
}

export const useStoreNewRelationshipTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreNewRelationshipTypeHook>>>,
        TError,
        { data: RelationshipType },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewRelationshipTypeHook>>>, TError, { data: RelationshipType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeNewRelationshipType = useStoreNewRelationshipTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreNewRelationshipTypeHook>>>, { data: RelationshipType }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return storeNewRelationshipType(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreNewRelationshipTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreNewRelationshipTypeHook>>>>
export type StoreNewRelationshipTypeMutationBody = RelationshipType
export type StoreNewRelationshipTypeMutationError = ApiError

export const useStoreNewRelationshipType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreNewRelationshipTypeHook>>>,
        TError,
        { data: RelationshipType },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreNewRelationshipTypeHook>>>, TError, { data: RelationshipType }, TContext> => {
    const mutationOptions = useStoreNewRelationshipTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetSummarizingCardHook = () => {
    const getSummarizingCard = useTypesRepoSwaggerClient<SummarizingCardUi>()

    return useCallback(
        (technicalName: string, signal?: AbortSignal) => {
            return getSummarizingCard({ url: `/citypes/citype/${technicalName}/summarizingcard`, method: 'GET', signal })
        },
        [getSummarizingCard],
    )
}

export const getGetSummarizingCardQueryKey = (technicalName: string) => {
    return [`/citypes/citype/${technicalName}/summarizingcard`] as const
}

export const useGetSummarizingCardQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetSummarizingCardQueryKey(technicalName)

    const getSummarizingCard = useGetSummarizingCardHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>> = ({ signal }) =>
        getSummarizingCard(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetSummarizingCardQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>>
export type GetSummarizingCardQueryError = ApiError

export function useGetSummarizingCard<TData = Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetSummarizingCardQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useSetSummarizingCardHook = () => {
    const setSummarizingCard = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (technicalName: string, summarizingCardUi: SummarizingCardUi) => {
            return setSummarizingCard({
                url: `/citypes/citype/${technicalName}/summarizingcard`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: summarizingCardUi,
            })
        },
        [setSummarizingCard],
    )
}

export const useSetSummarizingCardMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSetSummarizingCardHook>>>,
        TError,
        { technicalName: string; data: SummarizingCardUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetSummarizingCardHook>>>,
    TError,
    { technicalName: string; data: SummarizingCardUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const setSummarizingCard = useSetSummarizingCardHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useSetSummarizingCardHook>>>,
        { technicalName: string; data: SummarizingCardUi }
    > = (props) => {
        const { technicalName, data } = props ?? {}

        return setSummarizingCard(technicalName, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SetSummarizingCardMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetSummarizingCardHook>>>>
export type SetSummarizingCardMutationBody = SummarizingCardUi
export type SetSummarizingCardMutationError = ApiError

export const useSetSummarizingCard = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSetSummarizingCardHook>>>,
        TError,
        { technicalName: string; data: SummarizingCardUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useSetSummarizingCardHook>>>,
    TError,
    { technicalName: string; data: SummarizingCardUi },
    TContext
> => {
    const mutationOptions = useSetSummarizingCardMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteSummarizingCardHook = () => {
    const deleteSummarizingCard = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (technicalName: string) => {
            return deleteSummarizingCard({ url: `/citypes/citype/${technicalName}/summarizingcard`, method: 'DELETE' })
        },
        [deleteSummarizingCard],
    )
}

export const useDeleteSummarizingCardMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteSummarizingCardHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteSummarizingCardHook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteSummarizingCard = useDeleteSummarizingCardHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteSummarizingCardHook>>>, { technicalName: string }> = (props) => {
        const { technicalName } = props ?? {}

        return deleteSummarizingCard(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteSummarizingCardMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteSummarizingCardHook>>>>

export type DeleteSummarizingCardMutationError = ApiError

export const useDeleteSummarizingCard = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteSummarizingCardHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteSummarizingCardHook>>>, TError, { technicalName: string }, TContext> => {
    const mutationOptions = useDeleteSummarizingCardMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreAttributeTextation1Hook = () => {
    const storeAttributeTextation1 = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (technicalName: string, attTecName: string, attribute: Attribute) => {
            return storeAttributeTextation1({
                url: `/citypes/citype/${technicalName}/attribute/${attTecName}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: attribute,
            })
        },
        [storeAttributeTextation1],
    )
}

export const useStoreAttributeTextation1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextation1Hook>>>,
        TError,
        { technicalName: string; attTecName: string; data: Attribute },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextation1Hook>>>,
    TError,
    { technicalName: string; attTecName: string; data: Attribute },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeAttributeTextation1 = useStoreAttributeTextation1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextation1Hook>>>,
        { technicalName: string; attTecName: string; data: Attribute }
    > = (props) => {
        const { technicalName, attTecName, data } = props ?? {}

        return storeAttributeTextation1(technicalName, attTecName, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreAttributeTextation1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextation1Hook>>>>
export type StoreAttributeTextation1MutationBody = Attribute
export type StoreAttributeTextation1MutationError = ApiError

export const useStoreAttributeTextation1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextation1Hook>>>,
        TError,
        { technicalName: string; attTecName: string; data: Attribute },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextation1Hook>>>,
    TError,
    { technicalName: string; attTecName: string; data: Attribute },
    TContext
> => {
    const mutationOptions = useStoreAttributeTextation1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteAttributeTextation1Hook = () => {
    const deleteAttributeTextation1 = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (technicalName: string, attTecName: string) => {
            return deleteAttributeTextation1({ url: `/citypes/citype/${technicalName}/attribute/${attTecName}`, method: 'DELETE' })
        },
        [deleteAttributeTextation1],
    )
}

export const useDeleteAttributeTextation1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextation1Hook>>>,
        TError,
        { technicalName: string; attTecName: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextation1Hook>>>,
    TError,
    { technicalName: string; attTecName: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteAttributeTextation1 = useDeleteAttributeTextation1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextation1Hook>>>,
        { technicalName: string; attTecName: string }
    > = (props) => {
        const { technicalName, attTecName } = props ?? {}

        return deleteAttributeTextation1(technicalName, attTecName)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteAttributeTextation1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextation1Hook>>>>

export type DeleteAttributeTextation1MutationError = ApiError

export const useDeleteAttributeTextation1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextation1Hook>>>,
        TError,
        { technicalName: string; attTecName: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextation1Hook>>>,
    TError,
    { technicalName: string; attTecName: string },
    TContext
> => {
    const mutationOptions = useDeleteAttributeTextation1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreValidHook = () => {
    const storeValid = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (technicalName: string) => {
            return storeValid({ url: `/citypes/citype/valid/${technicalName}`, method: 'PUT' })
        },
        [storeValid],
    )
}

export const useStoreValidMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreValidHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreValidHook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeValid = useStoreValidHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreValidHook>>>, { technicalName: string }> = (props) => {
        const { technicalName } = props ?? {}

        return storeValid(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreValidMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreValidHook>>>>

export type StoreValidMutationError = ApiError

export const useStoreValid = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreValidHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreValidHook>>>, TError, { technicalName: string }, TContext> => {
    const mutationOptions = useStoreValidMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreUnvalidHook = () => {
    const storeUnvalid = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (technicalName: string) => {
            return storeUnvalid({ url: `/citypes/citype/valid/${technicalName}`, method: 'DELETE' })
        },
        [storeUnvalid],
    )
}

export const useStoreUnvalidMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreUnvalidHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreUnvalidHook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeUnvalid = useStoreUnvalidHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreUnvalidHook>>>, { technicalName: string }> = (props) => {
        const { technicalName } = props ?? {}

        return storeUnvalid(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreUnvalidMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreUnvalidHook>>>>

export type StoreUnvalidMutationError = ApiError

export const useStoreUnvalid = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreUnvalidHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreUnvalidHook>>>, TError, { technicalName: string }, TContext> => {
    const mutationOptions = useStoreUnvalidMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreExistCiTypeHook = () => {
    const storeExistCiType = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (ciType: CiType) => {
            return storeExistCiType({ url: `/citypes/citype/store`, method: 'PUT', headers: { 'Content-Type': 'application/json' }, data: ciType })
        },
        [storeExistCiType],
    )
}

export const useStoreExistCiTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistCiTypeHook>>>, TError, { data: CiType }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistCiTypeHook>>>, TError, { data: CiType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeExistCiType = useStoreExistCiTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreExistCiTypeHook>>>, { data: CiType }> = (props) => {
        const { data } = props ?? {}

        return storeExistCiType(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreExistCiTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreExistCiTypeHook>>>>
export type StoreExistCiTypeMutationBody = CiType
export type StoreExistCiTypeMutationError = ApiError

export const useStoreExistCiType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistCiTypeHook>>>, TError, { data: CiType }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreExistCiTypeHook>>>, TError, { data: CiType }, TContext> => {
    const mutationOptions = useStoreExistCiTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreNewCiTypeHook = () => {
    const storeNewCiType = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (ciType: CiType) => {
            return storeNewCiType({ url: `/citypes/citype/store`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: ciType })
        },
        [storeNewCiType],
    )
}

export const useStoreNewCiTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeHook>>>, TError, { data: CiType }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeHook>>>, TError, { data: CiType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeNewCiType = useStoreNewCiTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeHook>>>, { data: CiType }> = (props) => {
        const { data } = props ?? {}

        return storeNewCiType(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreNewCiTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeHook>>>>
export type StoreNewCiTypeMutationBody = CiType
export type StoreNewCiTypeMutationError = ApiError

export const useStoreNewCiType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeHook>>>, TError, { data: CiType }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeHook>>>, TError, { data: CiType }, TContext> => {
    const mutationOptions = useStoreNewCiTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreExistsCiTypeRelationshipTypeMapHook = () => {
    const storeExistsCiTypeRelationshipTypeMap = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (ciTypeRelationshipTypeMap: CiTypeRelationshipTypeMap) => {
            return storeExistsCiTypeRelationshipTypeMap({
                url: `/cityperelationshiptypemaps/cityperelationshiptypemap/store`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: ciTypeRelationshipTypeMap,
            })
        },
        [storeExistsCiTypeRelationshipTypeMap],
    )
}

export const useStoreExistsCiTypeRelationshipTypeMapMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreExistsCiTypeRelationshipTypeMapHook>>>,
        TError,
        { data: CiTypeRelationshipTypeMap },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreExistsCiTypeRelationshipTypeMapHook>>>,
    TError,
    { data: CiTypeRelationshipTypeMap },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeExistsCiTypeRelationshipTypeMap = useStoreExistsCiTypeRelationshipTypeMapHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreExistsCiTypeRelationshipTypeMapHook>>>,
        { data: CiTypeRelationshipTypeMap }
    > = (props) => {
        const { data } = props ?? {}

        return storeExistsCiTypeRelationshipTypeMap(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreExistsCiTypeRelationshipTypeMapMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useStoreExistsCiTypeRelationshipTypeMapHook>>>
>
export type StoreExistsCiTypeRelationshipTypeMapMutationBody = CiTypeRelationshipTypeMap
export type StoreExistsCiTypeRelationshipTypeMapMutationError = ApiError

export const useStoreExistsCiTypeRelationshipTypeMap = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreExistsCiTypeRelationshipTypeMapHook>>>,
        TError,
        { data: CiTypeRelationshipTypeMap },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreExistsCiTypeRelationshipTypeMapHook>>>,
    TError,
    { data: CiTypeRelationshipTypeMap },
    TContext
> => {
    const mutationOptions = useStoreExistsCiTypeRelationshipTypeMapMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreNewCiTypeRelationshipTypeMapHook = () => {
    const storeNewCiTypeRelationshipTypeMap = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (ciTypeRelationshipTypeMap: CiTypeRelationshipTypeMap) => {
            return storeNewCiTypeRelationshipTypeMap({
                url: `/cityperelationshiptypemaps/cityperelationshiptypemap/store`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: ciTypeRelationshipTypeMap,
            })
        },
        [storeNewCiTypeRelationshipTypeMap],
    )
}

export const useStoreNewCiTypeRelationshipTypeMapMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeRelationshipTypeMapHook>>>,
        TError,
        { data: CiTypeRelationshipTypeMap },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeRelationshipTypeMapHook>>>,
    TError,
    { data: CiTypeRelationshipTypeMap },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeNewCiTypeRelationshipTypeMap = useStoreNewCiTypeRelationshipTypeMapHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeRelationshipTypeMapHook>>>,
        { data: CiTypeRelationshipTypeMap }
    > = (props) => {
        const { data } = props ?? {}

        return storeNewCiTypeRelationshipTypeMap(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreNewCiTypeRelationshipTypeMapMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeRelationshipTypeMapHook>>>
>
export type StoreNewCiTypeRelationshipTypeMapMutationBody = CiTypeRelationshipTypeMap
export type StoreNewCiTypeRelationshipTypeMapMutationError = ApiError

export const useStoreNewCiTypeRelationshipTypeMap = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeRelationshipTypeMapHook>>>,
        TError,
        { data: CiTypeRelationshipTypeMap },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeRelationshipTypeMapHook>>>,
    TError,
    { data: CiTypeRelationshipTypeMap },
    TContext
> => {
    const mutationOptions = useStoreNewCiTypeRelationshipTypeMapMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreValid1Hook = () => {
    const storeValid1 = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (technicalName: string) => {
            return storeValid1({ url: `/attrprofiles/attrprofile/valid/${technicalName}`, method: 'PUT' })
        },
        [storeValid1],
    )
}

export const useStoreValid1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreValid1Hook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreValid1Hook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeValid1 = useStoreValid1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreValid1Hook>>>, { technicalName: string }> = (props) => {
        const { technicalName } = props ?? {}

        return storeValid1(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreValid1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreValid1Hook>>>>

export type StoreValid1MutationError = ApiError

export const useStoreValid1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreValid1Hook>>>, TError, { technicalName: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreValid1Hook>>>, TError, { technicalName: string }, TContext> => {
    const mutationOptions = useStoreValid1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreUnValidHook = () => {
    const storeUnValid = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (technicalName: string) => {
            return storeUnValid({ url: `/attrprofiles/attrprofile/valid/${technicalName}`, method: 'DELETE' })
        },
        [storeUnValid],
    )
}

export const useStoreUnValidMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreUnValidHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreUnValidHook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeUnValid = useStoreUnValidHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreUnValidHook>>>, { technicalName: string }> = (props) => {
        const { technicalName } = props ?? {}

        return storeUnValid(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreUnValidMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreUnValidHook>>>>

export type StoreUnValidMutationError = ApiError

export const useStoreUnValid = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreUnValidHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreUnValidHook>>>, TError, { technicalName: string }, TContext> => {
    const mutationOptions = useStoreUnValidMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreExistAttrProfileHook = () => {
    const storeExistAttrProfile = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (attributeProfile: AttributeProfile) => {
            return storeExistAttrProfile({
                url: `/attrprofiles/attrprofile/store`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: attributeProfile,
            })
        },
        [storeExistAttrProfile],
    )
}

export const useStoreExistAttrProfileMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeExistAttrProfile = useStoreExistAttrProfileHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreExistAttrProfileHook>>>, { data: AttributeProfile }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return storeExistAttrProfile(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreExistAttrProfileMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreExistAttrProfileHook>>>>
export type StoreExistAttrProfileMutationBody = AttributeProfile
export type StoreExistAttrProfileMutationError = ApiError

export const useStoreExistAttrProfile = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreExistAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext> => {
    const mutationOptions = useStoreExistAttrProfileMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreNewAttrProfileHook = () => {
    const storeNewAttrProfile = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (attributeProfile: AttributeProfile) => {
            return storeNewAttrProfile({
                url: `/attrprofiles/attrprofile/store`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: attributeProfile,
            })
        },
        [storeNewAttrProfile],
    )
}

export const useStoreNewAttrProfileMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeNewAttrProfile = useStoreNewAttrProfileHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreNewAttrProfileHook>>>, { data: AttributeProfile }> = (props) => {
        const { data } = props ?? {}

        return storeNewAttrProfile(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreNewAttrProfileMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreNewAttrProfileHook>>>>
export type StoreNewAttrProfileMutationBody = AttributeProfile
export type StoreNewAttrProfileMutationError = ApiError

export const useStoreNewAttrProfile = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreNewAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext> => {
    const mutationOptions = useStoreNewAttrProfileMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreExistGenericAttrProfileHook = () => {
    const storeExistGenericAttrProfile = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (attributeProfile: AttributeProfile) => {
            return storeExistGenericAttrProfile({
                url: `/attrprofiles/attrprofile/store/generic`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: attributeProfile,
            })
        },
        [storeExistGenericAttrProfile],
    )
}

export const useStoreExistGenericAttrProfileMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreExistGenericAttrProfileHook>>>,
        TError,
        { data: AttributeProfile },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistGenericAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeExistGenericAttrProfile = useStoreExistGenericAttrProfileHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreExistGenericAttrProfileHook>>>, { data: AttributeProfile }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return storeExistGenericAttrProfile(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreExistGenericAttrProfileMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreExistGenericAttrProfileHook>>>>
export type StoreExistGenericAttrProfileMutationBody = AttributeProfile
export type StoreExistGenericAttrProfileMutationError = ApiError

export const useStoreExistGenericAttrProfile = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreExistGenericAttrProfileHook>>>,
        TError,
        { data: AttributeProfile },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreExistGenericAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext> => {
    const mutationOptions = useStoreExistGenericAttrProfileMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreValid2Hook = () => {
    const storeValid2 = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (attrProfileTechnicalName: string, technicalName: string) => {
            return storeValid2({ url: `/attributes/attribute/valid/${attrProfileTechnicalName}/${technicalName}`, method: 'PUT' })
        },
        [storeValid2],
    )
}

export const useStoreValid2MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreValid2Hook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreValid2Hook>>>,
    TError,
    { attrProfileTechnicalName: string; technicalName: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeValid2 = useStoreValid2Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreValid2Hook>>>,
        { attrProfileTechnicalName: string; technicalName: string }
    > = (props) => {
        const { attrProfileTechnicalName, technicalName } = props ?? {}

        return storeValid2(attrProfileTechnicalName, technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreValid2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreValid2Hook>>>>

export type StoreValid2MutationError = ApiError

export const useStoreValid2 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreValid2Hook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreValid2Hook>>>,
    TError,
    { attrProfileTechnicalName: string; technicalName: string },
    TContext
> => {
    const mutationOptions = useStoreValid2MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreUnvalid1Hook = () => {
    const storeUnvalid1 = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (attrProfileTechnicalName: string, technicalName: string) => {
            return storeUnvalid1({ url: `/attributes/attribute/valid/${attrProfileTechnicalName}/${technicalName}`, method: 'DELETE' })
        },
        [storeUnvalid1],
    )
}

export const useStoreUnvalid1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreUnvalid1Hook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreUnvalid1Hook>>>,
    TError,
    { attrProfileTechnicalName: string; technicalName: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeUnvalid1 = useStoreUnvalid1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreUnvalid1Hook>>>,
        { attrProfileTechnicalName: string; technicalName: string }
    > = (props) => {
        const { attrProfileTechnicalName, technicalName } = props ?? {}

        return storeUnvalid1(attrProfileTechnicalName, technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreUnvalid1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreUnvalid1Hook>>>>

export type StoreUnvalid1MutationError = ApiError

export const useStoreUnvalid1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreUnvalid1Hook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreUnvalid1Hook>>>,
    TError,
    { attrProfileTechnicalName: string; technicalName: string },
    TContext
> => {
    const mutationOptions = useStoreUnvalid1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreExistAttributeHook = () => {
    const storeExistAttribute = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (attribute: Attribute) => {
            return storeExistAttribute({
                url: `/attributes/attribute/store`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: attribute,
            })
        },
        [storeExistAttribute],
    )
}

export const useStoreExistAttributeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistAttributeHook>>>, TError, { data: Attribute }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistAttributeHook>>>, TError, { data: Attribute }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeExistAttribute = useStoreExistAttributeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreExistAttributeHook>>>, { data: Attribute }> = (props) => {
        const { data } = props ?? {}

        return storeExistAttribute(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreExistAttributeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreExistAttributeHook>>>>
export type StoreExistAttributeMutationBody = Attribute
export type StoreExistAttributeMutationError = ApiError

export const useStoreExistAttribute = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistAttributeHook>>>, TError, { data: Attribute }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreExistAttributeHook>>>, TError, { data: Attribute }, TContext> => {
    const mutationOptions = useStoreExistAttributeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreVisibleHook = () => {
    const storeVisible = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (attrProfileTechnicalName: string, technicalName: string) => {
            return storeVisible({ url: `/attributes/attribute/invisible/${attrProfileTechnicalName}/${technicalName}`, method: 'PUT' })
        },
        [storeVisible],
    )
}

export const useStoreVisibleMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreVisibleHook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreVisibleHook>>>,
    TError,
    { attrProfileTechnicalName: string; technicalName: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeVisible = useStoreVisibleHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreVisibleHook>>>,
        { attrProfileTechnicalName: string; technicalName: string }
    > = (props) => {
        const { attrProfileTechnicalName, technicalName } = props ?? {}

        return storeVisible(attrProfileTechnicalName, technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreVisibleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreVisibleHook>>>>

export type StoreVisibleMutationError = ApiError

export const useStoreVisible = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreVisibleHook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreVisibleHook>>>,
    TError,
    { attrProfileTechnicalName: string; technicalName: string },
    TContext
> => {
    const mutationOptions = useStoreVisibleMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreInvisibleHook = () => {
    const storeInvisible = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (attrProfileTechnicalName: string, technicalName: string) => {
            return storeInvisible({ url: `/attributes/attribute/invisible/${attrProfileTechnicalName}/${technicalName}`, method: 'DELETE' })
        },
        [storeInvisible],
    )
}

export const useStoreInvisibleMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreInvisibleHook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreInvisibleHook>>>,
    TError,
    { attrProfileTechnicalName: string; technicalName: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeInvisible = useStoreInvisibleHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreInvisibleHook>>>,
        { attrProfileTechnicalName: string; technicalName: string }
    > = (props) => {
        const { attrProfileTechnicalName, technicalName } = props ?? {}

        return storeInvisible(attrProfileTechnicalName, technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreInvisibleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreInvisibleHook>>>>

export type StoreInvisibleMutationError = ApiError

export const useStoreInvisible = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreInvisibleHook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreInvisibleHook>>>,
    TError,
    { attrProfileTechnicalName: string; technicalName: string },
    TContext
> => {
    const mutationOptions = useStoreInvisibleMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreAdminEntityHook = () => {
    const storeAdminEntity = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (relationshipType: RelationshipType) => {
            return storeAdminEntity({
                url: `/relationshiptypes/adminentity/store`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: relationshipType,
            })
        },
        [storeAdminEntity],
    )
}

export const useStoreAdminEntityMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreAdminEntityHook>>>, TError, { data: RelationshipType }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreAdminEntityHook>>>, TError, { data: RelationshipType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeAdminEntity = useStoreAdminEntityHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreAdminEntityHook>>>, { data: RelationshipType }> = (props) => {
        const { data } = props ?? {}

        return storeAdminEntity(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreAdminEntityMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreAdminEntityHook>>>>
export type StoreAdminEntityMutationBody = RelationshipType
export type StoreAdminEntityMutationError = ApiError

export const useStoreAdminEntity = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreAdminEntityHook>>>, TError, { data: RelationshipType }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreAdminEntityHook>>>, TError, { data: RelationshipType }, TContext> => {
    const mutationOptions = useStoreAdminEntityMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary listTypes
 */
export const useListTypesHook = () => {
    const listTypes = useTypesRepoSwaggerClient<CiTypesRelationshipTypesList>()

    return useCallback(
        (typesFilter: TypesFilter) => {
            return listTypes({
                url: `/citypes/relationshiptypes/list`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: typesFilter,
            })
        },
        [listTypes],
    )
}

export const useListTypesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListTypesHook>>>, TError, { data: TypesFilter }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListTypesHook>>>, TError, { data: TypesFilter }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const listTypes = useListTypesHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useListTypesHook>>>, { data: TypesFilter }> = (props) => {
        const { data } = props ?? {}

        return listTypes(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ListTypesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListTypesHook>>>>
export type ListTypesMutationBody = TypesFilter
export type ListTypesMutationError = ApiError

/**
 * @summary listTypes
 */
export const useListTypes = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListTypesHook>>>, TError, { data: TypesFilter }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useListTypesHook>>>, TError, { data: TypesFilter }, TContext> => {
    const mutationOptions = useListTypesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreAdminEntity1Hook = () => {
    const storeAdminEntity1 = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (ciType: CiType, params?: StoreAdminEntity1Params) => {
            return storeAdminEntity1({
                url: `/citypes/adminentity/store`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: ciType,
                params,
            })
        },
        [storeAdminEntity1],
    )
}

export const useStoreAdminEntity1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreAdminEntity1Hook>>>,
        TError,
        { data: CiType; params?: StoreAdminEntity1Params },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreAdminEntity1Hook>>>,
    TError,
    { data: CiType; params?: StoreAdminEntity1Params },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeAdminEntity1 = useStoreAdminEntity1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreAdminEntity1Hook>>>,
        { data: CiType; params?: StoreAdminEntity1Params }
    > = (props) => {
        const { data, params } = props ?? {}

        return storeAdminEntity1(data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreAdminEntity1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreAdminEntity1Hook>>>>
export type StoreAdminEntity1MutationBody = CiType
export type StoreAdminEntity1MutationError = ApiError

export const useStoreAdminEntity1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreAdminEntity1Hook>>>,
        TError,
        { data: CiType; params?: StoreAdminEntity1Params },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreAdminEntity1Hook>>>,
    TError,
    { data: CiType; params?: StoreAdminEntity1Params },
    TContext
> => {
    const mutationOptions = useStoreAdminEntity1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreNewAttributeHook = () => {
    const storeNewAttribute = useTypesRepoSwaggerClient<void>()

    return useCallback(
        (atrProfTechnicalName: string, attribute: Attribute) => {
            return storeNewAttribute({
                url: `/attributes/attribute/store/${atrProfTechnicalName}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: attribute,
            })
        },
        [storeNewAttribute],
    )
}

export const useStoreNewAttributeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreNewAttributeHook>>>,
        TError,
        { atrProfTechnicalName: string; data: Attribute },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreNewAttributeHook>>>,
    TError,
    { atrProfTechnicalName: string; data: Attribute },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeNewAttribute = useStoreNewAttributeHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreNewAttributeHook>>>,
        { atrProfTechnicalName: string; data: Attribute }
    > = (props) => {
        const { atrProfTechnicalName, data } = props ?? {}

        return storeNewAttribute(atrProfTechnicalName, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreNewAttributeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreNewAttributeHook>>>>
export type StoreNewAttributeMutationBody = Attribute
export type StoreNewAttributeMutationError = ApiError

export const useStoreNewAttribute = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreNewAttributeHook>>>,
        TError,
        { atrProfTechnicalName: string; data: Attribute },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useStoreNewAttributeHook>>>,
    TError,
    { atrProfTechnicalName: string; data: Attribute },
    TContext
> => {
    const mutationOptions = useStoreNewAttributeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useListRelationshipTypes2Hook = () => {
    const listRelationshipTypes2 = useTypesRepoSwaggerClient<RightsTypeHolder[]>()

    return useCallback(
        (params: ListRelationshipTypes2Params, signal?: AbortSignal) => {
            return listRelationshipTypes2({ url: `/rights/list`, method: 'GET', params, signal })
        },
        [listRelationshipTypes2],
    )
}

export const getListRelationshipTypes2QueryKey = (params: ListRelationshipTypes2Params) => {
    return [`/rights/list`, ...(params ? [params] : [])] as const
}

export const useListRelationshipTypes2QueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>,
    TError = ApiError,
>(
    params: ListRelationshipTypes2Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListRelationshipTypes2QueryKey(params)

    const listRelationshipTypes2 = useListRelationshipTypes2Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>> = ({ signal }) =>
        listRelationshipTypes2(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListRelationshipTypes2QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>>
export type ListRelationshipTypes2QueryError = ApiError

export function useListRelationshipTypes2<TData = Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>, TError = ApiError>(
    params: ListRelationshipTypes2Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListRelationshipTypes2QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindDerivedByDerivedTechnicalNameHook = () => {
    const findDerivedByDerivedTechnicalName = useTypesRepoSwaggerClient<DerivedRelationshipType>()

    return useCallback(
        (derivedTechnicalName: string, signal?: AbortSignal) => {
            return findDerivedByDerivedTechnicalName({ url: `/relationshiptypes/${derivedTechnicalName}/derived`, method: 'GET', signal })
        },
        [findDerivedByDerivedTechnicalName],
    )
}

export const getFindDerivedByDerivedTechnicalNameQueryKey = (derivedTechnicalName: string) => {
    return [`/relationshiptypes/${derivedTechnicalName}/derived`] as const
}

export const useFindDerivedByDerivedTechnicalNameQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>,
    TError = ApiError,
>(
    derivedTechnicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindDerivedByDerivedTechnicalNameQueryKey(derivedTechnicalName)

    const findDerivedByDerivedTechnicalName = useFindDerivedByDerivedTechnicalNameHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>> = ({ signal }) =>
        findDerivedByDerivedTechnicalName(derivedTechnicalName, signal)

    return { queryKey, queryFn, enabled: !!derivedTechnicalName, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type FindDerivedByDerivedTechnicalNameQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>
>
export type FindDerivedByDerivedTechnicalNameQueryError = ApiError

export function useFindDerivedByDerivedTechnicalName<
    TData = Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>,
    TError = ApiError,
>(
    derivedTechnicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useFindDerivedByDerivedTechnicalNameQueryOptions(derivedTechnicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetRelationshipTypeHook = () => {
    const getRelationshipType = useTypesRepoSwaggerClient<RelationshipType>()

    return useCallback(
        (technicalName: string, signal?: AbortSignal) => {
            return getRelationshipType({ url: `/relationshiptypes/relationshiptype/${technicalName}`, method: 'GET', signal })
        },
        [getRelationshipType],
    )
}

export const getGetRelationshipTypeQueryKey = (technicalName: string) => {
    return [`/relationshiptypes/relationshiptype/${technicalName}`] as const
}

export const useGetRelationshipTypeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRelationshipTypeQueryKey(technicalName)

    const getRelationshipType = useGetRelationshipTypeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>> = ({ signal }) =>
        getRelationshipType(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetRelationshipTypeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>>
export type GetRelationshipTypeQueryError = ApiError

export function useGetRelationshipType<TData = Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetRelationshipTypeQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAttributeOverridesHook = () => {
    const getAttributeOverrides = useTypesRepoSwaggerClient<Attribute[]>()

    return useCallback(
        (technicalName: string, signal?: AbortSignal) => {
            return getAttributeOverrides({ url: `/relationshiptypes/relationshiptype/${technicalName}/attributeOverride`, method: 'GET', signal })
        },
        [getAttributeOverrides],
    )
}

export const getGetAttributeOverridesQueryKey = (technicalName: string) => {
    return [`/relationshiptypes/relationshiptype/${technicalName}/attributeOverride`] as const
}

export const useGetAttributeOverridesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAttributeOverridesQueryKey(technicalName)

    const getAttributeOverrides = useGetAttributeOverridesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>> = ({ signal }) =>
        getAttributeOverrides(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetAttributeOverridesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>>
export type GetAttributeOverridesQueryError = ApiError

export function useGetAttributeOverrides<TData = Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetAttributeOverridesQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGenerateCodeHook = () => {
    const generateCode = useTypesRepoSwaggerClient<RelationshipCode>()

    return useCallback(
        (technicalName: string, signal?: AbortSignal) => {
            return generateCode({ url: `/relationshiptypes/generate/${technicalName}`, method: 'GET', signal })
        },
        [generateCode],
    )
}

export const getGenerateCodeQueryKey = (technicalName: string) => {
    return [`/relationshiptypes/generate/${technicalName}`] as const
}

export const useGenerateCodeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGenerateCodeQueryKey(technicalName)

    const generateCode = useGenerateCodeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>> = ({ signal }) => generateCode(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GenerateCodeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>>
export type GenerateCodeQueryError = ApiError

export function useGenerateCode<TData = Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGenerateCodeQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAllDerivedByCiTypeHook = () => {
    const findAllDerivedByCiType = useTypesRepoSwaggerClient<DerivedRelationshipType[]>()

    return useCallback(
        (ciType: string, signal?: AbortSignal) => {
            return findAllDerivedByCiType({ url: `/relationshiptypes/citypes/${ciType}/derived`, method: 'GET', signal })
        },
        [findAllDerivedByCiType],
    )
}

export const getFindAllDerivedByCiTypeQueryKey = (ciType: string) => {
    return [`/relationshiptypes/citypes/${ciType}/derived`] as const
}

export const useFindAllDerivedByCiTypeQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>,
    TError = ApiError,
>(
    ciType: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAllDerivedByCiTypeQueryKey(ciType)

    const findAllDerivedByCiType = useFindAllDerivedByCiTypeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>> = ({ signal }) =>
        findAllDerivedByCiType(ciType, signal)

    return { queryKey, queryFn, enabled: !!ciType, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type FindAllDerivedByCiTypeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>>
export type FindAllDerivedByCiTypeQueryError = ApiError

export function useFindAllDerivedByCiType<TData = Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>, TError = ApiError>(
    ciType: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useFindAllDerivedByCiTypeQueryOptions(ciType, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetLastCodeHook = () => {
    const getLastCode = useTypesRepoSwaggerClient<CiCode>()

    return useCallback(
        (technicalName: string, signal?: AbortSignal) => {
            return getLastCode({ url: `/citypes/getLastCode/${technicalName}`, method: 'GET', signal })
        },
        [getLastCode],
    )
}

export const getGetLastCodeQueryKey = (technicalName: string) => {
    return [`/citypes/getLastCode/${technicalName}`] as const
}

export const useGetLastCodeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetLastCodeQueryKey(technicalName)

    const getLastCode = useGetLastCodeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>> = ({ signal }) => getLastCode(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetLastCodeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>>
export type GetLastCodeQueryError = ApiError

export function useGetLastCode<TData = Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetLastCodeQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGenerateCodeAndURLHook = () => {
    const generateCodeAndURL = useTypesRepoSwaggerClient<CiCode>()

    return useCallback(
        (technicalName: string, signal?: AbortSignal) => {
            return generateCodeAndURL({ url: `/citypes/generate/${technicalName}`, method: 'GET', signal })
        },
        [generateCodeAndURL],
    )
}

export const getGenerateCodeAndURLQueryKey = (technicalName: string) => {
    return [`/citypes/generate/${technicalName}`] as const
}

export const useGenerateCodeAndURLQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGenerateCodeAndURLQueryKey(technicalName)

    const generateCodeAndURL = useGenerateCodeAndURLHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>> = ({ signal }) =>
        generateCodeAndURL(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GenerateCodeAndURLQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>>
export type GenerateCodeAndURLQueryError = ApiError

export function useGenerateCodeAndURL<TData = Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGenerateCodeAndURLQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGenerateCodeAndURL1Hook = () => {
    const generateCodeAndURL1 = useTypesRepoSwaggerClient<CiCode[]>()

    return useCallback(
        (technicalName: string, params: GenerateCodeAndURL1Params, signal?: AbortSignal) => {
            return generateCodeAndURL1({ url: `/citypes/generate/${technicalName}/bulk`, method: 'GET', params, signal })
        },
        [generateCodeAndURL1],
    )
}

export const getGenerateCodeAndURL1QueryKey = (technicalName: string, params: GenerateCodeAndURL1Params) => {
    return [`/citypes/generate/${technicalName}/bulk`, ...(params ? [params] : [])] as const
}

export const useGenerateCodeAndURL1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>, TError = ApiError>(
    technicalName: string,
    params: GenerateCodeAndURL1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGenerateCodeAndURL1QueryKey(technicalName, params)

    const generateCodeAndURL1 = useGenerateCodeAndURL1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>> = ({ signal }) =>
        generateCodeAndURL1(technicalName, params, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GenerateCodeAndURL1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>>
export type GenerateCodeAndURL1QueryError = ApiError

export function useGenerateCodeAndURL1<TData = Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>, TError = ApiError>(
    technicalName: string,
    params: GenerateCodeAndURL1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGenerateCodeAndURL1QueryOptions(technicalName, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetCiTypeHook = () => {
    const getCiType = useTypesRepoSwaggerClient<CiType>()

    return useCallback(
        (technicalName: string, signal?: AbortSignal) => {
            return getCiType({ url: `/citypes/citype/${technicalName}`, method: 'GET', signal })
        },
        [getCiType],
    )
}

export const getGetCiTypeQueryKey = (technicalName: string) => {
    return [`/citypes/citype/${technicalName}`] as const
}

export const useGetCiTypeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCiTypeQueryKey(technicalName)

    const getCiType = useGetCiTypeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>> = ({ signal }) => getCiType(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCiTypeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>>
export type GetCiTypeQueryError = ApiError

export function useGetCiType<TData = Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetCiTypeQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListRelatedCiTypesHook = () => {
    const listRelatedCiTypes = useTypesRepoSwaggerClient<RelatedCiTypePreviewList>()

    return useCallback(
        (technicalName: string, signal?: AbortSignal) => {
            return listRelatedCiTypes({ url: `/citypes/citype/${technicalName}/related`, method: 'GET', signal })
        },
        [listRelatedCiTypes],
    )
}

export const getListRelatedCiTypesQueryKey = (technicalName: string) => {
    return [`/citypes/citype/${technicalName}/related`] as const
}

export const useListRelatedCiTypesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListRelatedCiTypesQueryKey(technicalName)

    const listRelatedCiTypes = useListRelatedCiTypesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>> = ({ signal }) =>
        listRelatedCiTypes(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListRelatedCiTypesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>>
export type ListRelatedCiTypesQueryError = ApiError

export function useListRelatedCiTypes<TData = Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListRelatedCiTypesQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAttributeOverrides1Hook = () => {
    const getAttributeOverrides1 = useTypesRepoSwaggerClient<Attribute[]>()

    return useCallback(
        (technicalName: string, signal?: AbortSignal) => {
            return getAttributeOverrides1({ url: `/citypes/citype/${technicalName}/attributeOverride`, method: 'GET', signal })
        },
        [getAttributeOverrides1],
    )
}

export const getGetAttributeOverrides1QueryKey = (technicalName: string) => {
    return [`/citypes/citype/${technicalName}/attributeOverride`] as const
}

export const useGetAttributeOverrides1QueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>,
    TError = ApiError,
>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAttributeOverrides1QueryKey(technicalName)

    const getAttributeOverrides1 = useGetAttributeOverrides1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>> = ({ signal }) =>
        getAttributeOverrides1(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetAttributeOverrides1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>>
export type GetAttributeOverrides1QueryError = ApiError

export function useGetAttributeOverrides1<TData = Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetAttributeOverrides1QueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListAttrProfileLMHook = () => {
    const listAttrProfileLM = useTypesRepoSwaggerClient<AttributeProfilePreview>()

    return useCallback(
        (signal?: AbortSignal) => {
            return listAttrProfileLM({ url: `/attrprofiles/listLM`, method: 'GET', signal })
        },
        [listAttrProfileLM],
    )
}

export const getListAttrProfileLMQueryKey = () => {
    return [`/attrprofiles/listLM`] as const
}

export const useListAttrProfileLMQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListAttrProfileLMQueryKey()

    const listAttrProfileLM = useListAttrProfileLMHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>> = ({ signal }) => listAttrProfileLM(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListAttrProfileLMQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>>
export type ListAttrProfileLMQueryError = ApiError

export function useListAttrProfileLM<TData = Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListAttrProfileLMQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListGenericAttrProfileHook = () => {
    const listGenericAttrProfile = useTypesRepoSwaggerClient<AttributeProfilePreview>()

    return useCallback(
        (params: ListGenericAttrProfileParams, signal?: AbortSignal) => {
            return listGenericAttrProfile({ url: `/attrprofiles/list/generic`, method: 'GET', params, signal })
        },
        [listGenericAttrProfile],
    )
}

export const getListGenericAttrProfileQueryKey = (params: ListGenericAttrProfileParams) => {
    return [`/attrprofiles/list/generic`, ...(params ? [params] : [])] as const
}

export const useListGenericAttrProfileQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>,
    TError = ApiError,
>(
    params: ListGenericAttrProfileParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListGenericAttrProfileQueryKey(params)

    const listGenericAttrProfile = useListGenericAttrProfileHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>> = ({ signal }) =>
        listGenericAttrProfile(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListGenericAttrProfileQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>>
export type ListGenericAttrProfileQueryError = ApiError

export function useListGenericAttrProfile<TData = Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>, TError = ApiError>(
    params: ListGenericAttrProfileParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListGenericAttrProfileQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAttributeProfileHook = () => {
    const getAttributeProfile = useTypesRepoSwaggerClient<AttributeProfile>()

    return useCallback(
        (technicalName: string, signal?: AbortSignal) => {
            return getAttributeProfile({ url: `/attrprofiles/attrprofile/${technicalName}`, method: 'GET', signal })
        },
        [getAttributeProfile],
    )
}

export const getGetAttributeProfileQueryKey = (technicalName: string) => {
    return [`/attrprofiles/attrprofile/${technicalName}`] as const
}

export const useGetAttributeProfileQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAttributeProfileQueryKey(technicalName)

    const getAttributeProfile = useGetAttributeProfileHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>> = ({ signal }) =>
        getAttributeProfile(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetAttributeProfileQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>>
export type GetAttributeProfileQueryError = ApiError

export function useGetAttributeProfile<TData = Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetAttributeProfileQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
