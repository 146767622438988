//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * MetaIS TCO
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { useTcoSwaggerClient } from '../hooks/useTcoSwaggerClient'
export type GetTcoEkoCodeByIsvsIdParams = {
    page: number
    count: number
    sortBy?: string
    ascending?: boolean
}

export type GetTcoGroupByPhaseIdPoParams = {
    page: number
    perPage: number
}

export type GetTcoGroupByPhaseIdUserParams = {
    page: number
    perPage: number
}

export type GetTcoGroupByPhaseIdKapitolaParams = {
    page: number
    perPage: number
}

export type GetTcoIsvsByPhaseIdGetParams = {
    page: number
    perPage: number
}

export type ListIsvsExpensesGetWithoutDateParams = {
    page: number
    perPage: number
    nazov?: string
}

export type GetEkoCodesParams = {
    sortBy?: string
    ascending?: boolean
    isActive?: boolean
}

export type InsertExpensesDmsBodyTwo = {
    file: Blob
    json: ExpensesSet
}

export type InsertExpensesDmsBodyOne = {
    file: Blob
    json: ExpensesSet
}

export type StoreRisExpenses200 = { [key: string]: unknown }

export type GetRisExpensesParams = {
    elements: string[]
    yearFrom?: number
    yearTo?: number
}

export type ListIsvsExpensesGetParams = {
    page: number
    perPage: number
}

export interface ExpenseYearList {
    yearsList?: string[]
}

export interface ExpensesSetList {
    expensesSetList?: ExpensesSet[]
}

export interface RisPreviewAnnualBudget {
    accepted?: number
    corrected?: number
    real?: number
    year?: number
}

export interface RisPreviewSummaryPerYear {
    summaryBudget?: RisPreviewAnnualBudget
}

export interface RisPreviewBudgetsForEco {
    annualBudgets?: RisPreviewAnnualBudget[]
    description?: string
    ekoCode?: string
    ekoCodeName?: string
}

export interface RisPreviewBudgetsForEcoList {
    count?: number
    ecoBudgetsList?: RisPreviewBudgetsForEco[]
    summaryPerYearList?: RisPreviewSummaryPerYear[]
}

export interface DmsInfo {
    dmsId?: string
}

export interface ExpensePhase {
    description?: string
    id?: number
    name?: string
}

export interface ExpensePhaseList {
    expensePhases?: ExpensePhase[]
}

export interface ExpensesSet {
    dmsId?: string
    expenseItems: ExpensesSetExpenseItemsItem[]
    expensePhase?: ExpensePhase
    expensesType: string
    fileDmsRef?: string
    modifiedAt?: string
    modifiedBy?: string
    years?: string[]
}

export type ExpenseItemSwAllOf = {
    ekoCode?: string
}

export type ExpenseItemSw = ExpenseItem & ExpenseItemSwAllOf

export type ExpensesSetExpenseItemsItem = ExpenseItemHw | ExpenseItemOwnership | ExpenseItemSw

export type ExpenseItemOwnershipAllOf = {
    item?: string
}

export type ExpenseItemHwAllOf = {
    ekoCode?: string
}

export interface ExpenseItem {
    type?: string
    value?: number
    year?: number
}

export type ExpenseItemOwnership = ExpenseItem & ExpenseItemOwnershipAllOf

export type ExpenseItemHw = ExpenseItem & ExpenseItemHwAllOf

export interface RisExpenseItem {
    acceptedBudget?: number
    administrator?: string
    correctedBudget?: number
    ekoCode?: string
    element?: string
    id?: number
    operator?: string
    realBudget?: number
}

export interface RisAnnualExpense {
    id?: number
    month?: number
    risExpenseItems?: RisExpenseItem[]
    sentAt?: string
    year?: number
}

export interface MetaData {
    createdAt?: string
    createdBy?: string
    lastModifiedAt?: string
    lastModifiedBy?: string
}

export type EkoCodeEkoCodeState = (typeof EkoCodeEkoCodeState)[keyof typeof EkoCodeEkoCodeState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EkoCodeEkoCodeState = {
    ACTIVE: 'ACTIVE',
    INVALIDATED: 'INVALIDATED',
} as const

export interface EkoCode {
    description?: string
    ekoCode?: string
    ekoCodeState?: EkoCodeEkoCodeState
    isUsed?: boolean
    metaData?: MetaData
    name?: string
}

export interface EkoCodeList {
    ekoCodes?: EkoCode[]
    ekoCodesCount?: number
}

export interface TcoRok {
    name?: string
    value?: number
}

export interface TcoNaklady {
    code?: string
    id?: string
    maxYear?: string
    minYear?: string
    name?: string
    prevadzka?: string
    rokList?: TcoRok[]
    spolu?: number
    sprava?: string
}

export interface TcoNakladyList {
    nakladyList?: TcoNaklady[]
    rokySpolu?: TcoRok[]
    size?: number
}

export interface PhaseDate {
    phaseDate?: string
    phaseId?: number
}

export interface IsvsExpenses {
    isvsCode?: string
    isvsId?: string
    isvsName?: string
    phaseDates?: PhaseDate[]
}

export interface IsvsExpensesList {
    count?: number
    isvsList?: IsvsExpenses[]
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useListIsvsExpensesGetHook = () => {
    const listIsvsExpensesGet = useTcoSwaggerClient<IsvsExpensesList>()

    return useCallback(
        (params: ListIsvsExpensesGetParams, signal?: AbortSignal) => {
            return listIsvsExpensesGet({ url: `/expenses`, method: 'GET', params, signal })
        },
        [listIsvsExpensesGet],
    )
}

export const getListIsvsExpensesGetQueryKey = (params: ListIsvsExpensesGetParams) => {
    return [`/expenses`, ...(params ? [params] : [])] as const
}

export const useListIsvsExpensesGetQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetHook>>>, TError = ApiError>(
    params: ListIsvsExpensesGetParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListIsvsExpensesGetQueryKey(params)

    const listIsvsExpensesGet = useListIsvsExpensesGetHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetHook>>>> = ({ signal }) =>
        listIsvsExpensesGet(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListIsvsExpensesGetQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetHook>>>>
export type ListIsvsExpensesGetQueryError = ApiError

export function useListIsvsExpensesGet<TData = Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetHook>>>, TError = ApiError>(
    params: ListIsvsExpensesGetParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListIsvsExpensesGetQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListIsvsExpensesHook = () => {
    const listIsvsExpenses = useTcoSwaggerClient<IsvsExpensesList>()

    return useCallback(
        (isvsExpensesList: IsvsExpensesList) => {
            return listIsvsExpenses({ url: `/expenses`, method: 'PUT', headers: { 'Content-Type': 'application/json' }, data: isvsExpensesList })
        },
        [listIsvsExpenses],
    )
}

export const useListIsvsExpensesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesHook>>>, TError, { data: IsvsExpensesList }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesHook>>>, TError, { data: IsvsExpensesList }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const listIsvsExpenses = useListIsvsExpensesHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesHook>>>, { data: IsvsExpensesList }> = (props) => {
        const { data } = props ?? {}

        return listIsvsExpenses(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ListIsvsExpensesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesHook>>>>
export type ListIsvsExpensesMutationBody = IsvsExpensesList
export type ListIsvsExpensesMutationError = ApiError

export const useListIsvsExpenses = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesHook>>>, TError, { data: IsvsExpensesList }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesHook>>>, TError, { data: IsvsExpensesList }, TContext> => {
    const mutationOptions = useListIsvsExpensesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetTcoIsvsByPhaseIdHook = () => {
    const getTcoIsvsByPhaseId = useTcoSwaggerClient<TcoNakladyList>()

    return useCallback(
        (phaseId: number, tcoNakladyList: TcoNakladyList) => {
            return getTcoIsvsByPhaseId({
                url: `/expenses/naklady/isvs/phase/${phaseId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: tcoNakladyList,
            })
        },
        [getTcoIsvsByPhaseId],
    )
}

export const useGetTcoIsvsByPhaseIdMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTcoIsvsByPhaseIdHook>>>,
        TError,
        { phaseId: number; data: TcoNakladyList },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetTcoIsvsByPhaseIdHook>>>,
    TError,
    { phaseId: number; data: TcoNakladyList },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const getTcoIsvsByPhaseId = useGetTcoIsvsByPhaseIdHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useGetTcoIsvsByPhaseIdHook>>>,
        { phaseId: number; data: TcoNakladyList }
    > = (props) => {
        const { phaseId, data } = props ?? {}

        return getTcoIsvsByPhaseId(phaseId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetTcoIsvsByPhaseIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTcoIsvsByPhaseIdHook>>>>
export type GetTcoIsvsByPhaseIdMutationBody = TcoNakladyList
export type GetTcoIsvsByPhaseIdMutationError = ApiError

export const useGetTcoIsvsByPhaseId = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTcoIsvsByPhaseIdHook>>>,
        TError,
        { phaseId: number; data: TcoNakladyList },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useGetTcoIsvsByPhaseIdHook>>>,
    TError,
    { phaseId: number; data: TcoNakladyList },
    TContext
> => {
    const mutationOptions = useGetTcoIsvsByPhaseIdMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetTcoGroupByPhaseIdHook = () => {
    const getTcoGroupByPhaseId = useTcoSwaggerClient<TcoNakladyList>()

    return useCallback(
        (phaseId: number, sumValue: boolean, tcoNakladyList: TcoNakladyList) => {
            return getTcoGroupByPhaseId({
                url: `/expenses/naklady/group/phase/${phaseId}/sum/${sumValue}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: tcoNakladyList,
            })
        },
        [getTcoGroupByPhaseId],
    )
}

export const useGetTcoGroupByPhaseIdMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdHook>>>,
        TError,
        { phaseId: number; sumValue: boolean; data: TcoNakladyList },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdHook>>>,
    TError,
    { phaseId: number; sumValue: boolean; data: TcoNakladyList },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const getTcoGroupByPhaseId = useGetTcoGroupByPhaseIdHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdHook>>>,
        { phaseId: number; sumValue: boolean; data: TcoNakladyList }
    > = (props) => {
        const { phaseId, sumValue, data } = props ?? {}

        return getTcoGroupByPhaseId(phaseId, sumValue, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetTcoGroupByPhaseIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdHook>>>>
export type GetTcoGroupByPhaseIdMutationBody = TcoNakladyList
export type GetTcoGroupByPhaseIdMutationError = ApiError

export const useGetTcoGroupByPhaseId = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdHook>>>,
        TError,
        { phaseId: number; sumValue: boolean; data: TcoNakladyList },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdHook>>>,
    TError,
    { phaseId: number; sumValue: boolean; data: TcoNakladyList },
    TContext
> => {
    const mutationOptions = useGetTcoGroupByPhaseIdMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateEkoCodeHook = () => {
    const updateEkoCode = useTcoSwaggerClient<void>()

    return useCallback(
        (ekoCodeId: string, ekoCode: EkoCode) => {
            return updateEkoCode({ url: `/ekocodes/${ekoCodeId}`, method: 'PUT', headers: { 'Content-Type': 'application/json' }, data: ekoCode })
        },
        [updateEkoCode],
    )
}

export const useUpdateEkoCodeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateEkoCodeHook>>>,
        TError,
        { ekoCodeId: string; data: EkoCode },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEkoCodeHook>>>, TError, { ekoCodeId: string; data: EkoCode }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateEkoCode = useUpdateEkoCodeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateEkoCodeHook>>>, { ekoCodeId: string; data: EkoCode }> = (
        props,
    ) => {
        const { ekoCodeId, data } = props ?? {}

        return updateEkoCode(ekoCodeId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateEkoCodeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateEkoCodeHook>>>>
export type UpdateEkoCodeMutationBody = EkoCode
export type UpdateEkoCodeMutationError = ApiError

export const useUpdateEkoCode = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateEkoCodeHook>>>,
        TError,
        { ekoCodeId: string; data: EkoCode },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useUpdateEkoCodeHook>>>, TError, { ekoCodeId: string; data: EkoCode }, TContext> => {
    const mutationOptions = useUpdateEkoCodeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteEkoCodeHook = () => {
    const deleteEkoCode = useTcoSwaggerClient<void>()

    return useCallback(
        (ekoCodeId: string) => {
            return deleteEkoCode({ url: `/ekocodes/${ekoCodeId}`, method: 'DELETE' })
        },
        [deleteEkoCode],
    )
}

export const useDeleteEkoCodeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEkoCodeHook>>>, TError, { ekoCodeId: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEkoCodeHook>>>, TError, { ekoCodeId: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteEkoCode = useDeleteEkoCodeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteEkoCodeHook>>>, { ekoCodeId: string }> = (props) => {
        const { ekoCodeId } = props ?? {}

        return deleteEkoCode(ekoCodeId)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteEkoCodeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteEkoCodeHook>>>>

export type DeleteEkoCodeMutationError = ApiError

export const useDeleteEkoCode = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEkoCodeHook>>>, TError, { ekoCodeId: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteEkoCodeHook>>>, TError, { ekoCodeId: string }, TContext> => {
    const mutationOptions = useDeleteEkoCodeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateHrEkoCodeHook = () => {
    const updateHrEkoCode = useTcoSwaggerClient<void>()

    return useCallback(
        (ekoCodeList: EkoCodeList) => {
            return updateHrEkoCode({ url: `/ekocodes/invalidate`, method: 'PUT', headers: { 'Content-Type': 'application/json' }, data: ekoCodeList })
        },
        [updateHrEkoCode],
    )
}

export const useUpdateHrEkoCodeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateHrEkoCodeHook>>>, TError, { data: EkoCodeList }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateHrEkoCodeHook>>>, TError, { data: EkoCodeList }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateHrEkoCode = useUpdateHrEkoCodeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateHrEkoCodeHook>>>, { data: EkoCodeList }> = (props) => {
        const { data } = props ?? {}

        return updateHrEkoCode(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateHrEkoCodeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateHrEkoCodeHook>>>>
export type UpdateHrEkoCodeMutationBody = EkoCodeList
export type UpdateHrEkoCodeMutationError = ApiError

export const useUpdateHrEkoCode = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateHrEkoCodeHook>>>, TError, { data: EkoCodeList }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useUpdateHrEkoCodeHook>>>, TError, { data: EkoCodeList }, TContext> => {
    const mutationOptions = useUpdateHrEkoCodeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useEditEkoCodeHook = () => {
    const editEkoCode = useTcoSwaggerClient<void>()

    return useCallback(
        (ekoCodeId: string, ekoCode: EkoCode) => {
            return editEkoCode({ url: `/ekocodes/edit/${ekoCodeId}`, method: 'PUT', headers: { 'Content-Type': 'application/json' }, data: ekoCode })
        },
        [editEkoCode],
    )
}

export const useEditEkoCodeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditEkoCodeHook>>>, TError, { ekoCodeId: string; data: EkoCode }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditEkoCodeHook>>>, TError, { ekoCodeId: string; data: EkoCode }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const editEkoCode = useEditEkoCodeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useEditEkoCodeHook>>>, { ekoCodeId: string; data: EkoCode }> = (
        props,
    ) => {
        const { ekoCodeId, data } = props ?? {}

        return editEkoCode(ekoCodeId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type EditEkoCodeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useEditEkoCodeHook>>>>
export type EditEkoCodeMutationBody = EkoCode
export type EditEkoCodeMutationError = ApiError

export const useEditEkoCode = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditEkoCodeHook>>>, TError, { ekoCodeId: string; data: EkoCode }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useEditEkoCodeHook>>>, TError, { ekoCodeId: string; data: EkoCode }, TContext> => {
    const mutationOptions = useEditEkoCodeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetRisExpensesHook = () => {
    const getRisExpenses = useTcoSwaggerClient<RisPreviewBudgetsForEcoList>()

    return useCallback(
        (params: GetRisExpensesParams, signal?: AbortSignal) => {
            return getRisExpenses({ url: `/ris`, method: 'GET', params, signal })
        },
        [getRisExpenses],
    )
}

export const getGetRisExpensesQueryKey = (params: GetRisExpensesParams) => {
    return [`/ris`, ...(params ? [params] : [])] as const
}

export const useGetRisExpensesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetRisExpensesHook>>>, TError = ApiError>(
    params: GetRisExpensesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRisExpensesHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRisExpensesQueryKey(params)

    const getRisExpenses = useGetRisExpensesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRisExpensesHook>>>> = ({ signal }) => getRisExpenses(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRisExpensesHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type GetRisExpensesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRisExpensesHook>>>>
export type GetRisExpensesQueryError = ApiError

export function useGetRisExpenses<TData = Awaited<ReturnType<ReturnType<typeof useGetRisExpensesHook>>>, TError = ApiError>(
    params: GetRisExpensesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRisExpensesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetRisExpensesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useStoreRisExpensesHook = () => {
    const storeRisExpenses = useTcoSwaggerClient<StoreRisExpenses200>()

    return useCallback(
        (risAnnualExpense: RisAnnualExpense) => {
            return storeRisExpenses({ url: `/ris`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: risAnnualExpense })
        },
        [storeRisExpenses],
    )
}

export const useStoreRisExpensesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreRisExpensesHook>>>, TError, { data: RisAnnualExpense }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreRisExpensesHook>>>, TError, { data: RisAnnualExpense }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeRisExpenses = useStoreRisExpensesHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreRisExpensesHook>>>, { data: RisAnnualExpense }> = (props) => {
        const { data } = props ?? {}

        return storeRisExpenses(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreRisExpensesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreRisExpensesHook>>>>
export type StoreRisExpensesMutationBody = RisAnnualExpense
export type StoreRisExpensesMutationError = ApiError

export const useStoreRisExpenses = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreRisExpensesHook>>>, TError, { data: RisAnnualExpense }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useStoreRisExpensesHook>>>, TError, { data: RisAnnualExpense }, TContext> => {
    const mutationOptions = useStoreRisExpensesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useListExpensesHook = () => {
    const listExpenses = useTcoSwaggerClient<ExpensesSetList>()

    return useCallback(
        (relatedCiUuid: string, signal?: AbortSignal) => {
            return listExpenses({ url: `/expenses/${relatedCiUuid}`, method: 'GET', signal })
        },
        [listExpenses],
    )
}

export const getListExpensesQueryKey = (relatedCiUuid: string) => {
    return [`/expenses/${relatedCiUuid}`] as const
}

export const useListExpensesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListExpensesHook>>>, TError = ApiError>(
    relatedCiUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListExpensesHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListExpensesQueryKey(relatedCiUuid)

    const listExpenses = useListExpensesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListExpensesHook>>>> = ({ signal }) => listExpenses(relatedCiUuid, signal)

    return { queryKey, queryFn, enabled: !!relatedCiUuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListExpensesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListExpensesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListExpensesHook>>>>
export type ListExpensesQueryError = ApiError

export function useListExpenses<TData = Awaited<ReturnType<ReturnType<typeof useListExpensesHook>>>, TError = ApiError>(
    relatedCiUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListExpensesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListExpensesQueryOptions(relatedCiUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useInsertExpensesHook = () => {
    const insertExpenses = useTcoSwaggerClient<DmsInfo>()

    return useCallback(
        (relatedCiUuid: string, expensesSet: ExpensesSet) => {
            return insertExpenses({
                url: `/expenses/${relatedCiUuid}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: expensesSet,
            })
        },
        [insertExpenses],
    )
}

export const useInsertExpensesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInsertExpensesHook>>>,
        TError,
        { relatedCiUuid: string; data: ExpensesSet },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInsertExpensesHook>>>,
    TError,
    { relatedCiUuid: string; data: ExpensesSet },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const insertExpenses = useInsertExpensesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useInsertExpensesHook>>>,
        { relatedCiUuid: string; data: ExpensesSet }
    > = (props) => {
        const { relatedCiUuid, data } = props ?? {}

        return insertExpenses(relatedCiUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type InsertExpensesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInsertExpensesHook>>>>
export type InsertExpensesMutationBody = ExpensesSet
export type InsertExpensesMutationError = ApiError

export const useInsertExpenses = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInsertExpensesHook>>>,
        TError,
        { relatedCiUuid: string; data: ExpensesSet },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useInsertExpensesHook>>>,
    TError,
    { relatedCiUuid: string; data: ExpensesSet },
    TContext
> => {
    const mutationOptions = useInsertExpensesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useInsertExpenses1Hook = () => {
    const insertExpenses1 = useTcoSwaggerClient<void>()

    return useCallback(
        (relatedCiUuid: string, tcoId: string, expensesSet: ExpensesSet) => {
            return insertExpenses1({
                url: `/expenses/${relatedCiUuid}/tco/${tcoId}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: expensesSet,
            })
        },
        [insertExpenses1],
    )
}

export const useInsertExpenses1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInsertExpenses1Hook>>>,
        TError,
        { relatedCiUuid: string; tcoId: string; data: ExpensesSet },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInsertExpenses1Hook>>>,
    TError,
    { relatedCiUuid: string; tcoId: string; data: ExpensesSet },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const insertExpenses1 = useInsertExpenses1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useInsertExpenses1Hook>>>,
        { relatedCiUuid: string; tcoId: string; data: ExpensesSet }
    > = (props) => {
        const { relatedCiUuid, tcoId, data } = props ?? {}

        return insertExpenses1(relatedCiUuid, tcoId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type InsertExpenses1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInsertExpenses1Hook>>>>
export type InsertExpenses1MutationBody = ExpensesSet
export type InsertExpenses1MutationError = ApiError

export const useInsertExpenses1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInsertExpenses1Hook>>>,
        TError,
        { relatedCiUuid: string; tcoId: string; data: ExpensesSet },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useInsertExpenses1Hook>>>,
    TError,
    { relatedCiUuid: string; tcoId: string; data: ExpensesSet },
    TContext
> => {
    const mutationOptions = useInsertExpenses1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useInsertExpensesDmsHook = () => {
    const insertExpensesDms = useTcoSwaggerClient<DmsInfo>()

    return useCallback(
        (relatedCiUuid: string, insertExpensesDmsBody: InsertExpensesDmsBodyOne | InsertExpensesDmsBodyTwo) => {
            return insertExpensesDms({ url: `/expenses/withDms/${relatedCiUuid}`, method: 'POST', data: insertExpensesDmsBody })
        },
        [insertExpensesDms],
    )
}

export const useInsertExpensesDmsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInsertExpensesDmsHook>>>,
        TError,
        { relatedCiUuid: string; data: InsertExpensesDmsBodyOne | InsertExpensesDmsBodyTwo },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInsertExpensesDmsHook>>>,
    TError,
    { relatedCiUuid: string; data: InsertExpensesDmsBodyOne | InsertExpensesDmsBodyTwo },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const insertExpensesDms = useInsertExpensesDmsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useInsertExpensesDmsHook>>>,
        { relatedCiUuid: string; data: InsertExpensesDmsBodyOne | InsertExpensesDmsBodyTwo }
    > = (props) => {
        const { relatedCiUuid, data } = props ?? {}

        return insertExpensesDms(relatedCiUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type InsertExpensesDmsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInsertExpensesDmsHook>>>>
export type InsertExpensesDmsMutationBody = InsertExpensesDmsBodyOne | InsertExpensesDmsBodyTwo
export type InsertExpensesDmsMutationError = ApiError

export const useInsertExpensesDms = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInsertExpensesDmsHook>>>,
        TError,
        { relatedCiUuid: string; data: InsertExpensesDmsBodyOne | InsertExpensesDmsBodyTwo },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useInsertExpensesDmsHook>>>,
    TError,
    { relatedCiUuid: string; data: InsertExpensesDmsBodyOne | InsertExpensesDmsBodyTwo },
    TContext
> => {
    const mutationOptions = useInsertExpensesDmsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetEkoCodesHook = () => {
    const getEkoCodes = useTcoSwaggerClient<EkoCodeList>()

    return useCallback(
        (params?: GetEkoCodesParams, signal?: AbortSignal) => {
            return getEkoCodes({ url: `/ekocodes`, method: 'GET', params, signal })
        },
        [getEkoCodes],
    )
}

export const getGetEkoCodesQueryKey = (params?: GetEkoCodesParams) => {
    return [`/ekocodes`, ...(params ? [params] : [])] as const
}

export const useGetEkoCodesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetEkoCodesHook>>>, TError = ApiError>(
    params?: GetEkoCodesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEkoCodesHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetEkoCodesQueryKey(params)

    const getEkoCodes = useGetEkoCodesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEkoCodesHook>>>> = ({ signal }) => getEkoCodes(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEkoCodesHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type GetEkoCodesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetEkoCodesHook>>>>
export type GetEkoCodesQueryError = ApiError

export function useGetEkoCodes<TData = Awaited<ReturnType<ReturnType<typeof useGetEkoCodesHook>>>, TError = ApiError>(
    params?: GetEkoCodesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEkoCodesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetEkoCodesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCreateEkoCodeHook = () => {
    const createEkoCode = useTcoSwaggerClient<void>()

    return useCallback(
        (ekoCode: EkoCode) => {
            return createEkoCode({ url: `/ekocodes`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: ekoCode })
        },
        [createEkoCode],
    )
}

export const useCreateEkoCodeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateEkoCodeHook>>>, TError, { data: EkoCode }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateEkoCodeHook>>>, TError, { data: EkoCode }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const createEkoCode = useCreateEkoCodeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateEkoCodeHook>>>, { data: EkoCode }> = (props) => {
        const { data } = props ?? {}

        return createEkoCode(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateEkoCodeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateEkoCodeHook>>>>
export type CreateEkoCodeMutationBody = EkoCode
export type CreateEkoCodeMutationError = ApiError

export const useCreateEkoCode = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateEkoCodeHook>>>, TError, { data: EkoCode }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCreateEkoCodeHook>>>, TError, { data: EkoCode }, TContext> => {
    const mutationOptions = useCreateEkoCodeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useListExpenseYearsHook = () => {
    const listExpenseYears = useTcoSwaggerClient<ExpenseYearList>()

    return useCallback(
        (signal?: AbortSignal) => {
            return listExpenseYears({ url: `/expenses/years`, method: 'GET', signal })
        },
        [listExpenseYears],
    )
}

export const getListExpenseYearsQueryKey = () => {
    return [`/expenses/years`] as const
}

export const useListExpenseYearsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListExpenseYearsHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListExpenseYearsHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListExpenseYearsQueryKey()

    const listExpenseYears = useListExpenseYearsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListExpenseYearsHook>>>> = ({ signal }) => listExpenseYears(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListExpenseYearsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListExpenseYearsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListExpenseYearsHook>>>>
export type ListExpenseYearsQueryError = ApiError

export function useListExpenseYears<TData = Awaited<ReturnType<ReturnType<typeof useListExpenseYearsHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListExpenseYearsHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListExpenseYearsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListIsvsExpensesGetWithoutDateHook = () => {
    const listIsvsExpensesGetWithoutDate = useTcoSwaggerClient<IsvsExpensesList>()

    return useCallback(
        (params: ListIsvsExpensesGetWithoutDateParams, signal?: AbortSignal) => {
            return listIsvsExpensesGetWithoutDate({ url: `/expenses/withoutDate`, method: 'GET', params, signal })
        },
        [listIsvsExpensesGetWithoutDate],
    )
}

export const getListIsvsExpensesGetWithoutDateQueryKey = (params: ListIsvsExpensesGetWithoutDateParams) => {
    return [`/expenses/withoutDate`, ...(params ? [params] : [])] as const
}

export const useListIsvsExpensesGetWithoutDateQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetWithoutDateHook>>>,
    TError = ApiError,
>(
    params: ListIsvsExpensesGetWithoutDateParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetWithoutDateHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListIsvsExpensesGetWithoutDateQueryKey(params)

    const listIsvsExpensesGetWithoutDate = useListIsvsExpensesGetWithoutDateHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetWithoutDateHook>>>> = ({ signal }) =>
        listIsvsExpensesGetWithoutDate(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetWithoutDateHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListIsvsExpensesGetWithoutDateQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetWithoutDateHook>>>>
export type ListIsvsExpensesGetWithoutDateQueryError = ApiError

export function useListIsvsExpensesGetWithoutDate<
    TData = Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetWithoutDateHook>>>,
    TError = ApiError,
>(
    params: ListIsvsExpensesGetWithoutDateParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListIsvsExpensesGetWithoutDateHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListIsvsExpensesGetWithoutDateQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListExpensePhasesHook = () => {
    const listExpensePhases = useTcoSwaggerClient<ExpensePhaseList>()

    return useCallback(
        (signal?: AbortSignal) => {
            return listExpensePhases({ url: `/expenses/phases`, method: 'GET', signal })
        },
        [listExpensePhases],
    )
}

export const getListExpensePhasesQueryKey = () => {
    return [`/expenses/phases`] as const
}

export const useListExpensePhasesQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListExpensePhasesHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListExpensePhasesHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListExpensePhasesQueryKey()

    const listExpensePhases = useListExpensePhasesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListExpensePhasesHook>>>> = ({ signal }) => listExpensePhases(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListExpensePhasesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListExpensePhasesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListExpensePhasesHook>>>>
export type ListExpensePhasesQueryError = ApiError

export function useListExpensePhases<TData = Awaited<ReturnType<ReturnType<typeof useListExpensePhasesHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListExpensePhasesHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListExpensePhasesQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetExpensesHook = () => {
    const getExpenses = useTcoSwaggerClient<ExpensesSet>()

    return useCallback(
        (phaseId: number, relatedCiUuid: string, signal?: AbortSignal) => {
            return getExpenses({ url: `/expenses/phase/${phaseId}/isvs/${relatedCiUuid}`, method: 'GET', signal })
        },
        [getExpenses],
    )
}

export const getGetExpensesQueryKey = (phaseId: number, relatedCiUuid: string) => {
    return [`/expenses/phase/${phaseId}/isvs/${relatedCiUuid}`] as const
}

export const useGetExpensesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetExpensesHook>>>, TError = ApiError>(
    phaseId: number,
    relatedCiUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetExpensesHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetExpensesQueryKey(phaseId, relatedCiUuid)

    const getExpenses = useGetExpensesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetExpensesHook>>>> = ({ signal }) =>
        getExpenses(phaseId, relatedCiUuid, signal)

    return { queryKey, queryFn, enabled: !!(phaseId && relatedCiUuid), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetExpensesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetExpensesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetExpensesHook>>>>
export type GetExpensesQueryError = ApiError

export function useGetExpenses<TData = Awaited<ReturnType<ReturnType<typeof useGetExpensesHook>>>, TError = ApiError>(
    phaseId: number,
    relatedCiUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetExpensesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetExpensesQueryOptions(phaseId, relatedCiUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTcoIsvsByPhaseIdGetHook = () => {
    const getTcoIsvsByPhaseIdGet = useTcoSwaggerClient<TcoNakladyList>()

    return useCallback(
        (phaseId: number, poId: string, params: GetTcoIsvsByPhaseIdGetParams, signal?: AbortSignal) => {
            return getTcoIsvsByPhaseIdGet({ url: `/expenses/naklady/isvs/phase/${phaseId}/po/${poId}`, method: 'GET', params, signal })
        },
        [getTcoIsvsByPhaseIdGet],
    )
}

export const getGetTcoIsvsByPhaseIdGetQueryKey = (phaseId: number, poId: string, params: GetTcoIsvsByPhaseIdGetParams) => {
    return [`/expenses/naklady/isvs/phase/${phaseId}/po/${poId}`, ...(params ? [params] : [])] as const
}

export const useGetTcoIsvsByPhaseIdGetQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetTcoIsvsByPhaseIdGetHook>>>,
    TError = ApiError,
>(
    phaseId: number,
    poId: string,
    params: GetTcoIsvsByPhaseIdGetParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTcoIsvsByPhaseIdGetHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTcoIsvsByPhaseIdGetQueryKey(phaseId, poId, params)

    const getTcoIsvsByPhaseIdGet = useGetTcoIsvsByPhaseIdGetHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTcoIsvsByPhaseIdGetHook>>>> = ({ signal }) =>
        getTcoIsvsByPhaseIdGet(phaseId, poId, params, signal)

    return { queryKey, queryFn, enabled: !!(phaseId && poId), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTcoIsvsByPhaseIdGetHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTcoIsvsByPhaseIdGetQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTcoIsvsByPhaseIdGetHook>>>>
export type GetTcoIsvsByPhaseIdGetQueryError = ApiError

export function useGetTcoIsvsByPhaseIdGet<TData = Awaited<ReturnType<ReturnType<typeof useGetTcoIsvsByPhaseIdGetHook>>>, TError = ApiError>(
    phaseId: number,
    poId: string,
    params: GetTcoIsvsByPhaseIdGetParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTcoIsvsByPhaseIdGetHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTcoIsvsByPhaseIdGetQueryOptions(phaseId, poId, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTcoGroupByPhaseIdKapitolaHook = () => {
    const getTcoGroupByPhaseIdKapitola = useTcoSwaggerClient<TcoNakladyList>()

    return useCallback(
        (phaseId: number, params: GetTcoGroupByPhaseIdKapitolaParams, signal?: AbortSignal) => {
            return getTcoGroupByPhaseIdKapitola({ url: `/expenses/naklady/group/phase/${phaseId}`, method: 'GET', params, signal })
        },
        [getTcoGroupByPhaseIdKapitola],
    )
}

export const getGetTcoGroupByPhaseIdKapitolaQueryKey = (phaseId: number, params: GetTcoGroupByPhaseIdKapitolaParams) => {
    return [`/expenses/naklady/group/phase/${phaseId}`, ...(params ? [params] : [])] as const
}

export const useGetTcoGroupByPhaseIdKapitolaQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdKapitolaHook>>>,
    TError = ApiError,
>(
    phaseId: number,
    params: GetTcoGroupByPhaseIdKapitolaParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdKapitolaHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTcoGroupByPhaseIdKapitolaQueryKey(phaseId, params)

    const getTcoGroupByPhaseIdKapitola = useGetTcoGroupByPhaseIdKapitolaHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdKapitolaHook>>>> = ({ signal }) =>
        getTcoGroupByPhaseIdKapitola(phaseId, params, signal)

    return { queryKey, queryFn, enabled: !!phaseId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdKapitolaHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTcoGroupByPhaseIdKapitolaQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdKapitolaHook>>>>
export type GetTcoGroupByPhaseIdKapitolaQueryError = ApiError

export function useGetTcoGroupByPhaseIdKapitola<
    TData = Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdKapitolaHook>>>,
    TError = ApiError,
>(
    phaseId: number,
    params: GetTcoGroupByPhaseIdKapitolaParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdKapitolaHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTcoGroupByPhaseIdKapitolaQueryOptions(phaseId, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTcoGroupByPhaseIdUserHook = () => {
    const getTcoGroupByPhaseIdUser = useTcoSwaggerClient<TcoNakladyList>()

    return useCallback(
        (phaseId: number, params: GetTcoGroupByPhaseIdUserParams, signal?: AbortSignal) => {
            return getTcoGroupByPhaseIdUser({ url: `/expenses/naklady/group/phase/${phaseId}/user`, method: 'GET', params, signal })
        },
        [getTcoGroupByPhaseIdUser],
    )
}

export const getGetTcoGroupByPhaseIdUserQueryKey = (phaseId: number, params: GetTcoGroupByPhaseIdUserParams) => {
    return [`/expenses/naklady/group/phase/${phaseId}/user`, ...(params ? [params] : [])] as const
}

export const useGetTcoGroupByPhaseIdUserQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdUserHook>>>,
    TError = ApiError,
>(
    phaseId: number,
    params: GetTcoGroupByPhaseIdUserParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdUserHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTcoGroupByPhaseIdUserQueryKey(phaseId, params)

    const getTcoGroupByPhaseIdUser = useGetTcoGroupByPhaseIdUserHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdUserHook>>>> = ({ signal }) =>
        getTcoGroupByPhaseIdUser(phaseId, params, signal)

    return { queryKey, queryFn, enabled: !!phaseId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdUserHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTcoGroupByPhaseIdUserQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdUserHook>>>>
export type GetTcoGroupByPhaseIdUserQueryError = ApiError

export function useGetTcoGroupByPhaseIdUser<TData = Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdUserHook>>>, TError = ApiError>(
    phaseId: number,
    params: GetTcoGroupByPhaseIdUserParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdUserHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTcoGroupByPhaseIdUserQueryOptions(phaseId, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTcoGroupByPhaseIdPoHook = () => {
    const getTcoGroupByPhaseIdPo = useTcoSwaggerClient<TcoNakladyList>()

    return useCallback(
        (phaseId: number, poId: string, params: GetTcoGroupByPhaseIdPoParams, signal?: AbortSignal) => {
            return getTcoGroupByPhaseIdPo({ url: `/expenses/naklady/group/phase/${phaseId}/po/${poId}`, method: 'GET', params, signal })
        },
        [getTcoGroupByPhaseIdPo],
    )
}

export const getGetTcoGroupByPhaseIdPoQueryKey = (phaseId: number, poId: string, params: GetTcoGroupByPhaseIdPoParams) => {
    return [`/expenses/naklady/group/phase/${phaseId}/po/${poId}`, ...(params ? [params] : [])] as const
}

export const useGetTcoGroupByPhaseIdPoQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdPoHook>>>,
    TError = ApiError,
>(
    phaseId: number,
    poId: string,
    params: GetTcoGroupByPhaseIdPoParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdPoHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTcoGroupByPhaseIdPoQueryKey(phaseId, poId, params)

    const getTcoGroupByPhaseIdPo = useGetTcoGroupByPhaseIdPoHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdPoHook>>>> = ({ signal }) =>
        getTcoGroupByPhaseIdPo(phaseId, poId, params, signal)

    return { queryKey, queryFn, enabled: !!(phaseId && poId), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdPoHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTcoGroupByPhaseIdPoQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdPoHook>>>>
export type GetTcoGroupByPhaseIdPoQueryError = ApiError

export function useGetTcoGroupByPhaseIdPo<TData = Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdPoHook>>>, TError = ApiError>(
    phaseId: number,
    poId: string,
    params: GetTcoGroupByPhaseIdPoParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTcoGroupByPhaseIdPoHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTcoGroupByPhaseIdPoQueryOptions(phaseId, poId, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTcoEkoCodeByIsvsIdHook = () => {
    const getTcoEkoCodeByIsvsId = useTcoSwaggerClient<TcoNakladyList>()

    return useCallback(
        (isvsId: string, phaseId: number, params: GetTcoEkoCodeByIsvsIdParams, signal?: AbortSignal) => {
            return getTcoEkoCodeByIsvsId({ url: `/expenses/naklady/ekoCode/isvs/${isvsId}/phase/${phaseId}`, method: 'GET', params, signal })
        },
        [getTcoEkoCodeByIsvsId],
    )
}

export const getGetTcoEkoCodeByIsvsIdQueryKey = (isvsId: string, phaseId: number, params: GetTcoEkoCodeByIsvsIdParams) => {
    return [`/expenses/naklady/ekoCode/isvs/${isvsId}/phase/${phaseId}`, ...(params ? [params] : [])] as const
}

export const useGetTcoEkoCodeByIsvsIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTcoEkoCodeByIsvsIdHook>>>, TError = ApiError>(
    isvsId: string,
    phaseId: number,
    params: GetTcoEkoCodeByIsvsIdParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTcoEkoCodeByIsvsIdHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTcoEkoCodeByIsvsIdQueryKey(isvsId, phaseId, params)

    const getTcoEkoCodeByIsvsId = useGetTcoEkoCodeByIsvsIdHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTcoEkoCodeByIsvsIdHook>>>> = ({ signal }) =>
        getTcoEkoCodeByIsvsId(isvsId, phaseId, params, signal)

    return { queryKey, queryFn, enabled: !!(isvsId && phaseId), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTcoEkoCodeByIsvsIdHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTcoEkoCodeByIsvsIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTcoEkoCodeByIsvsIdHook>>>>
export type GetTcoEkoCodeByIsvsIdQueryError = ApiError

export function useGetTcoEkoCodeByIsvsId<TData = Awaited<ReturnType<ReturnType<typeof useGetTcoEkoCodeByIsvsIdHook>>>, TError = ApiError>(
    isvsId: string,
    phaseId: number,
    params: GetTcoEkoCodeByIsvsIdParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTcoEkoCodeByIsvsIdHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTcoEkoCodeByIsvsIdQueryOptions(isvsId, phaseId, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetDmsUuidHook = () => {
    const getDmsUuid = useTcoSwaggerClient<DmsInfo>()

    return useCallback(
        (relatedCiUuid: string, signal?: AbortSignal) => {
            return getDmsUuid({ url: `/expenses/dms/${relatedCiUuid}`, method: 'GET', signal })
        },
        [getDmsUuid],
    )
}

export const getGetDmsUuidQueryKey = (relatedCiUuid: string) => {
    return [`/expenses/dms/${relatedCiUuid}`] as const
}

export const useGetDmsUuidQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDmsUuidHook>>>, TError = ApiError>(
    relatedCiUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDmsUuidHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetDmsUuidQueryKey(relatedCiUuid)

    const getDmsUuid = useGetDmsUuidHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDmsUuidHook>>>> = ({ signal }) => getDmsUuid(relatedCiUuid, signal)

    return { queryKey, queryFn, enabled: !!relatedCiUuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetDmsUuidHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetDmsUuidQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDmsUuidHook>>>>
export type GetDmsUuidQueryError = ApiError

export function useGetDmsUuid<TData = Awaited<ReturnType<ReturnType<typeof useGetDmsUuidHook>>>, TError = ApiError>(
    relatedCiUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDmsUuidHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetDmsUuidQueryOptions(relatedCiUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useDeleteHrEkoCodeHook = () => {
    const deleteHrEkoCode = useTcoSwaggerClient<void>()

    return useCallback(
        (ekoCodeList: EkoCodeList) => {
            return deleteHrEkoCode({ url: `/ekocodes/delete`, method: 'DELETE', headers: { 'Content-Type': 'application/json' }, data: ekoCodeList })
        },
        [deleteHrEkoCode],
    )
}

export const useDeleteHrEkoCodeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteHrEkoCodeHook>>>, TError, { data: EkoCodeList }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteHrEkoCodeHook>>>, TError, { data: EkoCodeList }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteHrEkoCode = useDeleteHrEkoCodeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteHrEkoCodeHook>>>, { data: EkoCodeList }> = (props) => {
        const { data } = props ?? {}

        return deleteHrEkoCode(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteHrEkoCodeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteHrEkoCodeHook>>>>
export type DeleteHrEkoCodeMutationBody = EkoCodeList
export type DeleteHrEkoCodeMutationError = ApiError

export const useDeleteHrEkoCode = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteHrEkoCodeHook>>>, TError, { data: EkoCodeList }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteHrEkoCodeHook>>>, TError, { data: EkoCodeList }, TContext> => {
    const mutationOptions = useDeleteHrEkoCodeMutationOptions(options)

    return useMutation(mutationOptions)
}
