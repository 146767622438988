import { BreadCrumbs, HomeIcon } from '@isdd/idsk-ui-kit/index'
import { RouteNames, RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import { useTranslation } from 'react-i18next'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { RefIdentifiersContainer } from '@/components/containers/ref-identifiers/RefIdentifiersContainer'
import { RefIdentifierListView } from '@/components/views/ref-identifiers/RefIdentifierListView'

const RefIdentifiersRequestsPage = () => {
    const { t } = useTranslation()
    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: RouteNames.HOME, icon: HomeIcon },
                    { label: t('breadcrumbs.dataObjects'), href: RouteNames.HOW_TO_DATA_OBJECTS_MAIN },
                    { label: t('breadcrumbs.refIdentifiers'), href: RouterRoutes.DATA_OBJECT_REF_IDENTIFIER_REQUESTS },
                ]}
            />
            <MainContentWrapper>
                <RefIdentifiersContainer View={(props) => <RefIdentifierListView {...props} />} pageType="requests" />
            </MainContentWrapper>
        </>
    )
}

export default RefIdentifiersRequestsPage
