import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Input, LoadingIndicator } from '@isdd/idsk-ui-kit'
import { TFunction } from 'i18next'
import React, { useId, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import classNames from 'classnames'

import styles from './user-informations/userInformation.module.scss'

import { MutationFeedback } from '@isdd/metais-common/components/mutation-feedback/MutationFeedback'
import { useChangePassword1Hook } from '@isdd/metais-common/api/generated/iam-swagger'
import { ReponseErrorCodeEnum } from '@isdd/metais-common/constants'
import { CrossedEyeIcon, EyeIcon } from '@isdd/metais-common/assets/images'
interface FormData {
    'current-password': string
    'new-password': string
    'new-password-repeat': string
}

const yupSchema = (t: TFunction<'translation', undefined, 'translation'>): Yup.ObjectSchema<FormData> =>
    Yup.object({
        'current-password': Yup.string().trim().required(t('validation.required')).defined(),
        'new-password': Yup.string()
            .trim()
            .required(t('validation.required'))
            .min(8, t('validation.minCharacters', { itemName: t('userProfile.newPassword'), min: 8 }))
            .max(50, t('validation.maxCharacters', { itemName: t('userProfile.newPassword'), max: 50 }))
            .test('password', t('validation.password'), (value) => {
                // Check if the password meets the criteria
                if (!/[A-Z]/.test(value)) return false // Check for at least one uppercase character
                if (!/[a-z]/.test(value)) return false // Check for at least one lowercase character
                if (!/[^a-zA-Z]/.test(value)) return false // Check for at least one other character (not a letter)
                return true // All conditions met
            }),
        'new-password-repeat': Yup.string()
            .trim()
            .when('new-password', {
                is: (val: string) => val !== '',
                then: (schema) => schema.required().oneOf([Yup.ref('new-password')], t('validation.repeatPassword')),
            })
            .required(t('validation.required'))
            .defined(),
    }).defined()

export const UserPasswordChangePage = () => {
    const [isSuccess, setIsSuccess] = useState(false)
    const [error, setError] = useState<string>()
    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation()
    const [showPass, setShowPass] = useState<Set<string>>(new Set())
    const inputId = useId()

    const changePassword = useChangePassword1Hook()
    const { register, formState, handleSubmit } = useForm<FormData>({
        resolver: yupResolver(yupSchema(t)),
        mode: 'onChange',
    })
    const onSubmit = (formData: FormData) => {
        setIsLoading(true)
        changePassword({ newPassword: formData['new-password'], oldPassword: formData['current-password'] })
            .then(() => setIsSuccess(true))
            .catch((err) => {
                ReponseErrorCodeEnum.WRONG_OLD_PASSWORD == JSON.parse(err.message).message
                    ? setError(t(`feedback.WRONG_OLD_PASSWORD`))
                    : setError(t('feedback.mutationErrorMessage'))
            })
            .finally(() => setIsLoading(false))
    }

    const changeVisibility = (field: string) => {
        const updatedSet = new Set(showPass)

        if (updatedSet.has(field)) {
            updatedSet.delete(field)
        } else {
            updatedSet.add(field)
        }

        setShowPass(updatedSet)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classNames({ [styles.positionRelative]: isLoading })} noValidate>
            {isLoading && <LoadingIndicator />}
            <MutationFeedback
                success={isSuccess}
                error={!!error}
                errorMessage={error}
                successMessage={t('feedback.passwordChanged')}
                onMessageClose={() => setIsSuccess(false)}
            />
            <Input
                {...register('current-password')}
                id={`${inputId}-old`}
                required
                label={t('userProfile.oldPassword')}
                error={formState.errors['current-password']?.message}
                type={showPass.has('oldPassword') ? 'text' : 'password'}
                autoComplete="current-password"
                spellCheck="false"
                suffixElement={
                    <div className={styles.showPasswordWrapper}>
                        <Button
                            type="button"
                            variant="secondary"
                            className={styles.showPassword}
                            aria-controls={`${inputId}-old`}
                            aria-label={`${showPass.has('oldPassword') ? t('userProfile.hide') : t('userProfile.show')} ${t(
                                'userProfile.oldPassword',
                            )}`}
                            aria-pressed={showPass.has('oldPassword')}
                            onClick={() => changeVisibility('oldPassword')}
                            label={<img className="" src={showPass.has('oldPassword') ? EyeIcon : CrossedEyeIcon} alt="" />}
                        />
                    </div>
                }
            />

            <Input
                {...register('new-password')}
                id={`${inputId}-new`}
                required
                hint={t('validation.password')}
                label={t('userProfile.newPassword')}
                error={formState.errors['new-password']?.message}
                type={showPass.has('newPassword') ? 'text' : 'password'}
                autoComplete="new-password"
                spellCheck="false"
                suffixElement={
                    <div className={styles.showPasswordWrapper}>
                        <Button
                            type="button"
                            variant="secondary"
                            className={styles.showPassword}
                            aria-controls={`${inputId}-new`}
                            aria-label={`${showPass.has('newPassword') ? t('userProfile.hide') : t('userProfile.show')} ${t(
                                'userProfile.newPassword',
                            )}`}
                            aria-pressed={showPass.has('newPassword')}
                            onClick={() => changeVisibility('newPassword')}
                            label={<img className="" src={showPass.has('newPassword') ? EyeIcon : CrossedEyeIcon} alt="" />}
                        />
                    </div>
                }
            />

            <Input
                {...register('new-password-repeat')}
                id={`${inputId}-newRepeat`}
                label={t('userProfile.repeatPassword')}
                error={formState.errors['new-password-repeat']?.message}
                type={showPass.has('newPasswordRepeat') ? 'text' : 'password'}
                required
                autoComplete="new-password"
                suffixElement={
                    <div className={styles.showPasswordWrapper}>
                        <Button
                            type="button"
                            variant="secondary"
                            className={styles.showPassword}
                            aria-controls={`${inputId}-newRepeat`}
                            aria-label={`${showPass.has('newPasswordRepeat') ? t('userProfile.hide') : t('userProfile.show')} ${t(
                                'userProfile.repeatPassword',
                            )}`}
                            aria-pressed={showPass.has('newPasswordRepeat')}
                            onClick={() => changeVisibility('newPasswordRepeat')}
                            label={<img className="" src={showPass.has('newPasswordRepeat') ? EyeIcon : CrossedEyeIcon} alt="" />}
                        />
                    </div>
                }
            />
            <Button label={t('userProfile.save')} type="submit" />
        </form>
    )
}
