//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * MetaIS ImpExp CMDB
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { useImpexpCmdbSwaggerClient } from '../hooks/useImpexpCmdbSwaggerClient'
export type GetProgess200 = ExportResult | ImportResult

export type Export6Type = (typeof Export6Type)[keyof typeof Export6Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Export6Type = {
    COMMON: 'COMMON',
    GOALS: 'GOALS',
    KRIS: 'KRIS',
    KS: 'KS',
    ISVS: 'ISVS',
    PROJECT: 'PROJECT',
    ACTIVITY: 'ACTIVITY',
} as const

export type Export6Params = {
    uuid: string
    type: Export6Type
    version: number
}

export type Export4Params = {
    serviceType: string
    project: string
    intervalStart: string
    intervalEnd: string
    services?: string[]
    isvs?: string
}

export type Export2Params = {
    trainingUuid: string
}

export type ValidateContentBody = {
    file: Blob
}

export type ValidateContentParams = {
    ciType: string
    roleId?: string
    poId?: string
}

export type UpdateContentBody = {
    file: Blob
}

export type UpdateContentParams = {
    roleId?: string
    poId?: string
}

export type ResultState = (typeof ResultState)[keyof typeof ResultState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultState = {
    STARTED: 'STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    DONE_WITH_ERRORS: 'DONE_WITH_ERRORS',
} as const

export type ResultResultType = (typeof ResultResultType)[keyof typeof ResultResultType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultResultType = {
    IMPORT_RESULT: 'IMPORT_RESULT',
    EXPORT_RESULT: 'EXPORT_RESULT',
} as const

export type ResultProcessingType = (typeof ResultProcessingType)[keyof typeof ResultProcessingType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultProcessingType = {
    IMPORT: 'IMPORT',
    VALIDATE: 'VALIDATE',
    EXPORT_DEFAULT_EXCEL: 'EXPORT_DEFAULT_EXCEL',
    EXPORT_DEFAULT_CSV: 'EXPORT_DEFAULT_CSV',
    EXPORT_DEFAULT_XML: 'EXPORT_DEFAULT_XML',
    EXPORT_DEFAULT_REL_EXCEL: 'EXPORT_DEFAULT_REL_EXCEL',
    EXPORT_DEFAULT_REL_CSV: 'EXPORT_DEFAULT_REL_CSV',
    EXPORT_DEFAULT_REL_XML: 'EXPORT_DEFAULT_REL_XML',
    EXPORT_DEFAULT_RELATIONS_EXCEL: 'EXPORT_DEFAULT_RELATIONS_EXCEL',
    EXPORT_DEFAULT_RELATIONS_CSV: 'EXPORT_DEFAULT_RELATIONS_CSV',
    EXPORT_DEFAULT_RELATIONS_XML: 'EXPORT_DEFAULT_RELATIONS_XML',
    EXPORT_KRIS_EXCEL: 'EXPORT_KRIS_EXCEL',
    EXPORT_KRIS_CSV: 'EXPORT_KRIS_CSV',
    EXPORT_KRIS_XML: 'EXPORT_KRIS_XML',
    EXPORT_MONITORING_EXCEL: 'EXPORT_MONITORING_EXCEL',
    EXPORT_MONITORING_CSV: 'EXPORT_MONITORING_CSV',
    EXPORT_MONITORING_XML: 'EXPORT_MONITORING_XML',
    EXPORT_REPORT_EXCEL: 'EXPORT_REPORT_EXCEL',
    EXPORT_REPORT_CSV: 'EXPORT_REPORT_CSV',
    EXPORT_REPORT_XML: 'EXPORT_REPORT_XML',
    EXPORT_TRAINEE_EXCEL: 'EXPORT_TRAINEE_EXCEL',
    EXPORT_TRAINEE_CSV: 'EXPORT_TRAINEE_CSV',
    EXPORT_TRAINEE_XML: 'EXPORT_TRAINEE_XML',
    EXPORT_MEETING_EXCEL: 'EXPORT_MEETING_EXCEL',
    EXPORT_MEETING_CSV: 'EXPORT_MEETING_CSV',
    EXPORT_MEETING_XML: 'EXPORT_MEETING_XML',
    EXPORT_VOTES_EXCEL: 'EXPORT_VOTES_EXCEL',
    EXPORT_VOTES_CSV: 'EXPORT_VOTES_CSV',
    EXPORT_VOTES_XML: 'EXPORT_VOTES_XML',
    EXPORT_GROUPS_EXCEL: 'EXPORT_GROUPS_EXCEL',
    EXPORT_GROUPS_CSV: 'EXPORT_GROUPS_CSV',
    EXPORT_GROUPS_XML: 'EXPORT_GROUPS_XML',
} as const

export interface Result {
    errorClass?: string
    errorMessage?: string
    fileExtension?: string
    fileName?: string
    id?: string
    processingType?: ResultProcessingType
    resultType?: ResultResultType
    state?: ResultState
}

export interface ExportGroupsRequest {
    ascending?: boolean
    expression?: string
    fieldsToExport: string[]
    identity?: string
    organization?: string
    sortBy?: string
}

export interface ExportFilter {
    ids?: string[]
}

export interface ExportMeetingRequest {
    ascending?: boolean
    createdBy?: string
    fieldsToExport: string[]
    fromDate?: string
    onlyMy?: boolean
    sortAttribute?: string
    state?: string
    toDate?: string
    workGroupId?: string
}

export type ReportExecuteParameters = { [key: string]: { [key: string]: unknown } }

export interface ReportExecute {
    page?: number
    parameters?: ReportExecuteParameters
    perPage?: number
}

export interface ExportVotesRequest {
    ascending?: boolean
    createdBy?: string
    fieldsToExport: string[]
    fromDate?: string
    isSecret?: boolean
    onlyMy?: boolean
    secret?: boolean
    sortAttribute?: string
    state?: string
    toDate?: string
}

export type ExportResult = Result

export type UsageTypeFilterUiWhiteListItem = (typeof UsageTypeFilterUiWhiteListItem)[keyof typeof UsageTypeFilterUiWhiteListItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsageTypeFilterUiWhiteListItem = {
    system: 'system',
    application: 'application',
} as const

export type UsageTypeFilterUiBlackListItem = (typeof UsageTypeFilterUiBlackListItem)[keyof typeof UsageTypeFilterUiBlackListItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsageTypeFilterUiBlackListItem = {
    system: 'system',
    application: 'application',
} as const

export interface UsageTypeFilterUi {
    blackList?: UsageTypeFilterUiBlackListItem[]
    whiteList?: UsageTypeFilterUiWhiteListItem[]
}

export interface RelTypeFilterUi {
    byHierarchy?: boolean
    onlyValidRel?: boolean
    relCiUuids?: string[]
    relType?: string
}

export interface FilterValueUi {
    equality?: string
    value?: string
}

export interface FilterMetaAttributesUi {
    createdAtFrom?: string
    createdAtTo?: string
    createdBy?: string[]
    lastAction?: string[]
    lastModifiedAtFrom?: string
    lastModifiedAtTo?: string
    lastModifiedBy?: string[]
    liableEntity?: string[]
    liableEntityByHierarchy?: boolean
    owner?: string[]
    state?: string[]
}

export interface FilterAttributesUi {
    filterValue?: FilterValueUi[]
    name?: string
}

export interface RelFilterUi {
    attributes?: FilterAttributesUi[]
    endCiTypeName?: string[]
    endCiUuid?: string[]
    fullTextSearch?: string
    metaAttributes?: FilterMetaAttributesUi
    mustExistAttributes?: string[]
    mustNotExistAttributes?: string[]
    poUuid?: string
    searchFields?: string[]
    startCiTypeName?: string[]
    startCiUuid?: string[]
    startOrEndCiUuid?: string[]
    type?: string[]
    usageType?: string[]
    uuid?: string[]
}

export interface RelListFilterContainerUi {
    getIncidentRelations?: boolean
    page?: number
    perpage?: number
    relFilter?: RelFilterUi
    sortBy?: string
    sortByEndCi?: boolean
    sortSource?: string
    sortType?: string
    usageTypeFilter?: UsageTypeFilterUi
}

export interface CiFilterUi {
    attributes?: FilterAttributesUi[]
    fullTextSearch?: string
    metaAttributes?: FilterMetaAttributesUi
    mustExistAttributes?: string[]
    mustNotExistAttributes?: string[]
    poUuid?: string
    relTypeFilters?: RelTypeFilterUi[]
    searchFields?: string[]
    type?: string[]
    usageType?: string[]
    uuid?: string[]
}

export interface CiListFilterContainerUi {
    filter?: CiFilterUi
    getIncidentRelations?: boolean
    page?: number
    perpage?: number
    relFilter?: RelFilterUi
    sortBy?: string
    sortByEndCi?: boolean
    sortSource?: string
    sortType?: string
    usageTypeFilter?: UsageTypeFilterUi
}

export type ImportResult = Result & ImportResultAllOf

export type ErrorMessageUiExtendedItemType = (typeof ErrorMessageUiExtendedItemType)[keyof typeof ErrorMessageUiExtendedItemType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorMessageUiExtendedItemType = {
    RELATIONSHIP: 'RELATIONSHIP',
    CONFIGURATION_ITEM: 'CONFIGURATION_ITEM',
} as const

export type ErrorMessageUiExtendedErrorType = (typeof ErrorMessageUiExtendedErrorType)[keyof typeof ErrorMessageUiExtendedErrorType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorMessageUiExtendedErrorType = {
    OWNER: 'OWNER',
    OWNER_CREATE_ROLE: 'OWNER_CREATE_ROLE',
    CREATE_CI: 'CREATE_CI',
    UPDATE_CI: 'UPDATE_CI',
    CREATE_REL: 'CREATE_REL',
    UPDATE_REL: 'UPDATE_REL',
    UPDATE_INVALIDATE_CI: 'UPDATE_INVALIDATE_CI',
    CHANGE_ATTRIBUTE: 'CHANGE_ATTRIBUTE',
    LIMIT: 'LIMIT',
    ENUM: 'ENUM',
    EXPRESION: 'EXPRESION',
    ATTRIBUTE: 'ATTRIBUTE',
    MANDATORY: 'MANDATORY',
    REGEX: 'REGEX',
    NUMBER_FORMAT: 'NUMBER_FORMAT',
    MAX_STRING_SIZE: 'MAX_STRING_SIZE',
    INVALID_TYPE: 'INVALID_TYPE',
    GROUP_RIGHT: 'GROUP_RIGHT',
    VALIDATION_ERROR: 'VALIDATION_ERROR',
    PROCESSING_ERROR: 'PROCESSING_ERROR',
} as const

export interface ErrorDetailUi {
    description?: string
    enumConstrain?: string
    invalidType?: string
    itemTechnicalTypeName?: string
    maxConstrain?: number
    maxStringSize?: number
    minConstrain?: number
    notAllowedExpression?: string
    notAuthorizedGid?: string
    notAuthorizedRole?: string
    notExistAttribute?: string
    notFilledAttribute?: string
    notNumber?: string
    regex?: string
}

export interface ErrorMessageUiExtended {
    attributeName?: string
    errorDetail?: ErrorDetailUi
    errorType?: ErrorMessageUiExtendedErrorType
    itemCode?: string
    itemId?: string
    itemName?: string
    itemType?: ErrorMessageUiExtendedItemType
}

export interface CMDBItem {
    code?: string
    uuid?: string
}

export type ImportResultAllOf = {
    count?: number
    errorMessages?: ErrorMessageUiExtended[]
    items?: CMDBItem[]
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useUpdateContentHook = () => {
    const updateContent = useImpexpCmdbSwaggerClient<ImportResult>()

    return useCallback(
        (updateContentBody: UpdateContentBody, params?: UpdateContentParams) => {
            const formData = new FormData()
            formData.append('file', updateContentBody.file)

            return updateContent({ url: `/import`, method: 'POST', headers: { 'Content-Type': 'multipart/form-data' }, data: formData, params })
        },
        [updateContent],
    )
}

export const useUpdateContentMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>,
        TError,
        { data: UpdateContentBody; params?: UpdateContentParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>,
    TError,
    { data: UpdateContentBody; params?: UpdateContentParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateContent = useUpdateContentHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>,
        { data: UpdateContentBody; params?: UpdateContentParams }
    > = (props) => {
        const { data, params } = props ?? {}

        return updateContent(data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateContentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>>
export type UpdateContentMutationBody = UpdateContentBody
export type UpdateContentMutationError = ApiError

export const useUpdateContent = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>,
        TError,
        { data: UpdateContentBody; params?: UpdateContentParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>,
    TError,
    { data: UpdateContentBody; params?: UpdateContentParams },
    TContext
> => {
    const mutationOptions = useUpdateContentMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useValidateContentHook = () => {
    const validateContent = useImpexpCmdbSwaggerClient<ImportResult>()

    return useCallback(
        (validateContentBody: ValidateContentBody, params: ValidateContentParams) => {
            const formData = new FormData()
            formData.append('file', validateContentBody.file)

            return validateContent({
                url: `/import/validate`,
                method: 'POST',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData,
                params,
            })
        },
        [validateContent],
    )
}

export const useValidateContentMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useValidateContentHook>>>,
        TError,
        { data: ValidateContentBody; params: ValidateContentParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useValidateContentHook>>>,
    TError,
    { data: ValidateContentBody; params: ValidateContentParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const validateContent = useValidateContentHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useValidateContentHook>>>,
        { data: ValidateContentBody; params: ValidateContentParams }
    > = (props) => {
        const { data, params } = props ?? {}

        return validateContent(data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ValidateContentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useValidateContentHook>>>>
export type ValidateContentMutationBody = ValidateContentBody
export type ValidateContentMutationError = ApiError

export const useValidateContent = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useValidateContentHook>>>,
        TError,
        { data: ValidateContentBody; params: ValidateContentParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useValidateContentHook>>>,
    TError,
    { data: ValidateContentBody; params: ValidateContentParams },
    TContext
> => {
    const mutationOptions = useValidateContentMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteOldExportFilesHook = () => {
    const deleteOldExportFiles = useImpexpCmdbSwaggerClient<string[]>()

    return useCallback(() => {
        return deleteOldExportFiles({ url: `/file/deleteOldExportFiles`, method: 'POST' })
    }, [deleteOldExportFiles])
}

export const useDeleteOldExportFilesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOldExportFilesHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOldExportFilesHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteOldExportFiles = useDeleteOldExportFilesHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteOldExportFilesHook>>>, void> = () => {
        return deleteOldExportFiles()
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteOldExportFilesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteOldExportFilesHook>>>>

export type DeleteOldExportFilesMutationError = ApiError

export const useDeleteOldExportFiles = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOldExportFilesHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteOldExportFilesHook>>>, TError, void, TContext> => {
    const mutationOptions = useDeleteOldExportFilesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const use_ExportHook = () => {
    const _export = useImpexpCmdbSwaggerClient<ExportResult>()

    return useCallback(
        (fileType: 'EXCEL' | 'CSV' | 'XML', ciListFilterContainerUi: CiListFilterContainerUi) => {
            return _export({
                url: `/export/${fileType}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: ciListFilterContainerUi,
            })
        },
        [_export],
    )
}

export const useExportMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const _export = use_ExportHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi }
    > = (props) => {
        const { fileType, data } = props ?? {}

        return _export(fileType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type _ExportMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>>
export type _ExportMutationBody = CiListFilterContainerUi
export type _ExportMutationError = ApiError

export const useExport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
    TContext
> => {
    const mutationOptions = useExportMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport1Hook = () => {
    const export1 = useImpexpCmdbSwaggerClient<ExportResult>()

    return useCallback(
        (fileType: 'EXCEL' | 'CSV' | 'XML', exportVotesRequest: ExportVotesRequest) => {
            return export1({
                url: `/export/votes/${fileType}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: exportVotesRequest,
            })
        },
        [export1],
    )
}

export const useExport1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport1Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportVotesRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport1Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportVotesRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export1 = useExport1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport1Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportVotesRequest }
    > = (props) => {
        const { fileType, data } = props ?? {}

        return export1(fileType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport1Hook>>>>
export type Export1MutationBody = ExportVotesRequest
export type Export1MutationError = ApiError

export const useExport1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport1Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportVotesRequest },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useExport1Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportVotesRequest },
    TContext
> => {
    const mutationOptions = useExport1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport2Hook = () => {
    const export2 = useImpexpCmdbSwaggerClient<ExportResult>()

    return useCallback(
        (fileType: 'EXCEL' | 'CSV' | 'XML', params: Export2Params) => {
            return export2({ url: `/export/trainee/${fileType}`, method: 'POST', params })
        },
        [export2],
    )
}

export const useExport2MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport2Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export2Params },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport2Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export2Params },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export2 = useExport2Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport2Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export2Params }
    > = (props) => {
        const { fileType, params } = props ?? {}

        return export2(fileType, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport2Hook>>>>

export type Export2MutationError = ApiError

export const useExport2 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport2Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export2Params },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useExport2Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export2Params },
    TContext
> => {
    const mutationOptions = useExport2MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport3Hook = () => {
    const export3 = useImpexpCmdbSwaggerClient<ExportResult>()

    return useCallback(
        (fileType: 'EXCEL' | 'CSV' | 'XML', reportId: number, reportExecute: ReportExecute) => {
            return export3({
                url: `/export/report/${fileType}/${reportId}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: reportExecute,
            })
        },
        [export3],
    )
}

export const useExport3MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport3Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; reportId: number; data: ReportExecute },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport3Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; reportId: number; data: ReportExecute },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export3 = useExport3Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport3Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; reportId: number; data: ReportExecute }
    > = (props) => {
        const { fileType, reportId, data } = props ?? {}

        return export3(fileType, reportId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export3MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport3Hook>>>>
export type Export3MutationBody = ReportExecute
export type Export3MutationError = ApiError

export const useExport3 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport3Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; reportId: number; data: ReportExecute },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useExport3Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; reportId: number; data: ReportExecute },
    TContext
> => {
    const mutationOptions = useExport3MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExportByLookupKeyHook = () => {
    const exportByLookupKey = useImpexpCmdbSwaggerClient<ExportResult>()

    return useCallback(
        (fileType: 'EXCEL' | 'CSV' | 'XML', lookupKey: string, reportExecute: ReportExecute) => {
            return exportByLookupKey({
                url: `/export/report/${fileType}/lookup/${lookupKey}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: reportExecute,
            })
        },
        [exportByLookupKey],
    )
}

export const useExportByLookupKeyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportByLookupKeyHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; lookupKey: string; data: ReportExecute },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportByLookupKeyHook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; lookupKey: string; data: ReportExecute },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const exportByLookupKey = useExportByLookupKeyHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportByLookupKeyHook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; lookupKey: string; data: ReportExecute }
    > = (props) => {
        const { fileType, lookupKey, data } = props ?? {}

        return exportByLookupKey(fileType, lookupKey, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ExportByLookupKeyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExportByLookupKeyHook>>>>
export type ExportByLookupKeyMutationBody = ReportExecute
export type ExportByLookupKeyMutationError = ApiError

export const useExportByLookupKey = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportByLookupKeyHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; lookupKey: string; data: ReportExecute },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useExportByLookupKeyHook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; lookupKey: string; data: ReportExecute },
    TContext
> => {
    const mutationOptions = useExportByLookupKeyMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExportRelationsHook = () => {
    const exportRelations = useImpexpCmdbSwaggerClient<ExportResult>()

    return useCallback(
        (fileType: 'EXCEL' | 'CSV' | 'XML', relListFilterContainerUi: RelListFilterContainerUi) => {
            return exportRelations({
                url: `/export/relations-export/${fileType}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: relListFilterContainerUi,
            })
        },
        [exportRelations],
    )
}

export const useExportRelationsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportRelationsHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: RelListFilterContainerUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportRelationsHook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: RelListFilterContainerUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const exportRelations = useExportRelationsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportRelationsHook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: RelListFilterContainerUi }
    > = (props) => {
        const { fileType, data } = props ?? {}

        return exportRelations(fileType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ExportRelationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExportRelationsHook>>>>
export type ExportRelationsMutationBody = RelListFilterContainerUi
export type ExportRelationsMutationError = ApiError

export const useExportRelations = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportRelationsHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: RelListFilterContainerUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useExportRelationsHook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: RelListFilterContainerUi },
    TContext
> => {
    const mutationOptions = useExportRelationsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExportRelHook = () => {
    const exportRel = useImpexpCmdbSwaggerClient<ExportResult>()

    return useCallback(
        (fileType: 'EXCEL' | 'CSV' | 'XML', ciListFilterContainerUi: CiListFilterContainerUi) => {
            return exportRel({
                url: `/export/rel/${fileType}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: ciListFilterContainerUi,
            })
        },
        [exportRel],
    )
}

export const useExportRelMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportRelHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportRelHook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const exportRel = useExportRelHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportRelHook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi }
    > = (props) => {
        const { fileType, data } = props ?? {}

        return exportRel(fileType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ExportRelMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExportRelHook>>>>
export type ExportRelMutationBody = CiListFilterContainerUi
export type ExportRelMutationError = ApiError

export const useExportRel = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportRelHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useExportRelHook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
    TContext
> => {
    const mutationOptions = useExportRelMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport4Hook = () => {
    const export4 = useImpexpCmdbSwaggerClient<ExportResult>()

    return useCallback(
        (fileType: 'EXCEL' | 'CSV' | 'XML', params: Export4Params) => {
            return export4({ url: `/export/monitoring/${fileType}`, method: 'POST', params })
        },
        [export4],
    )
}

export const useExport4MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport4Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export4Params },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport4Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export4Params },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export4 = useExport4Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport4Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export4Params }
    > = (props) => {
        const { fileType, params } = props ?? {}

        return export4(fileType, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export4MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport4Hook>>>>

export type Export4MutationError = ApiError

export const useExport4 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport4Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export4Params },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useExport4Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export4Params },
    TContext
> => {
    const mutationOptions = useExport4MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport5Hook = () => {
    const export5 = useImpexpCmdbSwaggerClient<ExportResult>()

    return useCallback(
        (fileType: 'EXCEL' | 'CSV' | 'XML', exportMeetingRequest: ExportMeetingRequest) => {
            return export5({
                url: `/export/meeting/${fileType}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: exportMeetingRequest,
            })
        },
        [export5],
    )
}

export const useExport5MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport5Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportMeetingRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport5Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportMeetingRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export5 = useExport5Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport5Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportMeetingRequest }
    > = (props) => {
        const { fileType, data } = props ?? {}

        return export5(fileType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export5MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport5Hook>>>>
export type Export5MutationBody = ExportMeetingRequest
export type Export5MutationError = ApiError

export const useExport5 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport5Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportMeetingRequest },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useExport5Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportMeetingRequest },
    TContext
> => {
    const mutationOptions = useExport5MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport6Hook = () => {
    const export6 = useImpexpCmdbSwaggerClient<ExportResult>()

    return useCallback(
        (fileType: 'EXCEL' | 'CSV' | 'XML', exportFilter: ExportFilter, params: Export6Params) => {
            return export6({
                url: `/export/kris-to-be/${fileType}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: exportFilter,
                params,
            })
        },
        [export6],
    )
}

export const useExport6MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport6Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportFilter; params: Export6Params },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport6Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportFilter; params: Export6Params },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export6 = useExport6Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport6Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportFilter; params: Export6Params }
    > = (props) => {
        const { fileType, data, params } = props ?? {}

        return export6(fileType, data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export6MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport6Hook>>>>
export type Export6MutationBody = ExportFilter
export type Export6MutationError = ApiError

export const useExport6 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport6Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportFilter; params: Export6Params },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useExport6Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportFilter; params: Export6Params },
    TContext
> => {
    const mutationOptions = useExport6MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport7Hook = () => {
    const export7 = useImpexpCmdbSwaggerClient<ExportResult>()

    return useCallback(
        (fileType: 'EXCEL' | 'CSV' | 'XML', exportGroupsRequest: ExportGroupsRequest) => {
            return export7({
                url: `/export/groups/${fileType}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: exportGroupsRequest,
            })
        },
        [export7],
    )
}

export const useExport7MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport7Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportGroupsRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport7Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportGroupsRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export7 = useExport7Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport7Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportGroupsRequest }
    > = (props) => {
        const { fileType, data } = props ?? {}

        return export7(fileType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export7MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport7Hook>>>>
export type Export7MutationBody = ExportGroupsRequest
export type Export7MutationError = ApiError

export const useExport7 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport7Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportGroupsRequest },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useExport7Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportGroupsRequest },
    TContext
> => {
    const mutationOptions = useExport7MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetProgessHook = () => {
    const getProgess = useImpexpCmdbSwaggerClient<GetProgess200>()

    return useCallback(
        (taskType: 'IMPORT' | 'EXPORT', requestId: string, signal?: AbortSignal) => {
            return getProgess({ url: `/progress/${taskType}/${requestId}`, method: 'GET', signal })
        },
        [getProgess],
    )
}

export const getGetProgessQueryKey = (taskType: 'IMPORT' | 'EXPORT', requestId: string) => {
    return [`/progress/${taskType}/${requestId}`] as const
}

export const useGetProgessQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>, TError = ApiError>(
    taskType: 'IMPORT' | 'EXPORT',
    requestId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetProgessQueryKey(taskType, requestId)

    const getProgess = useGetProgessHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>> = ({ signal }) => getProgess(taskType, requestId, signal)

    return { queryKey, queryFn, enabled: !!(taskType && requestId), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetProgessQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>>
export type GetProgessQueryError = ApiError

export function useGetProgess<TData = Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>, TError = ApiError>(
    taskType: 'IMPORT' | 'EXPORT',
    requestId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetProgessQueryOptions(taskType, requestId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useLoadFileHook = () => {
    const loadFile = useImpexpCmdbSwaggerClient<Blob>()

    return useCallback(
        (fileName: string, signal?: AbortSignal) => {
            return loadFile({ url: `/file/load/${fileName}`, method: 'GET', responseType: 'blob', signal })
        },
        [loadFile],
    )
}

export const getLoadFileQueryKey = (fileName: string) => {
    return [`/file/load/${fileName}`] as const
}

export const useLoadFileQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>, TError = ApiError>(
    fileName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getLoadFileQueryKey(fileName)

    const loadFile = useLoadFileHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>> = ({ signal }) => loadFile(fileName, signal)

    return { queryKey, queryFn, enabled: !!fileName, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type LoadFileQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>>
export type LoadFileQueryError = ApiError

export function useLoadFile<TData = Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>, TError = ApiError>(
    fileName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useLoadFileQueryOptions(fileName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
