//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Wiki Integration
 * OpenAPI spec version: latest
 */
import { useQuery } from '@tanstack/react-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useWikiSwaggerClient } from '../hooks/useWikiSwaggerClient'
export type UploadProjectDocument1Params = {
    documentUuid?: string
}

export type GetDocumentParams = {
    createIfNotExists?: boolean
    rerenderDynamicContent?: boolean
}

export type UploadProjectDocumentParams = {
    documentUuid?: string
}

export type GetProjectDocumentParams = {
    createIfNotExists?: boolean
    rerenderDynamicContent?: boolean
}

export interface WikiDocument {
    created?: string
    createdBy?: string
    majorVersion?: number
    minorVersion?: number
    modified?: string
    modifiedBy?: string
    name?: string
    type?: string
    wiki?: string
    xwikiPath?: string
    xwikiUrl?: string
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useGetProjectDocumentHook = () => {
    const getProjectDocument = useWikiSwaggerClient<WikiDocument>()

    return useCallback(
        (projectUuid: string, documentId: number, params?: GetProjectDocumentParams, signal?: AbortSignal) => {
            return getProjectDocument({ url: `/project/${projectUuid}/document/${documentId}`, method: 'GET', params, signal })
        },
        [getProjectDocument],
    )
}

export const getGetProjectDocumentQueryKey = (projectUuid: string, documentId: number, params?: GetProjectDocumentParams) => {
    return [`/project/${projectUuid}/document/${documentId}`, ...(params ? [params] : [])] as const
}

export const useGetProjectDocumentQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>, TError = ApiError>(
    projectUuid: string,
    documentId: number,
    params?: GetProjectDocumentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetProjectDocumentQueryKey(projectUuid, documentId, params)

    const getProjectDocument = useGetProjectDocumentHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>> = ({ signal }) =>
        getProjectDocument(projectUuid, documentId, params, signal)

    return { queryKey, queryFn, enabled: !!(projectUuid && documentId), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetProjectDocumentQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>>
export type GetProjectDocumentQueryError = ApiError

export function useGetProjectDocument<TData = Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>, TError = ApiError>(
    projectUuid: string,
    documentId: number,
    params?: GetProjectDocumentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetProjectDocumentQueryOptions(projectUuid, documentId, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUploadProjectDocumentHook = () => {
    const uploadProjectDocument = useWikiSwaggerClient<string>()

    return useCallback(
        (projectUuid: string, documentId: number, params?: UploadProjectDocumentParams, signal?: AbortSignal) => {
            return uploadProjectDocument({ url: `/project/${projectUuid}/document/${documentId}/upload`, method: 'GET', params, signal })
        },
        [uploadProjectDocument],
    )
}

export const getUploadProjectDocumentQueryKey = (projectUuid: string, documentId: number, params?: UploadProjectDocumentParams) => {
    return [`/project/${projectUuid}/document/${documentId}/upload`, ...(params ? [params] : [])] as const
}

export const useUploadProjectDocumentQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>, TError = ApiError>(
    projectUuid: string,
    documentId: number,
    params?: UploadProjectDocumentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getUploadProjectDocumentQueryKey(projectUuid, documentId, params)

    const uploadProjectDocument = useUploadProjectDocumentHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>> = ({ signal }) =>
        uploadProjectDocument(projectUuid, documentId, params, signal)

    return { queryKey, queryFn, enabled: !!(projectUuid && documentId), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type UploadProjectDocumentQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>>
export type UploadProjectDocumentQueryError = ApiError

export function useUploadProjectDocument<TData = Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>, TError = ApiError>(
    projectUuid: string,
    documentId: number,
    params?: UploadProjectDocumentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useUploadProjectDocumentQueryOptions(projectUuid, documentId, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetDocumentHook = () => {
    const getDocument = useWikiSwaggerClient<WikiDocument>()

    return useCallback(
        (entityType: string, entityId: string, documentType: string, params?: GetDocumentParams, signal?: AbortSignal) => {
            return getDocument({ url: `/document/${entityType}/${entityId}/document/${documentType}`, method: 'GET', params, signal })
        },
        [getDocument],
    )
}

export const getGetDocumentQueryKey = (entityType: string, entityId: string, documentType: string, params?: GetDocumentParams) => {
    return [`/document/${entityType}/${entityId}/document/${documentType}`, ...(params ? [params] : [])] as const
}

export const useGetDocumentQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>, TError = ApiError>(
    entityType: string,
    entityId: string,
    documentType: string,
    params?: GetDocumentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetDocumentQueryKey(entityType, entityId, documentType, params)

    const getDocument = useGetDocumentHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>> = ({ signal }) =>
        getDocument(entityType, entityId, documentType, params, signal)

    return { queryKey, queryFn, enabled: !!(entityType && entityId && documentType), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetDocumentQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>>
export type GetDocumentQueryError = ApiError

export function useGetDocument<TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>, TError = ApiError>(
    entityType: string,
    entityId: string,
    documentType: string,
    params?: GetDocumentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetDocumentQueryOptions(entityType, entityId, documentType, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUploadProjectDocument1Hook = () => {
    const uploadProjectDocument1 = useWikiSwaggerClient<string>()

    return useCallback(
        (entityType: string, entityId: string, documentType: string, params?: UploadProjectDocument1Params, signal?: AbortSignal) => {
            return uploadProjectDocument1({
                url: `/document/${entityType}/${entityId}/document/${documentType}/upload`,
                method: 'GET',
                params,
                signal,
            })
        },
        [uploadProjectDocument1],
    )
}

export const getUploadProjectDocument1QueryKey = (
    entityType: string,
    entityId: string,
    documentType: string,
    params?: UploadProjectDocument1Params,
) => {
    return [`/document/${entityType}/${entityId}/document/${documentType}/upload`, ...(params ? [params] : [])] as const
}

export const useUploadProjectDocument1QueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>,
    TError = ApiError,
>(
    entityType: string,
    entityId: string,
    documentType: string,
    params?: UploadProjectDocument1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getUploadProjectDocument1QueryKey(entityType, entityId, documentType, params)

    const uploadProjectDocument1 = useUploadProjectDocument1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>> = ({ signal }) =>
        uploadProjectDocument1(entityType, entityId, documentType, params, signal)

    return { queryKey, queryFn, enabled: !!(entityType && entityId && documentType), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type UploadProjectDocument1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>>
export type UploadProjectDocument1QueryError = ApiError

export function useUploadProjectDocument1<TData = Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>, TError = ApiError>(
    entityType: string,
    entityId: string,
    documentType: string,
    params?: UploadProjectDocument1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useUploadProjectDocument1QueryOptions(entityType, entityId, documentType, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
