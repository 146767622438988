//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * MetaIS DMS
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { useDmsSwaggerClient } from '../hooks/useDmsSwaggerClient'
export type GetMeta1Params = {
    version?: string
}

export type GetMeta200 = { [key: string]: Metadata }

export type CreateContentBody = {
    file: Blob
    refAttributes: CreateContentBodyRefAttributes
    'x-content-uuid': string
}

export type UpdateContentBody = {
    file: Blob
    refAttributes: UpdateContentBodyRefAttributes
}

export type UpdateContent1Body = {
    file: Blob
    refAttributes: UpdateContent1BodyRefAttributes
}

export type GetContentParams = {
    version?: string
}

export type UpdateBatchRefAttributesBody = {
    [key: string]:
        | CiRefAttributes
        | DocumentTemplateRefAttributes
        | MeetingRequestRefAttributes
        | StandardRequestRefAttributes
        | UnknownRefAttributes
        | VoteRefAttributes
}

export type UpdateBatchRefAttributes1Body = {
    [key: string]:
        | CiRefAttributes
        | DocumentTemplateRefAttributes
        | MeetingRequestRefAttributes
        | StandardRequestRefAttributes
        | UnknownRefAttributes
        | VoteRefAttributes
}

export type UpdateRefAttributesBody =
    | CiRefAttributes
    | DocumentTemplateRefAttributes
    | MeetingRequestRefAttributes
    | StandardRequestRefAttributes
    | UnknownRefAttributes
    | VoteRefAttributes

export type UpdateRefAttributes1Body =
    | CiRefAttributes
    | DocumentTemplateRefAttributes
    | MeetingRequestRefAttributes
    | StandardRequestRefAttributes
    | UnknownRefAttributes
    | VoteRefAttributes

export interface MetaVersion {
    contentLength?: number
    encoding?: string
    filename?: string
    lastModified?: string
    lastModifiedBy?: string
    mimeType?: string
    version?: string
}

export interface History {
    created?: string
    createdBy?: string
    uuid?: string
    versions?: MetaVersion[]
}

export interface FileSetUi {
    fileItemSet?: string[]
}

export type ReindexTaskStatus = (typeof ReindexTaskStatus)[keyof typeof ReindexTaskStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReindexTaskStatus = {
    CREATED: 'CREATED',
    IN_PROGRESS: 'IN_PROGRESS',
    FINISHED: 'FINISHED',
    CANCELLED: 'CANCELLED',
} as const

export interface ReindexTask {
    failed?: boolean
    failedCount?: number
    finishedAt?: string
    id?: string
    lastModifiedFrom?: string
    startAt?: string
    status?: ReindexTaskStatus
    successCount?: number
    totalCount?: number
}

export type DocumentInfoState = (typeof DocumentInfoState)[keyof typeof DocumentInfoState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentInfoState = {
    ERROR: 'ERROR',
    INCONSISTENT: 'INCONSISTENT',
    NOT_FOUND: 'NOT_FOUND',
    SOFT_DELETED: 'SOFT_DELETED',
    STATE_MISSING: 'STATE_MISSING',
    UPDATED: 'UPDATED',
} as const

export interface DocumentInfo {
    errorMessage?: string
    id?: string
    state?: DocumentInfoState
}

export type MetadataStatus = (typeof MetadataStatus)[keyof typeof MetadataStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetadataStatus = {
    CREATED: 'CREATED',
    UPDATED: 'UPDATED',
    REMOVED: 'REMOVED',
} as const

export type VoteRefAttributesAllOf = {
    /** The ID of vote to which the DMS document is bound. */
    refVoteId: number
}

/**
 * Class eligible for Votes parent (in STANDARDS module).
 */
export type VoteRefAttributes = RefAttributes & VoteRefAttributesAllOf

/**
 * This class is be just as an return value of old DMS documents for which we were not able to find parent object (and so fill their Metadata.RefAttributes). It can not be used for creation of new DMS documents or updating of old DMS documents. If you are updating an old document then you know who the parent is, so fill RefAttributes object with proper data.
 */
export type UnknownRefAttributes = RefAttributes

export type CreateContentBodyRefAttributes =
    | CiRefAttributes
    | DocumentTemplateRefAttributes
    | MeetingRequestRefAttributes
    | StandardRequestRefAttributes
    | UnknownRefAttributes
    | VoteRefAttributes

export type MetadataRefAttributes =
    | CiRefAttributes
    | DocumentTemplateRefAttributes
    | MeetingRequestRefAttributes
    | StandardRequestRefAttributes
    | UnknownRefAttributes
    | VoteRefAttributes

export interface Metadata {
    contentLength?: number
    created?: string
    createdBy?: string
    encoding?: string
    extension?: string
    filename?: string
    lastModified?: string
    lastModifiedBy?: string
    mimeType?: string
    refAttributes?: MetadataRefAttributes
    status?: MetadataStatus
    uuid?: string
    version?: string
}

export type StandardRequestRefAttributesAllOf = {
    /** The ID of standard request to which the DMS document is bound. */
    refStandardRequestId: number
}

export type RefAttributesRefType = (typeof RefAttributesRefType)[keyof typeof RefAttributesRefType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefAttributesRefType = {
    CI: 'CI',
    STANDARD_REQUEST: 'STANDARD_REQUEST',
    MEETING_REQUEST: 'MEETING_REQUEST',
    VOTE: 'VOTE',
    DOCUMENT_TEMPLATE: 'DOCUMENT_TEMPLATE',
    UNKNOWN: 'UNKNOWN',
} as const

export interface RefAttributes {
    refType?: RefAttributesRefType
}

/**
 * Class eligible for Standard parent (in STANDARDS module).
 */
export type StandardRequestRefAttributes = RefAttributes & StandardRequestRefAttributesAllOf

export type MeetingRequestRefAttributesAllOf = {
    /** The ID of meeting request to which the DMS document is bound. */
    refMeetingRequestId: number
}

/**
 * Class eligible for Meeting_requests parent (in STANDARDS module).
 */
export type MeetingRequestRefAttributes = RefAttributes & MeetingRequestRefAttributesAllOf

export type DocumentTemplateRefAttributesAllOf = {
    /** The ID of document to which the DMS document is bound. */
    refDocumentTemplateId: number
}

/**
 * Class eligible for Document parent (in KRIS module).
 */
export type DocumentTemplateRefAttributes = RefAttributes & DocumentTemplateRefAttributesAllOf

export type CiRefAttributesAllOf = {
    /** The ID of configuration item (CI) to which the DMS document is bound. */
    refCiId: string
    /** The metais code of configuration item (CI) to which the DMS document is bound. */
    refCiMetaisCode?: string
    /** The GID of owner of configuration item (CI) to which the DMS document is bound. */
    refCiOwner: string
    /** The technical name of configuration item (CI) to which the DMS document is bound. */
    refCiTechnicalName: string
}

/**
 * Class eligible for ConfiguratioItem parent.
 */
export type CiRefAttributes = RefAttributes & CiRefAttributesAllOf

export type UpdateContentBodyRefAttributes =
    | CiRefAttributes
    | DocumentTemplateRefAttributes
    | MeetingRequestRefAttributes
    | StandardRequestRefAttributes
    | UnknownRefAttributes
    | VoteRefAttributes

export type UpdateContent1BodyRefAttributes =
    | CiRefAttributes
    | DocumentTemplateRefAttributes
    | MeetingRequestRefAttributes
    | StandardRequestRefAttributes
    | UnknownRefAttributes
    | VoteRefAttributes

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useUpdateRefAttributes1Hook = () => {
    const updateRefAttributes1 = useDmsSwaggerClient<Metadata>()

    return useCallback(
        (uuid: string, updateRefAttributes1Body: UpdateRefAttributes1Body) => {
            return updateRefAttributes1({
                url: `/file/refAttributes/${uuid}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: updateRefAttributes1Body,
            })
        },
        [updateRefAttributes1],
    )
}

export const useUpdateRefAttributes1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateRefAttributes1Hook>>>,
        TError,
        { uuid: string; data: UpdateRefAttributes1Body },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateRefAttributes1Hook>>>,
    TError,
    { uuid: string; data: UpdateRefAttributes1Body },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateRefAttributes1 = useUpdateRefAttributes1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateRefAttributes1Hook>>>,
        { uuid: string; data: UpdateRefAttributes1Body }
    > = (props) => {
        const { uuid, data } = props ?? {}

        return updateRefAttributes1(uuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateRefAttributes1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateRefAttributes1Hook>>>>
export type UpdateRefAttributes1MutationBody = UpdateRefAttributes1Body
export type UpdateRefAttributes1MutationError = ApiError

export const useUpdateRefAttributes1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateRefAttributes1Hook>>>,
        TError,
        { uuid: string; data: UpdateRefAttributes1Body },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateRefAttributes1Hook>>>,
    TError,
    { uuid: string; data: UpdateRefAttributes1Body },
    TContext
> => {
    const mutationOptions = useUpdateRefAttributes1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateRefAttributesHook = () => {
    const updateRefAttributes = useDmsSwaggerClient<Metadata>()

    return useCallback(
        (uuid: string, updateRefAttributesBody: UpdateRefAttributesBody) => {
            return updateRefAttributes({
                url: `/file/refAttributes/${uuid}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: updateRefAttributesBody,
            })
        },
        [updateRefAttributes],
    )
}

export const useUpdateRefAttributesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateRefAttributesHook>>>,
        TError,
        { uuid: string; data: UpdateRefAttributesBody },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateRefAttributesHook>>>,
    TError,
    { uuid: string; data: UpdateRefAttributesBody },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateRefAttributes = useUpdateRefAttributesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateRefAttributesHook>>>,
        { uuid: string; data: UpdateRefAttributesBody }
    > = (props) => {
        const { uuid, data } = props ?? {}

        return updateRefAttributes(uuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateRefAttributesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateRefAttributesHook>>>>
export type UpdateRefAttributesMutationBody = UpdateRefAttributesBody
export type UpdateRefAttributesMutationError = ApiError

export const useUpdateRefAttributes = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateRefAttributesHook>>>,
        TError,
        { uuid: string; data: UpdateRefAttributesBody },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateRefAttributesHook>>>,
    TError,
    { uuid: string; data: UpdateRefAttributesBody },
    TContext
> => {
    const mutationOptions = useUpdateRefAttributesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateBatchRefAttributes1Hook = () => {
    const updateBatchRefAttributes1 = useDmsSwaggerClient<DocumentInfo[]>()

    return useCallback(
        (updateBatchRefAttributes1Body: UpdateBatchRefAttributes1Body) => {
            return updateBatchRefAttributes1({
                url: `/file/refAttributes/batch`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: updateBatchRefAttributes1Body,
            })
        },
        [updateBatchRefAttributes1],
    )
}

export const useUpdateBatchRefAttributes1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateBatchRefAttributes1Hook>>>,
        TError,
        { data: UpdateBatchRefAttributes1Body },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateBatchRefAttributes1Hook>>>,
    TError,
    { data: UpdateBatchRefAttributes1Body },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateBatchRefAttributes1 = useUpdateBatchRefAttributes1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateBatchRefAttributes1Hook>>>,
        { data: UpdateBatchRefAttributes1Body }
    > = (props) => {
        const { data } = props ?? {}

        return updateBatchRefAttributes1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateBatchRefAttributes1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateBatchRefAttributes1Hook>>>>
export type UpdateBatchRefAttributes1MutationBody = UpdateBatchRefAttributes1Body
export type UpdateBatchRefAttributes1MutationError = ApiError

export const useUpdateBatchRefAttributes1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateBatchRefAttributes1Hook>>>,
        TError,
        { data: UpdateBatchRefAttributes1Body },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateBatchRefAttributes1Hook>>>,
    TError,
    { data: UpdateBatchRefAttributes1Body },
    TContext
> => {
    const mutationOptions = useUpdateBatchRefAttributes1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateBatchRefAttributesHook = () => {
    const updateBatchRefAttributes = useDmsSwaggerClient<DocumentInfo[]>()

    return useCallback(
        (updateBatchRefAttributesBody: UpdateBatchRefAttributesBody) => {
            return updateBatchRefAttributes({
                url: `/file/refAttributes/batch`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: updateBatchRefAttributesBody,
            })
        },
        [updateBatchRefAttributes],
    )
}

export const useUpdateBatchRefAttributesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateBatchRefAttributesHook>>>,
        TError,
        { data: UpdateBatchRefAttributesBody },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateBatchRefAttributesHook>>>,
    TError,
    { data: UpdateBatchRefAttributesBody },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateBatchRefAttributes = useUpdateBatchRefAttributesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateBatchRefAttributesHook>>>,
        { data: UpdateBatchRefAttributesBody }
    > = (props) => {
        const { data } = props ?? {}

        return updateBatchRefAttributes(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateBatchRefAttributesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateBatchRefAttributesHook>>>>
export type UpdateBatchRefAttributesMutationBody = UpdateBatchRefAttributesBody
export type UpdateBatchRefAttributesMutationError = ApiError

export const useUpdateBatchRefAttributes = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateBatchRefAttributesHook>>>,
        TError,
        { data: UpdateBatchRefAttributesBody },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateBatchRefAttributesHook>>>,
    TError,
    { data: UpdateBatchRefAttributesBody },
    TContext
> => {
    const mutationOptions = useUpdateBatchRefAttributesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetContentHook = () => {
    const getContent = useDmsSwaggerClient<Blob>()

    return useCallback(
        (uuid: string, params?: GetContentParams, signal?: AbortSignal) => {
            return getContent({ url: `/file/${uuid}`, method: 'GET', params, responseType: 'blob', signal })
        },
        [getContent],
    )
}

export const getGetContentQueryKey = (uuid: string, params?: GetContentParams) => {
    return [`/file/${uuid}`, ...(params ? [params] : [])] as const
}

export const useGetContentQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetContentHook>>>, TError = ApiError>(
    uuid: string,
    params?: GetContentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContentHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetContentQueryKey(uuid, params)

    const getContent = useGetContentHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetContentHook>>>> = ({ signal }) => getContent(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetContentHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetContentQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetContentHook>>>>
export type GetContentQueryError = ApiError

export function useGetContent<TData = Awaited<ReturnType<ReturnType<typeof useGetContentHook>>>, TError = ApiError>(
    uuid: string,
    params?: GetContentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContentHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetContentQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateContent1Hook = () => {
    const updateContent1 = useDmsSwaggerClient<Metadata>()

    return useCallback(
        (uuid: string, updateContent1Body: UpdateContent1Body) => {
            const formData = new FormData()
            formData.append('file', updateContent1Body.file)
            formData.append('refAttributes', updateContent1Body.refAttributes)

            return updateContent1({ url: `/file/${uuid}`, method: 'PUT', headers: { 'Content-Type': 'multipart/form-data' }, data: formData })
        },
        [updateContent1],
    )
}

export const useUpdateContent1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateContent1Hook>>>,
        TError,
        { uuid: string; data: UpdateContent1Body },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateContent1Hook>>>,
    TError,
    { uuid: string; data: UpdateContent1Body },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateContent1 = useUpdateContent1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateContent1Hook>>>, { uuid: string; data: UpdateContent1Body }> = (
        props,
    ) => {
        const { uuid, data } = props ?? {}

        return updateContent1(uuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateContent1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateContent1Hook>>>>
export type UpdateContent1MutationBody = UpdateContent1Body
export type UpdateContent1MutationError = ApiError

export const useUpdateContent1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateContent1Hook>>>,
        TError,
        { uuid: string; data: UpdateContent1Body },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateContent1Hook>>>,
    TError,
    { uuid: string; data: UpdateContent1Body },
    TContext
> => {
    const mutationOptions = useUpdateContent1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateContentHook = () => {
    const updateContent = useDmsSwaggerClient<Metadata>()

    return useCallback(
        (uuid: string, updateContentBody: UpdateContentBody) => {
            const formData = new FormData()
            formData.append('file', updateContentBody.file)
            formData.append('refAttributes', updateContentBody.refAttributes)

            return updateContent({ url: `/file/${uuid}`, method: 'POST', headers: { 'Content-Type': 'multipart/form-data' }, data: formData })
        },
        [updateContent],
    )
}

export const useUpdateContentMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>,
        TError,
        { uuid: string; data: UpdateContentBody },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>, TError, { uuid: string; data: UpdateContentBody }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateContent = useUpdateContentHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>, { uuid: string; data: UpdateContentBody }> = (
        props,
    ) => {
        const { uuid, data } = props ?? {}

        return updateContent(uuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateContentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>>
export type UpdateContentMutationBody = UpdateContentBody
export type UpdateContentMutationError = ApiError

export const useUpdateContent = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>,
        TError,
        { uuid: string; data: UpdateContentBody },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>, TError, { uuid: string; data: UpdateContentBody }, TContext> => {
    const mutationOptions = useUpdateContentMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteContentHook = () => {
    const deleteContent = useDmsSwaggerClient<void>()

    return useCallback(
        (uuid: string) => {
            return deleteContent({ url: `/file/${uuid}`, method: 'DELETE' })
        },
        [deleteContent],
    )
}

export const useDeleteContentMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteContentHook>>>, TError, { uuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteContentHook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteContent = useDeleteContentHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteContentHook>>>, { uuid: string }> = (props) => {
        const { uuid } = props ?? {}

        return deleteContent(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteContentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteContentHook>>>>

export type DeleteContentMutationError = ApiError

export const useDeleteContent = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteContentHook>>>, TError, { uuid: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteContentHook>>>, TError, { uuid: string }, TContext> => {
    const mutationOptions = useDeleteContentMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCreateContentHook = () => {
    const createContent = useDmsSwaggerClient<Metadata>()

    return useCallback(
        (createContentBody: CreateContentBody) => {
            const formData = new FormData()
            formData.append('file', createContentBody.file)
            formData.append('x-content-uuid', createContentBody['x-content-uuid'])
            formData.append('refAttributes', createContentBody.refAttributes)

            return createContent({ url: `/file`, method: 'POST', headers: { 'Content-Type': 'multipart/form-data' }, data: formData })
        },
        [createContent],
    )
}

export const useCreateContentMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateContentHook>>>, TError, { data: CreateContentBody }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateContentHook>>>, TError, { data: CreateContentBody }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const createContent = useCreateContentHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateContentHook>>>, { data: CreateContentBody }> = (props) => {
        const { data } = props ?? {}

        return createContent(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateContentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateContentHook>>>>
export type CreateContentMutationBody = CreateContentBody
export type CreateContentMutationError = ApiError

export const useCreateContent = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateContentHook>>>, TError, { data: CreateContentBody }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCreateContentHook>>>, TError, { data: CreateContentBody }, TContext> => {
    const mutationOptions = useCreateContentMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetMetaHook = () => {
    const getMeta = useDmsSwaggerClient<GetMeta200>()

    return useCallback(
        (getMetaBody: string[]) => {
            return getMeta({ url: `/file/meta/uuids`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: getMetaBody })
        },
        [getMeta],
    )
}

export const getGetMetaQueryKey = (getMetaBody: string[]) => {
    return [`/file/meta/uuids`, getMetaBody] as const
}

export const useGetMetaQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetMetaHook>>>, TError = ApiError>(
    getMetaBody: string[],
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMetaHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetMetaQueryKey(getMetaBody)

    const getMeta = useGetMetaHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMetaHook>>>> = ({ signal }) => getMeta(getMetaBody, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMetaHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type GetMetaQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetMetaHook>>>>
export type GetMetaQueryError = ApiError

export function useGetMeta<TData = Awaited<ReturnType<ReturnType<typeof useGetMetaHook>>>, TError = ApiError>(
    getMetaBody: string[],
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMetaHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetMetaQueryOptions(getMetaBody, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useDeleteDocumentsHook = () => {
    const deleteDocuments = useDmsSwaggerClient<void>()

    return useCallback(
        (fileSetUi: FileSetUi) => {
            return deleteDocuments({ url: `/file/delete/list`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: fileSetUi })
        },
        [deleteDocuments],
    )
}

export const useDeleteDocumentsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentsHook>>>, TError, { data: FileSetUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentsHook>>>, TError, { data: FileSetUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteDocuments = useDeleteDocumentsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentsHook>>>, { data: FileSetUi }> = (props) => {
        const { data } = props ?? {}

        return deleteDocuments(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteDocumentsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentsHook>>>>
export type DeleteDocumentsMutationBody = FileSetUi
export type DeleteDocumentsMutationError = ApiError

export const useDeleteDocuments = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentsHook>>>, TError, { data: FileSetUi }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentsHook>>>, TError, { data: FileSetUi }, TContext> => {
    const mutationOptions = useDeleteDocumentsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetMeta1Hook = () => {
    const getMeta1 = useDmsSwaggerClient<Metadata>()

    return useCallback(
        (uuid: string, params?: GetMeta1Params, signal?: AbortSignal) => {
            return getMeta1({ url: `/file/meta/${uuid}`, method: 'GET', params, signal })
        },
        [getMeta1],
    )
}

export const getGetMeta1QueryKey = (uuid: string, params?: GetMeta1Params) => {
    return [`/file/meta/${uuid}`, ...(params ? [params] : [])] as const
}

export const useGetMeta1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetMeta1Hook>>>, TError = ApiError>(
    uuid: string,
    params?: GetMeta1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMeta1Hook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetMeta1QueryKey(uuid, params)

    const getMeta1 = useGetMeta1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMeta1Hook>>>> = ({ signal }) => getMeta1(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetMeta1Hook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetMeta1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetMeta1Hook>>>>
export type GetMeta1QueryError = ApiError

export function useGetMeta1<TData = Awaited<ReturnType<ReturnType<typeof useGetMeta1Hook>>>, TError = ApiError>(
    uuid: string,
    params?: GetMeta1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMeta1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetMeta1QueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetHistoryHook = () => {
    const getHistory = useDmsSwaggerClient<History>()

    return useCallback(
        (uuid: string, signal?: AbortSignal) => {
            return getHistory({ url: `/file/history/${uuid}`, method: 'GET', signal })
        },
        [getHistory],
    )
}

export const getGetHistoryQueryKey = (uuid: string) => {
    return [`/file/history/${uuid}`] as const
}

export const useGetHistoryQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetHistoryHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHistoryHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetHistoryQueryKey(uuid)

    const getHistory = useGetHistoryHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetHistoryHook>>>> = ({ signal }) => getHistory(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetHistoryHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetHistoryQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetHistoryHook>>>>
export type GetHistoryQueryError = ApiError

export function useGetHistory<TData = Awaited<ReturnType<ReturnType<typeof useGetHistoryHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHistoryHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetHistoryQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
