//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * MetaIS Reference Registers
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { useReferenceRegistersSwaggerClient } from '../hooks/useReferenceRegistersSwaggerClient'
export type GetReferenceRegisterItemSubGroupsParams = {
    pattern?: string
}

export type GetReferenceRegisterItemGroupsParams = {
    pattern?: string
}

export type GetFOPReferenceRegisters1Muk = (typeof GetFOPReferenceRegisters1Muk)[keyof typeof GetFOPReferenceRegisters1Muk]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetFOPReferenceRegisters1Muk = {
    NONE: 'NONE',
    BOTH: 'BOTH',
    MV: 'MV',
    MF: 'MF',
} as const

export type GetFOPReferenceRegisters1State = (typeof GetFOPReferenceRegisters1State)[keyof typeof GetFOPReferenceRegisters1State]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetFOPReferenceRegisters1State = {
    IN_CONSTRUCTION: 'IN_CONSTRUCTION',
    READY_TO_APPROVAL: 'READY_TO_APPROVAL',
    APPROVAL_IN_PROGRESS: 'APPROVAL_IN_PROGRESS',
    MPK_IN_PROGRESS: 'MPK_IN_PROGRESS',
    PUBLISHED: 'PUBLISHED',
    REJECTED: 'REJECTED',
} as const

export type GetFOPReferenceRegisters1Params = {
    pageNumber: number
    perPage: number
    sortBy?: string
    ascending?: boolean
    isvsUuid?: string
    managerUuid?: string
    registratorUuid?: string
    state?: GetFOPReferenceRegisters1State
    muk?: GetFOPReferenceRegisters1Muk
    name?: string
    name_en?: string
}

export type CreateAccessEvidenceItem200 = { [key: string]: unknown }

export type GetFOPAccessEvidenceItemsParams = {
    pageNumber: number
    perPage: number
    sortBy?: string
    ascending?: boolean
}

export type GetFOPReferenceRegisterItemsParams = {
    pageNumber: number
    perPage: number
    sortBy?: string
    ascending?: boolean
}

export type CreateUsingEvidenceItem200 = { [key: string]: unknown }

export type GetFOPUsingEvidenceItemsParams = {
    pageNumber: number
    perPage: number
    sortBy?: string
    ascending?: boolean
}

export type DeleteAccessEvidenceItem200 = { [key: string]: unknown }

export type UpdateAccessEvidenceItem200 = { [key: string]: unknown }

export type DeleteUsingEvidenceItem200 = { [key: string]: unknown }

export type UpdateUsingEvidenceItem200 = { [key: string]: unknown }

export interface ApiReferenceRegisterList {
    referenceRegistersCount?: number
    referenceRegistersList?: ApiReferenceRegister[]
}

export type VoteRefAttributesAllOf = {
    /** The ID of vote to which the DMS document is bound. */
    refVoteId: number
}

/**
 * Class eligible for Votes parent (in STANDARDS module).
 */
export type VoteRefAttributes = RefAttributes & VoteRefAttributesAllOf

/**
 * This class is be just as an return value of old DMS documents for which we were not able to find parent object (and so fill their Metadata.RefAttributes). It can not be used for creation of new DMS documents or updating of old DMS documents. If you are updating an old document then you know who the parent is, so fill RefAttributes object with proper data.
 */
export type UnknownRefAttributes = RefAttributes

export type StandardRequestRefAttributesAllOf = {
    /** The ID of standard request to which the DMS document is bound. */
    refStandardRequestId: number
}

/**
 * Class eligible for Standard parent (in STANDARDS module).
 */
export type StandardRequestRefAttributes = RefAttributes & StandardRequestRefAttributesAllOf

export type RefAttributesRefType = (typeof RefAttributesRefType)[keyof typeof RefAttributesRefType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefAttributesRefType = {
    CI: 'CI',
    STANDARD_REQUEST: 'STANDARD_REQUEST',
    MEETING_REQUEST: 'MEETING_REQUEST',
    VOTE: 'VOTE',
    DOCUMENT_TEMPLATE: 'DOCUMENT_TEMPLATE',
    UNKNOWN: 'UNKNOWN',
} as const

export interface RefAttributes {
    refType?: RefAttributesRefType
}

export type MetadataStatus = (typeof MetadataStatus)[keyof typeof MetadataStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetadataStatus = {
    CREATED: 'CREATED',
    UPDATED: 'UPDATED',
    REMOVED: 'REMOVED',
} as const

export interface Metadata {
    contentLength?: number
    created?: string
    createdBy?: string
    encoding?: string
    extension?: string
    filename?: string
    lastModified?: string
    lastModifiedBy?: string
    mimeType?: string
    refAttributes?: MetadataRefAttributes
    status?: MetadataStatus
    uuid?: string
    version?: string
}

export type MeetingRequestRefAttributesAllOf = {
    /** The ID of meeting request to which the DMS document is bound. */
    refMeetingRequestId: number
}

/**
 * Class eligible for Meeting_requests parent (in STANDARDS module).
 */
export type MeetingRequestRefAttributes = RefAttributes & MeetingRequestRefAttributesAllOf

export type DocumentTemplateRefAttributesAllOf = {
    /** The ID of document to which the DMS document is bound. */
    refDocumentTemplateId: number
}

/**
 * Class eligible for Document parent (in KRIS module).
 */
export type DocumentTemplateRefAttributes = RefAttributes & DocumentTemplateRefAttributesAllOf

export type CiRefAttributesAllOf = {
    /** The ID of configuration item (CI) to which the DMS document is bound. */
    refCiId: string
    /** The metais code of configuration item (CI) to which the DMS document is bound. */
    refCiMetaisCode?: string
    /** The GID of owner of configuration item (CI) to which the DMS document is bound. */
    refCiOwner: string
    /** The technical name of configuration item (CI) to which the DMS document is bound. */
    refCiTechnicalName: string
}

/**
 * Class eligible for ConfiguratioItem parent.
 */
export type CiRefAttributes = RefAttributes & CiRefAttributesAllOf

export type MetadataRefAttributes =
    | CiRefAttributes
    | DocumentTemplateRefAttributes
    | MeetingRequestRefAttributes
    | StandardRequestRefAttributes
    | UnknownRefAttributes
    | VoteRefAttributes

export interface ApiCountResult {
    count?: number
}

export interface ApiReferenceRegisterItemList {
    apiReferenceRegisterItems?: ApiReferenceRegisterItem[]
    apiReferenceRegisterItemsCount?: number
}

export interface ApiUsedByAsList {
    asList?: ApiUsedByAs[]
    asListCount?: number
}

export interface ApiAccessedByPo {
    note?: string
    poName?: string
    poUuid?: string
    uuid?: string
}

export interface ApiAccessedByPoList {
    poList?: ApiAccessedByPo[]
    poListCount?: number
}

export interface ApiContact {
    contactEmail?: string
    contactFirstName?: string
    contactLastName?: string
    contactPhone?: string
    contactRegistratorEmail?: string
    contactRegistratorFirstName?: string
    contactRegistratorLastName?: string
    contactRegistratorPhone?: string
}

export interface ApiReferenceRegisterItemSourceReference {
    sourceIsvsMetaisCode?: string
    sourceIsvsName?: string
    sourceIsvsUuid?: string
    sourceRegistratorName?: string
    sourceRegistratorUuid?: string
}

export interface ApiReferenceRegisterItemGroup {
    groupName?: string
    uuid?: string
}

export interface ApiReferenceRegisterItem {
    dataElementRefID?: string
    itemName?: string
    note?: string
    order?: string
    referenceRegisterGroup?: ApiReferenceRegisterItemGroup
    referenceRegisterSubGroup?: ApiReferenceRegisterItemGroup
    refID?: string
    sourceReferenceHolders?: ApiReferenceRegisterItemSourceReference[]
    subjectIdentification?: string
    uuid?: string
}

export interface ApiDescription {
    description?: string
}

export interface ApiUsedByAs {
    asCode?: string
    asManagesName?: string
    asName?: string
    asUuid?: string
    note?: string
    uuid?: string
}

export type ApiChangeStateTargetState = (typeof ApiChangeStateTargetState)[keyof typeof ApiChangeStateTargetState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiChangeStateTargetState = {
    IN_CONSTRUCTION: 'IN_CONSTRUCTION',
    READY_TO_APPROVAL: 'READY_TO_APPROVAL',
    APPROVAL_IN_PROGRESS: 'APPROVAL_IN_PROGRESS',
    MPK_IN_PROGRESS: 'MPK_IN_PROGRESS',
    PUBLISHED: 'PUBLISHED',
    REJECTED: 'REJECTED',
} as const

export interface ApiChangeState {
    attachementIds?: string[]
    description?: string
    targetState?: ApiChangeStateTargetState
}

export interface RequestIdUi {
    requestId?: string
}

export type ApiReferenceRegisterState = (typeof ApiReferenceRegisterState)[keyof typeof ApiReferenceRegisterState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiReferenceRegisterState = {
    IN_CONSTRUCTION: 'IN_CONSTRUCTION',
    READY_TO_APPROVAL: 'READY_TO_APPROVAL',
    APPROVAL_IN_PROGRESS: 'APPROVAL_IN_PROGRESS',
    MPK_IN_PROGRESS: 'MPK_IN_PROGRESS',
    PUBLISHED: 'PUBLISHED',
    REJECTED: 'REJECTED',
} as const

export type ApiReferenceRegisterMuk = (typeof ApiReferenceRegisterMuk)[keyof typeof ApiReferenceRegisterMuk]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiReferenceRegisterMuk = {
    NONE: 'NONE',
    BOTH: 'BOTH',
    MV: 'MV',
    MF: 'MF',
} as const

export interface ApiReferenceRegister {
    additionalData?: string
    contactEmail?: string
    contactFirstName?: string
    contactLastName?: string
    contactPhone?: string
    contactRegistratorEmail?: string
    contactRegistratorFirstName?: string
    contactRegistratorLastName?: string
    contactRegistratorPhone?: string
    creatorUuid?: string
    effectiveFrom?: string
    effectiveTo?: string
    isvsCode?: string
    isvsLastModifiedAt?: string
    isvsName?: string
    isvsRefId?: string
    isvsUuid?: string
    lastModifiedAt?: string
    managerName?: string
    managerUuid?: string
    muk?: ApiReferenceRegisterMuk
    name?: string
    name_en?: string
    note?: string
    owner?: string
    registratorName?: string
    registratorUuid?: string
    state?: ApiReferenceRegisterState
    uuid?: string
    validFrom?: string
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useGetReferenceRegisterByUuidHook = () => {
    const getReferenceRegisterByUuid = useReferenceRegistersSwaggerClient<ApiReferenceRegister>()

    return useCallback(
        (referenceRegisterUuid: string, signal?: AbortSignal) => {
            return getReferenceRegisterByUuid({ url: `/${referenceRegisterUuid}`, method: 'GET', signal })
        },
        [getReferenceRegisterByUuid],
    )
}

export const getGetReferenceRegisterByUuidQueryKey = (referenceRegisterUuid: string) => {
    return [`/${referenceRegisterUuid}`] as const
}

export const useGetReferenceRegisterByUuidQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterByUuidHook>>>,
    TError = ApiError,
>(
    referenceRegisterUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterByUuidHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetReferenceRegisterByUuidQueryKey(referenceRegisterUuid)

    const getReferenceRegisterByUuid = useGetReferenceRegisterByUuidHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterByUuidHook>>>> = ({ signal }) =>
        getReferenceRegisterByUuid(referenceRegisterUuid, signal)

    return { queryKey, queryFn, enabled: !!referenceRegisterUuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterByUuidHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetReferenceRegisterByUuidQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterByUuidHook>>>>
export type GetReferenceRegisterByUuidQueryError = ApiError

export function useGetReferenceRegisterByUuid<TData = Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterByUuidHook>>>, TError = ApiError>(
    referenceRegisterUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterByUuidHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetReferenceRegisterByUuidQueryOptions(referenceRegisterUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateReferenceRegisterHook = () => {
    const updateReferenceRegister = useReferenceRegistersSwaggerClient<RequestIdUi>()

    return useCallback(
        (referenceRegisterUuid: string, apiReferenceRegister: ApiReferenceRegister) => {
            return updateReferenceRegister({
                url: `/${referenceRegisterUuid}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: apiReferenceRegister,
            })
        },
        [updateReferenceRegister],
    )
}

export const useUpdateReferenceRegisterMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiReferenceRegister },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiReferenceRegister },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateReferenceRegister = useUpdateReferenceRegisterHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterHook>>>,
        { referenceRegisterUuid: string; data: ApiReferenceRegister }
    > = (props) => {
        const { referenceRegisterUuid, data } = props ?? {}

        return updateReferenceRegister(referenceRegisterUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateReferenceRegisterMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterHook>>>>
export type UpdateReferenceRegisterMutationBody = ApiReferenceRegister
export type UpdateReferenceRegisterMutationError = ApiError

export const useUpdateReferenceRegister = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiReferenceRegister },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiReferenceRegister },
    TContext
> => {
    const mutationOptions = useUpdateReferenceRegisterMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteReferenceRegisterHook = () => {
    const deleteReferenceRegister = useReferenceRegistersSwaggerClient<RequestIdUi>()

    return useCallback(
        (referenceRegisterUuid: string) => {
            return deleteReferenceRegister({ url: `/${referenceRegisterUuid}`, method: 'DELETE' })
        },
        [deleteReferenceRegister],
    )
}

export const useDeleteReferenceRegisterMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteReferenceRegisterHook>>>,
        TError,
        { referenceRegisterUuid: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteReferenceRegisterHook>>>,
    TError,
    { referenceRegisterUuid: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteReferenceRegister = useDeleteReferenceRegisterHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteReferenceRegisterHook>>>, { referenceRegisterUuid: string }> = (
        props,
    ) => {
        const { referenceRegisterUuid } = props ?? {}

        return deleteReferenceRegister(referenceRegisterUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteReferenceRegisterMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteReferenceRegisterHook>>>>

export type DeleteReferenceRegisterMutationError = ApiError

export const useDeleteReferenceRegister = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteReferenceRegisterHook>>>,
        TError,
        { referenceRegisterUuid: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useDeleteReferenceRegisterHook>>>,
    TError,
    { referenceRegisterUuid: string },
    TContext
> => {
    const mutationOptions = useDeleteReferenceRegisterMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useProcessRequestActionHook = () => {
    const processRequestAction = useReferenceRegistersSwaggerClient<RequestIdUi>()

    return useCallback(
        (referenceRegisterUuid: string, apiChangeState: ApiChangeState) => {
            return processRequestAction({
                url: `/${referenceRegisterUuid}/wfaction`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiChangeState,
            })
        },
        [processRequestAction],
    )
}

export const useProcessRequestActionMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessRequestActionHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiChangeState },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProcessRequestActionHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiChangeState },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const processRequestAction = useProcessRequestActionHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useProcessRequestActionHook>>>,
        { referenceRegisterUuid: string; data: ApiChangeState }
    > = (props) => {
        const { referenceRegisterUuid, data } = props ?? {}

        return processRequestAction(referenceRegisterUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ProcessRequestActionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProcessRequestActionHook>>>>
export type ProcessRequestActionMutationBody = ApiChangeState
export type ProcessRequestActionMutationError = ApiError

export const useProcessRequestAction = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessRequestActionHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiChangeState },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useProcessRequestActionHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiChangeState },
    TContext
> => {
    const mutationOptions = useProcessRequestActionMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateUsingEvidenceItemHook = () => {
    const updateUsingEvidenceItem = useReferenceRegistersSwaggerClient<UpdateUsingEvidenceItem200>()

    return useCallback(
        (referenceRegisterUuid: string, usingEvidenceItemUuid: string, apiUsedByAs: ApiUsedByAs) => {
            return updateUsingEvidenceItem({
                url: `/${referenceRegisterUuid}/usingevidence/${usingEvidenceItemUuid}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: apiUsedByAs,
            })
        },
        [updateUsingEvidenceItem],
    )
}

export const useUpdateUsingEvidenceItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateUsingEvidenceItemHook>>>,
        TError,
        { referenceRegisterUuid: string; usingEvidenceItemUuid: string; data: ApiUsedByAs },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUsingEvidenceItemHook>>>,
    TError,
    { referenceRegisterUuid: string; usingEvidenceItemUuid: string; data: ApiUsedByAs },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateUsingEvidenceItem = useUpdateUsingEvidenceItemHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateUsingEvidenceItemHook>>>,
        { referenceRegisterUuid: string; usingEvidenceItemUuid: string; data: ApiUsedByAs }
    > = (props) => {
        const { referenceRegisterUuid, usingEvidenceItemUuid, data } = props ?? {}

        return updateUsingEvidenceItem(referenceRegisterUuid, usingEvidenceItemUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateUsingEvidenceItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateUsingEvidenceItemHook>>>>
export type UpdateUsingEvidenceItemMutationBody = ApiUsedByAs
export type UpdateUsingEvidenceItemMutationError = ApiError

export const useUpdateUsingEvidenceItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateUsingEvidenceItemHook>>>,
        TError,
        { referenceRegisterUuid: string; usingEvidenceItemUuid: string; data: ApiUsedByAs },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateUsingEvidenceItemHook>>>,
    TError,
    { referenceRegisterUuid: string; usingEvidenceItemUuid: string; data: ApiUsedByAs },
    TContext
> => {
    const mutationOptions = useUpdateUsingEvidenceItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteUsingEvidenceItemHook = () => {
    const deleteUsingEvidenceItem = useReferenceRegistersSwaggerClient<DeleteUsingEvidenceItem200>()

    return useCallback(
        (referenceRegisterUuid: string, usingEvidenceItemUuid: string) => {
            return deleteUsingEvidenceItem({ url: `/${referenceRegisterUuid}/usingevidence/${usingEvidenceItemUuid}`, method: 'DELETE' })
        },
        [deleteUsingEvidenceItem],
    )
}

export const useDeleteUsingEvidenceItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteUsingEvidenceItemHook>>>,
        TError,
        { referenceRegisterUuid: string; usingEvidenceItemUuid: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUsingEvidenceItemHook>>>,
    TError,
    { referenceRegisterUuid: string; usingEvidenceItemUuid: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteUsingEvidenceItem = useDeleteUsingEvidenceItemHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteUsingEvidenceItemHook>>>,
        { referenceRegisterUuid: string; usingEvidenceItemUuid: string }
    > = (props) => {
        const { referenceRegisterUuid, usingEvidenceItemUuid } = props ?? {}

        return deleteUsingEvidenceItem(referenceRegisterUuid, usingEvidenceItemUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteUsingEvidenceItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteUsingEvidenceItemHook>>>>

export type DeleteUsingEvidenceItemMutationError = ApiError

export const useDeleteUsingEvidenceItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteUsingEvidenceItemHook>>>,
        TError,
        { referenceRegisterUuid: string; usingEvidenceItemUuid: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useDeleteUsingEvidenceItemHook>>>,
    TError,
    { referenceRegisterUuid: string; usingEvidenceItemUuid: string },
    TContext
> => {
    const mutationOptions = useDeleteUsingEvidenceItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateReferenceRegisterNoteHook = () => {
    const updateReferenceRegisterNote = useReferenceRegistersSwaggerClient<RequestIdUi>()

    return useCallback(
        (referenceRegisterUuid: string, apiDescription: ApiDescription) => {
            return updateReferenceRegisterNote({
                url: `/${referenceRegisterUuid}/note`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: apiDescription,
            })
        },
        [updateReferenceRegisterNote],
    )
}

export const useUpdateReferenceRegisterNoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterNoteHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiDescription },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterNoteHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiDescription },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateReferenceRegisterNote = useUpdateReferenceRegisterNoteHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterNoteHook>>>,
        { referenceRegisterUuid: string; data: ApiDescription }
    > = (props) => {
        const { referenceRegisterUuid, data } = props ?? {}

        return updateReferenceRegisterNote(referenceRegisterUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateReferenceRegisterNoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterNoteHook>>>>
export type UpdateReferenceRegisterNoteMutationBody = ApiDescription
export type UpdateReferenceRegisterNoteMutationError = ApiError

export const useUpdateReferenceRegisterNote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterNoteHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiDescription },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterNoteHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiDescription },
    TContext
> => {
    const mutationOptions = useUpdateReferenceRegisterNoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetReferenceRegisterItemHook = () => {
    const getReferenceRegisterItem = useReferenceRegistersSwaggerClient<ApiReferenceRegisterItem>()

    return useCallback(
        (referenceRegisterUuid: string, referenceRegisterItemUuid: string, signal?: AbortSignal) => {
            return getReferenceRegisterItem({ url: `/${referenceRegisterUuid}/items/${referenceRegisterItemUuid}`, method: 'GET', signal })
        },
        [getReferenceRegisterItem],
    )
}

export const getGetReferenceRegisterItemQueryKey = (referenceRegisterUuid: string, referenceRegisterItemUuid: string) => {
    return [`/${referenceRegisterUuid}/items/${referenceRegisterItemUuid}`] as const
}

export const useGetReferenceRegisterItemQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemHook>>>,
    TError = ApiError,
>(
    referenceRegisterUuid: string,
    referenceRegisterItemUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetReferenceRegisterItemQueryKey(referenceRegisterUuid, referenceRegisterItemUuid)

    const getReferenceRegisterItem = useGetReferenceRegisterItemHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemHook>>>> = ({ signal }) =>
        getReferenceRegisterItem(referenceRegisterUuid, referenceRegisterItemUuid, signal)

    return { queryKey, queryFn, enabled: !!(referenceRegisterUuid && referenceRegisterItemUuid), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetReferenceRegisterItemQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemHook>>>>
export type GetReferenceRegisterItemQueryError = ApiError

export function useGetReferenceRegisterItem<TData = Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemHook>>>, TError = ApiError>(
    referenceRegisterUuid: string,
    referenceRegisterItemUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetReferenceRegisterItemQueryOptions(referenceRegisterUuid, referenceRegisterItemUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateReferenceRegisterItemHook = () => {
    const updateReferenceRegisterItem = useReferenceRegistersSwaggerClient<RequestIdUi>()

    return useCallback(
        (referenceRegisterUuid: string, referenceRegisterItemUuid: string, apiReferenceRegisterItem: ApiReferenceRegisterItem) => {
            return updateReferenceRegisterItem({
                url: `/${referenceRegisterUuid}/items/${referenceRegisterItemUuid}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: apiReferenceRegisterItem,
            })
        },
        [updateReferenceRegisterItem],
    )
}

export const useUpdateReferenceRegisterItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterItemHook>>>,
        TError,
        { referenceRegisterUuid: string; referenceRegisterItemUuid: string; data: ApiReferenceRegisterItem },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterItemHook>>>,
    TError,
    { referenceRegisterUuid: string; referenceRegisterItemUuid: string; data: ApiReferenceRegisterItem },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateReferenceRegisterItem = useUpdateReferenceRegisterItemHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterItemHook>>>,
        { referenceRegisterUuid: string; referenceRegisterItemUuid: string; data: ApiReferenceRegisterItem }
    > = (props) => {
        const { referenceRegisterUuid, referenceRegisterItemUuid, data } = props ?? {}

        return updateReferenceRegisterItem(referenceRegisterUuid, referenceRegisterItemUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateReferenceRegisterItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterItemHook>>>>
export type UpdateReferenceRegisterItemMutationBody = ApiReferenceRegisterItem
export type UpdateReferenceRegisterItemMutationError = ApiError

export const useUpdateReferenceRegisterItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterItemHook>>>,
        TError,
        { referenceRegisterUuid: string; referenceRegisterItemUuid: string; data: ApiReferenceRegisterItem },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterItemHook>>>,
    TError,
    { referenceRegisterUuid: string; referenceRegisterItemUuid: string; data: ApiReferenceRegisterItem },
    TContext
> => {
    const mutationOptions = useUpdateReferenceRegisterItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteReferenceRegisterItemHook = () => {
    const deleteReferenceRegisterItem = useReferenceRegistersSwaggerClient<RequestIdUi>()

    return useCallback(
        (referenceRegisterUuid: string, referenceRegisterItemUuid: string) => {
            return deleteReferenceRegisterItem({ url: `/${referenceRegisterUuid}/items/${referenceRegisterItemUuid}`, method: 'DELETE' })
        },
        [deleteReferenceRegisterItem],
    )
}

export const useDeleteReferenceRegisterItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteReferenceRegisterItemHook>>>,
        TError,
        { referenceRegisterUuid: string; referenceRegisterItemUuid: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteReferenceRegisterItemHook>>>,
    TError,
    { referenceRegisterUuid: string; referenceRegisterItemUuid: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteReferenceRegisterItem = useDeleteReferenceRegisterItemHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteReferenceRegisterItemHook>>>,
        { referenceRegisterUuid: string; referenceRegisterItemUuid: string }
    > = (props) => {
        const { referenceRegisterUuid, referenceRegisterItemUuid } = props ?? {}

        return deleteReferenceRegisterItem(referenceRegisterUuid, referenceRegisterItemUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteReferenceRegisterItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteReferenceRegisterItemHook>>>>

export type DeleteReferenceRegisterItemMutationError = ApiError

export const useDeleteReferenceRegisterItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteReferenceRegisterItemHook>>>,
        TError,
        { referenceRegisterUuid: string; referenceRegisterItemUuid: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useDeleteReferenceRegisterItemHook>>>,
    TError,
    { referenceRegisterUuid: string; referenceRegisterItemUuid: string },
    TContext
> => {
    const mutationOptions = useDeleteReferenceRegisterItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateReferenceRegisterContactHook = () => {
    const updateReferenceRegisterContact = useReferenceRegistersSwaggerClient<RequestIdUi>()

    return useCallback(
        (referenceRegisterUuid: string, apiContact: ApiContact) => {
            return updateReferenceRegisterContact({
                url: `/${referenceRegisterUuid}/contact`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: apiContact,
            })
        },
        [updateReferenceRegisterContact],
    )
}

export const useUpdateReferenceRegisterContactMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterContactHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiContact },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterContactHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiContact },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateReferenceRegisterContact = useUpdateReferenceRegisterContactHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterContactHook>>>,
        { referenceRegisterUuid: string; data: ApiContact }
    > = (props) => {
        const { referenceRegisterUuid, data } = props ?? {}

        return updateReferenceRegisterContact(referenceRegisterUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateReferenceRegisterContactMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterContactHook>>>>
export type UpdateReferenceRegisterContactMutationBody = ApiContact
export type UpdateReferenceRegisterContactMutationError = ApiError

export const useUpdateReferenceRegisterContact = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterContactHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiContact },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterContactHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiContact },
    TContext
> => {
    const mutationOptions = useUpdateReferenceRegisterContactMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateAccessEvidenceItemHook = () => {
    const updateAccessEvidenceItem = useReferenceRegistersSwaggerClient<UpdateAccessEvidenceItem200>()

    return useCallback(
        (referenceRegisterUuid: string, accesEvidenceItemUuid: string, apiAccessedByPo: ApiAccessedByPo) => {
            return updateAccessEvidenceItem({
                url: `/${referenceRegisterUuid}/accessevidence/${accesEvidenceItemUuid}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: apiAccessedByPo,
            })
        },
        [updateAccessEvidenceItem],
    )
}

export const useUpdateAccessEvidenceItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateAccessEvidenceItemHook>>>,
        TError,
        { referenceRegisterUuid: string; accesEvidenceItemUuid: string; data: ApiAccessedByPo },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateAccessEvidenceItemHook>>>,
    TError,
    { referenceRegisterUuid: string; accesEvidenceItemUuid: string; data: ApiAccessedByPo },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateAccessEvidenceItem = useUpdateAccessEvidenceItemHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateAccessEvidenceItemHook>>>,
        { referenceRegisterUuid: string; accesEvidenceItemUuid: string; data: ApiAccessedByPo }
    > = (props) => {
        const { referenceRegisterUuid, accesEvidenceItemUuid, data } = props ?? {}

        return updateAccessEvidenceItem(referenceRegisterUuid, accesEvidenceItemUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateAccessEvidenceItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateAccessEvidenceItemHook>>>>
export type UpdateAccessEvidenceItemMutationBody = ApiAccessedByPo
export type UpdateAccessEvidenceItemMutationError = ApiError

export const useUpdateAccessEvidenceItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateAccessEvidenceItemHook>>>,
        TError,
        { referenceRegisterUuid: string; accesEvidenceItemUuid: string; data: ApiAccessedByPo },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateAccessEvidenceItemHook>>>,
    TError,
    { referenceRegisterUuid: string; accesEvidenceItemUuid: string; data: ApiAccessedByPo },
    TContext
> => {
    const mutationOptions = useUpdateAccessEvidenceItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteAccessEvidenceItemHook = () => {
    const deleteAccessEvidenceItem = useReferenceRegistersSwaggerClient<DeleteAccessEvidenceItem200>()

    return useCallback(
        (referenceRegisterUuid: string, accesEvidenceItemUuid: string) => {
            return deleteAccessEvidenceItem({ url: `/${referenceRegisterUuid}/accessevidence/${accesEvidenceItemUuid}`, method: 'DELETE' })
        },
        [deleteAccessEvidenceItem],
    )
}

export const useDeleteAccessEvidenceItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteAccessEvidenceItemHook>>>,
        TError,
        { referenceRegisterUuid: string; accesEvidenceItemUuid: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAccessEvidenceItemHook>>>,
    TError,
    { referenceRegisterUuid: string; accesEvidenceItemUuid: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteAccessEvidenceItem = useDeleteAccessEvidenceItemHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteAccessEvidenceItemHook>>>,
        { referenceRegisterUuid: string; accesEvidenceItemUuid: string }
    > = (props) => {
        const { referenceRegisterUuid, accesEvidenceItemUuid } = props ?? {}

        return deleteAccessEvidenceItem(referenceRegisterUuid, accesEvidenceItemUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteAccessEvidenceItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteAccessEvidenceItemHook>>>>

export type DeleteAccessEvidenceItemMutationError = ApiError

export const useDeleteAccessEvidenceItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteAccessEvidenceItemHook>>>,
        TError,
        { referenceRegisterUuid: string; accesEvidenceItemUuid: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useDeleteAccessEvidenceItemHook>>>,
    TError,
    { referenceRegisterUuid: string; accesEvidenceItemUuid: string },
    TContext
> => {
    const mutationOptions = useDeleteAccessEvidenceItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateReferenceRegisterAccessDataHook = () => {
    const updateReferenceRegisterAccessData = useReferenceRegistersSwaggerClient<RequestIdUi>()

    return useCallback(
        (referenceRegisterUuid: string, apiDescription: ApiDescription) => {
            return updateReferenceRegisterAccessData({
                url: `/${referenceRegisterUuid}/accessDataDescription`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: apiDescription,
            })
        },
        [updateReferenceRegisterAccessData],
    )
}

export const useUpdateReferenceRegisterAccessDataMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterAccessDataHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiDescription },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterAccessDataHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiDescription },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateReferenceRegisterAccessData = useUpdateReferenceRegisterAccessDataHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterAccessDataHook>>>,
        { referenceRegisterUuid: string; data: ApiDescription }
    > = (props) => {
        const { referenceRegisterUuid, data } = props ?? {}

        return updateReferenceRegisterAccessData(referenceRegisterUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateReferenceRegisterAccessDataMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterAccessDataHook>>>
>
export type UpdateReferenceRegisterAccessDataMutationBody = ApiDescription
export type UpdateReferenceRegisterAccessDataMutationError = ApiError

export const useUpdateReferenceRegisterAccessData = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterAccessDataHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiDescription },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateReferenceRegisterAccessDataHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiDescription },
    TContext
> => {
    const mutationOptions = useUpdateReferenceRegisterAccessDataMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetFOPUsingEvidenceItemsHook = () => {
    const getFOPUsingEvidenceItems = useReferenceRegistersSwaggerClient<ApiUsedByAsList>()

    return useCallback(
        (referenceRegisterUuid: string, params: GetFOPUsingEvidenceItemsParams, signal?: AbortSignal) => {
            return getFOPUsingEvidenceItems({ url: `/${referenceRegisterUuid}/usingevidence`, method: 'GET', params, signal })
        },
        [getFOPUsingEvidenceItems],
    )
}

export const getGetFOPUsingEvidenceItemsQueryKey = (referenceRegisterUuid: string, params: GetFOPUsingEvidenceItemsParams) => {
    return [`/${referenceRegisterUuid}/usingevidence`, ...(params ? [params] : [])] as const
}

export const useGetFOPUsingEvidenceItemsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetFOPUsingEvidenceItemsHook>>>,
    TError = ApiError,
>(
    referenceRegisterUuid: string,
    params: GetFOPUsingEvidenceItemsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFOPUsingEvidenceItemsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetFOPUsingEvidenceItemsQueryKey(referenceRegisterUuid, params)

    const getFOPUsingEvidenceItems = useGetFOPUsingEvidenceItemsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFOPUsingEvidenceItemsHook>>>> = ({ signal }) =>
        getFOPUsingEvidenceItems(referenceRegisterUuid, params, signal)

    return { queryKey, queryFn, enabled: !!referenceRegisterUuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetFOPUsingEvidenceItemsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetFOPUsingEvidenceItemsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFOPUsingEvidenceItemsHook>>>>
export type GetFOPUsingEvidenceItemsQueryError = ApiError

export function useGetFOPUsingEvidenceItems<TData = Awaited<ReturnType<ReturnType<typeof useGetFOPUsingEvidenceItemsHook>>>, TError = ApiError>(
    referenceRegisterUuid: string,
    params: GetFOPUsingEvidenceItemsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFOPUsingEvidenceItemsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetFOPUsingEvidenceItemsQueryOptions(referenceRegisterUuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCreateUsingEvidenceItemHook = () => {
    const createUsingEvidenceItem = useReferenceRegistersSwaggerClient<CreateUsingEvidenceItem200>()

    return useCallback(
        (referenceRegisterUuid: string, apiUsedByAs: ApiUsedByAs) => {
            return createUsingEvidenceItem({
                url: `/${referenceRegisterUuid}/usingevidence`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: apiUsedByAs,
            })
        },
        [createUsingEvidenceItem],
    )
}

export const useCreateUsingEvidenceItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateUsingEvidenceItemHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiUsedByAs },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateUsingEvidenceItemHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiUsedByAs },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const createUsingEvidenceItem = useCreateUsingEvidenceItemHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCreateUsingEvidenceItemHook>>>,
        { referenceRegisterUuid: string; data: ApiUsedByAs }
    > = (props) => {
        const { referenceRegisterUuid, data } = props ?? {}

        return createUsingEvidenceItem(referenceRegisterUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateUsingEvidenceItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateUsingEvidenceItemHook>>>>
export type CreateUsingEvidenceItemMutationBody = ApiUsedByAs
export type CreateUsingEvidenceItemMutationError = ApiError

export const useCreateUsingEvidenceItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateUsingEvidenceItemHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiUsedByAs },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useCreateUsingEvidenceItemHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiUsedByAs },
    TContext
> => {
    const mutationOptions = useCreateUsingEvidenceItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetFOPReferenceRegisterItemsHook = () => {
    const getFOPReferenceRegisterItems = useReferenceRegistersSwaggerClient<ApiReferenceRegisterItemList>()

    return useCallback(
        (referenceRegisterUuid: string, params: GetFOPReferenceRegisterItemsParams, signal?: AbortSignal) => {
            return getFOPReferenceRegisterItems({ url: `/${referenceRegisterUuid}/items`, method: 'GET', params, signal })
        },
        [getFOPReferenceRegisterItems],
    )
}

export const getGetFOPReferenceRegisterItemsQueryKey = (referenceRegisterUuid: string, params: GetFOPReferenceRegisterItemsParams) => {
    return [`/${referenceRegisterUuid}/items`, ...(params ? [params] : [])] as const
}

export const useGetFOPReferenceRegisterItemsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisterItemsHook>>>,
    TError = ApiError,
>(
    referenceRegisterUuid: string,
    params: GetFOPReferenceRegisterItemsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisterItemsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetFOPReferenceRegisterItemsQueryKey(referenceRegisterUuid, params)

    const getFOPReferenceRegisterItems = useGetFOPReferenceRegisterItemsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisterItemsHook>>>> = ({ signal }) =>
        getFOPReferenceRegisterItems(referenceRegisterUuid, params, signal)

    return { queryKey, queryFn, enabled: !!referenceRegisterUuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisterItemsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetFOPReferenceRegisterItemsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisterItemsHook>>>>
export type GetFOPReferenceRegisterItemsQueryError = ApiError

export function useGetFOPReferenceRegisterItems<
    TData = Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisterItemsHook>>>,
    TError = ApiError,
>(
    referenceRegisterUuid: string,
    params: GetFOPReferenceRegisterItemsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisterItemsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetFOPReferenceRegisterItemsQueryOptions(referenceRegisterUuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCreateReferenceRegisterItemHook = () => {
    const createReferenceRegisterItem = useReferenceRegistersSwaggerClient<RequestIdUi>()

    return useCallback(
        (referenceRegisterUuid: string, apiReferenceRegisterItem: ApiReferenceRegisterItem) => {
            return createReferenceRegisterItem({
                url: `/${referenceRegisterUuid}/items`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: apiReferenceRegisterItem,
            })
        },
        [createReferenceRegisterItem],
    )
}

export const useCreateReferenceRegisterItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateReferenceRegisterItemHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiReferenceRegisterItem },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateReferenceRegisterItemHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiReferenceRegisterItem },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const createReferenceRegisterItem = useCreateReferenceRegisterItemHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCreateReferenceRegisterItemHook>>>,
        { referenceRegisterUuid: string; data: ApiReferenceRegisterItem }
    > = (props) => {
        const { referenceRegisterUuid, data } = props ?? {}

        return createReferenceRegisterItem(referenceRegisterUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateReferenceRegisterItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateReferenceRegisterItemHook>>>>
export type CreateReferenceRegisterItemMutationBody = ApiReferenceRegisterItem
export type CreateReferenceRegisterItemMutationError = ApiError

export const useCreateReferenceRegisterItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateReferenceRegisterItemHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiReferenceRegisterItem },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useCreateReferenceRegisterItemHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiReferenceRegisterItem },
    TContext
> => {
    const mutationOptions = useCreateReferenceRegisterItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetFOPAccessEvidenceItemsHook = () => {
    const getFOPAccessEvidenceItems = useReferenceRegistersSwaggerClient<ApiAccessedByPoList>()

    return useCallback(
        (referenceRegisterUuid: string, params: GetFOPAccessEvidenceItemsParams, signal?: AbortSignal) => {
            return getFOPAccessEvidenceItems({ url: `/${referenceRegisterUuid}/accessevidence`, method: 'GET', params, signal })
        },
        [getFOPAccessEvidenceItems],
    )
}

export const getGetFOPAccessEvidenceItemsQueryKey = (referenceRegisterUuid: string, params: GetFOPAccessEvidenceItemsParams) => {
    return [`/${referenceRegisterUuid}/accessevidence`, ...(params ? [params] : [])] as const
}

export const useGetFOPAccessEvidenceItemsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetFOPAccessEvidenceItemsHook>>>,
    TError = ApiError,
>(
    referenceRegisterUuid: string,
    params: GetFOPAccessEvidenceItemsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFOPAccessEvidenceItemsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetFOPAccessEvidenceItemsQueryKey(referenceRegisterUuid, params)

    const getFOPAccessEvidenceItems = useGetFOPAccessEvidenceItemsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFOPAccessEvidenceItemsHook>>>> = ({ signal }) =>
        getFOPAccessEvidenceItems(referenceRegisterUuid, params, signal)

    return { queryKey, queryFn, enabled: !!referenceRegisterUuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetFOPAccessEvidenceItemsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetFOPAccessEvidenceItemsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFOPAccessEvidenceItemsHook>>>>
export type GetFOPAccessEvidenceItemsQueryError = ApiError

export function useGetFOPAccessEvidenceItems<TData = Awaited<ReturnType<ReturnType<typeof useGetFOPAccessEvidenceItemsHook>>>, TError = ApiError>(
    referenceRegisterUuid: string,
    params: GetFOPAccessEvidenceItemsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFOPAccessEvidenceItemsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetFOPAccessEvidenceItemsQueryOptions(referenceRegisterUuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCreateAccessEvidenceItemHook = () => {
    const createAccessEvidenceItem = useReferenceRegistersSwaggerClient<CreateAccessEvidenceItem200>()

    return useCallback(
        (referenceRegisterUuid: string, apiAccessedByPo: ApiAccessedByPo) => {
            return createAccessEvidenceItem({
                url: `/${referenceRegisterUuid}/accessevidence`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: apiAccessedByPo,
            })
        },
        [createAccessEvidenceItem],
    )
}

export const useCreateAccessEvidenceItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateAccessEvidenceItemHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiAccessedByPo },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateAccessEvidenceItemHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiAccessedByPo },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const createAccessEvidenceItem = useCreateAccessEvidenceItemHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCreateAccessEvidenceItemHook>>>,
        { referenceRegisterUuid: string; data: ApiAccessedByPo }
    > = (props) => {
        const { referenceRegisterUuid, data } = props ?? {}

        return createAccessEvidenceItem(referenceRegisterUuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateAccessEvidenceItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateAccessEvidenceItemHook>>>>
export type CreateAccessEvidenceItemMutationBody = ApiAccessedByPo
export type CreateAccessEvidenceItemMutationError = ApiError

export const useCreateAccessEvidenceItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateAccessEvidenceItemHook>>>,
        TError,
        { referenceRegisterUuid: string; data: ApiAccessedByPo },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useCreateAccessEvidenceItemHook>>>,
    TError,
    { referenceRegisterUuid: string; data: ApiAccessedByPo },
    TContext
> => {
    const mutationOptions = useCreateAccessEvidenceItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetFOPReferenceRegisters1Hook = () => {
    const getFOPReferenceRegisters1 = useReferenceRegistersSwaggerClient<ApiReferenceRegisterList>()

    return useCallback(
        (params: GetFOPReferenceRegisters1Params, signal?: AbortSignal) => {
            return getFOPReferenceRegisters1({ url: `/`, method: 'GET', params, signal })
        },
        [getFOPReferenceRegisters1],
    )
}

export const getGetFOPReferenceRegisters1QueryKey = (params: GetFOPReferenceRegisters1Params) => {
    return [`/`, ...(params ? [params] : [])] as const
}

export const useGetFOPReferenceRegisters1QueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisters1Hook>>>,
    TError = ApiError,
>(
    params: GetFOPReferenceRegisters1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisters1Hook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetFOPReferenceRegisters1QueryKey(params)

    const getFOPReferenceRegisters1 = useGetFOPReferenceRegisters1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisters1Hook>>>> = ({ signal }) =>
        getFOPReferenceRegisters1(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisters1Hook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetFOPReferenceRegisters1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisters1Hook>>>>
export type GetFOPReferenceRegisters1QueryError = ApiError

export function useGetFOPReferenceRegisters1<TData = Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisters1Hook>>>, TError = ApiError>(
    params: GetFOPReferenceRegisters1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFOPReferenceRegisters1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetFOPReferenceRegisters1QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCreateReferenceRegister1Hook = () => {
    const createReferenceRegister1 = useReferenceRegistersSwaggerClient<RequestIdUi>()

    return useCallback(
        (apiReferenceRegister: ApiReferenceRegister) => {
            return createReferenceRegister1({
                url: `/`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: apiReferenceRegister,
            })
        },
        [createReferenceRegister1],
    )
}

export const useCreateReferenceRegister1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateReferenceRegister1Hook>>>,
        TError,
        { data: ApiReferenceRegister },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateReferenceRegister1Hook>>>, TError, { data: ApiReferenceRegister }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const createReferenceRegister1 = useCreateReferenceRegister1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateReferenceRegister1Hook>>>, { data: ApiReferenceRegister }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return createReferenceRegister1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateReferenceRegister1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateReferenceRegister1Hook>>>>
export type CreateReferenceRegister1MutationBody = ApiReferenceRegister
export type CreateReferenceRegister1MutationError = ApiError

export const useCreateReferenceRegister1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateReferenceRegister1Hook>>>,
        TError,
        { data: ApiReferenceRegister },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCreateReferenceRegister1Hook>>>, TError, { data: ApiReferenceRegister }, TContext> => {
    const mutationOptions = useCreateReferenceRegister1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetReferenceRegisterItemGroupsHook = () => {
    const getReferenceRegisterItemGroups = useReferenceRegistersSwaggerClient<ApiReferenceRegisterItemGroup[]>()

    return useCallback(
        (referenceRegisterUuid: string, params?: GetReferenceRegisterItemGroupsParams, signal?: AbortSignal) => {
            return getReferenceRegisterItemGroups({ url: `/${referenceRegisterUuid}/itemGroups`, method: 'GET', params, signal })
        },
        [getReferenceRegisterItemGroups],
    )
}

export const getGetReferenceRegisterItemGroupsQueryKey = (referenceRegisterUuid: string, params?: GetReferenceRegisterItemGroupsParams) => {
    return [`/${referenceRegisterUuid}/itemGroups`, ...(params ? [params] : [])] as const
}

export const useGetReferenceRegisterItemGroupsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsHook>>>,
    TError = ApiError,
>(
    referenceRegisterUuid: string,
    params?: GetReferenceRegisterItemGroupsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetReferenceRegisterItemGroupsQueryKey(referenceRegisterUuid, params)

    const getReferenceRegisterItemGroups = useGetReferenceRegisterItemGroupsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsHook>>>> = ({ signal }) =>
        getReferenceRegisterItemGroups(referenceRegisterUuid, params, signal)

    return { queryKey, queryFn, enabled: !!referenceRegisterUuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetReferenceRegisterItemGroupsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsHook>>>>
export type GetReferenceRegisterItemGroupsQueryError = ApiError

export function useGetReferenceRegisterItemGroups<
    TData = Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsHook>>>,
    TError = ApiError,
>(
    referenceRegisterUuid: string,
    params?: GetReferenceRegisterItemGroupsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetReferenceRegisterItemGroupsQueryOptions(referenceRegisterUuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetReferenceRegisterItemSubGroupsHook = () => {
    const getReferenceRegisterItemSubGroups = useReferenceRegistersSwaggerClient<ApiReferenceRegisterItemGroup[]>()

    return useCallback(
        (referenceRegisterUuid: string, itemGroupUuid: string, params?: GetReferenceRegisterItemSubGroupsParams, signal?: AbortSignal) => {
            return getReferenceRegisterItemSubGroups({
                url: `/${referenceRegisterUuid}/itemGroups/${itemGroupUuid}/subGroups`,
                method: 'GET',
                params,
                signal,
            })
        },
        [getReferenceRegisterItemSubGroups],
    )
}

export const getGetReferenceRegisterItemSubGroupsQueryKey = (
    referenceRegisterUuid: string,
    itemGroupUuid: string,
    params?: GetReferenceRegisterItemSubGroupsParams,
) => {
    return [`/${referenceRegisterUuid}/itemGroups/${itemGroupUuid}/subGroups`, ...(params ? [params] : [])] as const
}

export const useGetReferenceRegisterItemSubGroupsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsHook>>>,
    TError = ApiError,
>(
    referenceRegisterUuid: string,
    itemGroupUuid: string,
    params?: GetReferenceRegisterItemSubGroupsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetReferenceRegisterItemSubGroupsQueryKey(referenceRegisterUuid, itemGroupUuid, params)

    const getReferenceRegisterItemSubGroups = useGetReferenceRegisterItemSubGroupsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsHook>>>> = ({ signal }) =>
        getReferenceRegisterItemSubGroups(referenceRegisterUuid, itemGroupUuid, params, signal)

    return { queryKey, queryFn, enabled: !!(referenceRegisterUuid && itemGroupUuid), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetReferenceRegisterItemSubGroupsQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsHook>>>
>
export type GetReferenceRegisterItemSubGroupsQueryError = ApiError

export function useGetReferenceRegisterItemSubGroups<
    TData = Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsHook>>>,
    TError = ApiError,
>(
    referenceRegisterUuid: string,
    itemGroupUuid: string,
    params?: GetReferenceRegisterItemSubGroupsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetReferenceRegisterItemSubGroupsQueryOptions(referenceRegisterUuid, itemGroupUuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetReferenceRegisterItemSubGroupsCountHook = () => {
    const getReferenceRegisterItemSubGroupsCount = useReferenceRegistersSwaggerClient<ApiCountResult>()

    return useCallback(
        (itemSubGroupUuid: string, signal?: AbortSignal) => {
            return getReferenceRegisterItemSubGroupsCount({ url: `/subgroupitemscount/${itemSubGroupUuid}`, method: 'GET', signal })
        },
        [getReferenceRegisterItemSubGroupsCount],
    )
}

export const getGetReferenceRegisterItemSubGroupsCountQueryKey = (itemSubGroupUuid: string) => {
    return [`/subgroupitemscount/${itemSubGroupUuid}`] as const
}

export const useGetReferenceRegisterItemSubGroupsCountQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsCountHook>>>,
    TError = ApiError,
>(
    itemSubGroupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsCountHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetReferenceRegisterItemSubGroupsCountQueryKey(itemSubGroupUuid)

    const getReferenceRegisterItemSubGroupsCount = useGetReferenceRegisterItemSubGroupsCountHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsCountHook>>>> = ({ signal }) =>
        getReferenceRegisterItemSubGroupsCount(itemSubGroupUuid, signal)

    return { queryKey, queryFn, enabled: !!itemSubGroupUuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsCountHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetReferenceRegisterItemSubGroupsCountQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsCountHook>>>
>
export type GetReferenceRegisterItemSubGroupsCountQueryError = ApiError

export function useGetReferenceRegisterItemSubGroupsCount<
    TData = Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsCountHook>>>,
    TError = ApiError,
>(
    itemSubGroupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemSubGroupsCountHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetReferenceRegisterItemSubGroupsCountQueryOptions(itemSubGroupUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetReferenceRegisterItemGroupsCountHook = () => {
    const getReferenceRegisterItemGroupsCount = useReferenceRegistersSwaggerClient<ApiCountResult>()

    return useCallback(
        (itemGroupUuid: string, signal?: AbortSignal) => {
            return getReferenceRegisterItemGroupsCount({ url: `/groupitemscount/${itemGroupUuid}`, method: 'GET', signal })
        },
        [getReferenceRegisterItemGroupsCount],
    )
}

export const getGetReferenceRegisterItemGroupsCountQueryKey = (itemGroupUuid: string) => {
    return [`/groupitemscount/${itemGroupUuid}`] as const
}

export const useGetReferenceRegisterItemGroupsCountQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsCountHook>>>,
    TError = ApiError,
>(
    itemGroupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsCountHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetReferenceRegisterItemGroupsCountQueryKey(itemGroupUuid)

    const getReferenceRegisterItemGroupsCount = useGetReferenceRegisterItemGroupsCountHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsCountHook>>>> = ({ signal }) =>
        getReferenceRegisterItemGroupsCount(itemGroupUuid, signal)

    return { queryKey, queryFn, enabled: !!itemGroupUuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsCountHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetReferenceRegisterItemGroupsCountQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsCountHook>>>
>
export type GetReferenceRegisterItemGroupsCountQueryError = ApiError

export function useGetReferenceRegisterItemGroupsCount<
    TData = Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsCountHook>>>,
    TError = ApiError,
>(
    itemGroupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReferenceRegisterItemGroupsCountHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetReferenceRegisterItemGroupsCountQueryOptions(itemGroupUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGenerateReferenceRegisterByUuidHook = () => {
    const generateReferenceRegisterByUuid = useReferenceRegistersSwaggerClient<Metadata>()

    return useCallback(
        (referenceRegisterUuid: string, signal?: AbortSignal) => {
            return generateReferenceRegisterByUuid({ url: `/generate/${referenceRegisterUuid}`, method: 'GET', signal })
        },
        [generateReferenceRegisterByUuid],
    )
}

export const getGenerateReferenceRegisterByUuidQueryKey = (referenceRegisterUuid: string) => {
    return [`/generate/${referenceRegisterUuid}`] as const
}

export const useGenerateReferenceRegisterByUuidQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGenerateReferenceRegisterByUuidHook>>>,
    TError = ApiError,
>(
    referenceRegisterUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateReferenceRegisterByUuidHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGenerateReferenceRegisterByUuidQueryKey(referenceRegisterUuid)

    const generateReferenceRegisterByUuid = useGenerateReferenceRegisterByUuidHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGenerateReferenceRegisterByUuidHook>>>> = ({ signal }) =>
        generateReferenceRegisterByUuid(referenceRegisterUuid, signal)

    return { queryKey, queryFn, enabled: !!referenceRegisterUuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGenerateReferenceRegisterByUuidHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GenerateReferenceRegisterByUuidQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGenerateReferenceRegisterByUuidHook>>>>
export type GenerateReferenceRegisterByUuidQueryError = ApiError

export function useGenerateReferenceRegisterByUuid<
    TData = Awaited<ReturnType<ReturnType<typeof useGenerateReferenceRegisterByUuidHook>>>,
    TError = ApiError,
>(
    referenceRegisterUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateReferenceRegisterByUuidHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGenerateReferenceRegisterByUuidQueryOptions(referenceRegisterUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
