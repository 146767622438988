//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * MetaIS BPM Engine
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { useTasksSwaggerClient } from '../hooks/useTasksSwaggerClient'
export type GetTaskByIdParams = {
    id: number
}

export interface TaskList {
    tasks?: Task[]
    tasksCount?: number
    tasksCountCreated?: number
    tasksCountDone?: number
    tasksCountInProgress?: number
}

export interface TaskRequest {
    appId?: string
    ascending?: boolean
    assignedTo?: string[]
    createdBy?: string
    createdFrom?: string
    createdTo?: string
    entityRef?: string
    name?: string
    pageNumber?: number
    perPage?: number
    sortBy?: string
    states?: string[]
}

export type TaskHistoryState = (typeof TaskHistoryState)[keyof typeof TaskHistoryState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskHistoryState = {
    CREATED: 'CREATED',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
} as const

export type TaskHistoryAssigneeType = (typeof TaskHistoryAssigneeType)[keyof typeof TaskHistoryAssigneeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskHistoryAssigneeType = {
    IDENTITY_LOGIN: 'IDENTITY_LOGIN',
    ROLE_UUID: 'ROLE_UUID',
    GID: 'GID',
} as const

export interface TaskHistory {
    activity?: string
    assignedTo?: string
    assigneeType?: TaskHistoryAssigneeType
    changedAt?: string
    changedBy?: string
    state?: TaskHistoryState
}

export interface TaskHistoryList {
    taskHistoryList?: TaskHistory[]
}

export interface TaskDmsRefsList {
    taskDmsRefs?: string[]
}

export type TaskState = (typeof TaskState)[keyof typeof TaskState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskState = {
    CREATED: 'CREATED',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
} as const

export type TaskAssigneeType = (typeof TaskAssigneeType)[keyof typeof TaskAssigneeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskAssigneeType = {
    IDENTITY_LOGIN: 'IDENTITY_LOGIN',
    ROLE_UUID: 'ROLE_UUID',
    GID: 'GID',
} as const

export interface Task {
    appId?: string
    assignedTo?: string
    assigneeType?: TaskAssigneeType
    createdAt?: string
    createdBy?: string
    description?: string
    dmsRefsList?: TaskDmsRefsList
    dueDate?: string
    entityRef?: string
    id?: string
    name?: string
    state?: TaskState
    taskGroupId?: string
    taskHistoryList?: TaskHistoryList
}

export type SimpleTaskAssigneeType = (typeof SimpleTaskAssigneeType)[keyof typeof SimpleTaskAssigneeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SimpleTaskAssigneeType = {
    IDENTITY_LOGIN: 'IDENTITY_LOGIN',
    ROLE_UUID: 'ROLE_UUID',
    GID: 'GID',
} as const

export interface SimpleTask {
    activity?: string
    assignedTo?: string
    assignedToIds?: string[]
    assigneeType?: SimpleTaskAssigneeType
    changedBy?: string
    id?: string
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useReassignTaskWithUserHook = () => {
    const reassignTaskWithUser = useTasksSwaggerClient<void>()

    return useCallback(
        (id: number, simpleTask: SimpleTask) => {
            return reassignTaskWithUser({
                url: `/tasks/${id}/reassign`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: simpleTask,
            })
        },
        [reassignTaskWithUser],
    )
}

export const useReassignTaskWithUserMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReassignTaskWithUserHook>>>,
        TError,
        { id: number; data: SimpleTask },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReassignTaskWithUserHook>>>, TError, { id: number; data: SimpleTask }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const reassignTaskWithUser = useReassignTaskWithUserHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReassignTaskWithUserHook>>>, { id: number; data: SimpleTask }> = (
        props,
    ) => {
        const { id, data } = props ?? {}

        return reassignTaskWithUser(id, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReassignTaskWithUserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReassignTaskWithUserHook>>>>
export type ReassignTaskWithUserMutationBody = SimpleTask
export type ReassignTaskWithUserMutationError = ApiError

export const useReassignTaskWithUser = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useReassignTaskWithUserHook>>>,
        TError,
        { id: number; data: SimpleTask },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReassignTaskWithUserHook>>>, TError, { id: number; data: SimpleTask }, TContext> => {
    const mutationOptions = useReassignTaskWithUserMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReassignTaskHook = () => {
    const reassignTask = useTasksSwaggerClient<void>()

    return useCallback(
        (simpleTask: SimpleTask) => {
            return reassignTask({
                url: `/tasks/reassign`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: simpleTask,
            })
        },
        [reassignTask],
    )
}

export const useReassignTaskMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReassignTaskHook>>>, TError, { data: SimpleTask }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReassignTaskHook>>>, TError, { data: SimpleTask }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const reassignTask = useReassignTaskHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReassignTaskHook>>>, { data: SimpleTask }> = (props) => {
        const { data } = props ?? {}

        return reassignTask(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReassignTaskMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReassignTaskHook>>>>
export type ReassignTaskMutationBody = SimpleTask
export type ReassignTaskMutationError = ApiError

export const useReassignTask = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReassignTaskHook>>>, TError, { data: SimpleTask }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useReassignTaskHook>>>, TError, { data: SimpleTask }, TContext> => {
    const mutationOptions = useReassignTaskMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCloseTaskHook = () => {
    const closeTask = useTasksSwaggerClient<void>()

    return useCallback(
        (simpleTask: SimpleTask) => {
            return closeTask({ url: `/tasks/close`, method: 'PUT', headers: { 'Content-Type': 'application/json;charset=UTF-8' }, data: simpleTask })
        },
        [closeTask],
    )
}

export const useCloseTaskMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCloseTaskHook>>>, TError, { data: SimpleTask }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCloseTaskHook>>>, TError, { data: SimpleTask }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const closeTask = useCloseTaskHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCloseTaskHook>>>, { data: SimpleTask }> = (props) => {
        const { data } = props ?? {}

        return closeTask(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CloseTaskMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCloseTaskHook>>>>
export type CloseTaskMutationBody = SimpleTask
export type CloseTaskMutationError = ApiError

export const useCloseTask = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCloseTaskHook>>>, TError, { data: SimpleTask }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCloseTaskHook>>>, TError, { data: SimpleTask }, TContext> => {
    const mutationOptions = useCloseTaskMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCreateTaskHook = () => {
    const createTask = useTasksSwaggerClient<void>()

    return useCallback(
        (task: Task) => {
            return createTask({ url: `/tasks`, method: 'POST', headers: { 'Content-Type': 'application/json;charset=UTF-8' }, data: task })
        },
        [createTask],
    )
}

export const useCreateTaskMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateTaskHook>>>, TError, { data: Task }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateTaskHook>>>, TError, { data: Task }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const createTask = useCreateTaskHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateTaskHook>>>, { data: Task }> = (props) => {
        const { data } = props ?? {}

        return createTask(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateTaskMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateTaskHook>>>>
export type CreateTaskMutationBody = Task
export type CreateTaskMutationError = ApiError

export const useCreateTask = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateTaskHook>>>, TError, { data: Task }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCreateTaskHook>>>, TError, { data: Task }, TContext> => {
    const mutationOptions = useCreateTaskMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary getTasks
 */
export const useGetTasksHook = () => {
    const getTasks = useTasksSwaggerClient<TaskList>()

    return useCallback(
        (taskRequest: TaskRequest) => {
            return getTasks({ url: `/tasks/list`, method: 'POST', headers: { 'Content-Type': 'application/json;charset=UTF-8' }, data: taskRequest })
        },
        [getTasks],
    )
}

export const useGetTasksMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, { data: TaskRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, { data: TaskRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getTasks = useGetTasksHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, { data: TaskRequest }> = (props) => {
        const { data } = props ?? {}

        return getTasks(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetTasksMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>>
export type GetTasksMutationBody = TaskRequest
export type GetTasksMutationError = ApiError

/**
 * @summary getTasks
 */
export const useGetTasks = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, { data: TaskRequest }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, { data: TaskRequest }, TContext> => {
    const mutationOptions = useGetTasksMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetTaskByIdHook = () => {
    const getTaskById = useTasksSwaggerClient<Task>()

    return useCallback(
        (params: GetTaskByIdParams, signal?: AbortSignal) => {
            return getTaskById({ url: `/tasks/detail`, method: 'GET', params, signal })
        },
        [getTaskById],
    )
}

export const getGetTaskByIdQueryKey = (params: GetTaskByIdParams) => {
    return [`/tasks/detail`, ...(params ? [params] : [])] as const
}

export const useGetTaskByIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>, TError = ApiError>(
    params: GetTaskByIdParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTaskByIdQueryKey(params)

    const getTaskById = useGetTaskByIdHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>> = ({ signal }) => getTaskById(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type GetTaskByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>>
export type GetTaskByIdQueryError = ApiError

export function useGetTaskById<TData = Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>, TError = ApiError>(
    params: GetTaskByIdParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTaskByIdQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
