import { CheckBox } from '@isdd/idsk-ui-kit/checkbox/CheckBox'
import { Button, ButtonLink, PaginatorWrapper } from '@isdd/idsk-ui-kit/index'
import { Table, TableHandle } from '@isdd/idsk-ui-kit/table/Table'
import { CHECKBOX_CELL } from '@isdd/idsk-ui-kit/table/constants'
import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'
import { BASE_PAGE_SIZE, PROJEKT_STANOVISKO } from '@isdd/metais-common/api/constants'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { useGetMeta1Hook } from '@isdd/metais-common/api/generated/dms-swagger'
import styles from '@isdd/metais-common/components/actions-over-table/actionsOverTable.module.scss'
import { BASE_PAGE_NUMBER, DEFAULT_PAGESIZE_OPTIONS, DOCUMENTS, INVALIDATED, PROJECT, ReponseErrorCodeEnum } from '@isdd/metais-common/constants'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { IBulkActionResult, useBulkAction } from '@isdd/metais-common/hooks/useBulkAction'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import {
    useGetDocumentHook,
    useGetProjectDocumentHook,
    useUploadProjectDocument1Hook,
    useUploadProjectDocumentHook,
} from '@isdd/metais-common/api/generated/wiki-swagger'
import {
    ActionsOverTable,
    BulkPopup,
    FileHistoryModal,
    InvalidateBulkModal,
    MutationFeedback,
    ProjectUploadFileModal,
    QueryFeedback,
    UpdateFileModal,
} from '@isdd/metais-common/index'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApiError } from '@isdd/metais-common/api/generated/iam-swagger'
import { Link } from 'react-router-dom'
import { Del } from '@isdd/idsk-ui-kit/typography/Del'
import { useGetFileSize } from '@isdd/metais-common/hooks/useGetFileSize'
import { formatBytes } from '@isdd/metais-common/components/file-import/fileImportUtils'
import { useRowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import { downloadFile, downloadFiles, filterAsync, isInvalid, isMeta } from './utils'

import { IDocType, IView } from '@/components/containers/ProjectDocumentListContainer'
import { IMutationErrors } from '@/components/containers/OlaContractDetailContainer'

export const ProjectDocumentsTable: React.FC<IView> = ({
    isLoading,
    isError,
    docs,
    addButtonSectionName,
    refetch,
    projectData,
    selectPageSize = false,
    setPageSize,
    pageSize,
    page,
    setPage,
    totalLength,
    hiddenColumnsNames,
    templatesMetadata,
}) => {
    const { t } = useTranslation()
    const {
        state: { user, token },
    } = useAuth()
    const isUserAdmin = user?.roles.includes('R_ADMIN')
    const isUserLogged = user !== null
    const isInvalidated = projectData?.metaAttributes?.state === INVALIDATED
    const xWikiDoc = useGetProjectDocumentHook()
    const xWikiSpecialDoc = useGetDocumentHook()
    const uploadxWikiDoc = useUploadProjectDocumentHook()
    const xWikiSpecialDocUpload = useUploadProjectDocument1Hook()

    const DMS_DOWNLOAD_FILE = `${import.meta.env.VITE_REST_CLIENT_DMS_TARGET_URL}/file/`
    const { errorMessage, isBulkLoading } = useBulkAction()
    const [invalidateItems, setInvalidateItems] = useState<ConfigurationItemUi[]>()
    const [deleteItems, setDeleteItems] = useState<ConfigurationItemUi[]>()
    const [updateFile, setUpdateFile] = useState<ConfigurationItemUi>()
    const [bulkActionResult, setBulkActionResult] = useState<IBulkActionResult & IMutationErrors>()
    const [openAddModal, setOpenAddModal] = useState<IDocType>()
    const [singleItemHistory, setSingleItemHistory] = useState<IDocType>()
    const [isLocalLoading, setIsLocalLoading] = useState(false)
    const rowSelection = useRowSelection<IDocType>()
    const tableHandle = useRef<TableHandle>(null)

    const handleCloseBulkModal = (actionResult: IBulkActionResult & IMutationErrors, closeFunction: () => void) => {
        closeFunction()
        refetch()
        setBulkActionResult(actionResult)
        rowSelection.clearSelectedRows()
    }
    const getMeta = useGetMeta1Hook()
    const canManageOpinionDoc = user?.roles.some((role: string) => role === 'KRIS_SCHVAL') ?? false

    const [filesSizes, setFilesSizes] = useState<{ uuid: string; size: string; extension: string; metaComponent: string }[]>([])
    const [templatesSizes, setTemplatesSizes] = useState<{ uuid: string; size: string; extension: string; metaComponent: string }[]>([])

    const { loadData } = useGetFileSize()

    const loadSizeInfo = useCallback(async () => {
        const sizes = await loadData(docs?.filter((d) => !!d.uuid).map((d) => d?.uuid ?? '') ?? [])
        setFilesSizes(sizes)
    }, [docs, loadData])

    useEffect(() => {
        if ((docs?.length != 0 && filesSizes?.length == 0) || docs?.length != filesSizes.length) {
            loadSizeInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docs, filesSizes?.length])

    const loadTemplateSizeInfo = useCallback(async () => {
        const sizes = await loadData(docs?.filter((d) => !!d.templateUuid).map((d) => d?.templateUuid ?? '') ?? [])
        setTemplatesSizes(sizes)
    }, [docs, loadData])

    useEffect(() => {
        if ((docs?.length != 0 && templatesSizes?.length == 0) || docs?.length != filesSizes.length) {
            loadTemplateSizeInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docs, templatesSizes?.length])

    const createAndOpenSpecialDoc = (row: CellContext<IDocType, unknown>, rerenderDynamicContent = false) => {
        setIsLocalLoading(true)
        if (projectData?.uuid && row.cell.row.original.type) {
            xWikiSpecialDoc(PROJECT, projectData.uuid, PROJEKT_STANOVISKO, { createIfNotExists: true, rerenderDynamicContent })
                .then((doc) => doc.xwikiUrl && window.open(doc.xwikiUrl, '_blank'))
                .catch(() => setBulkActionResult({ isSuccess: false, isError: true }))
                .finally(() => setIsLocalLoading(false))
        }
    }

    const uploadSpecialDoc = async (row: CellContext<IDocType, unknown>) => {
        if (projectData?.uuid && row.cell.row.original.type == PROJEKT_STANOVISKO) {
            setIsLocalLoading(true)
            xWikiSpecialDocUpload(PROJECT, projectData?.uuid, PROJEKT_STANOVISKO)
                .then((resp) => {
                    if (resp) {
                        refetch()
                        setBulkActionResult({ isSuccess: true, isError: false, successMessage: t('bulkActions.addFile.success') })
                    }
                })
                .catch((err) => {
                    if (JSON.parse((err as ApiError).message ?? '').type == ReponseErrorCodeEnum.GNR404) {
                        setBulkActionResult({
                            isSuccess: false,
                            isError: true,
                            errorMessage: t('feedback.documentForProjectDoesntExists', {
                                docName: row.cell.row.original.attributes?.Gen_Profil_nazov,
                                projectCode: projectData.attributes?.Gen_Profil_nazov,
                            }),
                        })
                    }
                })
                .finally(() => setIsLocalLoading(false))
        }
    }

    const getDefaultBulkActions = (row: CellContext<IDocType, unknown>, metaComponent: string) => [
        <ButtonLink
            key={'buttonDownload'}
            inPopup
            label={t('actionOverTable.options.download') + metaComponent}
            onClick={async () => {
                const item = docs ? docs[row.row.index] : {}
                const response = await getMeta(item.uuid ?? '')
                if (response) {
                    downloadFile(`${DMS_DOWNLOAD_FILE}${item?.uuid}`, item.name ?? (item?.attributes?.Gen_Profil_nazov as string))
                }
            }}
        />,
        <ButtonLink
            inPopup
            key={'buttonUpdate'}
            label={t('actionOverTable.options.update')}
            disabled={!isUserLogged}
            onClick={() => {
                if (docs) {
                    const item = docs[row.row.index]
                    item && setUpdateFile(item)
                }
            }}
            aria={{ 'aria-haspopup': 'dialog' }}
        />,
        <ButtonLink
            inPopup
            key={'buttonInvalidate'}
            label={t('actionOverTable.options.invalidate')}
            disabled={!isUserLogged}
            onClick={() => {
                if (docs) {
                    const item = docs[row.row.index]
                    item && setInvalidateItems([item])
                }
            }}
            aria={{ 'aria-haspopup': 'dialog' }}
        />,

        <ButtonLink
            inPopup
            key={'buttonDelete'}
            label={t('actionOverTable.options.delete')}
            disabled={!isUserAdmin}
            onClick={() => {
                if (docs) {
                    const item = docs[row.row.index]
                    item && setDeleteItems([item])
                }
            }}
            aria={{ 'aria-haspopup': 'dialog' }}
        />,

        <ButtonLink
            inPopup
            key={'buttonHistory'}
            label={t('actionOverTable.options.history')}
            onClick={() => {
                if (docs) {
                    const item = docs[row.row.index]
                    item && setSingleItemHistory(item)
                }
            }}
            aria={{ 'aria-haspopup': 'dialog' }}
        />,
    ]

    const getAddAction = (row: CellContext<IDocType, unknown>) => [
        <ButtonLink
            inPopup
            key={'buttonDownload'}
            label={t('actionOverTable.options.addDocument')}
            onClick={() => {
                if (docs) {
                    const item = docs[row.row.index]
                    item && setOpenAddModal(item)
                }
            }}
            aria={{ 'aria-haspopup': 'dialog' }}
        />,
    ]

    const getSpecialDocAction = (row: CellContext<IDocType, unknown>) => [
        <ButtonLink key={'buttonOpen'} inPopup label={t('actionOverTable.options.openDocument')} onClick={() => createAndOpenSpecialDoc(row)} />,
        // <ButtonLink
        //     key={'buttonUpdate'}
        //     inPopup
        //     label={t('actionOverTable.options.updateDocument')}
        //     onClick={() => createAndOpenSpecialDoc(row, true)}
        // />,
        <ButtonLink key={'buttonUpload'} inPopup label={t('actionOverTable.options.uploadDocument')} onClick={async () => uploadSpecialDoc(row)} />,
    ]

    const getSpecialDocReadyActions = (row: CellContext<IDocType, unknown>, metaComponent: string) => [
        <ButtonLink
            inPopup
            key={'buttonOpen'}
            label={t('actionOverTable.options.openDocument')}
            onClick={() => createAndOpenSpecialDoc(row)}
            hidden={!canManageOpinionDoc}
        />,
        // <ButtonLink
        //     inPopup
        //     key={'buttonUpdate'}
        //     label={t('actionOverTable.options.updateDocument')}
        //     onClick={() => createAndOpenSpecialDoc(row, true)}
        //     hidden={!canManageOpinionDoc}
        // />,
        <ButtonLink
            key={'buttonDownload'}
            inPopup
            label={t('actionOverTable.options.download') + metaComponent}
            onClick={async () => {
                const item = docs ? docs[row.row.index] : {}
                const response = await getMeta(item.uuid ?? '')
                if (response) {
                    downloadFile(`${DMS_DOWNLOAD_FILE}${item?.uuid}`, item.name ?? (item?.attributes?.Gen_Profil_nazov as string))
                }
            }}
        />,
        <ButtonLink
            inPopup
            key={'buttonInvalidate'}
            label={t('actionOverTable.options.invalidate')}
            disabled={!isUserLogged}
            onClick={() => {
                if (docs) {
                    const item = docs[row.row.index]
                    item && setInvalidateItems([item])
                }
            }}
            hidden={!canManageOpinionDoc}
            aria={{ 'aria-haspopup': 'dialog' }}
        />,
        <ButtonLink
            inPopup
            key={'buttonDelete'}
            label={t('actionOverTable.options.delete')}
            disabled={!isUserAdmin}
            onClick={() => {
                if (docs) {
                    const item = docs[row.row.index]
                    item && setDeleteItems([item])
                }
            }}
            hidden={!canManageOpinionDoc}
            aria={{ 'aria-haspopup': 'dialog' }}
        />,
    ]

    const createAndOpenXWikiDoc = (row: CellContext<IDocType, unknown>, rerenderDynamicContent = false) => {
        setIsLocalLoading(true)
        if (projectData?.uuid && row.cell.row.original.id) {
            xWikiDoc(projectData?.uuid, row.cell.row.original.id, { createIfNotExists: true, rerenderDynamicContent })
                .then((doc) => doc.xwikiUrl && window.open(doc.xwikiUrl, '_blank'))
                .catch(() => setBulkActionResult({ isSuccess: false, isError: true }))
                .finally(() => setIsLocalLoading(false))
        }
    }

    const uploadXWikiDoc = (row: CellContext<IDocType, unknown>) => {
        setIsLocalLoading(true)

        if (projectData?.uuid && row.cell.row.original.id)
            uploadxWikiDoc(
                projectData?.uuid,
                row.cell.row.original.id,
                ...(row.cell.row.original.uuid ? [{ documentUuid: row.cell.row.original.uuid }] : []),
            )
                .then((resp) => {
                    if (resp) {
                        refetch()
                        setBulkActionResult({ isSuccess: true, isError: false, successMessage: t('bulkActions.addFile.success') })
                    }
                })
                .catch((err) => {
                    if (JSON.parse((err as ApiError).message ?? '').type == ReponseErrorCodeEnum.GNR404) {
                        setBulkActionResult({
                            isSuccess: false,
                            isError: true,
                            errorMessage: t('feedback.documentForProjectDoesntExists', {
                                docName: row.cell.row.original.name,
                                projectCode: projectData.attributes?.Gen_Profil_nazov,
                            }),
                        })
                    }
                })
                .finally(() => setIsLocalLoading(false))
    }

    const getConfluenceActions = (row: CellContext<IDocType, unknown>) => [
        <ButtonLink inPopup key={'buttonOpen'} label={t('actionOverTable.options.openDocument')} onClick={() => createAndOpenXWikiDoc(row)} />,
        // <ButtonLink
        //     inPopup
        //     key={'buttonOpen'}
        //     label={t('actionOverTable.options.updateDocument')}
        //     onClick={() => createAndOpenXWikiDoc(row, true)}
        // />,
        <ButtonLink inPopup key={'buttonUpload'} label={t('actionOverTable.options.uploadDocument')} onClick={async () => uploadXWikiDoc(row)} />,
    ]

    const getTemplateActions = (row: CellContext<IDocType, unknown>, closePopup: () => void, metaTemplateComponent?: string) => [
        <ButtonLink
            inPopup
            key={'buttonDownloadTemplate'}
            label={`${t('actionOverTable.options.downloadTemplate')} ${metaTemplateComponent}`}
            onClick={async () => {
                const response = await getMeta(row.cell.row.original.templateUuid ?? '')
                if (response) {
                    downloadFile(`${DMS_DOWNLOAD_FILE}${row.cell.row.original.templateUuid}`, response.filename ?? 'template')
                    closePopup()
                }
            }}
        />,
    ]

    const resolveAction = (
        row: CellContext<IDocType, unknown>,
        closePopup: () => void,
        metaComponent: string,
        metaTemplateComponent: string,
    ): JSX.Element[] => {
        let actions: JSX.Element[] = getDefaultBulkActions(row, metaComponent)
        if (row.cell.row.original.type == PROJEKT_STANOVISKO) {
            actions = getSpecialDocAction(row)
        } else if (row.cell.row.original.attributes?.Profil_Dokument_Wiki_typ_dokumentu == PROJEKT_STANOVISKO) {
            actions = getSpecialDocReadyActions(row, metaComponent)
        } else if (row.cell.row.original.uuid == undefined && !row.cell.row.original.confluence) {
            actions = getAddAction(row)
        } else if (row.cell.row.original.confluence) {
            actions = getConfluenceActions(row)
        } else if (row.cell.row.original.templateUuid) {
            actions = [...actions, ...getTemplateActions(row, closePopup, metaTemplateComponent)]
        }
        return actions
    }
    const columns: Array<ColumnDef<IDocType>> = [
        {
            accessorKey: 'selected',
            header: ({ table }) => (
                <div className="govuk-checkboxes govuk-checkboxes--small">
                    <CheckBox
                        label=""
                        name="checkbox"
                        id="checkbox-all"
                        onChange={table.getToggleAllRowsSelectedHandler()}
                        checked={table.getIsAllRowsSelected()}
                        aria-label={t('table.selectAllItems')}
                    />
                </div>
            ),
            size: 20,
            id: CHECKBOX_CELL,
            cell: ({ row }) =>
                row.original.uuid && (
                    <div className="govuk-checkboxes govuk-checkboxes--small">
                        <CheckBox
                            disabled={!row.original.uuid}
                            label=""
                            name="checkbox"
                            id={`checkbox_${row.id}`}
                            onChange={row.getToggleSelectedHandler()}
                            checked={row.getIsSelected()}
                            aria-label={t('table.selectItem', { itemName: row.original.name ?? row.original?.attributes?.Gen_Profil_nazov })}
                        />
                    </div>
                ),
        },
        {
            accessorFn: (row) => (row?.name != undefined ? row.name : row?.attributes?.Gen_Profil_nazov),
            header: t('documentsTab.table.name'),
            id: 'documentsTab.table.name',
            size: 300,
            meta: {
                getCellContext: (ctx: CellContext<IDocType, unknown>) => ctx?.getValue?.() as string,
            },
            cell: (row) => {
                const docMeta = filesSizes.find((f) => f.uuid == row.row.original?.uuid)
                return row.cell.row.original?.uuid ? (
                    <Del isInvalid={isInvalid(row.cell.row)}>
                        <ButtonLink
                            inTable
                            className={styles.buttonLinkWithIcon}
                            key="downloadFile"
                            label={
                                (row.getValue() as string) +
                                ' ' +
                                (docMeta?.metaComponent ?? '') +
                                ' ' +
                                (row.cell.row.original.required ? t('input.requiredField') : '')
                            }
                            onClick={async () => {
                                const item = docs ? docs[row.row.index] : {}
                                const response = await getMeta(item.uuid ?? '')
                                if (response) {
                                    downloadFile(`${DMS_DOWNLOAD_FILE}${item?.uuid}`, item.name ?? (item?.attributes?.Gen_Profil_nazov as string))
                                }
                            }}
                        />
                    </Del>
                ) : (
                    <>
                        {(row.getValue() as string) + ' ' + (row.cell.row.original.required ? t('input.requiredField') : '')}
                        {docMeta?.metaComponent ?? ''}
                    </>
                )
            },
        },
        {
            accessorFn: (row) => row?.metaAttributes?.state,
            header: t('documentsTab.table.evidenceStatus'),
            id: 'state',
            size: 100,

            meta: {
                getCellContext: (ctx: CellContext<IDocType, unknown>) => ctx.getValue() && t(`metaAttributes.state.${ctx.getValue()}`),
            },
            cell: (row) => row.getValue() && (t(`metaAttributes.state.${row.getValue()}`) as string),
        },
        {
            accessorFn: (row) => row?.metaAttributes?.createdAt,
            header: t('documentsTab.table.createdAt'),
            id: 'documentsTab.table.createdAt',
            size: 100,
            cell: (ctx) => t('dateTime', { date: ctx.getValue() as string }),
        },

        {
            accessorFn: (row) => row?.metaAttributes?.createdBy,
            header: t('documentsTab.table.createdBy'),
            id: 'documentsTab.table.createdBy',
            size: 100,
        },
        {
            accessorFn: (row) => row?.metaAttributes?.lastModifiedAt,
            header: t('documentsTab.table.lastModifiedAt'),
            id: 'documentsTab.table.lastModifiedAt',
            size: 100,
            cell: (ctx) => t('dateTime', { date: ctx.getValue() as string }),
        },

        {
            accessorFn: (row) => row?.metaAttributes?.lastModifiedBy,
            header: t('documentsTab.table.lastModifiedBy'),
            id: 'documentsTab.table.lastModifiedBy',
            size: 100,
        },
        {
            header: t('documentsTab.table.template'),
            accessorFn: (row) => row?.templateUuid,
            enableSorting: true,
            id: 'template',
            cell: (ctx) =>
                !!ctx?.getValue() && (
                    <Link to={`${DMS_DOWNLOAD_FILE}${ctx?.getValue()}`} state={{ from: location }} target="_blank" className="govuk-link">
                        {templatesMetadata?.find((tm) => tm?.uuid == ctx?.getValue())?.filename &&
                            `${templatesMetadata?.find((tm) => tm?.uuid == ctx?.getValue())?.filename} (${formatBytes(
                                templatesMetadata?.find((tm) => tm?.uuid == ctx?.getValue())?.contentLength ?? 0,
                            )})`}
                    </Link>
                ),
        },
        {
            header: t('fileHistory.contentLength'),
            id: 'contentLength',
            accessorKey: 'contentLength',
            cell: (row) => {
                return filesSizes.find((f) => f.uuid == row.row.original?.uuid)?.size
            },
        },
        {
            accessorKey: 'bulkActions',
            header: t('documentsTab.table.actions'),
            id: 'bulkActions',
            size: 120,
            cell: (row) => {
                const docMeta = filesSizes.find((f) => f.uuid == row.row.original?.uuid)
                const templateMeta = templatesSizes.find((f) => f.uuid == row.row.original?.templateUuid)

                return (
                    <BulkPopup
                        popupPosition="right"
                        label={t('actionOverTable.options.title')}
                        items={(closePopup) => resolveAction(row, closePopup, docMeta?.metaComponent ?? '', templateMeta?.metaComponent ?? '')}
                    />
                )
            },
        },
    ]

    let filteredColumns = isUserLogged
        ? columns
        : columns
              .filter((column) => column.id != 'documentsTab.table.lastModifiedBy')
              .filter((column) => column.id != 'documentsTab.table.createdBy')
              .filter((column) => column.id != 'bulkActions')

    if (hiddenColumnsNames) {
        filteredColumns = filteredColumns.filter((c) => !hiddenColumnsNames.includes(c.id ?? ''))
    }

    const { wrapperRef, scrollToMutationFeedback } = useScroll()

    useEffect(() => {
        if (bulkActionResult?.isError || bulkActionResult?.isSuccess) {
            scrollToMutationFeedback()
        }
    }, [bulkActionResult, scrollToMutationFeedback])

    useEffect(() => {
        tableHandle.current?.refreshColumns()
    }, [isLoading, docs, templatesMetadata])

    return (
        <QueryFeedback loading={isLoading || isBulkLoading || isLocalLoading} error={isError} indicatorProps={{ layer: 'parent' }} withChildren>
            <div ref={wrapperRef}>
                <MutationFeedback
                    success={bulkActionResult?.isSuccess}
                    successMessage={bulkActionResult?.successMessage}
                    error={bulkActionResult?.isError}
                    errorMessage={bulkActionResult?.errorMessage}
                    mutationProcessingError={bulkActionResult?.isProcessedError}
                    mutationTooLong={bulkActionResult?.isTooLongError}
                    onMessageClose={() => setBulkActionResult(undefined)}
                />
            </div>
            <ActionsOverTable
                pagination={{
                    pageNumber: page ?? BASE_PAGE_NUMBER,
                    pageSize: pageSize ?? BASE_PAGE_SIZE,
                    dataLength: totalLength ?? 0,
                }}
                handleFilterChange={(filter) => {
                    if (setPageSize) setPageSize(filter.pageSize ?? BASE_PAGE_SIZE)
                    if ((page ?? 0) * (filter.pageSize ?? BASE_PAGE_SIZE) > (totalLength ?? 0)) {
                        setPage && setPage(BASE_PAGE_NUMBER)
                    }
                }}
                pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                entityName={DOCUMENTS}
                hiddenButtons={{ SELECT_COLUMNS: true, PAGING: !selectPageSize }}
                createButton={
                    !!addButtonSectionName && (
                        <Button
                            disabled={isInvalidated}
                            label={t('documentsTab.addNewDocument')}
                            onClick={() => setOpenAddModal({})}
                            className={styles.bottomMargin0}
                        />
                    )
                }
                selectedRowsCount={rowSelection.count}
                bulkPopup={({ selectedRowsCount }) => (
                    <Tooltip
                        descriptionElement={errorMessage}
                        position={'center center'}
                        tooltipContent={() => (
                            <BulkPopup
                                checkedRowItems={selectedRowsCount}
                                items={(closePopup) => [
                                    <ButtonLink
                                        inPopup
                                        key={'downloadItems'}
                                        label={t('actionOverTable.options.download')}
                                        disabled={!isUserLogged}
                                        onClick={async () => {
                                            const filtered = await filterAsync(rowSelection.list, async (item) => {
                                                const meta = await isMeta(item.uuid ?? '', token)
                                                return meta
                                            })

                                            if (filtered.length != rowSelection.count) {
                                                setBulkActionResult({ isError: true, isSuccess: false, successMessage: '' })
                                            } else {
                                                setBulkActionResult(undefined)
                                            }
                                            downloadFiles(
                                                filtered.map((item) => ({
                                                    link: `${DMS_DOWNLOAD_FILE}${item?.uuid}`,
                                                    fileName: item.name ?? String(item?.attributes?.Gen_Profil_nazov),
                                                })),
                                            )
                                            closePopup()
                                        }}
                                    />,
                                    <ButtonLink
                                        inPopup
                                        key={'buttonInvalidateItems'}
                                        label={t('actionOverTable.invalidateItems')}
                                        disabled={!isUserLogged}
                                        onClick={() => {
                                            setInvalidateItems(rowSelection.list)
                                        }}
                                        aria={{ 'aria-haspopup': 'dialog' }}
                                    />,
                                    <ButtonLink
                                        inPopup
                                        key={'buttonDeleteItems'}
                                        label={t('actionOverTable.deleteItems')}
                                        disabled={!isUserAdmin}
                                        onClick={() => {
                                            setDeleteItems(rowSelection.list)
                                        }}
                                        aria={{ 'aria-haspopup': 'dialog' }}
                                    />,
                                ]}
                            />
                        )}
                    />
                )}
            />
            <Table
                handleRef={tableHandle}
                rowSelection={rowSelection.rowSelection}
                onRowSelectionChange={rowSelection.getOnRowSelectionChange({
                    pageNumber: 1,
                    mapper: (selectedUuid) => docs?.find((doc) => doc.uuid === selectedUuid) ?? null,
                })}
                getRowId={(originalRow) => `${originalRow.uuid}`}
                columns={filteredColumns}
                data={docs}
            />
            {selectPageSize && (
                <PaginatorWrapper
                    pageNumber={page ?? 0}
                    pageSize={pageSize ?? BASE_PAGE_SIZE}
                    handlePageChange={(filter) => {
                        setPage && setPage(filter.pageNumber ?? BASE_PAGE_NUMBER)
                    }}
                    dataLength={totalLength ?? 0}
                />
            )}
            {singleItemHistory && <FileHistoryModal item={singleItemHistory} onClose={() => setSingleItemHistory(undefined)} />}
            {updateFile && (
                <UpdateFileModal
                    addButtonSectionName={!!addButtonSectionName && addButtonSectionName != ' ' ? addButtonSectionName : undefined}
                    item={updateFile}
                    open
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, () => setUpdateFile(undefined))}
                    onClose={() => setUpdateFile(undefined)}
                />
            )}
            {invalidateItems && (
                <InvalidateBulkModal
                    items={invalidateItems}
                    open
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, () => setInvalidateItems(undefined))}
                    onClose={() => setInvalidateItems(undefined)}
                />
            )}
            {deleteItems && (
                <InvalidateBulkModal
                    items={deleteItems}
                    deleteFile
                    open
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, () => setDeleteItems(undefined))}
                    onClose={() => setDeleteItems(undefined)}
                />
            )}
            {openAddModal && (
                <ProjectUploadFileModal
                    project={projectData}
                    docNumber={String(docs?.length) ?? '0'}
                    addButtonSectionName={addButtonSectionName}
                    item={openAddModal}
                    open
                    onClose={() => setOpenAddModal(undefined)}
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, () => setOpenAddModal(undefined))}
                />
            )}
        </QueryFeedback>
    )
}
