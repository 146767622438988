import { yupResolver } from '@hookform/resolvers/yup'
import {
    AccordionContainer,
    Button,
    ButtonGroupRow,
    ButtonLink,
    ErrorBlock,
    IAccordionSection,
    IOption,
    SelectWithGroupedOptions,
    TextHeading,
} from '@isdd/idsk-ui-kit/index'
import { ISection } from '@isdd/idsk-ui-kit/stepper/StepperSection'
import { MetaAttributesState } from '@isdd/idsk-ui-kit/types'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api/constants'
import {
    ConfigurationItemUi,
    FilterAttributesUi,
    GraphRequestUi,
    IncidentRelationshipSetUi,
    useReadRelationshipsHook,
    useStoreGraph,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { Attribute, AttributeProfile, useGenerateCodeHook, useGetRelationshipTypeHook } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { SelectPublicAuthorityAndRole } from '@isdd/metais-common/common/SelectPublicAuthorityAndRole'
import { ElementToScrollTo } from '@isdd/metais-common/components/element-to-scroll-to/ElementToScrollTo'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { SubHeading } from '@isdd/metais-common/components/sub-heading/SubHeading'
import { JOIN_OPERATOR, metaisEmail } from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import {
    useInvalidateCiHistoryListCache,
    useInvalidateCiNeighboursWithAllRelsCacheByUuid,
    useInvalidateRefIdentifiersCache,
    useInvalidateRelationsCountCache,
} from '@isdd/metais-common/hooks/invalidate-cache'
import { useAbilityContextWithFeedback } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'
import { MutationFeedback, QueryFeedback, SubmitWithFeedback } from '@isdd/metais-common/index'
import { Languages } from '@isdd/metais-common/localization/languages'
import { isObjectEmpty } from '@isdd/metais-common/utils/utils'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { FieldErrors, FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { MultiValue } from 'react-select'
import { v4 as uuidV4 } from 'uuid'
import { object, ObjectSchema } from 'yup'

import styles from './newRelationView.module.scss'
import { RelationStepperWrapper } from './RelationStepperWrapper'

import { ColumnsOutputDefinition } from '@/componentHelpers/ci/ciTableHelpers'
import { createSelectRelationTypeOptions, SELECT_REL_TYPE_GROUP } from '@/componentHelpers/new-relation'
import { AttributeInput } from '@/components/attribute-input/AttributeInput'
import { generateFormSchema } from '@/components/create-entity/createCiEntityFormSchema'
import {
    findAttributeConstraint,
    getAttributeInputErrorMessage,
    getAttributesInputErrorMessage,
    getAttributeUnits,
} from '@/components/create-entity/createEntityHelpers'
import { SelectCiItem } from '@/components/select-ci-item/SelectCiItem'
import { INewCiRelationData, ISelectedRelationTypeState } from '@/hooks/useNewCiRelation.hook'
import { PublicAuthorityState, RoleState } from '@/hooks/usePublicAuthorityAndRole.hook'

interface Props {
    ciItemData: ConfigurationItemUi | undefined
    relationData: INewCiRelationData | undefined
    selectedRelationTypeState: ISelectedRelationTypeState
    publicAuthorityState: PublicAuthorityState
    roleState: RoleState
    tabName: string
    entityId: string
    entityName: string
    ownerGid: string
    isLoading: boolean
    isError: boolean
    attributesFilter: FilterAttributesUi[]
}

export const NewRelationView: React.FC<Props> = ({
    ciItemData,
    tabName,
    entityId,
    entityName,
    relationData,
    selectedRelationTypeState,
    ownerGid,
    publicAuthorityState,
    roleState,
    isLoading,
    isError,
    attributesFilter,
}) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const { ability, isLoading: isAbilityLoading } = useAbilityContextWithFeedback()
    const canCreateRelationType = ability?.can(Actions.CREATE, `ci.create.newRelationType`)

    const [hasReset, setHasReset] = useState(false)
    const [hasErrors, setErrors] = useState<FieldErrors>({})
    const [hasMutationError, setHasMutationError] = useState(false)
    const location = useLocation()

    const { setIsActionSuccess } = useActionSuccess()

    const [selectedRelTypeOption, setSelectedRelTypeOption] = useState<IOption<string>>()

    const [selectedItems, setSelectedItems] = useState<ConfigurationItemUi | MultiValue<ConfigurationItemUi> | ColumnsOutputDefinition | null>(null)
    const [isOpen, setIsOpen] = useState(false)

    const relatedListAsSources = useMemo(() => relationData?.relatedListAsSources, [relationData])
    const relatedListAsTargets = useMemo(() => relationData?.relatedListAsTargets, [relationData])
    const constraintsData = useMemo(() => {
        return relationData?.constraintsData ?? []
    }, [relationData?.constraintsData])

    const unitsData = relationData?.unitsData
    const relationSchema = relationData?.relationTypeData

    const generateSchema = useMemo(
        () =>
            selectedItems && Array.isArray(selectedItems)
                ? selectedItems.map((item: ConfigurationItemUi) => {
                      return generateFormSchema(
                          [relationSchema as AttributeProfile, ...(relationSchema?.attributeProfiles ?? [])],
                          t,
                          i18n.language,
                          null,
                          relationSchema?.technicalName,
                          {},
                          item.uuid ?? '',
                      )
                  })
                : [],
        [i18n.language, relationSchema, selectedItems, t],
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const combinedSchema: ObjectSchema<any> = useMemo(
        () => generateSchema.reduce((acc, schema) => acc.concat(schema), object().shape({})),
        [generateSchema],
    )

    const {
        register,
        handleSubmit: handleFormSubmit,
        formState: { errors, isSubmitted },
        setValue,
        clearErrors,
        trigger,
        control,
        getValues,
    } = useForm({
        resolver: yupResolver(combinedSchema),
    })

    useEffect(() => {
        isSubmitted && trigger()
    }, [isSubmitted, t, trigger])

    const relationSchemaCombinedAttributes = [...(relationSchema?.attributes ?? [])]

    const existingValidRelations = {
        startRelationshipSet: relationData?.readRelationShipsData?.startRelationshipSet?.filter(
            (relation) => relation.metaAttributes?.state === MetaAttributesState.DRAFT,
        ),
        endRelationshipSet: relationData?.readRelationShipsData?.endRelationshipSet?.filter(
            (relation) => relation.metaAttributes?.state === MetaAttributesState.DRAFT,
        ),
    }

    const { selectedRelationTypeTechnicalName, setSelectedRelationTypeTechnicalName } = selectedRelationTypeState

    const areSelectedItems = Array.isArray(selectedItems) ? selectedItems.length > 0 : false
    const isSubmitDisabled =
        !selectedRelationTypeTechnicalName ||
        !roleState.selectedRole ||
        !publicAuthorityState.selectedPublicAuthority ||
        !ownerGid ||
        !areSelectedItems

    const currentName =
        i18n.language == Languages.SLOVAK
            ? (ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string)
            : (ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov] as string)

    const relationTypeOptions = useMemo(() => {
        return createSelectRelationTypeOptions({
            relatedListAsSources,
            relatedListAsTargets,
            t,
        })
    }, [relatedListAsSources, relatedListAsTargets, t])

    useEffect(() => {
        if (!selectedRelationTypeTechnicalName) {
            if (relationTypeOptions[0].options && relationTypeOptions[0].options.length > 0) {
                setSelectedRelTypeOption(relationTypeOptions[0].options[0])
                setSelectedRelationTypeTechnicalName(relationTypeOptions[0].options[0]?.value)
            } else if (relationTypeOptions[1].options && relationTypeOptions[1].options.length > 0) {
                setSelectedRelTypeOption(relationTypeOptions[1].options[0])
                setSelectedRelationTypeTechnicalName(relationTypeOptions[1].options[0]?.value)
            }
        }
    }, [relationTypeOptions, selectedRelationTypeTechnicalName, setSelectedRelationTypeTechnicalName, t])

    const {
        getRequestStatus,
        isLoading: isRequestStatusLoading,
        isError: isRequestStatusError,
        isProcessedError,
        isTooManyFetchesError,
    } = useGetStatus()

    const invalidateRelationsCountCache = useInvalidateRelationsCountCache()
    const invalidateRelationListCacheByUuid = useInvalidateCiNeighboursWithAllRelsCacheByUuid(entityId)
    const { invalidate: invalidateHistoryListCache } = useInvalidateCiHistoryListCache()
    const getRelTypeByName = useGetRelationshipTypeHook()
    const invalidateRelation = useInvalidateRefIdentifiersCache(entityId)
    const onStoreGraphSuccess = () => {
        setIsActionSuccess({ value: true, path: `/ci/${entityName}/${entityId}`, additionalInfo: { type: 'relationCreated' } })
        navigate(`/ci/${entityName}/${entityId}`, { state: { from: location } })
        setIsOpen(false)
        setSelectedItems(null)
        invalidateHistoryListCache(entityId)
        invalidateRelationListCacheByUuid.invalidate()
        invalidateRelationsCountCache.invalidate(entityId)
        invalidateRelation.invalidate()
    }

    const storeGraph = useStoreGraph({
        mutation: {
            onSuccess(data) {
                getRequestStatus(data.requestId ?? '', onStoreGraphSuccess)
            },
            onError() {
                setHasMutationError(true)
            },
        },
    })

    const handleSubmit = (formData: FieldValues) => {
        setHasMutationError(false)
        const splittedFormData = Object.keys(formData)
            .map((key) => key.split(JOIN_OPERATOR))
            .map((item) => ({ name: item[0], id: item[1] }))

        const profileAtt: Attribute[] = []

        const first =
            selectedItems && Array.isArray(selectedItems)
                ? selectedItems.map((item: ConfigurationItemUi) => {
                      relationSchema?.attributeProfiles && Array.isArray(relationSchema?.attributeProfiles)
                          ? relationSchema?.attributeProfiles.map((profile: AttributeProfile) => {
                                profile.attributes?.map((att: Attribute) => {
                                    profileAtt.push({
                                        ...splittedFormData
                                            .filter((key) => key.name === att.technicalName)
                                            .map((key) => {
                                                const value = getValues(key.name + JOIN_OPERATOR + key.id + JOIN_OPERATOR + item.uuid)
                                                return {
                                                    name: key.name,
                                                    value: value === '' ? null : value,
                                                }
                                            })[0],
                                    })
                                })
                            })
                          : []

                      return {
                          type: selectedRelationTypeTechnicalName,
                          attributes: [
                              ...splittedFormData
                                  .filter((key) => key.id == item.uuid)
                                  .map((key) => ({ name: key.name, value: formData[key.name + JOIN_OPERATOR + key.id] }))
                                  .filter((profAtt) => !isObjectEmpty(profAtt)),
                              ...profileAtt.filter((profAtt) => !isObjectEmpty(profAtt)),
                          ],
                          //uuid of picked entities
                          startUuid: selectedRelTypeOption?.group === SELECT_REL_TYPE_GROUP.source ? entityId : item.uuid,
                          //id of current entity
                          endUuid: selectedRelTypeOption?.group === SELECT_REL_TYPE_GROUP.source ? item.uuid : entityId,
                          //from getGroup Api
                          owner: ownerGid,
                          uuid: uuidV4(),
                      }
                  })
                : []

        const data = {
            storeSet: {
                relationshipSet: [...first],
            },
        } as GraphRequestUi

        storeGraph.mutateAsync({ data })
    }

    const handleReset = () => {
        setIsOpen(false)
        setSelectedItems(null)
        navigate(`/ci/${entityName}/${entityId}`, { state: { from: location } })
    }

    const getSections = (itemId: string): ISection[] => {
        return relationSchema?.attributeProfiles && Array.isArray(relationSchema?.attributeProfiles)
            ? relationSchema?.attributeProfiles?.map((profile: AttributeProfile, index) => {
                  return {
                      title: (i18n.language === Languages.SLOVAK ? profile.description : profile.engDescription) ?? profile.name ?? '',
                      error: getAttributesInputErrorMessage(profile.attributes ?? [], errors),
                      stepLabel: { label: (index + 1).toString(), variant: 'circle' },
                      id: profile.id + JOIN_OPERATOR + itemId,
                      last: relationSchema?.attributeProfiles?.length === index + 1 ? true : false,
                      isOpen: (relationSchema?.attributeProfiles?.at(index)?.attributes?.length ?? 0) > 0,
                      content: profile.attributes?.map((attribute) => {
                          const nameSuffix = JOIN_OPERATOR + profile.id + JOIN_OPERATOR + itemId
                          return (
                              attribute?.valid &&
                              !attribute.invisible && (
                                  <AttributeInput
                                      key={`${attribute?.id}+${profile.id}+${itemId}`}
                                      attribute={attribute ?? {}}
                                      register={register}
                                      setValue={setValue}
                                      clearErrors={clearErrors}
                                      trigger={trigger}
                                      isSubmitted={isSubmitted}
                                      error={getAttributeInputErrorMessage(attribute ?? {}, errors, nameSuffix)}
                                      nameSuffix={nameSuffix}
                                      hasResetState={{ hasReset, setHasReset }}
                                      constraints={findAttributeConstraint(
                                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                          //@ts-ignore
                                          attribute?.constraints?.map((att: AttributeConstraintEnumAllOf) => att.enumCode ?? '') ?? [],
                                          constraintsData,
                                      )}
                                      unitsData={attribute?.units ? getAttributeUnits(attribute.units ?? '', unitsData) : undefined}
                                      control={control}
                                  />
                              )
                          )
                      }),
                  }
              })
            : []
    }
    interface CiRelErrorPair {
        ci: ConfigurationItemUi
        error: string
    }
    const [relsErrors, setRelsErrors] = useState<(CiRelErrorPair | undefined)[]>([])

    const sectionsNew: IAccordionSection[] =
        selectedItems && Array.isArray(selectedItems)
            ? selectedItems
                  .filter((item) => !relsErrors.find((re) => re?.ci.uuid === item.uuid))
                  .map((item: ConfigurationItemUi) => {
                      const relsError = !!relsErrors.find((re) => re?.ci.uuid == item.uuid)?.error
                      const isAccordionSectionError = Object.keys(errors).find((i) => i.includes(item.uuid ?? ''))

                      return {
                          error: relsError || !!isAccordionSectionError,
                          title: item.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string,
                          onLoadOpen: relationSchemaCombinedAttributes.filter((attr) => attr.valid && !attr.invisible).length > 0,
                          canExpand: relationSchemaCombinedAttributes.filter((attr) => attr.valid && !attr.invisible).length > 0,
                          summary: (
                              <ButtonGroupRow key={item.uuid} className={styles.accordionButtonDiv}>
                                  <ButtonLink
                                      type="button"
                                      label={t('newRelation.detailButton')}
                                      className={classNames(styles.buttonLink, styles.blue)}
                                      onClick={() => {
                                          window.open(`/ci/${tabName}/${item.uuid}`, '_blank')
                                      }}
                                  />
                                  <ButtonLink
                                      label={t('newRelation.deleteButton')}
                                      className={classNames(styles.buttonLink, styles.red)}
                                      onClick={() => {
                                          setSelectedItems(
                                              (prev: ConfigurationItemUi | MultiValue<ConfigurationItemUi> | ColumnsOutputDefinition | null) =>
                                                  Array.isArray(prev)
                                                      ? prev.filter((prevItem: ConfigurationItemUi) => prevItem.uuid !== item.uuid)
                                                      : prev,
                                          )
                                          setRelsErrors(
                                              relsErrors.filter((re) =>
                                                  (selectedItems as MultiValue<ConfigurationItemUi>).map((si) => si.uuid).includes(re?.ci.uuid),
                                              ),
                                          )
                                      }}
                                  />
                              </ButtonGroupRow>
                          ),
                          content: (
                              <>
                                  {relationSchemaCombinedAttributes.map(
                                      (attribute) =>
                                          attribute?.valid &&
                                          !attribute.invisible && (
                                              <AttributeInput
                                                  key={`${attribute?.id}+${item.uuid}`}
                                                  attribute={attribute ?? {}}
                                                  register={register}
                                                  setValue={setValue}
                                                  clearErrors={clearErrors}
                                                  trigger={trigger}
                                                  isSubmitted={isSubmitted}
                                                  error={getAttributeInputErrorMessage(attribute ?? {}, errors)}
                                                  nameSuffix={JOIN_OPERATOR + item.uuid}
                                                  hint={attribute?.description}
                                                  hasResetState={{ hasReset, setHasReset }}
                                                  constraints={findAttributeConstraint(
                                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                      //@ts-ignore
                                                      attribute?.constraints?.map((att: AttributeConstraintEnumAllOf) => att.enumCode ?? '') ?? [],
                                                      constraintsData,
                                                  )}
                                                  unitsData={attribute?.units ? getAttributeUnits(attribute.units ?? '', unitsData) : undefined}
                                                  control={control}
                                                  disabled={attribute.technicalName == ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais}
                                              />
                                          ),
                                  )}
                                  {getSections(item.uuid ?? '').length > 0 && (
                                      <RelationStepperWrapper data={getSections(item.uuid ?? '')} errors={hasErrors} setErrors={setErrors} />
                                  )}
                              </>
                          ),
                      }
                  })
            : []

    const onError = (err: FieldErrors) => {
        setErrors(err)
    }

    const isQueryError = [storeGraph.isError, isRequestStatusError, isProcessedError, isTooManyFetchesError].some((item) => item)
    const isQueryLoading = [isLoading, storeGraph.isLoading, isRequestStatusLoading].some((item) => item)

    const generateRelCode = useGenerateCodeHook()
    const setRelMetaisCode = async (val: ConfigurationItemUi[]) => {
        const relTechName = relationData?.relationTypeData?.technicalName
        const hasGenRelCodeAttribute = relationSchema?.attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais)
        if (!relTechName || !hasGenRelCodeAttribute) return

        const formValues = getValues()
        val.forEach(async (item) => {
            const itemRelCodeInForm = `${ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais}${JOIN_OPERATOR}${item.uuid}`
            if (!formValues[itemRelCodeInForm]) {
                const code = await generateRelCode(relTechName)
                setValue(itemRelCodeInForm, code.code)
            }
        })
    }

    const readSourceRelations = useReadRelationshipsHook()
    const [relsLoading, setRelsLoading] = useState(false)
    const checkRels = async (
        currentCi: ConfigurationItemUi,
        selectedCi: ConfigurationItemUi,
        relTechName: string,
        selectedCisCount = 1,
    ): Promise<CiRelErrorPair | undefined> => {
        setRelsErrors([])
        const relType = await getRelTypeByName(relTechName)
        const isCurrentIsTarget = relType?.targets?.map((tar) => tar.technicalName).includes(currentCi?.type)
        const error = readSourceRelations(selectedCi.uuid ?? '').then(async (rels) => {
            const currRels = await readSourceRelations(currentCi.uuid ?? '')
            const currentCiRels: IncidentRelationshipSetUi = {
                endRelationshipSet: currRels.endRelationshipSet?.filter((r) => r.metaAttributes?.state == 'DRAFT'),
                startRelationshipSet: currRels.startRelationshipSet?.filter((r) => r.metaAttributes?.state == 'DRAFT'),
            }

            const selectedCiRels: IncidentRelationshipSetUi = {
                endRelationshipSet: rels.endRelationshipSet?.filter((r) => r.metaAttributes?.state == 'DRAFT'),
                startRelationshipSet: rels.startRelationshipSet?.filter((r) => r.metaAttributes?.state == 'DRAFT'),
            }

            if (isCurrentIsTarget) {
                const isAlsoTarget = relType?.sources?.map((tar) => tar.technicalName).includes(currentCi?.type)

                if (isAlsoTarget) {
                    const sourceSameRelsLength =
                        [...(selectedCiRels.endRelationshipSet ?? []), ...(selectedCiRels.startRelationshipSet ?? [])]
                            .map((r) => r.type)
                            .filter((e) => e === relTechName)?.length ?? 0

                    const relsMax = relType?.targetCardinality?.max ?? Infinity
                    if (sourceSameRelsLength >= relsMax || sourceSameRelsLength + selectedCisCount > relsMax) {
                        return {
                            ci: selectedCi,
                            error: t('cantGetMoreRels', { ciName: selectedCi.attributes?.Gen_Profil_nazov, relType: relType.name }),
                        } as CiRelErrorPair
                    }
                } else {
                    const targetSameRelsLength =
                        [...(currentCiRels.endRelationshipSet ?? []), ...(currentCiRels.startRelationshipSet ?? [])]
                            .map((r) => r.type)
                            .filter((e) => e === relTechName)?.length ?? 0

                    const relsMax = relType?.sourceCardinality?.max ?? Infinity
                    if (targetSameRelsLength >= relsMax || targetSameRelsLength + selectedCisCount > relsMax) {
                        return {
                            ci: selectedCi,
                            error: t('cantGetMoreRels', { ciName: currentCi.attributes?.Gen_Profil_nazov, relType: relType.name }),
                        } as CiRelErrorPair
                    }
                }
            } else {
                const sourceSameRelsLength =
                    [...(selectedCiRels.endRelationshipSet ?? []), ...(selectedCiRels.startRelationshipSet ?? [])]
                        .map((r) => r.type)
                        .filter((e) => e === relTechName)?.length ?? 0

                const relsMax = relType?.targetCardinality?.max ?? Infinity
                if (sourceSameRelsLength >= relsMax || sourceSameRelsLength + selectedCisCount > relsMax) {
                    return {
                        ci: selectedCi,
                        error: t('cantGetMoreRels', { ciName: selectedCi.attributes?.Gen_Profil_nazov, relType: relType.name }),
                    } as CiRelErrorPair
                } else {
                    const targetSameRelsLength =
                        [...(selectedCiRels.endRelationshipSet ?? []), ...(selectedCiRels.startRelationshipSet ?? [])]
                            .map((r) => r.type)
                            .filter((e) => e === relTechName)?.length ?? 0

                    const relMax = relType?.sourceCardinality?.max ?? Infinity
                    if (targetSameRelsLength >= relMax || targetSameRelsLength + selectedCisCount > relsMax) {
                        return {
                            ci: selectedCi,
                            error: t('cantGetMoreRels', { ciName: selectedCi.attributes?.Gen_Profil_nazov, relType: relType.name }),
                        } as CiRelErrorPair
                    }
                }
            }
        })
        return error
    }

    return (
        <QueryFeedback loading={isQueryLoading || relsLoading} error={isError} withChildren>
            <FlexColumnReverseWrapper>
                <TextHeading size="XL">
                    {t('breadcrumbs.newRelation', { itemName: ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] })}
                </TextHeading>

                <ElementToScrollTo trigger={isQueryError}>
                    <MutationFeedback error={isQueryError} mutationProcessingError={isProcessedError} mutationTooLong={isTooManyFetchesError} />
                </ElementToScrollTo>
            </FlexColumnReverseWrapper>
            <SubHeading entityName={entityName} entityId={entityId} currentName={currentName} ciType={ciItemData?.type ?? ''} />
            <SelectPublicAuthorityAndRole
                onChangeAuthority={(e) => publicAuthorityState.setSelectedPublicAuthority(e)}
                onChangeRole={(val) => roleState.setSelectedRole(val)}
                selectedRole={roleState.selectedRole ?? {}}
                selectedOrg={publicAuthorityState.selectedPublicAuthority}
                ciRoles={relationData?.relationTypeData?.roleList ?? []}
            />
            <SelectWithGroupedOptions
                isClearable={false}
                label={t('newRelation.selectRelType')}
                name="relation-type"
                options={relationTypeOptions}
                value={selectedRelTypeOption}
                onChange={(val) => {
                    setSelectedRelationTypeTechnicalName(val.value ?? '')
                    setSelectedRelTypeOption(val)
                    const selected = selectedItems as MultiValue<ConfigurationItemUi>

                    if (selected != null && selected.length > 0 && ciItemData) {
                        setRelsLoading(true)
                        const all = selected.map((selectedCi) => {
                            return checkRels(ciItemData, selectedCi, val.value ?? '', selected.length)
                        })
                        Promise.all(all).then((errs) => {
                            setRelsErrors(errs)
                            setRelsLoading(false)
                        })
                    }
                }}
                error={!canCreateRelationType && !isAbilityLoading && roleState.selectedRole ? t('newRelation.wrongRoleRelTypeError') : ''}
            />
            <SelectCiItem
                ciType={tabName}
                isOpen={isOpen}
                selectedItems={selectedItems}
                onChangeSelectedCiItem={(val) => {
                    setSelectedItems(val)
                    setRelMetaisCode(Array.isArray(val) ? val : [val])
                    const selected = val as MultiValue<ConfigurationItemUi>
                    if (selected != null && selected.length > 0 && ciItemData) {
                        setRelsLoading(true)
                        const all = selected.map((selectedCi) => {
                            return checkRels(ciItemData, selectedCi, selectedRelTypeOption?.value ?? '', selected.length)
                        })
                        Promise.all(all).then((errs) => {
                            setRelsErrors(errs)
                            setRelsLoading(false)
                        })
                    } else {
                        setRelsErrors([])
                    }
                }}
                onCloseModal={() => setIsOpen(false)}
                onOpenModal={() => setIsOpen(true)}
                perPage={200}
                existingRelations={existingValidRelations}
                attributesFilter={attributesFilter}
                relationType={selectedRelationTypeState?.selectedRelationTypeTechnicalName}
                ciItemData={ciItemData}
            />

            {relsErrors
                .filter((e) => !!e)
                .map((e, index) => (
                    <MutationFeedback key={`${e?.error ?? ''}${index}`} error={relsErrors.length > 0} errorMessage={e?.error} />
                ))}

            <form onSubmit={handleFormSubmit(handleSubmit, onError)} noValidate>
                {!relsLoading && selectedItems && Array.isArray(selectedItems) && selectedItems.length > 0 && (
                    <AccordionContainer sections={sectionsNew} />
                )}
                {hasMutationError && (
                    <ErrorBlock
                        errorTitle={t('newRelation.errorTitle')}
                        errorMessage={
                            <>
                                {t('newRelation.errorMessage')}
                                <Link className="govuk-link" state={{ from: location }} to={`mailto:${metaisEmail}`}>
                                    {metaisEmail}
                                </Link>
                            </>
                        }
                    />
                )}

                <SubmitWithFeedback
                    submitButtonLabel={t('newRelation.save')}
                    additionalButtons={[<Button key={1} label={t('newRelation.cancel')} type="reset" variant="secondary" onClick={handleReset} />]}
                    loading={storeGraph.isLoading}
                    disabled={
                        isSubmitDisabled ||
                        !canCreateRelationType ||
                        !roleState?.selectedRole?.roleUuid ||
                        (relsErrors && relsErrors.filter((e) => !!e).length > 0)
                    }
                />
            </form>
        </QueryFeedback>
    )
}
