//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * OpenAPI spec version: latest
 */
import { useQuery } from '@tanstack/react-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useOpendataSwaggerClient } from '../hooks/useOpendataSwaggerClient'
export type ListParams = {
    reportExportFileSize?: number
}

export type ReportExportFileType = (typeof ReportExportFileType)[keyof typeof ReportExportFileType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportExportFileType = {
    CSV: 'CSV',
    XML: 'XML',
} as const

export type ReportExportFileStatus = (typeof ReportExportFileStatus)[keyof typeof ReportExportFileStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportExportFileStatus = {
    CREATED: 'CREATED',
    EXPORTING: 'EXPORTING',
    DONE: 'DONE',
    FAILED: 'FAILED',
} as const

export interface ReportExportFile {
    createdAt?: string
    id?: number
    length?: number
    path?: string
    status?: ReportExportFileStatus
    type?: ReportExportFileType
    updatedAt?: string
    validTo?: string
}

export type ReportExportStatus = (typeof ReportExportStatus)[keyof typeof ReportExportStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportExportStatus = {
    IDLE: 'IDLE',
    EXPORTING: 'EXPORTING',
    FAILED: 'FAILED',
    RETRYING: 'RETRYING',
} as const

export interface ReportExport {
    createdAt?: string
    id?: number
    lookupKey?: string
    name?: string
    reportExportFiles?: ReportExportFile[]
    status?: ReportExportStatus
    updatedAt?: string
    validFrom?: string
    validTo?: string
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const use_ExportHook = () => {
    const _export = useOpendataSwaggerClient<Blob>()

    return useCallback(
        (path: string, signal?: AbortSignal) => {
            return _export({ url: `/files/${path}`, method: 'GET', responseType: 'blob', signal })
        },
        [_export],
    )
}

export const getExportQueryKey = (path: string) => {
    return [`/files/${path}`] as const
}

export const useExportQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>, TError = ApiError>(
    path: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getExportQueryKey(path)

    const _export = use_ExportHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>> = ({ signal }) => _export(path, signal)

    return { queryKey, queryFn, enabled: !!path, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type _ExportQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>>
export type _ExportQueryError = ApiError

export function useExport<TData = Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>, TError = ApiError>(
    path: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useExportQueryOptions(path, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListHook = () => {
    const list = useOpendataSwaggerClient<ReportExport[]>()

    return useCallback(
        (params?: ListParams, signal?: AbortSignal) => {
            return list({ url: `/exports`, method: 'GET', params, signal })
        },
        [list],
    )
}

export const getListQueryKey = (params?: ListParams) => {
    return [`/exports`, ...(params ? [params] : [])] as const
}

export const useListQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListHook>>>, TError = ApiError>(
    params?: ListParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListQueryKey(params)

    const list = useListHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListHook>>>> = ({ signal }) => list(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type ListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListHook>>>>
export type ListQueryError = ApiError

export function useList<TData = Awaited<ReturnType<ReturnType<typeof useListHook>>>, TError = ApiError>(
    params?: ListParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
