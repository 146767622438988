import { IOption, SelectWithGroupedOptions, TextHeading } from '@isdd/idsk-ui-kit/index'
import {
    ConfigurationItemUiAttributes,
    IncidentRelationshipSetUi,
    useReadRelationshipsHook,
    useStoreGraph,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { useGetRelationshipTypeHook } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { SelectPublicAuthorityAndRole } from '@isdd/metais-common/common/SelectPublicAuthorityAndRole'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { SubHeading } from '@isdd/metais-common/components/sub-heading/SubHeading'
import { ENTITY_KS } from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import {
    useInvalidateCiHistoryListCache,
    useInvalidateCiNeighboursWithAllRelsCacheByUuid,
    useInvalidateRelationsCountCache,
} from '@isdd/metais-common/hooks/invalidate-cache'
import { useAbilityContextWithFeedback } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import { ATTRIBUTE_NAME, MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { Languages } from '@isdd/metais-common/localization/languages'
import { findCommonStrings } from '@isdd/metais-common/utils/utils'
import { useEffect, useState } from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'

import { createSelectRelationTypeOptions, SELECT_REL_TYPE_GROUP } from '@/componentHelpers/new-relation'
import { ICiCreateItemAndRelationContainerView } from '@/components/containers/CiCreateItemAndRelationContainer'
import { CreateCiEntityForm } from '@/components/create-entity/CreateCiEntityForm'
import { formatFormAttributeValue } from '@/components/create-entity/createEntityHelpers'
import { CiRelErrorPair } from '@/components/views/ks-helper/AddNewPORelation'
import { useKSChannel } from '@/hooks/useChannelKS'
import { useRolesForPO } from '@/hooks/useRolesForPO'

export const NewCiWithRelationView: React.FC<ICiCreateItemAndRelationContainerView> = ({
    entityName,
    entityId,
    data,
    states,
    isError,
    isLoading,
    tabName,
    ciName,
}) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { wrapperRef, scrollToMutationFeedback } = useScroll()
    const { clearAction } = useActionSuccess()
    const { ability, isLoading: isAbilityLoading } = useAbilityContextWithFeedback()
    const canCreateRelationType = ability?.can(Actions.CREATE, `ci.create.newRelationType`)
    const getRelTypeByName = useGetRelationshipTypeHook()
    const readSourceRelations = useReadRelationshipsHook()

    const [relsLoading, setRelsLoading] = useState(false)
    const [relsErrors, setRelsErrors] = useState<(CiRelErrorPair | undefined)[]>([])
    const [selectedRelTypeOption, setSelectedRelTypeOption] = useState<IOption<string>>()

    const { attributesData, generatedEntityId, relationData, groupData } = data

    const {
        selectedRelationTypeState: { selectedRelationTypeTechnicalName, setSelectedRelationTypeTechnicalName },
        publicAuthorityState: { selectedPublicAuthority, setSelectedPublicAuthority },
        roleState: { selectedRole, setSelectedRole },
    } = states

    const { rolesForPO, isRightsForPOError } = useRolesForPO(selectedPublicAuthority?.poUUID ?? '', attributesData.ciTypeData?.roleList ?? [])

    const relatedListAsSources = relationData?.relatedListAsSources
    const relatedListAsTargets = relationData?.relatedListAsTargets
    const relationConstraintsData = relationData?.constraintsData
    const relCode = relationData?.generatedRelCode
    const relationSchema = relationData?.relationTypeData

    const relationAttributesKeys = [
        ...(relationSchema?.attributes?.map((item) => item.technicalName) ?? []),
        ...(relationSchema?.attributeProfiles?.map((profile) => profile?.attributes?.map((att) => att.technicalName)).flat() ?? []),
    ]
    const ciAttributeKeys = [
        ...(attributesData.ciTypeData?.attributes?.map((item) => item.technicalName) ?? []),
        ...(attributesData.ciTypeData?.attributeProfiles?.map((profile) => profile?.attributes?.map((att) => att.technicalName)).flat() ?? []),
    ]

    const { constraintsData, ciTypeData, unitsData } = attributesData
    const [uploadError, setUploadError] = useState(false)

    const currentName =
        i18n.language == Languages.SLOVAK
            ? data.ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]
            : data.ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]

    const relationTypeOptions = createSelectRelationTypeOptions({
        relatedListAsSources,
        relatedListAsTargets,
        t,
    })

    useEffect(() => {
        if (!selectedRelationTypeTechnicalName) {
            if (relationTypeOptions[0].options && relationTypeOptions[0].options.length > 0) {
                setSelectedRelTypeOption(relationTypeOptions[0].options[0])
                setSelectedRelationTypeTechnicalName(relationTypeOptions[0].options[0]?.value)
            } else if (relationTypeOptions[1].options && relationTypeOptions[1].options.length > 0) {
                setSelectedRelTypeOption(relationTypeOptions[1].options[0])
                setSelectedRelationTypeTechnicalName(relationTypeOptions[1].options[0]?.value)
            }
        }
    }, [
        relatedListAsSources,
        relatedListAsTargets,
        relationTypeOptions,
        selectedRelationTypeTechnicalName,
        selectedRole?.roleName,
        setSelectedRelationTypeTechnicalName,
        t,
    ])

    const checkRels = async () => {
        const relType = await getRelTypeByName(selectedRelationTypeTechnicalName)
        const isCurrentIsTarget = relType?.targets?.map((tar) => tar.technicalName).includes(data.ciItemData?.type)
        const error = readSourceRelations(data.ciItemData?.uuid ?? '').then(async (rels) => {
            const currRels = rels
            const currentCiRels: IncidentRelationshipSetUi = {
                endRelationshipSet: currRels.endRelationshipSet?.filter((r) => r.metaAttributes?.state == 'DRAFT'),
                startRelationshipSet: currRels.startRelationshipSet?.filter((r) => r.metaAttributes?.state == 'DRAFT'),
            }

            const selectedCiRels: IncidentRelationshipSetUi = {
                endRelationshipSet: rels.endRelationshipSet?.filter((r) => r.metaAttributes?.state == 'DRAFT'),
                startRelationshipSet: rels.startRelationshipSet?.filter((r) => r.metaAttributes?.state == 'DRAFT'),
            }

            if (isCurrentIsTarget) {
                const isAlsoTarget = relType?.sources?.map((tar) => tar.technicalName).includes(data.ciItemData?.type)

                if (isAlsoTarget) {
                    const sourceSameRelsLength =
                        [...(selectedCiRels.endRelationshipSet ?? []), ...(selectedCiRels.startRelationshipSet ?? [])]
                            .map((r) => r.type)
                            .filter((e) => e === selectedRelationTypeTechnicalName)?.length ?? 0

                    const relsMax = relType?.targetCardinality?.max ?? Infinity
                    if (sourceSameRelsLength >= relsMax) {
                        return {
                            ci: data.ciItemData,
                            error: t('cantGetMoreRels', { ciName: data.ciItemData?.attributes?.Gen_Profil_nazov, relType: relType.name }),
                        } as CiRelErrorPair
                    }
                } else {
                    const targetSameRelsLength =
                        [...(currentCiRels.endRelationshipSet ?? []), ...(currentCiRels.startRelationshipSet ?? [])]
                            .map((r) => r.type)
                            .filter((e) => e === selectedRelationTypeTechnicalName)?.length ?? 0

                    const relsMax = relType?.sourceCardinality?.max ?? Infinity
                    if (targetSameRelsLength >= relsMax) {
                        return {
                            ci: data.ciItemData,
                            error: t('cantGetMoreRels', { ciName: data.ciItemData?.attributes?.Gen_Profil_nazov, relType: relType.name }),
                        } as CiRelErrorPair
                    }
                }
            } else {
                const sourceSameRelsLength =
                    [...(selectedCiRels.endRelationshipSet ?? []), ...(selectedCiRels.startRelationshipSet ?? [])]
                        .map((r) => r.type)
                        .filter((e) => e === selectedRelationTypeTechnicalName)?.length ?? 0

                const relsMax = relType?.targetCardinality?.max ?? Infinity
                if (sourceSameRelsLength >= relsMax) {
                    return {
                        ci: data.ciItemData,
                        error: t('cantGetMoreRels', { ciName: data.ciItemData?.attributes?.Gen_Profil_nazov, relType: relType.name }),
                    } as CiRelErrorPair
                } else {
                    const targetSameRelsLength =
                        [...(selectedCiRels.endRelationshipSet ?? []), ...(selectedCiRels.startRelationshipSet ?? [])]
                            .map((r) => r.type)
                            .filter((e) => e === selectedRelationTypeTechnicalName)?.length ?? 0

                    const relMax = relType?.sourceCardinality?.max ?? Infinity
                    if (targetSameRelsLength >= relMax) {
                        return {
                            ci: data.ciItemData,
                            error: t('cantGetMoreRels', { ciName: data.ciItemData?.attributes?.Gen_Profil_nazov, relType: relType.name }),
                        } as CiRelErrorPair
                    }
                }
            }
        })
        return error
    }

    useEffect(() => {
        if (selectedRelationTypeTechnicalName) {
            setRelsErrors([])
            setRelsLoading(true)
            checkRels()
                .then((errs) => {
                    setRelsErrors([errs])
                })
                .finally(() => setRelsLoading(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRelationTypeTechnicalName])

    const {
        getRequestStatus,
        isLoading: isRequestStatusLoading,
        isError: isRequestStatusError,
        isProcessedError,
        isTooManyFetchesError,
    } = useGetStatus()
    const invalidateRelationListCacheByUuid = useInvalidateCiNeighboursWithAllRelsCacheByUuid(entityId)
    const { invalidate: invalidateHistoryListCache } = useInvalidateCiHistoryListCache()
    const invalidateRelationsCountCache = useInvalidateRelationsCountCache()

    const onStoreGraphSuccess = () => {
        navigate(`/ci/${entityName}/${entityId}`, { state: { from: location } })
        invalidateRelationListCacheByUuid.invalidate()
        invalidateHistoryListCache(entityId)
        invalidateRelationsCountCache.invalidate(entityId)
    }

    const { createChannelForKS, isLoading: isSubmitLoading, isError: isSubmitError } = useKSChannel()
    const storeGraph = useStoreGraph({
        mutation: {
            async onSuccess(successData, variables) {
                await getRequestStatus(
                    successData?.requestId ?? '',
                    async () => {
                        if (variables?.data.storeSet?.configurationItemSet?.[0]?.type === ENTITY_KS) {
                            await createChannelForKS(variables?.data.storeSet?.configurationItemSet?.[0] ?? {}, () => onStoreGraphSuccess())
                        } else {
                            onStoreGraphSuccess()
                        }
                    },
                    () => {
                        scrollToMutationFeedback()
                        clearAction()
                    },
                )
            },
            onError() {
                setUploadError(true)
                scrollToMutationFeedback()
                clearAction()
            },
        },
    })

    const onSubmit = async (formAttributes: FieldValues) => {
        setUploadError(false)
        const formAttributesKeys = Object.keys(formAttributes)

        const formattedRelationAttributes = formAttributesKeys
            .filter((key) => relationAttributesKeys.includes(key))
            .map((key) => ({
                name: key,
                value: formatFormAttributeValue(formAttributes, key),
            }))
        const formattedCiAttributes = formAttributesKeys
            .filter((key) => ciAttributeKeys.includes(key))
            .map((key) => ({
                name: key,
                value: formatFormAttributeValue(formAttributes, key, generatedEntityId?.ciurl),
            }))
        const type = tabName
        const ownerId = groupData?.gid
        const newEntityUuid = uuidV4()

        storeGraph.mutateAsync({
            data: {
                storeSet: {
                    configurationItemSet: [
                        {
                            uuid: newEntityUuid,
                            owner: ownerId,
                            type: type,
                            attributes: formattedCiAttributes as unknown as ConfigurationItemUiAttributes,
                        },
                    ],
                    relationshipSet: [
                        {
                            type: selectedRelationTypeTechnicalName,
                            attributes: formattedRelationAttributes,
                            startUuid: selectedRelTypeOption?.group === SELECT_REL_TYPE_GROUP.source ? entityId : newEntityUuid,
                            endUuid: selectedRelTypeOption?.group === SELECT_REL_TYPE_GROUP.source ? newEntityUuid : entityId,
                            owner: ownerId,
                            uuid: uuidV4(),
                        },
                    ],
                },
            },
        })
    }

    return (
        <QueryFeedback
            loading={isLoading || storeGraph.isLoading || isRequestStatusLoading || isSubmitLoading || relsLoading}
            error={isError}
            withChildren
        >
            <FlexColumnReverseWrapper>
                <TextHeading size="XL">{t('breadcrumbs.newCiAndRelation', { itemName: ciName })}</TextHeading>

                <div ref={wrapperRef}>
                    <MutationFeedback
                        error={
                            storeGraph.isError ||
                            isProcessedError ||
                            isTooManyFetchesError ||
                            isRequestStatusError ||
                            isSubmitError ||
                            isRightsForPOError
                        }
                        mutationProcessingError={isProcessedError}
                        mutationTooLong={isTooManyFetchesError}
                    />
                </div>
            </FlexColumnReverseWrapper>
            <SubHeading entityName={entityName} entityId={entityId} currentName={currentName as string} />
            <SelectPublicAuthorityAndRole
                selectedRole={selectedRole ?? {}}
                onChangeAuthority={setSelectedPublicAuthority}
                onChangeRole={setSelectedRole}
                selectedOrg={selectedPublicAuthority}
                ciRoles={findCommonStrings(relationData?.relationTypeData?.roleList ?? [], attributesData?.ciTypeData?.roleList ?? [])}
            />
            <SelectWithGroupedOptions
                isClearable={false}
                label={t('newRelation.selectRelType')}
                name="relation-type"
                options={relationTypeOptions}
                value={selectedRelTypeOption}
                onChange={(val) => {
                    setSelectedRelTypeOption(val)
                    setSelectedRelationTypeTechnicalName(val.value ?? '')
                }}
                error={!canCreateRelationType && !isAbilityLoading && selectedRole ? t('newRelation.wrongRoleRelTypeError') : ''}
            />
            {relsErrors
                .filter((e) => !!e)
                .map((e, index) => (
                    <MutationFeedback key={e?.error ?? '' + index} error={relsErrors.length > 0} errorMessage={e?.error} />
                ))}
            {relsErrors.filter((e) => !!e).length === 0 && (
                <CreateCiEntityForm
                    entityName={entityName}
                    ciTypeData={ciTypeData}
                    generatedEntityId={generatedEntityId ?? { cicode: '', ciurl: '' }}
                    constraintsData={[...constraintsData, ...(relationConstraintsData ?? [])]}
                    unitsData={unitsData}
                    uploadError={uploadError}
                    onSubmit={onSubmit}
                    relationSchema={relationSchema}
                    isProcessing={storeGraph.isLoading}
                    selectedRole={selectedRole}
                    generatedRelCode={relCode}
                    withRelation
                    rolesForPO={rolesForPO ?? []}
                />
            )}
        </QueryFeedback>
    )
}
