//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * MetaIS User Config
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { useUserConfigSwaggerClient } from '../hooks/useUserConfigSwaggerClient'
export interface FollowedItemSummary {
    email?: boolean
    portal?: boolean
    userId?: string
}

export interface FollowedItemSummaryList {
    items?: FollowedItemSummary[]
}

export interface FavoriteColumn {
    name?: string
    order?: number
}

export interface FavoriteCiType {
    attributes?: FavoriteColumn[]
    ciType?: string
    id?: number
    metaAttributes?: FavoriteColumn[]
}

export interface Paging {
    ascending?: boolean
    page?: number
    perPage?: number
    sortBy?: string
    totalCount?: number
}

export interface GetFollowedItemsRequest {
    name?: string
    paging?: Paging
}

export type FollowedItemItemType = (typeof FollowedItemItemType)[keyof typeof FollowedItemItemType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FollowedItemItemType = {
    CI: 'CI',
    REF_REGISTER: 'REF_REGISTER',
    CODELIST: 'CODELIST',
    REFID: 'REFID',
} as const

export interface FollowedItem {
    email?: boolean
    id?: number
    itemId?: string
    itemType?: FollowedItemItemType
    name?: string
    nameEn?: string
    portal?: boolean
    userId?: string
}

export interface FollowedItemList {
    items?: FollowedItem[]
    paging?: Paging
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useUpdateFollowedItemHook = () => {
    const updateFollowedItem = useUserConfigSwaggerClient<void>()

    return useCallback(
        (id: number, followedItem: FollowedItem) => {
            return updateFollowedItem({
                url: `/followed-items/${id}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: followedItem,
            })
        },
        [updateFollowedItem],
    )
}

export const useUpdateFollowedItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateFollowedItemHook>>>,
        TError,
        { id: number; data: FollowedItem },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateFollowedItemHook>>>, TError, { id: number; data: FollowedItem }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateFollowedItem = useUpdateFollowedItemHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateFollowedItemHook>>>, { id: number; data: FollowedItem }> = (
        props,
    ) => {
        const { id, data } = props ?? {}

        return updateFollowedItem(id, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateFollowedItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateFollowedItemHook>>>>
export type UpdateFollowedItemMutationBody = FollowedItem
export type UpdateFollowedItemMutationError = ApiError

export const useUpdateFollowedItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateFollowedItemHook>>>,
        TError,
        { id: number; data: FollowedItem },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useUpdateFollowedItemHook>>>, TError, { id: number; data: FollowedItem }, TContext> => {
    const mutationOptions = useUpdateFollowedItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRemoveFollowedItemHook = () => {
    const removeFollowedItem = useUserConfigSwaggerClient<void>()

    return useCallback(
        (id: number) => {
            return removeFollowedItem({ url: `/followed-items/${id}`, method: 'DELETE' })
        },
        [removeFollowedItem],
    )
}

export const useRemoveFollowedItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveFollowedItemHook>>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveFollowedItemHook>>>, TError, { id: number }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const removeFollowedItem = useRemoveFollowedItemHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRemoveFollowedItemHook>>>, { id: number }> = (props) => {
        const { id } = props ?? {}

        return removeFollowedItem(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type RemoveFollowedItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveFollowedItemHook>>>>

export type RemoveFollowedItemMutationError = ApiError

export const useRemoveFollowedItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveFollowedItemHook>>>, TError, { id: number }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useRemoveFollowedItemHook>>>, TError, { id: number }, TContext> => {
    const mutationOptions = useRemoveFollowedItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddFollowedItemHook = () => {
    const addFollowedItem = useUserConfigSwaggerClient<void>()

    return useCallback(
        (followedItem: FollowedItem) => {
            return addFollowedItem({ url: `/followed-items`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: followedItem })
        },
        [addFollowedItem],
    )
}

export const useAddFollowedItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddFollowedItemHook>>>, TError, { data: FollowedItem }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddFollowedItemHook>>>, TError, { data: FollowedItem }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const addFollowedItem = useAddFollowedItemHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddFollowedItemHook>>>, { data: FollowedItem }> = (props) => {
        const { data } = props ?? {}

        return addFollowedItem(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddFollowedItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddFollowedItemHook>>>>
export type AddFollowedItemMutationBody = FollowedItem
export type AddFollowedItemMutationError = ApiError

export const useAddFollowedItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddFollowedItemHook>>>, TError, { data: FollowedItem }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useAddFollowedItemHook>>>, TError, { data: FollowedItem }, TContext> => {
    const mutationOptions = useAddFollowedItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetFollowedItemsHook = () => {
    const getFollowedItems = useUserConfigSwaggerClient<FollowedItemList>()

    return useCallback(
        (getFollowedItemsRequest: GetFollowedItemsRequest) => {
            return getFollowedItems({
                url: `/followed-items/filter`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: getFollowedItemsRequest,
            })
        },
        [getFollowedItems],
    )
}

export const getGetFollowedItemsQueryKey = (getFollowedItemsRequest: GetFollowedItemsRequest) => {
    return [`/followed-items/filter`, getFollowedItemsRequest] as const
}

export const useGetFollowedItemsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetFollowedItemsHook>>>, TError = ApiError>(
    getFollowedItemsRequest: GetFollowedItemsRequest,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFollowedItemsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetFollowedItemsQueryKey(getFollowedItemsRequest)

    const getFollowedItems = useGetFollowedItemsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFollowedItemsHook>>>> = ({ signal }) =>
        getFollowedItems(getFollowedItemsRequest, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetFollowedItemsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetFollowedItemsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFollowedItemsHook>>>>
export type GetFollowedItemsQueryError = ApiError

export function useGetFollowedItems<TData = Awaited<ReturnType<ReturnType<typeof useGetFollowedItemsHook>>>, TError = ApiError>(
    getFollowedItemsRequest: GetFollowedItemsRequest,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFollowedItemsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetFollowedItemsQueryOptions(getFollowedItemsRequest, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useInsertUserColumnsHook = () => {
    const insertUserColumns = useUserConfigSwaggerClient<void>()

    return useCallback(
        (favoriteCiType: FavoriteCiType) => {
            return insertUserColumns({ url: `/columns/store`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: favoriteCiType })
        },
        [insertUserColumns],
    )
}

export const useInsertUserColumnsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useInsertUserColumnsHook>>>, TError, { data: FavoriteCiType }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useInsertUserColumnsHook>>>, TError, { data: FavoriteCiType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const insertUserColumns = useInsertUserColumnsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useInsertUserColumnsHook>>>, { data: FavoriteCiType }> = (props) => {
        const { data } = props ?? {}

        return insertUserColumns(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type InsertUserColumnsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInsertUserColumnsHook>>>>
export type InsertUserColumnsMutationBody = FavoriteCiType
export type InsertUserColumnsMutationError = ApiError

export const useInsertUserColumns = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useInsertUserColumnsHook>>>, TError, { data: FavoriteCiType }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useInsertUserColumnsHook>>>, TError, { data: FavoriteCiType }, TContext> => {
    const mutationOptions = useInsertUserColumnsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useInsertDefaultColumnsHook = () => {
    const insertDefaultColumns = useUserConfigSwaggerClient<void>()

    return useCallback(
        (favoriteCiType: FavoriteCiType) => {
            return insertDefaultColumns({
                url: `/columns/store/default`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: favoriteCiType,
            })
        },
        [insertDefaultColumns],
    )
}

export const useInsertDefaultColumnsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useInsertDefaultColumnsHook>>>, TError, { data: FavoriteCiType }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useInsertDefaultColumnsHook>>>, TError, { data: FavoriteCiType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const insertDefaultColumns = useInsertDefaultColumnsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useInsertDefaultColumnsHook>>>, { data: FavoriteCiType }> = (props) => {
        const { data } = props ?? {}

        return insertDefaultColumns(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type InsertDefaultColumnsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInsertDefaultColumnsHook>>>>
export type InsertDefaultColumnsMutationBody = FavoriteCiType
export type InsertDefaultColumnsMutationError = ApiError

export const useInsertDefaultColumns = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useInsertDefaultColumnsHook>>>, TError, { data: FavoriteCiType }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useInsertDefaultColumnsHook>>>, TError, { data: FavoriteCiType }, TContext> => {
    const mutationOptions = useInsertDefaultColumnsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetFollowersForItemHook = () => {
    const getFollowersForItem = useUserConfigSwaggerClient<FollowedItemSummaryList>()

    return useCallback(
        (itemType: 'CI' | 'REF_REGISTER' | 'CODELIST' | 'REFID', itemId: string, signal?: AbortSignal) => {
            return getFollowersForItem({ url: `/followed-items/followers/itemType/${itemType}/item/${itemId}`, method: 'GET', signal })
        },
        [getFollowersForItem],
    )
}

export const getGetFollowersForItemQueryKey = (itemType: 'CI' | 'REF_REGISTER' | 'CODELIST' | 'REFID', itemId: string) => {
    return [`/followed-items/followers/itemType/${itemType}/item/${itemId}`] as const
}

export const useGetFollowersForItemQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetFollowersForItemHook>>>, TError = ApiError>(
    itemType: 'CI' | 'REF_REGISTER' | 'CODELIST' | 'REFID',
    itemId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFollowersForItemHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetFollowersForItemQueryKey(itemType, itemId)

    const getFollowersForItem = useGetFollowersForItemHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFollowersForItemHook>>>> = ({ signal }) =>
        getFollowersForItem(itemType, itemId, signal)

    return { queryKey, queryFn, enabled: !!(itemType && itemId), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetFollowersForItemHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetFollowersForItemQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFollowersForItemHook>>>>
export type GetFollowersForItemQueryError = ApiError

export function useGetFollowersForItem<TData = Awaited<ReturnType<ReturnType<typeof useGetFollowersForItemHook>>>, TError = ApiError>(
    itemType: 'CI' | 'REF_REGISTER' | 'CODELIST' | 'REFID',
    itemId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFollowersForItemHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetFollowersForItemQueryOptions(itemType, itemId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetUserColumnsHook = () => {
    const getUserColumns = useUserConfigSwaggerClient<FavoriteCiType>()

    return useCallback(
        (citype: string, signal?: AbortSignal) => {
            return getUserColumns({ url: `/columns/citype/${citype}`, method: 'GET', signal })
        },
        [getUserColumns],
    )
}

export const getGetUserColumnsQueryKey = (citype: string) => {
    return [`/columns/citype/${citype}`] as const
}

export const useGetUserColumnsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetUserColumnsHook>>>, TError = ApiError>(
    citype: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserColumnsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetUserColumnsQueryKey(citype)

    const getUserColumns = useGetUserColumnsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserColumnsHook>>>> = ({ signal }) => getUserColumns(citype, signal)

    return { queryKey, queryFn, enabled: !!citype, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetUserColumnsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetUserColumnsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUserColumnsHook>>>>
export type GetUserColumnsQueryError = ApiError

export function useGetUserColumns<TData = Awaited<ReturnType<ReturnType<typeof useGetUserColumnsHook>>>, TError = ApiError>(
    citype: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserColumnsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetUserColumnsQueryOptions(citype, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useResetUserColumnsHook = () => {
    const resetUserColumns = useUserConfigSwaggerClient<void>()

    return useCallback(
        (citype: string) => {
            return resetUserColumns({ url: `/columns/citype/${citype}`, method: 'DELETE' })
        },
        [resetUserColumns],
    )
}

export const useResetUserColumnsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useResetUserColumnsHook>>>, TError, { citype: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useResetUserColumnsHook>>>, TError, { citype: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const resetUserColumns = useResetUserColumnsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useResetUserColumnsHook>>>, { citype: string }> = (props) => {
        const { citype } = props ?? {}

        return resetUserColumns(citype)
    }

    return { mutationFn, ...mutationOptions }
}

export type ResetUserColumnsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useResetUserColumnsHook>>>>

export type ResetUserColumnsMutationError = ApiError

export const useResetUserColumns = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useResetUserColumnsHook>>>, TError, { citype: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useResetUserColumnsHook>>>, TError, { citype: string }, TContext> => {
    const mutationOptions = useResetUserColumnsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetDefaultColumnsHook = () => {
    const getDefaultColumns = useUserConfigSwaggerClient<FavoriteCiType>()

    return useCallback(
        (citype: string, signal?: AbortSignal) => {
            return getDefaultColumns({ url: `/columns/citype/${citype}/default`, method: 'GET', signal })
        },
        [getDefaultColumns],
    )
}

export const getGetDefaultColumnsQueryKey = (citype: string) => {
    return [`/columns/citype/${citype}/default`] as const
}

export const useGetDefaultColumnsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDefaultColumnsHook>>>, TError = ApiError>(
    citype: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDefaultColumnsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetDefaultColumnsQueryKey(citype)

    const getDefaultColumns = useGetDefaultColumnsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDefaultColumnsHook>>>> = ({ signal }) => getDefaultColumns(citype, signal)

    return { queryKey, queryFn, enabled: !!citype, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetDefaultColumnsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetDefaultColumnsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDefaultColumnsHook>>>>
export type GetDefaultColumnsQueryError = ApiError

export function useGetDefaultColumns<TData = Awaited<ReturnType<ReturnType<typeof useGetDefaultColumnsHook>>>, TError = ApiError>(
    citype: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDefaultColumnsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetDefaultColumnsQueryOptions(citype, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
