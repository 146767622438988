import React, { useId, SetStateAction } from 'react'
import { Option } from '@isdd/idsk-ui-kit/common/SelectCommon'
import {
    BreadCrumbs,
    CircleLoadingArrowIcon,
    HomeIcon,
    PaginatorRightArrowIcon,
    TextBody,
    TextHeading,
    TransparentButtonWrapper,
} from '@isdd/idsk-ui-kit/index'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { CiLazySelect } from '@isdd/metais-common/components/ci-lazy-select/CiLazySelect'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { ENTITY_ZC } from '@isdd/metais-common/constants'
import { ATTRIBUTE_NAME, QueryFeedback, RELATION_TYPE } from '@isdd/metais-common/index'
import { NavigationSubRoutes, RouteNames } from '@isdd/metais-common/navigation/routeNames'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { OptionProps } from 'react-select'

import styles from './styles.module.scss'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { PublicAuthoritiesHierarchyItem } from '@/components/containers/public-authorities-hierarchy/PublicAuthoritiesHierarchyContainer'
interface IPublicAuthoritiesHierarchyView {
    hierarchy: PublicAuthoritiesHierarchyItem[]
    onChangeCodeList: React.Dispatch<SetStateAction<ConfigurationItemUi | undefined>>
    handleExpandClick: (row: PublicAuthoritiesHierarchyItem) => void
    isLoading: boolean
    expandableRowIdLoading: string | null
    expanded: Record<string, boolean>
}

export const CodeListHierarchyView: React.FC<IPublicAuthoritiesHierarchyView> = ({
    hierarchy,
    onChangeCodeList,
    handleExpandClick,
    isLoading,
    expandableRowIdLoading,
    expanded,
}) => {
    const { t } = useTranslation()

    const ExpandableList = ({ wrapperId, items }: { wrapperId: string; items?: PublicAuthoritiesHierarchyItem[] }) => {
        const id = useId()
        return (
            <ul className={styles.expandableList} id={wrapperId}>
                {items?.map((item, key) => {
                    const itemIsExpanded = expanded[item.uuid]
                    return (
                        <li key={key} className={styles.rowWrapper}>
                            <div className={styles.itemWrapper}>
                                <div className={styles.toggleWrapper}>
                                    {item.canExpand &&
                                        (expandableRowIdLoading === item.uuid ? (
                                            <img className={styles.spinner} src={CircleLoadingArrowIcon} alt={t('loading.subitems')} />
                                        ) : (
                                            <TransparentButtonWrapper
                                                onClick={() => handleExpandClick(item)}
                                                aria-label={
                                                    itemIsExpanded
                                                        ? t('publicAuthorities.collapse', { rowName: item.name })
                                                        : t('publicAuthorities.expand', { rowName: item.name })
                                                }
                                                aria-expanded={itemIsExpanded ? 'true' : 'false'}
                                                aria-controls={id}
                                            >
                                                <img
                                                    src={PaginatorRightArrowIcon}
                                                    className={classNames([styles.expandIcon, itemIsExpanded ? styles.rotate90 : styles.rotate0])}
                                                    alt=""
                                                />
                                            </TransparentButtonWrapper>
                                        ))}
                                </div>

                                <TextBody size="S" className={styles.rowDataWrapper}>
                                    {item.name}
                                    <small className={styles.address}>{item.address}</small>
                                </TextBody>
                            </div>
                            {item.canExpand && (
                                <div id={id}>{itemIsExpanded && item.children && <ExpandableList items={item.children ?? []} wrapperId={id} />}</div>
                            )}
                        </li>
                    )
                })}
            </ul>
        )
    }

    const selectLazyLoadingCiOption = (props: OptionProps<ConfigurationItemUi>) => {
        const optionAttributes = props.data.attributes
        return (
            <Option {...props}>
                <div>
                    <span>{optionAttributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string}</span>
                </div>
            </Option>
        )
    }

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('codeList.breadcrumbs.home'), href: RouteNames.HOME, icon: HomeIcon },
                    { label: t('codeList.breadcrumbs.dataObjects'), href: RouteNames.HOW_TO_DATA_OBJECTS_MAIN },
                    { label: t('codeList.breadcrumbs.codeLists'), href: RouteNames.HOW_TO_CODELIST },
                    { label: t('codeList.hierarchy'), href: NavigationSubRoutes.PUBLIC_AUTHORITY_HIERARCHY },
                ]}
            />
            <MainContentWrapper>
                <FlexColumnReverseWrapper>
                    <TextHeading size="XL">{t('codeList.hierarchy')}</TextHeading>
                </FlexColumnReverseWrapper>

                <CiLazySelect
                    ciType={ENTITY_ZC}
                    label={t('codeList.codeListSelect')}
                    setSelectedCi={onChangeCodeList}
                    metaAttributes={{
                        state: ['DRAFT'],
                    }}
                    relTypeFilters={[{ relType: RELATION_TYPE.Ciselnik_je_podriadeny_Ciselnik }]}
                    option={selectLazyLoadingCiOption}
                    isClearable={false}
                />

                <QueryFeedback loading={isLoading && !expandableRowIdLoading} withChildren>
                    <ExpandableList wrapperId="codeList_hierarchy" items={hierarchy} />
                </QueryFeedback>
            </MainContentWrapper>
        </>
    )
}
