import { ATTRIBUTE_NAME, ATTRIBUTE_PROFILE_NAME, ZbierkaZakonov } from '@isdd/metais-common/api'
import { Attribute, AttributeAttributeTypeEnum, AttributeProfile } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { ConfigurationItemUiAttributes } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ENTITY_OSOBITNY_POSTUP } from '@isdd/metais-common/constants'
import { useTranslation } from 'react-i18next'
import { GidRoleData } from '@isdd/metais-common/api/generated/iam-swagger'

import { generateFormSchema } from '@/components/create-entity/createCiEntityFormSchema'
import { formatForFormDefaultValues } from '@/componentHelpers/ci'

const GEN_PROFILE_BLACKLIST_ATTRIBUTES = [
    ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov,
    ATTRIBUTE_NAME.Gen_Profil_popis,
    ATTRIBUTE_NAME.Gen_Profil_anglicky_popis,
    ATTRIBUTE_NAME.Gen_Profil_poznamka,
]

const EU_URI_ATTRIBUTES: string[] = [ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_nazov, ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_eu_ref_id]

export const NON_EU_URI_ATTRIBUTES: string[] = [
    ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_paragraf,
    ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_cislo,
    ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_rok,
    ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_odsek,
    ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_pismeno,
    ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_bod,
    ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_zdroj,
]

const INVISIBLE_ATTRIBUTES: string[] = [
    ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_eli_uri,
    ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_jednotny_URI,
]

export const useEvidenceObjectAttribute = (
    attributeProfiles: AttributeProfile[],
    attributes: Attribute[],
    isEUUri: boolean,
    updateCiItemId: string | undefined,
    defaultItemAttributeValues: ConfigurationItemUiAttributes | undefined,
    referenceIdValue: string | undefined,
    metaIsCodeValue: string | undefined,
    selectedRole: GidRoleData | null | undefined,
    roleList: string[] | undefined,
) => {
    const { t, i18n } = useTranslation()

    const aoeAttrProfile =
        attributeProfiles
            ?.filter((profile) => profile.technicalName === ATTRIBUTE_PROFILE_NAME.Profil_AtributObjektuEvidencie)
            .map((p) => {
                return {
                    ...p,
                    attributes: p?.attributes
                        ?.filter((attr) => [...NON_EU_URI_ATTRIBUTES, ...EU_URI_ATTRIBUTES].includes(attr.technicalName ?? ''))
                        .map((a) => {
                            if (EU_URI_ATTRIBUTES.includes(a.technicalName ?? '')) {
                                return { ...a, mandatory: { type: 'critical' } }
                            } else if (
                                a.technicalName === ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_bod ||
                                a.technicalName === ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_pismeno
                            ) {
                                return {
                                    ...a,
                                    invisible: isEUUri,
                                    mandatory: { type: 'none', processIds: [] },
                                    defaultValue: '',
                                }
                            } else if (a.technicalName === ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_zdroj) {
                                return {
                                    ...a,
                                    mandatory: !isEUUri ? { type: 'critical', processIds: [] } : { type: 'none', processIds: [] },
                                    invisible: isEUUri,
                                }
                            } else if (a.technicalName === ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_odsek) {
                                return {
                                    ...a,
                                    mandatory: { type: 'none', processIds: [] },
                                }
                            } else if (a.technicalName === ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_paragraf) {
                                return {
                                    ...a,
                                    type: 'STRING',
                                    attributeTypeEnum: AttributeAttributeTypeEnum.STRING,
                                    mandatory: !isEUUri ? { type: 'critical', processIds: [] } : { type: 'none', processIds: [] },
                                    invisible: isEUUri,
                                }
                            } else {
                                return {
                                    ...a,
                                    mandatory: !isEUUri ? { type: 'critical', processIds: [] } : { type: 'none', processIds: [] },
                                    invisible: isEUUri,
                                    readOnly: a.technicalName === ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_zdroj,
                                    defaultValue:
                                        a.technicalName === ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_zdroj ? ZbierkaZakonov : a.defaultValue,
                                }
                            }
                        }),
                }
            })?.[0] ?? {}

    const invAttrProfile =
        attributeProfiles
            ?.filter((profile) => profile.technicalName === ATTRIBUTE_PROFILE_NAME.Profil_AtributObjektuEvidencie)
            .map((p) => {
                return {
                    ...p,
                    attributes: p?.attributes?.filter((a) => INVISIBLE_ATTRIBUTES.includes(a.technicalName ?? '')),
                }
            })?.[0] ?? {}

    const genProfileAttrs = attributes?.filter((attr) => !GEN_PROFILE_BLACKLIST_ATTRIBUTES.includes(attr.technicalName as ATTRIBUTE_NAME)) ?? []

    const allAttributes = [...(aoeAttrProfile.attributes ?? []), ...(invAttrProfile.attributes ?? []), ...genProfileAttrs]

    const defaultValuesFromSchema = allAttributes.reduce((acc, att) => {
        if (att?.defaultValue) {
            return { ...acc, [att?.technicalName?.toString() ?? '']: att?.defaultValue }
        }
        if (att?.technicalName === ATTRIBUTE_NAME.Gen_Profil_kod_metais) {
            return { ...acc, [att?.technicalName?.toString() ?? '']: metaIsCodeValue }
        }
        if (att?.technicalName === ATTRIBUTE_NAME.Gen_Profil_ref_id) {
            return { ...acc, [att?.technicalName?.toString() ?? '']: referenceIdValue }
        }

        return acc
    }, {})

    const defaultValues = formatForFormDefaultValues(
        updateCiItemId ? defaultItemAttributeValues ?? {} : (defaultValuesFromSchema as ConfigurationItemUiAttributes) ?? {},
        allAttributes,
    )

    const schema = generateFormSchema(
        [{ attributes: genProfileAttrs, roleList: roleList } as AttributeProfile, aoeAttrProfile, invAttrProfile],
        t,
        i18n.language,
        selectedRole,
        ENTITY_OSOBITNY_POSTUP,
        defaultValues,
    )

    return { aoeAttrProfile, genProfileAttrs, defaultValues, schema }
}
