import { yupResolver } from '@hookform/resolvers/yup'
import { Option } from '@isdd/idsk-ui-kit/common/SelectCommon'
import { DateInput } from '@isdd/idsk-ui-kit/date-input/DateInput'
import {
    BreadCrumbs,
    Button,
    ButtonGroupRow,
    ButtonLink,
    CheckBox,
    ErrorBlock,
    GridCol,
    GridRow,
    HomeIcon,
    Input,
    LoadingIndicator,
    PaginatorWrapper,
    SelectLazyLoading,
    Table,
    TextArea,
    TextHeading,
} from '@isdd/idsk-ui-kit/index'
import { CHECKBOX_CELL } from '@isdd/idsk-ui-kit/table/constants'
import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'
import { IFilter, Pagination, SortBy, SortType } from '@isdd/idsk-ui-kit/types/'
import {
    CiListFilterContainerUi,
    ConfigurationItemUi,
    useGetRoleParticipantHook,
    useReadCiList1Hook,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ElementToScrollTo } from '@isdd/metais-common/components/element-to-scroll-to/ElementToScrollTo'
import { ADD_REQUEST_ITEM, BASE_PAGE_NUMBER, BASE_PAGE_SIZE, DEFAULT_PAGESIZE_OPTIONS, RequestListState } from '@isdd/metais-common/constants'
import { Can, useAbilityContextWithFeedback } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions, Subjects } from '@isdd/metais-common/hooks/permissions/useRequestPermissions'
import {
    ActionsOverTable,
    ATTRIBUTE_NAME,
    BulkPopup,
    CODELIST_LINK,
    CreateEntityButton,
    MutationFeedback,
    QueryFeedback,
    RefIdentifierTypeEnum,
} from '@isdd/metais-common/index'
import { NavigationSubRoutes, RouteNames } from '@isdd/metais-common/navigation/routeNames'
import { CellContext, ColumnDef, ExpandedState, Row } from '@tanstack/react-table'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { OptionProps } from 'react-select'
import { ApiAutoincrementTypeType } from '@isdd/metais-common/api/generated/codelist-repo-swagger'
import { useRowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import { getItemCodelistRefId, IRequestForm, mapToCodeListDetail } from '@/componentHelpers/requests'
import { MainContentWrapper } from '@/components/MainContentWrapper'
import { CreateRequestViewProps } from '@/components/containers/CreateRequestContainer'
import { getDescription, getName } from '@/components/views/codeLists/CodeListDetailUtils'
import { AutoIncrement } from '@/components/views/requestLists/components/AutoIncrement'
import { RequestDetailItemsTableExpandedRow } from '@/components/views/requestLists/components/RequestDetailItemsTableExpandedRow'
import { DateModalItem } from '@/components/views/requestLists/components/modalItem/DateModalItem'
import { IItemForm, ModalItem } from '@/components/views/requestLists/components/modalItem/ModalItem'
import styles from '@/components/views/requestLists/requestView.module.scss'
import { useCreateRequestSchema } from '@/components/views/requestLists/useRequestSchemas'

export interface ICodeItem {
    id: string
    value?: string
    language?: string
    effectiveFrom?: string
    effectiveTo?: string
}

export interface IOption {
    name?: string
    value?: string
}

export interface INoteRow {
    id?: number
    text?: string
}

export enum RequestFormEnum {
    BASE = 'base',
    CODELISTNAME = 'codeListName',
    CODELISTCODE = 'codeListCode',
    RESORTCODE = 'resortCode',
    MAINGESTOR = 'mainGestor',
    REFINDICATOR = 'refIndicator',
    NOTES = 'notes',
    NAME = 'name',
    LASTNAME = 'lastName',
    PHONE = 'phone',
    EMAIL = 'email',
    CODELISTS = 'codeLists',
    VALIDDATE = 'validDate',
    STARTDATE = 'startDate',
    PREFIX = 'prefix',
    AUTOINCREMENT_VALID = 'valid',
    AUTOINCREMENT_TYPE = 'type',
    AUTOINCREMENT_CHAR_COUNT = 'charCount',
    REF_ID = 'refId',
}

export const renderCustomUriOption = (props: OptionProps<ConfigurationItemUi>) => {
    const { attributes } = props.data
    const renderURI = () => {
        return attributes?.[ATTRIBUTE_NAME.Gen_Profil_ref_id] as string
    }
    return (
        <Option {...props}>
            <div>{renderURI()}</div>
            <span>
                <small>{attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] as string}</small>
            </span>
        </Option>
    )
}

const getNextAlphabet = (current: string): string => {
    let result = ''
    let carry = true // To handle the case when we need to wrap around from 'z' to 'a'
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

    // Iterate from the end of the current string to the front
    for (let i = current.length - 1; i >= 0; i--) {
        if (carry) {
            if (current[i] === 'Z') {
                result = 'A' + result // Wrap around if it's 'z'
            } else {
                result = alphabet[alphabet.indexOf(current[i]) + 1] + result // Increment the letter
                carry = false // No carry needed anymore
            }
        } else {
            result = current[i] + result // Just add the remaining unchanged letters
        }
    }

    // If we still have a carry after processing all letters, it means we need to add another 'a'
    if (carry) {
        result = 'A' + result
    }

    return result
}
export const CreateRequestView: React.FC<CreateRequestViewProps> = ({
    workingLanguage,
    isError,
    errorMessages,
    isLoading,
    isLoadingMutation,
    errorMessageSetDates,
    isSuccessSetDates,
    editData,
    onHandleCheckIfCodeIsAvailable,
    firstNotUsedCode,
    loadOptions,
    onSend,
    onSave,
    canEdit,
    attributeProfile,
    onSaveDates,
    requestId,
    autoincrementTypes,
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const navigate = useNavigate()
    const { schema } = useCreateRequestSchema(canEdit ?? false)
    const getRoleParticipantHook = useGetRoleParticipantHook()
    const { ability, isLoading: isAbilityLoading, isError: isAbilityError } = useAbilityContextWithFeedback()

    const [pagination, setPagination] = useState<Pagination>({
        pageNumber: BASE_PAGE_NUMBER,
        pageSize: BASE_PAGE_SIZE,
        dataLength: 0,
    })
    const [seed, setSeed] = useState(1)

    const rowSelection = useRowSelection<IItemForm>()
    const [notes, setNotes] = useState<INoteRow[]>([{ id: 0, text: '' }])
    const [codeList, setCodeList] = useState<IItemForm[]>([])
    const [codeListItem, setCodeListItem] = useState<IItemForm>()
    const [isOpen, setOpen] = useState<boolean>(false)
    const [isSend, setSend] = useState<boolean>(false)
    const [isSetDatesDialogOpened, setIsSetDatesDialogOpened] = useState<boolean>(false)
    const [defaultSelectOrg, setDefaultSelectOrg] = useState<IOption>()
    const [expanded, setExpanded] = useState<ExpandedState>({})
    const [isCodeAvailable, setIsCodeAvailable] = useState<boolean>(false)

    const DEFAULT_CHAR_COUNT = 3
    const canUse = requestId ? ability.can(Actions.EDIT, Subjects.DETAIL) : ability.can(Actions.CREATE, Subjects.DETAIL)

    const { register, handleSubmit, formState, getValues, setValue, setError, trigger, control, watch, clearErrors } = useForm<IRequestForm>({
        resolver: yupResolver(schema),
        defaultValues: editData || {
            base: true,
            charCount: DEFAULT_CHAR_COUNT,
            type: ApiAutoincrementTypeType.NUMERIC,
        },
    })
    const charCountInput = watch(RequestFormEnum.AUTOINCREMENT_CHAR_COUNT)
    const prefixInput = watch(RequestFormEnum.PREFIX)
    const isAutoincrementValid = !!watch(RequestFormEnum.AUTOINCREMENT_VALID)
    const autoincrementType = watch(RequestFormEnum.AUTOINCREMENT_TYPE)
    const codelistRefId = watch(RequestFormEnum.REFINDICATOR)
    const hasAutoincrementButNotPrefixOrCount = isAutoincrementValid && (!prefixInput || !charCountInput)
    const DEFAULT_AUTOINCREMENT_INDEX = 1

    useEffect(() => {
        if (!isAutoincrementValid) {
            setValue(RequestFormEnum.PREFIX, codelistRefId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAutoincrementValid, codelistRefId])

    const handleDateChange = (date: Date | null, name: string) => {
        setValue(name as keyof ICodeItem, date ?? new Date())
    }

    useEffect(() => {
        setSeed((prevSeed) => prevSeed + 1)
    }, [editData])

    const getAutoIncrement = (index: number, charCount: number, codeItem: string) => {
        if (!isAutoincrementValid || !charCount) return ''
        if (autoincrementType === ApiAutoincrementTypeType.NUMERIC) {
            const charCountZeroLength = charCount - index.toString().length
            const charCountZeroArray = charCountZeroLength ? Array(charCountZeroLength).fill(0).join('') : ''
            return `${charCountZeroArray}${index}`
        } else {
            if (index === 1) return `${Array(Number(charCount)).fill('A').join('')}`
            else return `${getNextAlphabet(codeItem)}`
        }
    }

    const getAutoIncrementWithCodelistRefId = (index: number, charCount: number, codeItem: string) => {
        const code = getAutoIncrement(index, charCount, codeItem)
        return code ? getItemCodelistRefId(prefixInput ?? '', code) : ''
    }

    const onHandleSubmit = (formData: IRequestForm) => {
        const res = { ...formData, codeLists: [...codeList] }
        isSend ? onSend(res) : onSave(res)
    }

    useEffect(() => {
        const currentCharCount = getValues(RequestFormEnum.AUTOINCREMENT_CHAR_COUNT) ?? 1
        const maxItems = Math.pow(10, currentCharCount)
        if (codeList.length >= maxItems - 1) {
            setValue(RequestFormEnum.AUTOINCREMENT_CHAR_COUNT, parseInt(currentCharCount?.toString()) + 1)
        }
    }, [codeList.length, setValue, getValues])

    useEffect(() => {
        const codes: string[] = []
        for (let index = 0; index < codeList.length; index++) {
            codes.push(getAutoIncrement(index + 1, charCountInput ?? DEFAULT_CHAR_COUNT, index === 0 ? '' : codes[index - 1]))
        }
        setCodeList(
            codeList.map((item, index) => {
                return {
                    ...item,
                    codeItem: codes[index],
                    refident: getAutoIncrementWithCodelistRefId(index + 1, charCountInput ?? DEFAULT_CHAR_COUNT, codeList?.[index - 1]?.codeItem),
                }
            }),
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [charCountInput, prefixInput, autoincrementType])

    const onClickCheck = async () => {
        const isValid = await trigger(RequestFormEnum.CODELISTCODE)
        if (!isValid) {
            setIsCodeAvailable(false)
            return
        }

        const code = getValues(RequestFormEnum.CODELISTCODE)
        const result = await onHandleCheckIfCodeIsAvailable(code)
        if (result.isAvailable) {
            setIsCodeAvailable(true)
        } else {
            setIsCodeAvailable(false)
            result.errorTranslateKeys?.forEach((error) => {
                setError(RequestFormEnum.CODELISTCODE, { message: t([error ?? '', 'feedback.mutationErrorMessage']) })
            })
        }
    }

    const close = () => {
        setOpen(false)
    }

    const closeDate = () => {
        setIsSetDatesDialogOpened(false)
    }

    useEffect(() => {
        if (firstNotUsedCode) {
            setValue(RequestFormEnum.CODELISTCODE, firstNotUsedCode)
            setValue(RequestFormEnum.REF_ID, CODELIST_LINK + firstNotUsedCode)
        }
    }, [firstNotUsedCode, setValue])

    const addItem = (data: IItemForm) => {
        if (data.id) {
            const removeItem = codeList.filter((i) => i.id !== data.id)
            setCodeList([...removeItem, { ...data }])
        } else {
            if (codeList.every((i) => i.codeItem !== data.codeItem)) {
                setCodeList([...codeList, { ...data, state: 'PUBLISHED', id: 'create' + codeList.length }])
            } else {
                const removeItem = codeList.filter((i) => i.codeItem !== data.codeItem)
                setCodeList([...removeItem, { ...data, state: 'PUBLISHED', id: 'create' + removeItem.length }])
            }
        }
        close()
    }

    const handleFilterChange = (filter: IFilter) => {
        setPagination({
            ...pagination,
            pageSize: filter.pageSize ?? BASE_PAGE_SIZE,
            pageNumber: filter.pageNumber ?? BASE_PAGE_NUMBER,
        })
    }

    const paginatedCodeList = useMemo(() => {
        const startOfList = pagination.pageNumber * pagination.pageSize - pagination.pageSize
        const endOfList = pagination.pageNumber * pagination.pageSize
        setValue(RequestFormEnum.CODELISTS, codeList)
        if (codeList.length > 0) trigger(RequestFormEnum.CODELISTS)
        return codeList?.slice(startOfList, endOfList) || []
    }, [codeList, pagination.pageNumber, pagination.pageSize, setValue, trigger])

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            dataLength: codeList.length,
        }))
    }, [codeList.length])

    useEffect(() => {
        if (editData) {
            setCodeList(editData.codeLists ?? [])
            setNotes(editData.notes ?? [])
            setPagination({
                ...pagination,
                dataLength: editData.codeLists?.length ?? 0,
            })

            getRoleParticipantHook(editData?.gid ?? '')
                .then((res) => {
                    const val = {
                        name: res?.configurationItemUi?.attributes?.['Gen_Profil_nazov'],
                        value: res?.gid,
                    }

                    setValue(RequestFormEnum.MAINGESTOR, Array.isArray(val) ? val[0].value : val.value)
                    setDefaultSelectOrg(val as IOption)
                })
                .catch(() => {
                    return
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editData])

    const colDef: ColumnDef<IItemForm>[] = []
    if (editData) {
        colDef.push({
            id: CHECKBOX_CELL,
            header: ({ table }) => {
                return (
                    <div className="govuk-checkboxes govuk-checkboxes--small">
                        <CheckBox
                            label=""
                            name="checkbox"
                            id="checkbox-all"
                            onChange={table.getToggleAllRowsSelectedHandler()}
                            checked={table.getIsAllRowsSelected()}
                            aria-label={t('table.selectAllItems')}
                        />
                    </div>
                )
            },
            cell: ({ row }) => (
                <div className="govuk-checkboxes govuk-checkboxes--small">
                    <CheckBox
                        label=""
                        name="checkbox"
                        id={`checkbox_${row.id}`}
                        value="true"
                        onChange={row.getToggleSelectedHandler()}
                        checked={row.getIsSelected()}
                        aria-label={t('table.selectItem', { itemName: row.original.codeName })}
                    />
                </div>
            ),
        })
    }

    colDef.push(
        {
            header: t('codeListList.requestCreate.codeName'),
            accessorFn: (row: IItemForm) => row?.codeName,
            id: 'name',
            meta: { getCellContext: (ctx: CellContext<IItemForm, unknown>) => ctx?.getValue?.() },
            cell: (ctx: CellContext<IItemForm, unknown>) => <span>{ctx?.row?.original?.codeName}</span>,
            size: 250,
        },
        {
            header: t('codeListList.requestCreate.codeId'),
            accessorFn: (row: IItemForm) => row?.codeItem,
            id: 'id',
            cell: (ctx: CellContext<IItemForm, unknown>) => <span>{ctx?.row?.original?.codeItem}</span>,
        },
        {
            header: t('documentsTab.table.actions'),
            accessorFn: (row: IItemForm) => row?.codeItem,
            id: 'btn',
            cell: (ctx: CellContext<IItemForm, unknown>) =>
                canEdit && (
                    <Button
                        label={t('form.editItem')}
                        type="submit"
                        className={styles.marginBottom0}
                        onClick={() => {
                            setCodeListItem(ctx?.row?.original)
                            setOpen(true)
                        }}
                    />
                ),
        },
    )

    const ciOptionsHook = useReadCiList1Hook()

    const loadCiOptions = useCallback(
        async (searchQuery: string, additional: { page: number } | undefined) => {
            const page = !additional?.page ? 1 : (additional?.page || 0) + 1

            const defaultFilterValues = {
                page,
                perpage: 20,
                filter: {
                    type: [RefIdentifierTypeEnum.DatovyPrvok],
                    searchFields: [ATTRIBUTE_NAME.Gen_Profil_ref_id, ATTRIBUTE_NAME.Gen_Profil_nazov],
                    fullTextSearch: searchQuery,
                    ...additional,
                },
                sortBy: SortBy.GEN_PROFIL_NAZOV,
                sortType: SortType.ASC,
            } as CiListFilterContainerUi

            const ciResponse = await ciOptionsHook(defaultFilterValues)

            const options = ciResponse.configurationItemSet ?? []

            return {
                options,
                hasMore: page < (ciResponse.pagination?.totalPages ?? 0),
                additional: {
                    page,
                },
            }
        },
        [ciOptionsHook],
    )

    return (
        <>
            {editData ? (
                <BreadCrumbs
                    withWidthContainer
                    links={[
                        { label: t('codeList.breadcrumbs.home'), href: RouteNames.HOME, icon: HomeIcon },
                        { label: t('codeList.breadcrumbs.dataObjects'), href: RouteNames.HOW_TO_DATA_OBJECTS_MAIN },
                        { label: t('codeList.breadcrumbs.codeLists'), href: RouteNames.HOW_TO_CODELIST },
                        { label: t('codeList.breadcrumbs.requestList'), href: NavigationSubRoutes.REQUESTLIST },
                        { label: editData?.codeListCode ?? '', href: `${NavigationSubRoutes.REQUESTLIST}/${requestId}` },
                        { label: t('codeList.breadcrumbs.requestEdit'), href: `${NavigationSubRoutes.REQUESTLIST}/${requestId}/edit` },
                    ]}
                />
            ) : (
                <BreadCrumbs
                    withWidthContainer
                    links={[
                        { label: t('codeList.breadcrumbs.home'), href: RouteNames.HOME, icon: HomeIcon },
                        { label: t('codeList.breadcrumbs.dataObjects'), href: RouteNames.HOW_TO_DATA_OBJECTS_MAIN },
                        { label: t('codeList.breadcrumbs.codeLists'), href: RouteNames.HOW_TO_CODELIST },
                        { label: t('codeList.breadcrumbs.requestList'), href: NavigationSubRoutes.REQUESTLIST },
                        { label: t('codeList.breadcrumbs.requestCreate'), href: `${NavigationSubRoutes.REQUESTLIST}/create` },
                    ]}
                />
            )}
            {canUse && (
                <MainContentWrapper>
                    <QueryFeedback loading={isLoading || !!isAbilityLoading} error={isError || isAbilityError} withChildren>
                        <ElementToScrollTo trigger={errorMessages.length > 0} manualScroll>
                            {errorMessages.map((errorMessage, index) => (
                                <MutationFeedback key={index} error errorMessage={errorMessage && t(errorMessage)} />
                            ))}
                        </ElementToScrollTo>
                        {isLoadingMutation && <LoadingIndicator label={t('feedback.saving')} />}
                        <TextHeading size="XL">{t('codeListList.requestTitle')}</TextHeading>

                        {formState.isSubmitted && !formState.isValid && <ErrorBlock errorTitle={t('formErrors')} hidden />}

                        <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
                            <div className={styles.bottomGap}>
                                <CheckBox
                                    disabled={!canEdit}
                                    label={getDescription('Gui_Profil_ZC_zakladny_ciselnik', language, attributeProfile)}
                                    info={getName('Gui_Profil_ZC_zakladny_ciselnik', language, attributeProfile)}
                                    id={RequestFormEnum.BASE}
                                    {...register(RequestFormEnum.BASE)}
                                    name={RequestFormEnum.BASE}
                                />
                            </div>
                            <Input
                                required
                                disabled={!canEdit}
                                label={getDescription('Gui_Profil_ZC_nazov_ciselnika', language, attributeProfile)}
                                info={getName('Gui_Profil_ZC_nazov_ciselnika', language, attributeProfile)}
                                id={RequestFormEnum.CODELISTNAME}
                                {...register(RequestFormEnum.CODELISTNAME)}
                                error={formState.errors[RequestFormEnum.CODELISTNAME]?.message}
                            />

                            <Input
                                correct={isCodeAvailable}
                                required
                                disabled={!canEdit}
                                label={getDescription('Gui_Profil_ZC_kod_ciselnika', language, attributeProfile)}
                                info={getName('Gui_Profil_ZC_kod_ciselnika', language, attributeProfile)}
                                id={RequestFormEnum.CODELISTCODE}
                                {...register(RequestFormEnum.CODELISTCODE)}
                                error={formState.errors[RequestFormEnum.CODELISTCODE]?.message}
                                hint={t('codeListDetail.codeHint')}
                            />
                            <div className={styles.availWrapper}>
                                {!!canEdit && <Button label={t('codeListList.requestCreate.btnCheck')} variant="secondary" onClick={onClickCheck} />}
                                {isCodeAvailable && (
                                    <MutationFeedback
                                        success={isCodeAvailable}
                                        successMessage={t('codeListDetail.feedback.codeIsAvailable')}
                                        onMessageClose={() => {
                                            setIsCodeAvailable(false)
                                        }}
                                    />
                                )}
                            </div>
                            <Input
                                required
                                maxLength={10}
                                disabled={!canEdit}
                                label={getDescription('Gui_Profil_ZC_rezort', language, attributeProfile)}
                                info={getName('Gui_Profil_ZC_rezort', language, attributeProfile)}
                                id={RequestFormEnum.RESORTCODE}
                                {...register(RequestFormEnum.RESORTCODE)}
                                error={formState.errors[RequestFormEnum.RESORTCODE]?.message}
                                hint={t('codeListDetail.rezortHint')}
                            />
                            <SelectLazyLoading
                                required
                                key={defaultSelectOrg?.value}
                                disabled={!canEdit}
                                id={RequestFormEnum.MAINGESTOR}
                                name={RequestFormEnum.MAINGESTOR}
                                loadOptions={(searchTerm, _, additional) => loadOptions(searchTerm, additional)}
                                getOptionLabel={(item) => item.name ?? ''}
                                getOptionValue={(item) => item.value ?? ''}
                                label={getDescription('Gui_Profil_ZC_hlavny_gestor', language, attributeProfile)}
                                info={getName('Gui_Profil_ZC_hlavny_gestor', language, attributeProfile)}
                                isMulti={false}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                error={formState.errors[RequestFormEnum.MAINGESTOR]?.message}
                                defaultValue={defaultSelectOrg}
                            />

                            <Input
                                disabled
                                label={t('codeListList.refId')}
                                {...register(RequestFormEnum.REF_ID)}
                                error={formState.errors[RequestFormEnum.REF_ID]?.message}
                                name={RequestFormEnum.REF_ID}
                            />

                            <SelectLazyLoading<ConfigurationItemUi>
                                disabled={!canEdit}
                                id={RequestFormEnum.REFINDICATOR}
                                name={RequestFormEnum.REFINDICATOR}
                                loadOptions={(searchTerm, _, additional) => loadCiOptions(searchTerm, additional)}
                                getOptionLabel={(item) => (item.attributes?.[ATTRIBUTE_NAME.Gen_Profil_ref_id] as string) ?? ''}
                                getOptionValue={(item) => (item.attributes?.[ATTRIBUTE_NAME.Gen_Profil_ref_id] as string) ?? ''}
                                label={t('codeListList.CMU')}
                                info={getName('Gui_Profil_ZC_uri', language, attributeProfile)}
                                isMulti={false}
                                setValue={setValue}
                                error={formState.errors[RequestFormEnum.REFINDICATOR]?.message}
                                option={(props) => renderCustomUriOption(props)}
                            />

                            <AutoIncrement
                                register={register}
                                formState={formState}
                                isAutoIncremenetValid={isAutoincrementValid}
                                autoIncrement={getAutoIncrement(DEFAULT_AUTOINCREMENT_INDEX, charCountInput ?? DEFAULT_CHAR_COUNT, '')}
                                setValue={setValue}
                                autoincrementTypes={autoincrementTypes?.filter((type) => type.language === language)}
                                canEdit={canEdit ?? false}
                                seed={seed}
                            />

                            {(editData?.codeListState === RequestListState.ACCEPTED_SZZC ||
                                editData?.codeListState === RequestListState.KS_ISVS_ACCEPTED) && (
                                <>
                                    <DateInput
                                        required
                                        setValue={setValue}
                                        label={getDescription('Gui_Profil_ZC_zaciatok_ucinnosti_polozky', language, attributeProfile)}
                                        info={getName('Gui_Profil_ZC_zaciatok_ucinnosti_polozky', language, attributeProfile)}
                                        id={RequestFormEnum.STARTDATE}
                                        {...register(RequestFormEnum.STARTDATE)}
                                        error={formState.errors[RequestFormEnum.STARTDATE]?.message}
                                        control={control}
                                        handleDateChange={handleDateChange}
                                    />
                                    <DateInput
                                        setValue={setValue}
                                        label={getDescription('Gui_Profil_ZC_datum_platnosti', language, attributeProfile)}
                                        info={getName('Gui_Profil_ZC_datum_platnosti', language, attributeProfile)}
                                        id={RequestFormEnum.VALIDDATE}
                                        {...register(RequestFormEnum.VALIDDATE)}
                                        error={formState.errors[RequestFormEnum.VALIDDATE]?.message}
                                        control={control}
                                        handleDateChange={handleDateChange}
                                    />
                                </>
                            )}
                            {notes?.map((note, index) => {
                                const name = `${RequestFormEnum.NOTES}.${index}.text`
                                return (
                                    <TextArea
                                        key={index}
                                        defaultValue={note.text}
                                        rows={5}
                                        label={`${getDescription('Gui_Profil_ZC_poznamka_pre_ciselnik', language, attributeProfile)}  ${
                                            notes.length > 1 ? index + 1 : ''
                                        }`}
                                        info={getName('Gui_Profil_ZC_poznamka_pre_ciselnik', language, attributeProfile)}
                                        id={name}
                                        {...register(name)}
                                        error={formState.errors[RequestFormEnum.NOTES]?.[index]?.text?.message}
                                    />
                                )
                            })}
                            <ButtonGroupRow className={styles.bottomGap}>
                                <ButtonLink
                                    label={t('codeListList.requestCreate.addNote')}
                                    type="button"
                                    onClick={() => {
                                        setNotes([...notes, { id: notes.length, text: '' }])
                                    }}
                                />
                            </ButtonGroupRow>
                            <TextHeading size="L">{t('codeListList.requestCreate.contactTitle')}</TextHeading>
                            <GridRow>
                                <GridCol setWidth="one-half">
                                    <Input
                                        required
                                        disabled={!canEdit}
                                        label={getDescription('Gui_Profil_ZC_meno', language, attributeProfile)}
                                        info={getName('Gui_Profil_ZC_meno', language, attributeProfile)}
                                        id={RequestFormEnum.NAME}
                                        {...register(RequestFormEnum.NAME)}
                                        error={formState.errors[RequestFormEnum.NAME]?.message}
                                    />
                                </GridCol>
                                <GridCol setWidth="one-half">
                                    <Input
                                        required
                                        disabled={!canEdit}
                                        label={getDescription('Gui_Profil_ZC_priezvisko', language, attributeProfile)}
                                        info={getName('Gui_Profil_ZC_priezvisko', language, attributeProfile)}
                                        id={RequestFormEnum.LASTNAME}
                                        {...register(RequestFormEnum.LASTNAME)}
                                        error={formState.errors[RequestFormEnum.LASTNAME]?.message}
                                    />
                                </GridCol>
                            </GridRow>
                            <GridRow>
                                <GridCol setWidth="one-half">
                                    <Input
                                        required
                                        disabled={!canEdit}
                                        label={getDescription('Gui_Profil_ZC_tel_cislo', language, attributeProfile)}
                                        info={getName('Gui_Profil_ZC_tel_cislo', language, attributeProfile)}
                                        id={RequestFormEnum.PHONE}
                                        type="tel"
                                        {...register(RequestFormEnum.PHONE)}
                                        error={formState.errors[RequestFormEnum.PHONE]?.message}
                                    />
                                </GridCol>
                                <GridCol setWidth="one-half">
                                    <Input
                                        required
                                        disabled={!canEdit}
                                        label={getDescription('Gui_Profil_ZC_email', language, attributeProfile)}
                                        info={getName('Gui_Profil_ZC_email', language, attributeProfile)}
                                        id={RequestFormEnum.EMAIL}
                                        type="email"
                                        {...register(RequestFormEnum.EMAIL)}
                                        error={formState.errors[RequestFormEnum.EMAIL]?.message}
                                    />
                                </GridCol>
                            </GridRow>
                            <TextHeading size="L">{t('codeListList.requestCreate.codeListTableTitle')}</TextHeading>
                            <MutationFeedback
                                success={isSuccessSetDates}
                                successMessage={t('codeListDetail.feedback.editCodeListItems')}
                                error={!!errorMessageSetDates}
                                errorMessage={t([errorMessageSetDates ?? '', 'feedback.mutationErrorMessage'])}
                            />
                            <ActionsOverTable
                                pagination={{ pageNumber: BASE_PAGE_NUMBER, pageSize: BASE_PAGE_SIZE, dataLength: 0 }}
                                entityName={ADD_REQUEST_ITEM}
                                createButton={
                                    canEdit && (
                                        <Can I={Actions.ADD_ITEMS} a={Subjects.DETAIL}>
                                            <Tooltip
                                                descriptionElement={t('codeListList.requestCreate.hasAutoincrementButNotPrefixOrCount')}
                                                disabled={!hasAutoincrementButNotPrefixOrCount}
                                                triggerElement={
                                                    <CreateEntityButton
                                                        onClick={() => {
                                                            setCodeListItem(undefined)
                                                            if (!hasAutoincrementButNotPrefixOrCount) {
                                                                setOpen(true)
                                                            }
                                                        }}
                                                        label={t('codeListList.requestCreate.addItemBtn')}
                                                    />
                                                }
                                            />
                                        </Can>
                                    )
                                }
                                selectedRowsCount={rowSelection.count}
                                bulkPopup={
                                    editData
                                        ? ({ selectedRowsCount }) => (
                                              <BulkPopup
                                                  checkedRowItems={selectedRowsCount}
                                                  items={() => [
                                                      <ButtonLink
                                                          inPopup
                                                          key={'setDates'}
                                                          label={t('codeListDetail.button.setDatesBulk')}
                                                          onClick={() => {
                                                              setIsSetDatesDialogOpened(true)
                                                          }}
                                                      />,
                                                  ]}
                                              />
                                          )
                                        : undefined
                                }
                                handleFilterChange={handleFilterChange}
                                pagingOptions={[...DEFAULT_PAGESIZE_OPTIONS]}
                                hiddenButtons={{ SELECT_COLUMNS: true }}
                            />
                            {formState.errors[RequestFormEnum.CODELISTS] && (
                                <div className="govuk-form-group govuk-form-group--error">
                                    <span className="govuk-error-message">{formState.errors[RequestFormEnum.CODELISTS]?.message}</span>
                                </div>
                            )}
                            <Table
                                data={paginatedCodeList ?? []}
                                expandedRowsState={expanded}
                                onExpandedChange={setExpanded}
                                columns={colDef}
                                getExpandedRow={(row: Row<IItemForm>) => {
                                    return (
                                        <RequestDetailItemsTableExpandedRow
                                            workingLanguage={workingLanguage}
                                            codelistItem={mapToCodeListDetail(
                                                workingLanguage,
                                                codeList?.find((item) => item.codeItem === row.original.codeItem),
                                            )}
                                            attributeProfile={attributeProfile}
                                        />
                                    )
                                }}
                                getRowId={(originalRow) => `${originalRow.id}`}
                                rowSelection={rowSelection.rowSelection}
                                onRowSelectionChange={rowSelection.getOnRowSelectionChange({
                                    pageNumber: pagination.pageNumber,
                                    mapper: (item) => paginatedCodeList.find((d: IItemForm) => String(d.id) === item) ?? null,
                                })}
                            />
                            <PaginatorWrapper
                                {...pagination}
                                handlePageChange={(filter) => setPagination({ ...pagination, pageNumber: filter.pageNumber ?? BASE_PAGE_NUMBER })}
                            />

                            <ButtonGroupRow>
                                <Button
                                    label={t('form.cancel')}
                                    type="reset"
                                    variant="secondary"
                                    onClick={() => {
                                        const path = requestId
                                            ? `${NavigationSubRoutes.REQUESTLIST}/${requestId}`
                                            : `${NavigationSubRoutes.REQUESTLIST}`
                                        navigate(path)
                                    }}
                                />
                                <Button
                                    label={t('codeListList.requestCreate.saveBtn')}
                                    variant="secondary"
                                    type="submit"
                                    onClick={() => setSend(false)}
                                />
                                {canEdit &&
                                    editData?.codeListState !== RequestListState.KS_ISVS_ACCEPTED &&
                                    editData?.codeListState !== RequestListState.ACCEPTED_SZZC && (
                                        <Button label={t('codeListList.requestCreate.submitBtn')} type="submit" onClick={() => setSend(true)} />
                                    )}
                            </ButtonGroupRow>
                        </form>
                        {isOpen && (
                            <ModalItem
                                isOpen={isOpen}
                                close={close}
                                onSubmit={addItem}
                                item={codeListItem}
                                createRefIdUri={
                                    getAutoIncrementWithCodelistRefId(
                                        codeList.length + 1,

                                        charCountInput ?? 0,
                                        codeList[codeList.length - 1]?.codeItem,
                                    ) || watch(RequestFormEnum.REFINDICATOR)
                                }
                                createItemCode={getAutoIncrement(
                                    codeList.length + 1,

                                    charCountInput ?? 0,
                                    codeList[codeList.length - 1]?.codeItem,
                                )}
                                attributeProfile={attributeProfile}
                                canEdit={!!canEdit}
                                isAutoincrementValid={isAutoincrementValid}
                                requestId={Number(requestId)}
                            />
                        )}
                        <DateModalItem
                            isOpen={isSetDatesDialogOpened}
                            rowSelection={rowSelection.list}
                            close={closeDate}
                            onSubmit={(dates, itemCodes) => {
                                onSaveDates?.(dates, itemCodes)
                                rowSelection.clearSelectedRows()
                                closeDate()
                            }}
                        />
                    </QueryFeedback>
                </MainContentWrapper>
            )}
        </>
    )
}

export default CreateRequestView
