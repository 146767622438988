//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * MetaIS PDF Creator
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { usePdfCreatorSwaggerClient } from '../hooks/usePdfCreatorSwaggerClient'
export interface RequestIdUi {
    requestId?: string
}

export interface ApiKrisFutureState {
    allIsvs?: boolean
    allProjects?: boolean
    isvsIds?: string[]
    projectIds?: string[]
}

export interface KontrolaVysvetlivky {
    code?: string
    description?: string
    id?: number
}

export interface KontrolaVysledky {
    id?: number
    sluzby?: string[]
}

export interface ProtokolRequest {
    kontrolaVysledky?: KontrolaVysledky[]
    kontrolaVysvetlivky?: KontrolaVysvetlivky[]
    krisUuid?: string
    protokolUuid?: string
}

export interface VysledkyKontrolRequest {
    kontrolaIdList?: number[]
    krisUuid?: string
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

/**
 * @summary getVysledkyKontrol
 */
export const useGetVysledkyKontrolHook = () => {
    const getVysledkyKontrol = usePdfCreatorSwaggerClient<KontrolaVysledky[]>()

    return useCallback(
        (vysledkyKontrolRequest: VysledkyKontrolRequest) => {
            return getVysledkyKontrol({
                url: `/protokol/get-vysledky-kontrol`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: vysledkyKontrolRequest,
            })
        },
        [getVysledkyKontrol],
    )
}

export const useGetVysledkyKontrolMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetVysledkyKontrolHook>>>,
        TError,
        { data: VysledkyKontrolRequest },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetVysledkyKontrolHook>>>, TError, { data: VysledkyKontrolRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getVysledkyKontrol = useGetVysledkyKontrolHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetVysledkyKontrolHook>>>, { data: VysledkyKontrolRequest }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return getVysledkyKontrol(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetVysledkyKontrolMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVysledkyKontrolHook>>>>
export type GetVysledkyKontrolMutationBody = VysledkyKontrolRequest
export type GetVysledkyKontrolMutationError = ApiError

/**
 * @summary getVysledkyKontrol
 */
export const useGetVysledkyKontrol = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetVysledkyKontrolHook>>>,
        TError,
        { data: VysledkyKontrolRequest },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useGetVysledkyKontrolHook>>>, TError, { data: VysledkyKontrolRequest }, TContext> => {
    const mutationOptions = useGetVysledkyKontrolMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetProtokolPdfHook = () => {
    const getProtokolPdf = usePdfCreatorSwaggerClient<string[]>()

    return useCallback(
        (protokolRequest: ProtokolRequest) => {
            return getProtokolPdf({
                url: `/protokol/get-pdf`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: protokolRequest,
            })
        },
        [getProtokolPdf],
    )
}

export const useGetProtokolPdfMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetProtokolPdfHook>>>, TError, { data: ProtokolRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetProtokolPdfHook>>>, TError, { data: ProtokolRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getProtokolPdf = useGetProtokolPdfHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetProtokolPdfHook>>>, { data: ProtokolRequest }> = (props) => {
        const { data } = props ?? {}

        return getProtokolPdf(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetProtokolPdfMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProtokolPdfHook>>>>
export type GetProtokolPdfMutationBody = ProtokolRequest
export type GetProtokolPdfMutationError = ApiError

export const useGetProtokolPdf = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetProtokolPdfHook>>>, TError, { data: ProtokolRequest }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useGetProtokolPdfHook>>>, TError, { data: ProtokolRequest }, TContext> => {
    const mutationOptions = useGetProtokolPdfMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetKrisPdfHook = () => {
    const getKrisPdf = usePdfCreatorSwaggerClient<string[]>()

    return useCallback(
        (uuid: string, apiKrisFutureState: ApiKrisFutureState) => {
            return getKrisPdf({ url: `/kris/${uuid}`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: apiKrisFutureState })
        },
        [getKrisPdf],
    )
}

export const useGetKrisPdfMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetKrisPdfHook>>>,
        TError,
        { uuid: string; data: ApiKrisFutureState },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetKrisPdfHook>>>, TError, { uuid: string; data: ApiKrisFutureState }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getKrisPdf = useGetKrisPdfHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetKrisPdfHook>>>, { uuid: string; data: ApiKrisFutureState }> = (
        props,
    ) => {
        const { uuid, data } = props ?? {}

        return getKrisPdf(uuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetKrisPdfMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetKrisPdfHook>>>>
export type GetKrisPdfMutationBody = ApiKrisFutureState
export type GetKrisPdfMutationError = ApiError

export const useGetKrisPdf = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetKrisPdfHook>>>,
        TError,
        { uuid: string; data: ApiKrisFutureState },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useGetKrisPdfHook>>>, TError, { uuid: string; data: ApiKrisFutureState }, TContext> => {
    const mutationOptions = useGetKrisPdfMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetKrisFuturePdfHook = () => {
    const getKrisFuturePdf = usePdfCreatorSwaggerClient<string[]>()

    return useCallback(
        (uuid: string, apiKrisFutureState: ApiKrisFutureState) => {
            return getKrisFuturePdf({
                url: `/kris/${uuid}/future`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiKrisFutureState,
            })
        },
        [getKrisFuturePdf],
    )
}

export const useGetKrisFuturePdfMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetKrisFuturePdfHook>>>,
        TError,
        { uuid: string; data: ApiKrisFutureState },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetKrisFuturePdfHook>>>,
    TError,
    { uuid: string; data: ApiKrisFutureState },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const getKrisFuturePdf = useGetKrisFuturePdfHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useGetKrisFuturePdfHook>>>,
        { uuid: string; data: ApiKrisFutureState }
    > = (props) => {
        const { uuid, data } = props ?? {}

        return getKrisFuturePdf(uuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetKrisFuturePdfMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetKrisFuturePdfHook>>>>
export type GetKrisFuturePdfMutationBody = ApiKrisFutureState
export type GetKrisFuturePdfMutationError = ApiError

export const useGetKrisFuturePdf = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetKrisFuturePdfHook>>>,
        TError,
        { uuid: string; data: ApiKrisFutureState },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useGetKrisFuturePdfHook>>>,
    TError,
    { uuid: string; data: ApiKrisFutureState },
    TContext
> => {
    const mutationOptions = useGetKrisFuturePdfMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetKontrolyHook = () => {
    const getKontroly = usePdfCreatorSwaggerClient<KontrolaVysvetlivky[]>()

    return useCallback(
        (entityType: string, signal?: AbortSignal) => {
            return getKontroly({ url: `/protokol/get-kontroly/${entityType}`, method: 'GET', signal })
        },
        [getKontroly],
    )
}

export const getGetKontrolyQueryKey = (entityType: string) => {
    return [`/protokol/get-kontroly/${entityType}`] as const
}

export const useGetKontrolyQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetKontrolyHook>>>, TError = ApiError>(
    entityType: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKontrolyHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetKontrolyQueryKey(entityType)

    const getKontroly = useGetKontrolyHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetKontrolyHook>>>> = ({ signal }) => getKontroly(entityType, signal)

    return { queryKey, queryFn, enabled: !!entityType, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetKontrolyHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetKontrolyQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetKontrolyHook>>>>
export type GetKontrolyQueryError = ApiError

export function useGetKontroly<TData = Awaited<ReturnType<ReturnType<typeof useGetKontrolyHook>>>, TError = ApiError>(
    entityType: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKontrolyHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetKontrolyQueryOptions(entityType, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetIsvsCountHook = () => {
    const getIsvsCount = usePdfCreatorSwaggerClient<number>()

    return useCallback(
        (uuid: string, signal?: AbortSignal) => {
            return getIsvsCount({ url: `/kris/isvs-count/${uuid}`, method: 'GET', signal })
        },
        [getIsvsCount],
    )
}

export const getGetIsvsCountQueryKey = (uuid: string) => {
    return [`/kris/isvs-count/${uuid}`] as const
}

export const useGetIsvsCountQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetIsvsCountHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIsvsCountHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetIsvsCountQueryKey(uuid)

    const getIsvsCount = useGetIsvsCountHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetIsvsCountHook>>>> = ({ signal }) => getIsvsCount(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetIsvsCountHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetIsvsCountQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetIsvsCountHook>>>>
export type GetIsvsCountQueryError = ApiError

export function useGetIsvsCount<TData = Awaited<ReturnType<ReturnType<typeof useGetIsvsCountHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIsvsCountHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetIsvsCountQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetKrisPdfAsyncHook = () => {
    const getKrisPdfAsync = usePdfCreatorSwaggerClient<RequestIdUi>()

    return useCallback(
        (uuid: string, signal?: AbortSignal) => {
            return getKrisPdfAsync({ url: `/kris/async/${uuid}`, method: 'GET', signal })
        },
        [getKrisPdfAsync],
    )
}

export const getGetKrisPdfAsyncQueryKey = (uuid: string) => {
    return [`/kris/async/${uuid}`] as const
}

export const useGetKrisPdfAsyncQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetKrisPdfAsyncHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKrisPdfAsyncHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetKrisPdfAsyncQueryKey(uuid)

    const getKrisPdfAsync = useGetKrisPdfAsyncHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetKrisPdfAsyncHook>>>> = ({ signal }) => getKrisPdfAsync(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetKrisPdfAsyncHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetKrisPdfAsyncQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetKrisPdfAsyncHook>>>>
export type GetKrisPdfAsyncQueryError = ApiError

export function useGetKrisPdfAsync<TData = Awaited<ReturnType<ReturnType<typeof useGetKrisPdfAsyncHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKrisPdfAsyncHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetKrisPdfAsyncQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
